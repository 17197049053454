<template>
    <div :ref="`slide-sm-${id}${index}`"
        class="x br-5 f aic jcc p-relative"
        style="height: 0px; padding-bottom: 60%">
        <div class="row no-gutters f aic jcc p-absolute p-fill m-0 p-0">
            <div :ref="`text-sm-${id}${index}`" :style="`color:${color}`" class="col-6 p-2 pl-2">
                <p v-if="slide.title"
                    class="d-block f-11 small fwb line-height-smaller mb-0 mb-1"
                    >{{ slide.title }}</p
                >
                <p v-if="slide.enhancedBody"
                    class="d-block o-5 line-height-smaller mb-0"
                    style="font-size: 6px !important"
                    >{{ slide.enhancedBody.slice(0, 150) }}</p
                >
            </div>
            <div :ref="`image-sm-${id}${index}`" class="col-6 y">
                <img v-if="slide.imageURL" :src="slide.imageURL" class="x y o-cover" />
                <div v-else class="x y animated loading-pulse o-2"></div>
            </div>
        </div>
    </div>
</template>
<script>
import SlideStyleMixin from '@/mixins/slideStyleMixin';
import themeMixins from '@/mixins/themeMixins';

export default {
    name: 'SlideRightMini',
    mixins: [SlideStyleMixin],
    props: {
        id: {},
        index: {},
        slide: {},
        document: {},
    },
    computed: {
        color() {
            let theme =
                this.document.theme &&
                this.document.theme.colors &&
                this.document.theme.colors.primary;
            if (theme) {
                return this.complimentaryColor(this.document.theme.colors.primary);
            }
            return '';
        },
    },
    mounted() {
        if (this.document.theme) {
            let t = this.document.theme.colors || false;
            if (t) this.textTheme();
        }
    },
};
</script>
