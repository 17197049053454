<script>
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";
import SectionHeader from "@/components/elements/SectionHeader.vue";
import ButtonIcon from "@/components/buttons/ButtonIcon.vue";
// import lodash from 'lodash';
import _ from 'lodash';

export default {
    components: {ButtonIcon, SectionHeader, ButtonPrimary, ButtonSecondary},
    props: ['item','parentObject'],
    mounted() {
        this.formText = this.item.lyrics;
    },
    data() {
        return {
            debug: false,
            editing: false,
            formText: "",
            showEditButtons: false
        };
    },
    watch: {
    },
    methods: {

        updateItem(id, edit) {
            let obj = this.bigObject;
            // obj.html = this.bigObject.html;
            // obj.answer = this.item;
            // obj.promptData.html = this.item;
            // console.error(object)
            // this.updateObject('feedItem', ['id',obj],);
            this.updateObject('feedItem', ['id', `${this.bigObject.id}`], obj);
        },
        editText() {
            this.editing = !this.editing;


            setTimeout(() => {
                this.$nextTick(() => {
                    const textarea = this.$refs.texta;
                    this.adjustTextareaHeight({target: textarea});
                });

            }, 300);

        },
        adjustTextareaHeight(event) {
            // console.error(event);
            const texta = event.target;
            // setTimeout(() => {
            texta.style.height = 'auto';
            texta.style.height = texta.scrollHeight+'px';
        },
        mouseOver() {
            this.showEditButtons = true;
        },
        mouseOut() {
            if(this.editing){
                this.updateItem();
            }
            this.showEditButtons = false;
        },
        debouncedShowChild: _.debounce(function () {
            this.mouseOver();
        }, 100),
        debouncedHideChild: _.debounce(function () {
            this.editing = false;
            this.mouseOut();
        }, 100)
    },
    computed: {
        bigObject(){
            if(this.userFeed){
                let feedObject = this.parentObject;
                // let feedObject = this.userFeed.find(feedItem => feedItem.html === this.item.html);
                console.error(feedObject.id);
                console.error(feedObject)
                // console.error(this.userFeed.find(feedItem => feedItem.html === this.item.html));
                return feedObject;
                // return this.userFeed.find(feedItem => feedItem.html === this.item.html);
            } else {
                console.error(this.userFeed.find(feedItem => feedItem.id === this.item.id))
                return this.userFeed.find(feedItem => feedItem.id === this.item.id);
            }
        }
    },
};
</script>
<template>
    <div v-if="item" class="" @mouseleave="debouncedHideChild()" @mouseover="debouncedShowChild()">
        <div :class="{'bg-white shadow-xs':editing,'border-light':!editing,}" class="mb-3  hover:bg-bubble-100/50 br-10 transition-all overflow-hidden hover:shadow-xs p-relative">
            <div id="lyricsContainer" class="p-relative ">
                <div class="x y p-relative">
                    <p class=" text-capitalize p-0 f aic jcs px-3 px-md-3 pt-3 p-1 mb-0">
                        <span class="fwb text-uppercase f-15 mr-2"> {{ item.section }}</span>
                        <span class="text-monospace f-13 o-6">{{ item.chords }}</span>
                    </p>
                    <div class="height-min-150">
                        <textarea v-if="editing" ref="texta" v-model="item.lyrics" class="line-height-small px-3 m-0 overflow-visible z-3 ease-back text-base-700 text-monospace d-block x f-17"
                                  style=" border:none!important;background:none!important;" @input="adjustTextareaHeight"></textarea>
                        <div v-else class="text-monospace text-base-700 cursor-pointer">
                            <p class="d-block p-1 px-3 f-15  highlight" @click.prevent="editText()">{{ item.lyrics }}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div v-if="showEditButtons" id="lyricTextTools" class="animated fadeInUp ease-back p-1 px-2 f aic jce gap-5 py-0 p-absolute bottom right o-0  hover:opacity-100">
                <!--                Edit-->
                <button-icon icon="pencil" @click.prevent="editText()"></button-icon>
                <button-primary v-if="!editing" :form="true" class="py-0 px-0 br-0" text="Edit" @click.prevent="editText()"></button-primary>
                <button-primary v-else :form="true" class="py-0 px-0 br-0" text="Save" @click.prevent="updateItem()"></button-primary>
            </div>
        </div>
<!--{{bigObject}}-->
    </div>
</template>
<style lang="scss">

</style>
