<template>
    <div class="col-xl-4 p-relative" style="max-width: 500px">
        <div class="p-relative" @mouseover="mouseActive = true" @mouseleave="mouseActive = false">
            <div
                ref="container"
                class="p-relative br-10 overflow-hidden bg-base-400"
                style="height: 0px; padding-bottom: 100% !important">
                <transition appear enter-active-class="animated fadeIn ease-back" leave-active-class="animated fadeOut">
                    <img :src="image.image" v-show="isLoaded" class="x p-absolute p-fill" @load="onImgLoad" />
                </transition>
                <div class="p-absolute gradientBottom bottom left right" style="height: 250px"></div>
                <transition enter-active-class="animated fadeIn" leave-active-class="animated fadeOut" appear>
                    <div v-if="mouseActive">
                        <div class="p-absolute p-fill blackBackground o-7 backdrop-blur-3xl"></div>
                    </div>
                </transition>
                <div
                    v-if="isLoaded"
                    class="p-absolute p-fill f white z-5 p-3 transition-all duration-300 ease-in-out"
                    :class="{ ais: mouseActive, aie: !mouseActive }"
                    :style="`transform:translateY(${imageHeight}px)`">
                    <transition
                        enter-active-class="animated d-5 swingInUp"
                        leave-active-class="animated fadeOut"
                        appear>
                        <div
                            v-show="image.prompt"
                            :class="`o-${imageOpacity}`"
                            class="scrollSection y scrollbar-hide"
                            :style="`height:${containerHeight}px;max-height:${containerHeight}px;overflow-y:scroll;overflow-x:hidden;`">
                            <p v-if="image.angle" class="text-capitalize d-block fwb f-17 line-height-small mb-0">
                                {{ image.subject }}
                            </p>
                            <div class="f f-13 my-1 gap-2 whitespace-nowrap">
                                <p v-if="image.angle" class="d-block fwb line-height-small mb-0">{{ image.angle }}</p>
                                <p v-if="image.angle" class="d-block fwb line-height-small mb-0">{{ image.shot }}</p>
                            </div>
                            <p v-if="image.prompt" ref="prompt" class="d-block f-13 o-5 pb-3">{{ image.prompt }}</p>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "GeneratedImage",
    props: {
        image: {},
    },
    data() {
        return {
            isLoaded: false,
            height: 0,
            imageOpacity: 0,
            mouseActive: false,
            containerHeight: 0,
        };
    },
    methods: {
        async onImgLoad() {
            this.isLoaded = true;
            setTimeout(() => {
                if (this.$refs.prompt && this.isLoaded) {
                    this.height = parseInt(this.$refs.prompt.clientHeight, 0);
                    this.containerHeight = parseInt(this.$refs.container.clientHeight, 0);
                    // console.error(this.containerHeight);
                    this.imageOpacity = 100;
                }
            }, 100);
        },
    },
    computed: {
        imageHeight() {
            if (!this.mouseActive) {
                return parseInt(this.height, 10);
            } else {
                return 0;
            }
        },
    },
    mounted() {},
};
</script>
