import { ref as storageRef } from "firebase/storage";
import { useFirebaseStorage, useStorageFile } from "vuefire";
import JSON5 from "json5";

// gs://cleanmind/

export const presStore = {
    state() {
        return {};
    },
    mutations: {},
};
export default {
    created: function () {},
    data: () => ({}),
    computed: {
        extractPresentationText() {
            if (this.document && this.document.sections) {
                let text = "";
                let objects = this.document.sections;
                for (let i = 0; i < objects.length; i++) {
                    const slideIndex = i + 1;
                    text += "Slide " + slideIndex + ":\n";
                    text += "Title: " + objects[i].title + "\n";
                    if (objects[i].enhancedBody) {
                        text += "Body: " + objects[i].enhancedBody + "\n";
                    }
                    if (objects[i].body) {
                        text += "Body: " + objects[i].body + "\n";
                    }
                    text += "\n";
                }
                return text;
            }
            return "";
        },
    },

    methods: {
        setDocuments(documents) {
            this.$store.commit("setDocuments", documents);
        },

        // addSectionBelow(index, payload) {
        //     console.error(payload.kind);
        //     let section = payload;
        //     const indexBelow = index + 1;
        //     section.index = indexBelow;
        //     console.error("New section: Below");
        //     // console.error(index, section);
        //     this.$store.commit("addSectionBelow", section);
        //     this.setSectionIndex(indexBelow);
        // },
        // addSectionAbove(index, payload) {
        //     let section = payload;
        //     const indexAbove = index - 1;
        //     console.error("New section: above");
        //     console.error(index, section);
        //     this.$store.commit("addSectionAbove", payload);
        //     this.setSectionIndex(indexAbove);
        // },
        // addSectionAtEnd(index, payload) {
        //     let section = payload;
        //     const indexBelow = this.document.sections.length + 1;
        //     section.index = indexBelow;
        //     console.error("New section: end");
        //     this.$store.commit("addSectionAtEnd", payload);
        // },
        setSectionIndex(index, scroll) {
            this.$store.commit("setSectionIndex", index);
            if (scroll) {
                this.$nextTick(() => {
                    this.scrollToSlide(index);
                });
            }
        },
        scrollToSlide(index) {
            console.error(index);
            const ref = `slide${index}`;
            console.error(ref);
            const element = document.getElementById(ref);
            console.error(element);
            element.scrollIntoView({ behavior: "auto", block: "center" });
        },
        async serpImage(queryText) {
            const API_KEY = import.meta.env.VITE_SERPAPI_API;
            const query = queryText;
            const tbm = "isch";
            const ijn = "0";
            const url = `https://serpapi.com/search.json?q=${query}&tbm=${tbm}&ijn=${ijn}`;

            const results = fetch(url, {
                headers: {
                    Authorization: `Bearer ${API_KEY}`,
                },
            })
                .then(response => response.json())
                .then(data => console.log(data["images_results"]))
                .catch(error => console.error(error));
            this.serpResults = results;
        },
        async startNewDeck(prompt, kind) {
            this.newDeckStarted = true;
            console.error("saving new deck");
            const tempId = this.randomId();
            const timestamp = Date.now();
            let doc = {
                title: "New presentation",
                theme: {
                    colors: null,
                    shades: null,
                    why: null,
                },
                id: null,
                created: timestamp,
                updated: timestamp,
                documentType: "presentation",
                sections: [
                    {
                        title: "New slide",
                        imageURL: null,
                        body: "new slide",
                        id: tempId,
                        created: timestamp,
                        updated: timestamp,
                    },
                ],
            };
            const networkDoc = await this.addToFirebase("documents", doc);
            this.$router.push({ path: `/presentations/${networkDoc.id}` });
            console.error(networkDoc);
            this.$forceUpdate();
        },
        async enhanceColors(text, index) {
            this.document.theme = {};
            const request = {
                model: "text-davinci-003",
                prompt: `${text}\n
Generate a vibrant HSL style sheet based on colors inspired directly by the subject above. 
Pick a primary and secondary color. 
Incorporate vibrant saturated complimentary colors. Avoid black. Avoid Gray. 
What about the subject inspired our color choices?
Example format:
{ colors: [ //hsl values
'primaryColor', 
'secondaryColor'], 
whyTheseColors: "" //20 words max
}
### Answer:
`,
                temperature: 0.7,
                max_tokens: 688,
                top_p: 1,
                frequency_penalty: 0,
                presence_penalty: 0,
            };
            console.error(request.prompt);
            try {
                let colors;
                const response = await this.requestFromOpenAI(request, `Enhance Colors`);
                // let fixedResponse = response.replace(/^Body: /, '');

                try {
                    console.log(request.prompt);
                    colors = JSON5.parse(response);
                    // console.error(response);
                    let updatedColors;
                    if (colors.colors[0] && colors.colors[1]) {
                        let primaryColor = this.colorValueHelper(colors.colors[0], "light");
                        let primaryDark = this.colorValueHelper(colors.colors[0], "dark");
                        let secondaryColor = this.colorValueHelper(colors.colors[1], "light");
                        let secondaryDark = this.colorValueHelper(colors.colors[1], "dark");
                        let darks = primaryDark.concat(secondaryDark);
                        let lights = primaryColor.concat(secondaryColor);
                        let primaries = primaryColor.concat(primaryDark);
                        let secondaries = secondaryColor.concat(secondaryDark);
                        updatedColors = {
                            colors: {
                                primary: colors.colors[0],
                                secondary: colors.colors[1],
                                primaries: primaries,
                                secondaries: secondaries,
                                darks: darks,
                                lights: lights,
                            },
                            why: colors.whyTheseColors,
                        };
                        console.error(updatedColors);
                    }
                    window.colors = updatedColors;

                    // this.$store.dispatch("updateItemProperty", { id, 'theme':colors} );
                    this.$nextTick(() => {
                        this.document.theme = updatedColors;
                    });

                    await this.uDoc(this.document.id, { theme: updatedColors });

                    this.$router.push({ path: `/presentations/${this.$route.params.id}` });
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.document.theme = updatedColors;
                        }, 200);
                        this.setDocument(this.document);
                        this.$forceUpdate();
                    });

                    return;
                } catch (e) {
                    console.error(e);
                    colors = response;
                }

                // await this.saveDoc(this.document);
            } catch (e) {
                console.error(e);
                // await this.saveDoc(this.document);
            }
            // this.backgroundColor;
            return;
        },
        generatePrompt() {
            let prompt = "Create a ";
            const promptModifier = this.getRandomItem(this.promptModifiers);
            prompt += promptModifier + " ";
            if (
                promptModifier === "photo-manipulation" ||
                promptModifier === "ultrarealistic editorial photograph" ||
                promptModifier === "Award-Winning Art" ||
                promptModifier === "photorealistic" ||
                promptModifier === "4k/8k"
            ) {
                return this.generatePhotoPrompt();
            } else if (
                promptModifier === "concept art" ||
                promptModifier === "character design" ||
                promptModifier === "digital painting" ||
                promptModifier === "3D rendering" ||
                promptModifier === "trending on Artstation" ||
                promptModifier === "unreal engine"
            ) {
                return this.generateArtPrompt();
            } else {
                return this.generatePhotoPrompt();
            }
        },
        generatePhotoPrompt() {
            const shotModifier = this.getRandomItem(this.shotModifiers);
            const lightModifier = this.getRandomItem(this.lightModifiers);
            const realismModifier = this.getRandomItem(this.realismModifiers);
            const inspiration = this.getRandomItem(this.photographyInspirations);
            let string =
                "Create a photograph using a " +
                shotModifier +
                " shot with " +
                lightModifier +
                " and " +
                realismModifier +
                " and inspiration from " +
                inspiration;
            this.promptMod = string;
            return string;
        },
        generateArtPrompt() {
            const styleModifier = this.getRandomItem(this.styleModifiers);
            const realismModifier = this.getRandomItem(this.realismModifiers);
            const inspiration = this.getRandomItem(this.artInspirations);
            let string =
                "Create an image with a " +
                styleModifier +
                " style, " +
                realismModifier +
                " and inspiration from " +
                inspiration;
            this.promptMod = string;
            return string;
        },
        getRandomItem(arr) {
            return arr[Math.floor(Math.random() * arr.length)];
        },
        async enhanceBody(text, index, overview, section, doc) {
            let slideNumber = index + 1;
            const summary = this.document.summary;
            let prompt;
            if (section.style === "timeline") {
                // prompt = `Presentation overview: ${summary}\n${overview}\n\nExpand on the Body for slide ${slideNumber}. Stick to the topic of slide ${slideNumber} to keep things mutually exclusive, but feel free to lead into the following slides or make callbacks to prior slides if it provides better context:\n### \n`;
                prompt = `Presentation overview: ${summary}\n\n${overview}\n \n Return use \\n for new lines. ###\"\n\nExpand on the Body for slide ${slideNumber} by creating a detailed timeline. Stick to the topic of slide ${slideNumber} to keep things mutually exclusive, but feel free to lead into the following slides or make callbacks to prior slides if necessary.\n\nFormat: \n[{\"date\": \"Event Date 1\", \"event\": \"Event Name 1\", \"description\": \"Description of Event 1\"}]\n\n###  Timeline:\n`;
            } else if (section.style === "matrix") {
                prompt = `Presentation overview: ${summary}\n\n${overview}\n \n Return use \\n for spaces. ###\"\n\nExpand on the Body for slide ${slideNumber} by creating a detailed matrix. Stick to the topic of slide ${slideNumber} to keep things mutually exclusive, but feel free to lead into the following slides or make callbacks to prior slides if necessary.\nFormat: \n\"matrix\": {\"headers\":[\"H1\",\"H2\",\"H3\" ...],\"rows\":[{\"title\":\"R1\",\"data\":[\"A\",\"B\",\"C\" ... ]},{\"title\":\"R2\",\"data\":[\"X\",\"Y\",\"Z\" ...]}]}\n\n###  Matrix:`;
            } else if (section.style === "tablegrid") {
                prompt = `Presentation overview: ${summary}\n\n${overview}\n\n Return use \\n for new lines. ###\"\n\nExpand on the Body for slide ${slideNumber} by creating a detailed list. Stick to the topic of slide ${slideNumber} to keep things mutually exclusive, but feel free to lead into the following slides or make callbacks to prior slides if necessary.\n\nFormat: \n[{\"title\": \"Title 1\", \"subtitle\": \"Subtitle 1\", \"description\": \"Description 1\"}]\n\n###  List:`;
            } else {
                prompt = `Presentation overview: ${this.document.summary}\n${overview}\n\nExpand on the Body for slide ${slideNumber}. Stick to the topic of slide ${slideNumber} to keep things mutually exclusive, but feel free to lead into the following slides or make callbacks to prior slides if it provides better context:\n### \n`;
            }

            let request = {
                model: "text-davinci-003",
                prompt: prompt,
                temperature: 0.7,
                max_tokens: 300,
                top_p: 1,
                frequency_penalty: 0,
                presence_penalty: 0,
            };
            console.log(request.prompt);
            try {
                const response = await this.requestFromOpenAI(request, "Presentation");
                let fixedResponse = response.replace(/^Body: /, "");

                if (section.style === "matrix") {
                    this.document.sections[index].enhancedBody = this.document.sections[index].body;
                    this.document.sections[index].matrix = JSON5.parse(fixedResponse);
                } else if (section.style === "timeline") {
                    this.document.sections[index].enhancedBody = this.document.sections[index].body;
                    this.document.sections[index].timeline = JSON5.parse(fixedResponse);
                } else if (section.style === "tablegrid") {
                    this.document.sections[index].enhancedBody = this.document.sections[index].body;
                    this.document.sections[index].list = JSON5.parse(fixedResponse);
                } else {
                    this.document.sections[index].enhancedBody = fixedResponse;
                }
                this.document.id = this.$route.params.id;
                await this.saveDoc(this.document);
            } catch (e) {
                console.error(e);
                await this.saveDoc(this.document);
            }
            console.groupEnd();

            return;
        },
        async summarize(text) {
            let prompt;
            prompt = `Summarize this document in under 100 words:\n\n ${text}\n \n Return use \\n for spaces. ###`;
            if (this.document && this.document.documentType && this.document.documentType === "presentation") {
                prompt = `Describe the presentation in under 100 words and give context to what it is:\n\n${this.document.title}:\n${text}\n \n Return use \\n for spaces. ###`;
            }
            console.log(text);
            const request = {
                model: "text-davinci-003",
                prompt: prompt,
                max_tokens: 500,
                temperature: 1,
                top_p: 0.9,
                frequency_penalty: 0.5,
                presence_penalty: 0,
                best_of: 1,
            };
            const response = await this.requestFromOpenAI(request, "Testing this");
            // this.document.summary = response;
            if (!this.document.id) {
                console.error("has no id, setting from route");
                this.document.id = this.$route.params.id;
            }
            await this.uDoc(this.document.id, { summary: response });
            return;
        },
        addSlideBelow() {
            //add section below current section in documents.section (slice array)
            this.document.sections.splice(this.index + 1, 0, {
                title: "New slide",
                body: "Add some text",
                style: "left",
            });
            this.saveDoc(this.document);
        },
        removeSlide(index) {
            //add section below current section in documents.section (slice array)
            let spliceIndex;
            if (this.index) {
                spliceIndex = this.index;
            } else if (index) {
                spliceIndex = 0;
            }
            this.document.sections.splice(this.index, 1);
            this.saveDoc(this.document);
            // this.saveDoc(this.document);
        },
        async enhancePresentation() {
            const sections = this.document.sections;
            const document = this.document;
            const promptStart = `${this.extractPresentationText}`;
            if (!this.document.summary) {
                console.log(promptStart);
                await this.summarize(this.extractPresentationText).then(async summary => {
                    for (const [index, section] of sections.entries()) {
                        if (section.body && !section.enhancedBody) {
                            const text = section.body;
                            this.enhanceBody(text, index, promptStart, section, document);
                        }
                        if (section.image && !section.enhancedImage && !section.imageURL) {
                            const text = section.image;
                            this.enhancedImagePrompt(text, index, promptStart, document);
                        }
                    }
                });
            } else {
                console.log(promptStart);
                for (const [index, section] of sections.entries()) {
                    if (document.summary) {
                        if (section.body && !section.enhancedBody) {
                            const text = section.body;
                            this.enhanceBody(text, index, promptStart, section, document);
                        }
                        if (section.image && !section.enhancedImage && !section.imageURL) {
                            const text = section.image;
                            this.enhancedImagePrompt(text, index, promptStart, document);
                        }
                    }
                }
            }
            this.pushAlert({ message: "Done!", type: "success" });
            return;
        },
        styleFixer(word) {
            let slideStyle = word.toLowerCase();
            if (slideStyle.includes("right") || slideStyle.includes("imageRight") || slideStyle.includes("rSlide")) {
                return "right";
            } else if (slideStyle.includes("left")) {
                return "left";
            } else if (slideStyle.includes("matrix")) {
                return "matrix";
            } else if (slideStyle.includes("timeline")) {
                return "timeline";
            } else if (slideStyle.includes("tablegrid") || slideStyle.includes("list")) {
                return "tablegrid";
            } else if (
                slideStyle.includes("fullbleed") ||
                slideStyle.includes("full bleed") ||
                slideStyle.includes("fullBleedImage")
            ) {
                return "fullBleed";
            }
        },
        async requestPresentationFromOpenAI(subject) {
            this.pushAlert({ message: "Sending request", type: "success" });
            const slideCount = 15;
            // let prompt = `Write a ${slideCount} slide presentation about ${subject} in this format: Titles should be 2-5 words, the body (optional) should be informative but under 50 words. The image should be descriptive of the containing image. Style is about the type of slide ( Image Left, List, Image Right, Full Bleed Image, Timeline, Matrix, etc). Include a cover, an intro, info slides, and a conclusion. In the style of Steve Jobs
            //
            // Slide 1
            // Title:
            // Body:
            // Image: This is an image of ...
            // Style:
            //
            // ###\n\n
            // `;
            //             let prompt = `Write a ${slideCount} section outline for a ${subject} website: Titles should be 2-5 words, the body (optional) should be informative but under 50 words. The image should be descriptive of the containing image. Style is about the type of slide ( imageLeft, imageRight, fullBleedImage, timeline, matrix, etc). Include a cover, an intro, info slides, and a conclusion. In the style of Steve Jobs
            //
            // Slide 1
            // Title:
            // Body:
            // Image: This is an image of ...
            // Style:
            //
            // ###\n\n
            // `;
            let prompt = `Write a ${slideCount} slide presentation about ${subject} in this format: Titles should be 2-5 words, the body (optional) should be informative but under 50 words. The image should be descriptive of the containing image. Style is about the type of slide ( imageLeft, imageRight, fullBleedImage, timeline, matrix, etc). Include a cover, an intro, info slides, and a conclusion. In the style of Steve Jobs

Slide 1
Title:
Body:
Image: This is [an Image, a Drawing, or a Painting] of ...
Style:

###\n\n
`;

            const request = {
                model: "text-davinci-003",
                prompt: prompt,
                temperature: 0.8,
                max_tokens: 3000,
                top_p: 1,
                frequency_penalty: 0,
                presence_penalty: 0,
            };
            const response = await this.requestFromOpenAI(request, "Presentation");
            console.error(response);

            this.pushAlert({ message: "Outline generated", type: "success" });
            let rawData = response.replace(/Slide \d+\n/g, "");
            let lines = rawData.split("\n");
            let sections = [];
            let slide = {};
            const self = this;
            for (let line of lines) {
                if (line.startsWith("Title")) {
                    slide.title = line.split(":")[1].trim();
                } else if (line.startsWith("Body")) {
                    slide.body = line.split(":")[1].trim();
                } else if (line.startsWith("Image")) {
                    slide.image = line.split(":")[1].trim();
                } else if (line.startsWith("Style")) {
                    slide.style = line.split(":")[1].trim();
                    slide.style = self.styleFixer(slide.style);
                } else {
                    if (Object.keys(slide).length === 4) {
                        slide.id = this.randomId();
                        slide.documentId = this.$route.params.id;
                        sections.push(slide);
                        slide = {};
                    }
                }
            }
            if (Object.keys(slide).length === 4) {
                sections.push(slide);
            }
            // console.error(sections);
            let currentDocId = this.$route.params.id;
            this.document.title = subject;
            this.document.id = this.$route.params.id;
            this.document.sections = sections;
            this.document.documentType = "presentation";
            this.document.raw = response;
            this.document.id = currentDocId;
            this.$router.push({ path: `/presentations/${currentDocId}` });
            await this.uDoc(currentDocId, this.document);
            console.error(this.extractPresentationText);
            console.error("summarize");
            await this.summarize(this.extractPresentationText);
            console.error("got summary");
            this.setDocument(this.document);
            console.error("enhance presentation");
            await this.saveDoc(this.document);
            console.error("set theme");
            this.document.theme = this.enhanceColors(this.extractPresentationText);
            await this.enhancePresentation();
            // await this.enhancePresentation();

            return sections;
        },
    },
};
