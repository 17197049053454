<template>
    <div :key="document.id + `${randomId}`"
        :style="`background:${colorDarker}`"
        class="x br-10 p-lg-3 m-3 my-3 mb-5 bg-base-100 p-3">
        <div :style="`color:${colorLightest}`" class="py-0">
            <p class="fwb mb-0 f-15">Title</p>
            <contenteditable
                v-if="document.title"
                ref="contenteditable"
                v-model="document.title"
                :contenteditable="true"
                :no-html="true"
                :no-nl="false"
                class="o-7 stagger-2 x d-block mb-0 whitespace-pre-line  f-17"
                tag="p"
                @blur="saveSection()"></contenteditable>
            <p class="fwb f-15 mb-0 mt-2">Summary</p>
            <contenteditable
                v-if="document.summary"
                ref="contenteditable"
                v-model="document.summary"
                :contenteditable="true"
                :no-html="true"
                :no-nl="true"
                class="o-7 stagger-2 x d-block mb-0 whitespace-pre-line f-13 f-md-17"
                tag="p"
                @blur="saveSection()"></contenteditable>
            <p v-else class="o-7">There isn't a summary here yet!</p>
        </div>
    </div>
</template>
<script>
import contenteditable from 'vue-contenteditable';

export default {
    name: 'DocumentSummary',
    components: { contenteditable },
    props: {
        document: {},
        showSummary: {},
    },
    methods: {
        async saveSection() {
            await this.uDoc(this.$route.params.id, { summary: this.document.summary, title: this.document.title });
            return;
        },
    },
};
</script>
