<script setup>
import lodash from 'lodash';

import ScrollBadge from '@/components/marketing/scrollSections/ScrollBadge.vue';
</script>
<script>
var controller = new ScrollMagic.Controller();

export default {
    data: () => ({
        imageLink: 'https://cdn.shopify.com/s/files/1/1864/2187/files/lindsaybrush-00.png',
        frameCount: 3,
        current: { img: 0 },
        textHeight: `height:50vh`,
        scene1: null,
        scene2: null,
        scene3: null,
        scene4: null,
        timeout1: null,
    }),
    computed: {
        slideHeight() {
            return `height:${this.frameCount * 30}vh`;
        },
        screenHeight() {
            return $(window).height();
        },
    },
    mounted() {
        this.loadHowToScroll();
        this.initScroll1();
        window.onresize = lodash.debounce(evt => {
            const rect = this.$refs.roadmappin.getBoundingClientRect();
            this.textHeight = `height:${rect.width}px`;
            this.scene.duration(rect.width * 2);
        }, 1000);
    },
    beforeUnmount() {
        if (window.howToScene) {
            window.howToScene.destroy(true);
            window.howToScene = null;
        }

        this.destroyScroll();
    },
    methods: {
        loadHowToScroll() {
            if (window.howToScene) {
                window.howToScene.destroy(true);
                window.howToScene = null;
            }
            const howtween = new TimelineMax().add([
                TweenMax.staggerFromTo(
                    '.howtostep',
                    2,
                    {
                        bottom: '-50',
                        opacity: '0',
                    },
                    {
                        opacity: '1',
                        bottom: 0,
                        ease: Back.easeOut,
                    },
                    0.35,
                ),
            ]);
            // build scene
            window.howToScene = new ScrollMagic.Scene({
                triggerElement: '#howto',
                triggerHook: 0.7,
                duration: $('#howto').height(),
            })
                .setTween(howtween)
                .addTo(controller);
        },

        destroyScroll() {
            this.scene.destroy();
            this.scene2.destroy();
            this.scene3.destroy();
            this.scene4.destroy();
        },
        initScroll1() {
            const self = this;

            const roadmapTween = new TimelineMax().add([
                TweenMax.fromTo(
                    [this.$refs.shipment1],
                    1,
                    {
                        opacity: 1,
                    },
                    {
                        opacity: 1,
                        ease: Linear.easeOut,
                    },
                ),
            ]);

            const rect = this.$refs.roadmappin.getBoundingClientRect();
            // build scene
            this.textHeight = `height:${rect.width}px`;
            this.scene = new ScrollMagic.Scene({
                triggerElement: this.$refs.trigger,
                offset: -20,
                triggerHook: 'onLeave',
                duration: rect.width * 2,
            })
                .setPin(this.$refs.roadmappin)
                .setTween(roadmapTween)
                .addTo(controller);

            this.scene2 = new ScrollMagic.Scene({
                triggerElement: this.$refs.shipment1,
                triggerHook: 'onCenter',
            })
                .setClassToggle(this.$refs.image1, 'o-100')
                .addTo(controller);

            this.scene3 = new ScrollMagic.Scene({
                triggerElement: this.$refs.shipment2,
                triggerHook: 'onCenter',
            })
                .setClassToggle(this.$refs.image2, 'o-100')
                .addTo(controller);

            this.scene4 = new ScrollMagic.Scene({
                triggerElement: this.$refs.shipment3,
                triggerHook: 'onCenter',
            })
                .setClassToggle(this.$refs.image3, 'o-100')
                .addTo(controller);
        },
    },
};
</script>
<template>
    <div class="p-relative x d-block" style="z-index: 0">
        <div ref="scrollContainerWrapper" class="d-block select-off p-relative">
            <div ref="trigger" class="row p-relative no-gutters mt-nav">
                <div class="p-relative col-7 col-lg-6 py-7 pl-5 text-center">
                    <div class="p-relative">
                        <div ref="roadmappin" class="d-block p-relative">
                            <div class="p-absolute p-fill ease-out-cubic rounded transition">
                                <div class="p-relative z-1 ratio-100 overflow-hidden">
                                    <div class="p-absolute p-fill">
                                        <img ref="image1"
                                            class="x y o-cover o-center d-block select-off"
                                            src="https://res.cloudinary.com/bitess/image/upload/v1649123145/samples/7H17A1272hLmKkPhW-dLb-HpxC0fSAFL1is3X4UZYeE.jpg"
                                            style="transition-duration: 0.25s" />
                                    </div>
                                </div>
                            </div>
                            <div class="p-absolute p-fill ease-out-cubic rounded transition">
                                <div class="p-relative z-1 ratio-100 overflow-hidden">
                                    <div class="p-absolute p-fill">
                                        <img ref="image2"
                                            class="x y o-cover o-center o-0 d-block select-off"
                                            src="https://res.cloudinary.com/bitess/image/upload/v1649123193/samples/Screen_Shot_2022-04-04_at_6.38.39_PM.jpg"
                                            style="transition-duration: 0.25s" />
                                    </div>
                                </div>
                            </div>
                            <div class="p-absolute p-fill ease-out-cubic rounded transition">
                                <div class="p-relative z-1 ratio-100 overflow-hidden">
                                    <div class="p-absolute p-fill">
                                        <img ref="image3"
                                            class="x y o-cover o-center o-0 d-block select-off"
                                            src="https://res.cloudinary.com/bitess/image/upload/v1649123206/samples/Screen_Shot_2022-04-04_at_6.38.28_PM.jpg"
                                            style="transition-duration: 0.25s" />
                                        <ScrollBadge
                                            class="p-absolute p-fill z-3 o-cover o-0 d-block"></ScrollBadge>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="howto"
                    ref="scrollContainer"
                    class="col-6 col-lg-5 z-1 f aic jcc fc py-7 text-left">
                    <div class="mw-600 how-to mx-auto">
                        <div :style="textHeight" class="f aic jcc x flex-column p-5">
                            <div ref="shipment1">
                                <div class="text-md-left text-center">
                                    <h6 class="line-height-smaller font-weight-bold d-inline-block underline-header text-md-left mb-4 text-center"
                                        style=""
                                        >June 2022</h6
                                    >
                                    <h1 class="section-header font-weight-bold line-height-smaller d-block text-md-left text-uppercase text-neon mb-3 text-center"
                                        style="line-height: 0.8em">
                                        <!--                                        <span class="howtostep">Free </span>-->
                                        <span class="howtostep">Airdrop</span>
                                    </h1>
                                    <div class="f-20 f-sm-24 d-inline-block text-md-left mx-auto">
                                        <p class="stagger-body"
                                            >10-20 different backgrounds/environments - tiered
                                            rarity</p
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :style="textHeight" class="f aic jcc x flex-column p-5">
                            <div ref="shipment2">
                                <div class="text-md-left text-center">
                                    <h6 class="line-height-smaller font-weight-bold d-inline-block underline-header text-md-left mb-4 text-center"
                                        style=""
                                        >July 2022</h6
                                    >
                                    <h1 class="section-header font-weight-bold line-height-smaller d-block text-md-left text-neon text-uppercase mb-3 text-center"
                                        style="line-height: 0.8em">
                                        <span class="howtostep">Droptop</span>
                                    </h1>
                                    <div class="f-20 f-sm-24 d-inline-block text-md-left mx-auto">
                                        <ol class="how-to-list my-4 ml-0 pl-0 text-left">
                                            <li class="howtostep p-relative pl-0">
                                                <span>
                                                    <span class="font-weight-bold">Bite </span>
                                                    down on your Bit
                                                </span>
                                            </li>
                                        </ol>
                                        <a class="d-block black ml-md-4 howtostep mt-3 mb-3 ml-0"
                                            role="button">
                                            Shop now<i class="fa fa-arrow-right small ml-2"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div :style="textHeight" class="f aic jcc x flex-column p-5">
                            <div ref="shipment3">
                                <div class="text-md-left text-center">
                                    <h6 class="line-height-smaller font-weight-bold d-inline-block underline-header text-md-left mb-4 text-center"
                                        style=""
                                        >October 2022</h6
                                    >
                                    <h1 class="section-header font-weight-bold line-height-smaller d-block text-md-left text-neon text-uppercase mb-3 text-center"
                                        style="line-height: 0.8em">
                                        <span class="howtostep">Smokin' on</span>
                                        <span class="howtostep"> cookie in the hotbox </span>
                                    </h1>
                                    <div class="f-20 f-sm-24 d-inline-block text-md-left mx-auto">
                                        <ol class="how-to-list my-4 ml-0 pl-0 text-left">
                                            <li class="howtostep p-relative pl-0">
                                                <span>
                                                    <span class="font-weight-bold">Bite </span>
                                                    down on your Bit
                                                </span>
                                            </li>
                                            <li class="howtostep p-relative pl-0">
                                                <span>
                                                    <span class="font-weight-bold">Brush </span>
                                                    with a wet toothbrush
                                                </span>
                                            </li>
                                            <li class="howtostep p-relative">
                                                <span>
                                                    <span class="font-weight-bold">Smile </span>
                                                    and watch it foam up like magic
                                                </span>
                                            </li>
                                            <li class="howtostep p-relative">
                                                <span>
                                                    <span class="font-weight-bold">Repeat </span>
                                                    twice a day, every day
                                                </span>
                                            </li>
                                        </ol>
                                        <a class="d-block black ml-md-4 howtostep mt-3 mb-3 ml-0"
                                            role="button">
                                            Shop now<i class="fa fa-arrow-right small ml-2"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
