import { collection, addDoc, doc, deleteDoc, getDoc } from "firebase/firestore";
import * as firebase from "@/firebase";
import firestore, { db } from "@/firebase";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import firebaseApp from "@/firebase";
import { ref as storageRef } from "firebase/storage";
import { useFirebaseStorage, useStorageFile } from "vuefire";

export default {
    data: () => ({
        db,
        feedItem: [],
    }),
    mounted() {
        // const storage = getStorage(firebaseApp, "gs://ashermuse.appspot.com");
        // console.error(storage);
        // this.getDBItem('feedItem', this.feedItem);
    },
    methods: {
        async addImageToFirebase() {
            // const storage = getStorage(app);
            const bucket = "gs://ashermuse.appspot.com";
            const storage = getStorage(firebaseApp, "gs://ashermuse.appspot.com");
            var file = storage.bucket(bucket).file(filename);

            request({ url: url, encoding: null }, function (err, response, buffer) {
                var stream = file.createWriteStream({
                    metadata: {
                        contentType: response.headers["content-type"],
                    },
                });

                stream.end(buffer);
            });
            // const storage = getStorage();
            const storageRef = ref(storage, "images/test.jpg");
            const response = await fetch(
                "https://cdn.shopify.com/s/files/1/1864/2187/files/22.03.22---Body-Balm-Gif---MC_Bite_BodyBalm40846-1b.gif?width=350&height=455&crop=center&quality=70",
            );
            file = await response.blob();
            await uploadBytes(storageRef, file);
        },
        async uploadImageToFirebaseStorage(file) {
            // Create a reference to the 'images' folder in Firebase Storage
            const storageRef = firebase.storage().ref("images");
            // Generate a unique file name for the image
            const fileName = `${new Date().getTime()}-${file.name}`;
            // Create a reference to the file in the 'images' folder
            const imageRef = storageRef.child(fileName);
            // Upload the file to Firebase Storage
            const snapshot = await imageRef.put(file);
            // Return the download URL of the image
            return snapshot.ref.getDownloadURL();
        },
        async getFilteredArtistsOld() {
            const genre = this.artist.genre.name;
            const artists = collection(db, "artists");
            const q = query(citiesRef, where("genre.name", "==", genre)); // this is where im having confusion.
        },
        async getArtistsByGenre(genre) {
            const genreName = genre;
            const artistsRef = db.collection("artists");
            const query = artistsRef.where("genre.name", "==", genreName);
            const snapshot = await query.get();
            // const documents = snapshot.docs.map(doc => doc.data());
            const documents = snapshot.docs.map(doc => {
                const data = doc.data();
                data.id = doc.id;
                return data;
            });
            // console.error(documents);
            let sorted = documents.sort((a, b) => {
                if (a.created > b.created) {
                    return -1;
                }
                if (a.created < b.created) {
                    return 1;
                }
                return 0;
            });

            return documents;
        },
        async updateObject(collection, query, update) {
            // find the object in the collection that matches the query
            console.error(collection, query, update);
            const doc = await db.collection("feedItem").where(query[0], "==", query[1]).get();
            console.error("doc");
            console.error(doc);
            // get the document ID of the matched object
            const docId = query[1];
            // update the object using the merge option
            const response = await db.collection(collection).doc(docId).set(update, { merge: true });
            this.getDBItem("feedItem");
            // console.error(response);
            return response;
        },
        async updateAnyObject(collection, id, update) {
            // console.error(collection, id, update);
            const doc = await db.collection(collection).where("id", "==", id).get();
            // console.error('doc');
            console.log(doc);
            // get the document ID of the matched object
            const docId = id;
            // console.error(docId);
            // update the object using the merge option
            const response = await db.collection(collection).doc(docId).set(update, { merge: true });
            const updatedDoc = await this.getDocumentById(this.$route.params.id);
            console.error(`Done updating ${updatedDoc.id}:`, updatedDoc);
            // this.getDBItem(collection,false, id);
            // console.error(response);
            return updatedDoc;
        },
        async getDocumentById(id) {
            let document = await getDoc(doc(db, "documents", id))
                .then(doc => {
                    if (doc.exists()) {
                        console.log(`Get document data from Id ${id}:`, doc.data());
                        return doc.data();
                    } else {
                        console.error("No such document!");
                    }
                })
                .catch(error => {
                    console.error("Error getting document:", error);
                });
            return document;
        },
        async uDoc(id, update) {
            const collection = "documents";
            const docId = this.$route.params.id;
            let d = await db.collection(collection).where("id", "==", docId).get();
            console.log(d);
            await db.collection(collection).doc(docId).set(update, { merge: true });
            // let newDoc = await this.getDocumentById(id)
            // console.error(newDoc);
            // this.setDocument(newDoc);
            return;
        },
        async uDocSection(id, update) {
            const collection = "documents";
            const docId = id;
            const index = update.index;
            const image = update.enhancedImage;
            let d = await db.collection(collection).where("id", "==", docId).get();
            console.log(d);
            await db
                .collection(collection)
                .doc(docId)
                .update({
                    [`sections.${index}.enhancedImage`]: image,
                });
            return;
        },
        // async updateSection(id, update, index, property, payload) {
        //     const collection = 'documents';
        //
        //     // Query for the document to update
        //     const docRef = db.collection(collection).doc(id);
        //     const doc = await docRef.get();
        //
        //     if (!doc.exists) {
        //         console.log("Document does not exist!");
        //         return;
        //     }
        //
        //     // Method to build a section
        //     // Create a new section object
        //     let section = {
        //         title: this.section.title,
        //         body: this.section.body
        //     };
        //
        //     // Add the new section to the sections collection
        //     const sectionRef = await db.collection('sections').add(section);
        //
        //     // Update the sections array field in the documents collection
        //     await docRef.update({
        //         sections: firebase.firestore.FieldValue.arrayUnion(sectionRef.id)
        //     });
        // },
        async getDBItem(collection, array, id) {
            let items = [];
            if (id) {
                let d = await getDoc(doc(db, collection, id))
                    .then(doc => {
                        if (doc.exists()) {
                            console.log("Document data:", doc.data());
                            return doc.data();
                        } else {
                            console.log("No such document!");
                        }
                    })
                    .catch(error => {
                        console.log("Error getting document:", error);
                    });
                return d;
            } else {
                firestore
                    .collection(collection)
                    .get()
                    .then(querySnapshot => {
                        querySnapshot.forEach(doc => {
                            let obj = doc.data();
                            obj.id = doc.id;
                            // console.log(obj);
                            items.push(obj);
                        });
                        items.sort(function (x, y) {
                            return y.created - x.created;
                        });
                        if (collection === "feedItem") {
                            let newItems = [];
                            items.forEach(item => {
                                // let fixedResponse = item.response;
                                try {
                                    let fixedItem = JSON.parse(item.response);
                                    item.response = fixedItem;
                                    newItems.push(item);
                                } catch (e) {
                                    newItems.push(item);
                                }
                            });
                            this.$store.commit("refeshUserFeed", newItems);
                        }
                    });
                return items;
            }
        },
        async getAnyDBItem(collection, array, sort) {
            let items = [];
            let hasTime;
            await firestore
                .collection(collection)
                .get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        let obj = doc.data();
                        obj.id = doc.id;
                        if (obj.created) {
                            hasTime = true;
                        }
                        items.push(obj);
                    });
                    if (hasTime) {
                        if (sort === "updated") {
                            items.sort(function (x, y) {
                                let xUpdated = x.updated || x.created;
                                let yUpdated = y.updated || y.created;
                                if (xUpdated && yUpdated) {
                                    return yUpdated - xUpdated;
                                }
                                return true;
                            });
                        } else {
                            items.sort(function (x, y) {
                                if (x.created && y.created) {
                                    return y.created - x.created;
                                }
                                return true;
                            });
                        }
                    }
                    if (collection === "feedItem") {
                        let newItems = [];
                        items.forEach(item => {
                            // let fixedResponse = item.response;
                            try {
                                let fixedItem = JSON.parse(item.response);
                                item.response = fixedItem;
                                newItems.push(item);
                            } catch (e) {
                                newItems.push(item);
                            }
                        });
                        this.$store.commit("refeshUserFeed", newItems);
                    }
                });
            array = items;
            return items;
        },

        async removeFromFirebase(database, item, preventReload) {
            // 'users' collection reference
            const docRef = doc(db, database, item);
            // await removeDoc(colRef, dataObj);
            deleteDoc(docRef)
                .then(() => {
                    console.log("An item has been deleted from " + database);
                })
                .catch(error => {
                    console.log(error);
                });
            return;
            console.log("Document was removed with ID:", docRef.id);
            if (preventReload) {
            } else {
                this.getDBItem(database);
            }
        },
        async addToFirebase(database, payload) {
            const created = new Date();
            const updated = new Date();
            const colRef = collection(db, database);
            const dataObj = payload;
            dataObj.created = created;
            dataObj.updated = updated;
            const self = this;
            let docRefNew = {};
            await addDoc(colRef, dataObj)
                .then(function (docRef) {
                    console.log("Document written with ID: ", docRef.id);
                    docRefNew = docRef;
                    dataObj.id = docRef.id;
                })
                .then(() => {
                    console.error("new doc added");
                    console.error(dataObj);
                });
            return dataObj;

            // self.getDBItem(database);
        },
        async pushToFirebase(item) {
            // genreRef.doc("genre").set(item)
            await this.addToFirebase(item);
        },
        // getDBItem(collection, array) {
        //     let items = []
        //     firestore.collection(collection).get().then((querySnapshot) => {
        //         querySnapshot.forEach((doc) => {
        //             let obj = doc.data();
        //             obj.id = doc.id;
        //             console.log(obj);
        //             items.push(obj);
        //         });
        //         this.$store.commit('refeshUserFeed',items);
        //     });
        //
        // },
    },
};
