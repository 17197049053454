export default {
    created: function () {},
    data: () => ({
        // artist: null,
    }),
    computed: {
        artists() {
            return this.$store.state.artists;
        },
        genres() {
            return this.$store.state.genres;
        },
        songs() {
            return this.$store.state.songs;
        },
        imageStyles() {
            return this.$store.state.imageStylesStore;
        },

        // filterDocuments() {
        //     return this.documents.filter((document) => {
        //         return document.documentType !== 'presentation';
        //     });
        // },
        // filterDocumentsByPresentation() {
        //     return this.documents.filter((document) => {
        //         return document.documentType === 'presentation';
        //     });
        // },
        // documents() {
        //     return this.$store.state.documents;
        // }
    },
    methods: {
        // async getDocuments() {
        //     let documents = this.getAnyDBItem('documents');
        //     this.$store.commit('setDocuments', documents);
        // },
        async getArtists() {
            let artists = this.getAnyDBItem('artists');
            this.$store.commit('setArtists', artists);
        },
        async imageStyleStore() {
            let imageStyle = this.getAnyDBItem('imageStyle');
            this.$store.commit('setImageStyles', imageStyle);
        },
        async getGenres() {
            let genres = this.getAnyDBItem('genre');
            this.$store.commit('setGenres', genres);
        },
        async getSongs() {
            let songs = this.getAnyDBItem('songs');
            this.$store.commit('setSongs', songs);
        },
    },
};
