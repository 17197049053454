
// import { Configuration, OpenAIApi } from "@fortaine/openai";
import { Configuration, OpenAIApi } from "openai";
// import { Configuration, OpenAIApi } from "openai";
const configuration = new Configuration({
    organization: import.meta.env.VITE_OPENAI_ORG,
    apiKey: "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",
    // apiKey: import.meta.env.VITE_OPENAI_API,
});

// const openai = new OpenAIApi(configuration);

const openai = new OpenAIApi(configuration);
window.openai = openai;
export default openai;
