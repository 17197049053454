<template>
    <div class="p-relative z-0" style="overflow: hidden; border-radius: 10px">
        <SlideLeftMini
            v-if="blockStyle === 'left'"
            :document="document"
            :block-style="blockStyle"
            :index="index"
            :slide="slide"></SlideLeftMini>
        <SlideRightMini
            v-else-if="blockStyle === 'right'"
            :document="document"
            :block-style="blockStyle"
            :index="index"
            :slide="slide"></SlideRightMini>
        <SlideFullbleedMini
            v-else-if="blockStyle === 'fullBleed'"
            :document="document"
            :block-style="blockStyle"
            :index="index"
            :slide="slide"></SlideFullbleedMini>
        <SlideOtherMini
            v-else
            :document="document"
            :block-style="blockStyle"
            :index="index"
            :slide="slide"></SlideOtherMini>
    </div>
</template>
<script>
import SlideFullbleedMini from '@/components/Presentations/slides/smallSlides/FullbleedSlideMini.vue';
import SlideLeftMini from '@/components/Presentations/slides/smallSlides/LeftSlideMini.vue';
import SlideOtherMini from '@/components/Presentations/slides/smallSlides/OtherSlideMini.vue';
import SlideRightMini from '@/components/Presentations/slides/smallSlides/RightSlideMini.vue';
import SlideStyleMixin from '@/mixins/slideStyleMixin';

export default {
    name: 'PresentationMiniSlide',
    components: { SlideOtherMini, SlideFullbleedMini, SlideRightMini, SlideLeftMini },
    mixins: [SlideStyleMixin],
    props: {
        document: {
            type: Object,
            required: true,
        },
        slide: {
            type: Object,
            required: true,
        },
        index: {},
    },
    data: () => ({
        blockStyle: null,
    }),
    mounted() {
        if (this.document.theme) {
            let t = this.document.theme.colors || false;
            if (t) this.textTheme();
            if (this.slide.style) this.blockStyle = this.slideStyle(this.slide);
        }
    },
    methods: {
        slideStyle(slide) {
            let slideStyle;
            if (this.slide && this.slide.style) {
                slideStyle = this.slide.style.toLowerCase();
            }
            if (
                slideStyle.includes('right') ||
                slideStyle.includes('imageRight') ||
                slideStyle.includes('rSlide')
            ) {
                return 'right';
            } else if (slideStyle.includes('left')) {
                return 'left';
            } else if (slideStyle.includes('matrix')) {
                return 'matrix';
            } else if (slideStyle.includes('timeline')) {
                return 'timeline';
            } else if (slideStyle.includes('tablegrid') || slideStyle.includes('list')) {
                return 'tablegrid';
            } else if (
                slideStyle.includes('fullbleed') ||
                slideStyle.includes('full bleed') ||
                slideStyle.includes('fullBleedImage')
            ) {
                return 'fullBleed';
            }
        },
    },
};
</script>
