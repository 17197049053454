<template>
    <div class="">
        <ImagePromptGenerator></ImagePromptGenerator>
    </div>
</template>

<script setup>
import ImagePromptGenerator from '@/components/imageGeneration/ImagePromptGenerator.vue';
</script>
<script>
import imageMixin from '@/mixins/imageMixin';

export default {
    components: {},
    mixins: [imageMixin],
    data() {
        return {};
    },
    computed: {},
    watch: {},
    async created() {},
    mounted() {},
    methods: {},
};
</script>
