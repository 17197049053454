<script>
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";

export default {
    components: {ButtonSecondary},
    data: () => ({}),
    props: ['text', 'small', 'icon', 'index'],
    mounted() {},
    computed: {},
};
</script>
<template>
    <div :style="`animation-delay:${index*20}ms`" class="animated fadeInUpSmooth d-inline-block" appear>
        <div :style="`animation-delay:${10 * index}ms; animation-duration:${2000+(index*50)}ms`" class="floating-item d-block p-relative">
            <button-secondary :text="text" class="m-1 f-15 f-md-24"></button-secondary>
        </div>
    </div>
</template>
