<script>
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";

export default {
    components: {ButtonPrimary, ButtonSecondary},
    props: ['list','selected'],
    mounted() {},
    data() {
        return {
            debug: false,
        };
    },
    methods: {
        filterByThis(genre) {
            // Emit an event called 'genre-selected' with the genre as the payload
            this.$emit('genre-selected', genre);
        },
        // filterArtists(genre) {
            // Emit an event called 'genre-selected' with the genre as the payload
            // this.$emit('filter-by-this', genre);
        // },
    },
    computed: {

    },
};
</script>
<template>
    <div class="col-2 scrollSection bg-base-700">
        <div class="f fc">
            <a :class="{'bg-base-800 text-base-50':genre.name === selected}" v-for="genre in list"
               class="hover:text-base-100 d-block py-2 px-3 mb-0 line-height-normal text-base-200 hover:bg-base-800"
               @click.prevent="filterByThis(genre.name)">{{genre.name}}</a>
        </div>
    </div>
</template>
<style lang="scss">
</style>
