import {db} from "@/firebase";

export default {
    data: () => ({}),

    computed: {
        artistNames() {
            let names = [];
            for (const artist of this.pageArtists) {
                names.push(artist.name);
            }
            return this.stringArray(names);
        },
        filteredArray1() {
            return this.genreArtists.filter(object => {
                return !this.pageArtists.some(artist => artist.name === object.name);
            });
        },
        filteredGenres() {
            return this.genres.filter(object => {
                if (this.genreArtists.length > 0) {
                    return !this.genreArtists.some(artist => artist.genre === object.name);
                }
                return true;
            });
        },
    },
    methods: {
        async updateGenre(genre) {
            const excludedArtists = this.artistNames;
            const desiredCount = 20;
            const currentCount = this.pageArtists.length;
            const amountNeeded = desiredCount-currentCount;
            console.error(amountNeeded);
            if (currentCount > desiredCount) {
                return;
            }
            const newArtists = await this.getArtists(genre, excludedArtists, amountNeeded);
            // newArtists.slice(0, 25);
            for (const artist of newArtists) {
                if (this.pageArtists.length >= desiredCount) {
                    console.error('DONE!!');
                    return;
                }
                const exists = this.checkName(artist);
                if (exists) {
                    let existingArtist = {"name": artist};
                    console.error('IGNORING: '+existingArtist.name);
                    existingArtist.status = 'EXISTING!';
                } else {
                    console.error('UPDATING:'+artist);
                    let updatedArtist = await this.pickArtist(artist);
                    console.error('UPDATED: '+artist);
                    console.error(updatedArtist);
                    await this.saveArtist(updatedArtist);
                    updatedArtist.status = 'NEW!';
                    this.pageArtists.push(updatedArtist);

                }
                this.$nextTick(() => {
                    this.filterArtists(genre);
                    this.$forceUpdate();
                });
                // const updatedArtist = await this.pickArtist(artist);

            }
        },
        saveArtist(artist) {
            this.addToFirebase('artists', artist);
        },
        async saveItem(collection,item,array) {
            await this.addToFirebase(collection, item);
            if(array) {
                this.$nextTick(() => {
                    this.$forceUpdate();
                    array.push(item)
                });
            }
            return array;
            // this.updateObject('genre', ['id', this.selectedGenre.id], this.selectedGenre);
        },
        async removeItem(collection, item, array) {
            let index = array.findIndex((i) => i.id === item.id);
            if (index === -1) {
                console.error('Not found in array');
                return;
            }
            await this.removeFromFirebase(collection, item.id, false);
            this.$nextTick(() => {
                this.$forceUpdate();
                array.splice(index, 1);
            });
            return array;
        },
        // async saveGenre(genre) {
        //     let newGenre = genre;
        //     let array = await this.getFilteredArtists(genre.name);
        //     let artists = [];
        //     for (const artist of array) {
        //         artists.push(artist.id);
        //     }
        //     newGenre.artists = artists;
        //
        //     console.error(genre);
        //     // this.addToFirebase('genre', genre);
        //     // this.updateObject('genre', ['id', this.selectedGenre.id], this.selectedGenre);
        // },
        async getFilteredArtists(genre) {
            const genreName = genre;
            this.selectedGenre = genre;
            const artistsRef = db.collection('artists');
            const query = artistsRef.where('genre.name', '==', genreName);
            const snapshot = await query.get();
            // const documents = snapshot.docs.map(doc => doc.data());
            const documents = snapshot.docs.map(doc => {
                const data = doc.data();
                data.id = doc.id;
                return data;
            });
            // console.error(documents);
            let sorted = documents.sort((a, b) => {
                if (a.created > b.created) {
                    return -1;
                }
                if (a.created < b.created) {
                    return 1;
                }
                return 0;
            });

            return documents;
        },
        checkName(name) {
            return this.pageArtists.find(object => object.name === name);
        },
        // checkName(name) {
        //     const levDistances = this.pageArtists.map(object => levenshteinDistance(name, object.name));
        //     const minDistance = Math.min(...levDistances);
        //     return this.pageArtists[levDistances.indexOf(minDistance)];
        // },
        // checkName(name) {
        //     const THRESHOLD = 3;
        //     const levDistances = this.pageArtists.map(object => this.levenshteinDistance(name, object.name));
        //     const minDistance = Math.min(...levDistances);
        //     return minDistance <= THRESHOLD;
        // },
        levenshteinDistance(a, b) {
            if (a.length === 0) return b.length;
            if (b.length === 0) return a.length;

            const matrix = [];

            // increment along the first column of each row
            for (let i = 0; i <= b.length; i++) {
                matrix[i] = [i];
            }

            // increment each column in the first row
            for (let j = 0; j <= a.length; j++) {
                matrix[0][j] = j;
            }

            // Fill in the rest of the matrix
            for (let i = 1; i <= b.length; i++) {
                for (let j = 1; j <= a.length; j++) {
                    if (b.charAt(i-1) === a.charAt(j-1)) {
                        matrix[i][j] = matrix[i-1][j-1];
                    } else {
                        matrix[i][j] = Math.min(matrix[i-1][j-1]+1, // substitution
                            matrix[i][j-1]+1, // insertion
                            matrix[i-1][j]+1); // deletion
                    }
                }
            }

            return matrix[b.length][a.length];
        },

        checkArray(arrayString) {
            if (arrayString.startsWith('[“')) {
                arrayString = arrayString.slice(3);
            }
            if (arrayString.endsWith(']')) {
                arrayString = arrayString.slice(0, -1);
            }
            if (arrayString.endsWith('”')) {
                arrayString = arrayString.slice(0, -1);
            }
            if (arrayString.endsWith(',')) {
                arrayString = arrayString.slice(0, -1);
            }
            arrayString = '[“'+arrayString+'”]';
            console.error(arrayString); // Output: '[“example2”]'
        },
        async filterArtists(genre) {
            this.pageArtists = await this.getFilteredArtists(genre);
        },
        async pickArtistOld(artist) {
            let newArtist = {
                "name": artist,
                "keywords": [],
                "genre": {
                    "name": artist.genre,
                    "keywords": [],
                    "id": ''
                }
            };
            // this.setArtist(artist);
            // let existing = this.checkName(artist);

            let collection = 'artists';
            if (false) {
                // this.updateAnyObject('artists',existing.id,)
                const docRef = await db.collection(collection).doc(existing.id);
                const docSnap = await getDoc(docRef);
                const dbArtist = docSnap.data();
                const existingKeywords = existing.keywords;
                let keywords = existingKeywords;
                dbArtist.keywords.forEach((keyword) => {
                    let duplicate = keywords.find(kw => kw === keyword);
                    if (!duplicate) {
                        keywords.push(keyword);
                    }
                    console.error(keyword);
                });
                let updatedArtist = await this.getArtistKeywords(artist);
                let updatedKeywords = updatedArtist.keywords;
                console.error(updatedKeywords);
                updatedKeywords.forEach((keyword) => {
                    let duplicate = keywords.find(kw => kw === keyword);
                    if (!duplicate) {
                        keywords.push(keyword);
                    }
                });

                console.error('updated Keywords');
                console.table(keywords);
                let update = {keywords: keywords};
                dbArtist.set(update, {merge: true});
                // console.error(docSnap);
                // Update the item at index 1 of the 'arrayField' array

                // docRef.update({
                //     arrayField: firebase.firestore.FieldValue.arrayUnion('newItem')
                // });
                console.error(existing);
            } else {
                // console.error(existing);
                const updatedArtist = await this.getArtistKeywords(artist.name);

                updatedArtist.genre = {
                    "name": artist.genre,
                    "keywords": [],
                    "id": null
                };
                // updatedArtist.keywords = this.checkArray(updatedArtist.keywords);


                const validArray = Array.isArray(updatedArtist.keywords);
                console.error(updatedArtist);
                // return;

                if (validArray) {
                    await this.saveArtist(updatedArtist);
                    // updatedArtist.keywords = validArray;

                    return;
                } else {

                    try {
                        // let fixKeywords = updatedArtist.keywords+'"]';
                        let fixKeywords = this.fixArray(updatedArtist.keywords);
                        console.error(fixKeywords);
                        updatedArtist.keywords = JSON.parse(fixKeywords);
                        if (Array.isArray(fixedArray)) {
                            updatedArtist.keywords = fixedArray;
                        }
                        await this.saveArtist(updatedArtist);
                    } catch (e) {
                        console.error(e);
                        return;
                    }
                }
                //
                //
                //
                // return;
                // let updatedKeywords = updatedArtist.keywords;
                // updatedKeywords.forEach((keyword) => {
                //     let duplicate = keywords.find(kw => kw === keyword);
                //     if (!duplicate) {
                //         keywords.push(keyword);
                //     }
                // });
                // newArtist.keywords = updatedKeywords;
                //
                // console.log(updatedArtist);
                // return newArtist;
            }

            // this.nextStep()
        },
        async pickArtist(artist) {
            let genre;
            if (artist.genre && artist.genre.name) {
                genre = artist.genre.name;
            } else if (artist.genre) {
                genre = artist.genre;
            } else if (this.selectedGenre) {
                genre = this.selectedGenre;
            } else {
                genre = null;
            }
            const updatedArtist = await this.getArtistKeywords(artist);
            if (genre) {
                updatedArtist.genre = {
                    "name": genre,
                    "keywords": [],
                    "id": null
                };
            } else {
                updatedArtist.genre = {
                    "name": updatedArtist.genre,
                    "keywords": [],
                    "id": null
                };
            }

            const validArray = Array.isArray(updatedArtist.keywords);
            if (validArray) {
                return updatedArtist;
            } else {
                try {
                    // let fixKeywords = updatedArtist.keywords+'"]';
                    console.error('FIXING ARRAY');
                    let fixKeywords = this.fixArray(updatedArtist.keywords);
                    updatedArtist.keywords = JSON.parse(fixKeywords);
                    if (Array.isArray(fixKeywords)) {
                        updatedArtist.keywords = fixKeywords;
                        console.error("FIXED ARRAY");
                    }
                    console.error(updatedArtist);
                    return updatedArtist;
                } catch (e) {
                    console.error('could not make it work for'+updatedArtist);
                    console.error(e);
                    return;
                }
            }

        },
        async holdOnToYourButtsOld() {
            const array = this.filteredArray1.slice(0, 25);
            for (const artist of array) {
                const updatedArtist = await this.pickArtist(artist);
            }
        },
        async holdOnToYourButts(array) {
            array.slice(0, 25);
            for (const artist of array) {
                const updatedArtist = await this.pickArtist(artist);
            }
        },
        async fetchArtists(genre) {

            let artists = await this.getArtists(genre);
            artists.forEach((artist) => {
                let gArtist = {"name": artist, "genre": genre};
                this.genreArtists.push(gArtist);
            });
        },


    }
};
