<script>

export default {
    created() {

    },
    props: ['headline', 'biline', 'body', 'item', 'listItem', 'selected'],
    data() {
        return {
            closed: false,
        };
    },
    mounted() {
    },

    watch: {},
    methods: {},
    computed: {
        showImage(){
          if(this.listItem.image && !this.listItem.image.includes('dalle')) {
              return true;
          } else {
              return false;
          }
        },
        classesToReturn() {
            if (this.selected) {
                return ' text-base-200 hover:bg-base-600 bg-base-500 hover:text-base-300 hover:brightness-200';
            } else {
                return 'hover:bg-base-700 bg-base-700 text-base-200 hover:brightness-200';
            }
            // bg-base-800 text-base-300
        },

        createdTime() {
            if (this.listItem && this.listItem.created) {
                const obj = this.listItem.created;
                const seconds = obj['seconds'];
                const nanoseconds = obj['nanoseconds'];
                const date = new Date(seconds * 1000+nanoseconds / 1000000);

                const options = {
                    dateStyle: 'short', // Use the relative time format
                    timeStyle: 'short'
                };
                const dateString = date.toLocaleString('en-US', options);
                return dateString;
            }
            return false;
        },
        getDate() {
            if (this.listItem && this.listItem.created) {
                const obj = this.listItem.created;
                const seconds = obj['seconds'];
                const nanoseconds = obj['nanoseconds'];
                const dateRaw = new Date(seconds * 1000+nanoseconds / 1000000);
                return dateRaw;
            }
            return false;
        }
    },
};
</script>
<template>
    <div :class="classesToReturn" class="f hover:bg-base-800 ">
        <div class="py-3 pl-3" v-if="showImage">
            <img :src="listItem.image" class="x width-max-75 height-max-75">
        </div>
        <div class="p-3 x blend-dodge saturate-200 " style="border-bottom:1px solid rgba(255,255,255,0.1)">
            <!--            <p v-if="headline" class="fwb mb-0">{{ headline }}</p>-->
            <p v-if="listItem.promptData.name" class="fwb mb-0 f-15">
                <span v-if="listItem.promptData && listItem.promptData.name && listItem.promptData.name" class="">"{{ listItem.promptData.name }}"</span>
            </p>
            <p v-if="listItem.promptData.topic" class="my-1 f-13 ">
                <span v-if="listItem.promptData && listItem.promptData.topic && listItem.promptData.topic" class="">{{ listItem.promptData.topic.slice(0, 100) }} ... </span>
            </p>
            <p v-if="biline" class=" small mb-0 my-1  blend-dodge">
                <span v-if="createdTime" class="mr-2">{{ $filters.timeAgo(getDate) }}</span>
                <span v-if="listItem.promptData && listItem.promptData.genre && listItem.promptData.genre.name" class="mr-2 text-base-200 fwb">{{ listItem.promptData.genre.name }} </span>
                <span class="mr-2"><span class="o-7">Inspired by </span><span class="">{{ biline }}</span></span>
            </p>

            <p v-if="body" class="mb-0 f-13">
                {{ body }}
            </p>
        </div>
    </div>
</template>
<style lang="scss">
</style>
