<template>
    <div class="vh-90 f align-content-stretch p-relative dotgrid dark:bg-base-800">
        <Notification></Notification>

        <!--        <doc-list v-if="document" @create-document="createDocument" @select-item="selectItem" :item="document" :list="documents"></doc-list>-->
        <div ref="sectionsContainer" class="scrollSection fc align-content-stretch flex flex-grow pt-4">
            <div
                v-if="document"
                :key="document.id"
                class="x f fc align-content-stretch"
                style="min-height: 100vh"
                @keydown="keydown">
                <div ref="mainContent" class="" style="flex-basis: 100%; flex-grow: 1">
                    <div
                        ref="toolbar"
                        class="sticky-top f aic border-top border-bottom x jcb mb-5 mt-3 bg-snow py-2 px-3"
                        style="top: 20px">
                        <div class="f aic jcs x border-right">
                            <div class="mr-2"><p class="fwsb mb-0">What's the tone?</p></div>
                            <div>
                                <select
                                    v-model="document.tone"
                                    class="d-inline mb-0 pl-0 pr-3 text-left"
                                    style="padding: 8px 80px 8px 10px !important">
                                    <option v-for="tone in tones" :value="tone.label">
                                        {{ tone.label }}
                                    </option>
                                </select>
                            </div>
                            <!--                            <div class="mr-2"><p class="mb-0 fwsb">Section index: {{ sectionIndex }}</p></div>-->
                            <!--                            <div class="mr-2"><p class="mb-0 fwsb">Section Id: {{ sectionId }}</p></div>-->
                        </div>

                        <div class="f x aic jce border-left">
                            <div v-if="saving" class="f-15 o-5 x text-right">... Saving</div>
                            <div v-if="wordCount" class="mr-3">Word count: {{ wordCount }}</div>
                            <button
                                class="br-5 hover:text-red d-block mr-3 border border-base-900/20 bg-snow p-1 px-2 shadow hover:bg-base-50"
                                @click="generateImage(1, null, extractText(document.sections))">
                                <i aria-hidden="true" class="fa fa-image"></i
                            ></button>
                            <div
                                class="f-15 btn btn-outline-black border-1 border border-solid border-base-900/20 shadow"
                                @click.prevent="summarize(extractText(document.sections))"
                                >Summarize this
                            </div>
                            <button
                                class="br-5 hover:text-red d-block ml-3 border border-base-900/20 bg-snow p-1 px-2 shadow hover:bg-base-50"
                                @click="deleteDoc('documents', document, documents)">
                                <i aria-hidden="true" class="fa fa-trash"></i
                            ></button>
                        </div>
                    </div>
                    <div :key="document.id" v-drop class="x mx-auto max-w-lg pb-8">
                        <template v-if="true">
                            <TransitionGroup
                                :enter-active-class="`fadeInUpSmooth ease-back duration-3 d-1 animated stagger-${
                                    index + 1
                                }`"
                                appear
                                leave-active-class="ease-back duration-1 fadeOutDown animated">
                                <Draggable
                                    v-model="document.sections"
                                    group="sections"
                                    item-key="element.id"
                                    @end="drag = false"
                                    @start="drag = true">
                                    <!--                                fadeInUpSmooth animated-->
                                    <!--                                    :style="`animation-delay:${100+(50*index)}ms;transition-delay:${200+(50*index)}ms;animation-duration:1000ms;transition-duration:1000ms;`"-->
                                    <template #item="{ element, index }">
                                        <EditableSection
                                            :ref="`section-${index + 1}`"
                                            v-model="element.content"
                                            v-drag
                                            :index="index"
                                            :section="element"
                                            :section-id="`section-${index + 1}`"
                                            :tag="element.tag"
                                            class="mx-auto max-w-lg"
                                            @set-tag="setTag"
                                            @v-drag-end="dragonEnd"
                                            @focus-previous-selection="focusSection"
                                            @update-previous-section="updatePreviousSection"
                                            @new-section="newSection"
                                            @remove-section="removeSection"></EditableSection>
                                    </template>
                                </Draggable>
                            </TransitionGroup>
                        </template>
                    </div>
                </div>

                <div ref="summary" class="x bg-gray-100 py-20">
                    <div class="mx-auto max-w-lg">
                        <h5 class="mb-0 text-gray-300">Summary</h5>
                        <div class="x mx-auto mt-0 whitespace-pre-line p-2 pt-0 text-gray-500" contenteditable
                            >{{ document.summary }}
                        </div>
                        <div
                            class="whitespace-pre-line p-2 text-gray-500"
                            v-html="extractText(document.sections)"></div>
                        <!--                        <div>{{document.sections}}</div>-->
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-shrink-1 z-0 max-w-xs bg-gray-50"></div>
    </div>
</template>

<script setup>
import lodash from 'lodash';
import draggable from 'vuedraggable';
</script>

<script>
// import 'highlight.js/styles/monokai.css';
import cursorMixin from '../mixins/cursorMixin.js';
import documentMixin from '../mixins/documentMixins.js';

import DocList from '@/components/documents/docList.vue';
import DocumentListItem from '@/components/documents/documentListItem.vue';
import EditableSection from '@/components/documents/editableSection.vue';
import Notification from '@/components/Imported/tailwind/Notification.vue';
import OptionSelect from '@/components/Imported/tailwind/OptionSelect.vue';

export default {
    components: { draggable },
    mixins: [cursorMixin, documentMixin],
    data() {
        return {
            sectionCount: 0,
            sections: [
                {
                    content: 'New document',
                    tag: 'h1',
                    favorite: false,
                    id: this.randomId(),
                },
                {
                    content: ' ',
                    tag: 'p',
                    favorite: false,
                    id: this.randomId(),
                },
            ],
        };
    },
    watch: {
        sectionIndex: function (val) {
            this.$nextTick(() => {
                //this is how to reference a ref it properly.
                // good ref
                const ref = this.$refs[`${this.sectionId}`];
                if(ref.$refs.contenteditable.$refs.element) {
                    ref.$refs.contenteditable.$refs.element.focus();
                    this.setCaretPosition(ref.$refs.contenteditable.$refs.element, window.caret);
                }


                // console.error('total sections: ',this.document.sections.length);
                // console.error(this.sectionId);
                // console.error(val);
                // console.error(ref);
                // console.error(ref);
                // console.error(ref.$refs);
                // console.error(ref.$refs.contenteditable);
                // console.error(ref.$refs.contenteditable.$refs);
            });
        },
        document: function (val, oldVal) {
            if (val.sections.length > oldVal.sections.length) {
                console.error('new sections!');
            } else if (val.sections.length < oldVal.sections.length) {
                console.error('removed sections!');
            }
            if (val) {
                if (this.$refs.sectionsContainer) {
                    this.$nextTick(() => {
                        this.$refs.sectionsContainer.scrollTop = 0;
                    });
                }
                this.sections = val.sections;
            }
        },
    },
    async created() {
        window.addEventListener('keydown', event => {
            if (event.key === 's' && event.metaKey) {
                event.preventDefault();
                // Trigger the save function for your application
            }
            if (event.key === 'n' && event.metaKey) {
                event.preventDefault();
                // Trigger the save function for your application
            }
        });
        try {
            const documents = await this.getAnyDBItem('documents');
            this.document = documents[0];
            this.setDocuments(documents);
            this.pushAlert({ type: 'Success', message: 'Documents loaded' });
        } catch (e) {
            console.log(e);
            this.pushAlert({ type: 'Error', message: 'Could not load documents' });
        }
    },
    mounted() {
        this.addIndexToSections();
        // this.setDocument = this.documents[0];
        // this.sections = this.document.sections;
    },
    methods: {
        dragonEnd(ev) {
            console.error(ev);
        },
        addIndexToSections() {
            this.document.sections.forEach((section, index) => {
                section.index = index;
            });
        },
        async summarize(text) {
            let prompt;
            prompt = `Summarize this document in under 100 words:\n\n ${text}\n \n Return use \\n for spaces. ###`;

            console.log(text);
            const request = {
                model: 'text-davinci-003',
                prompt: prompt,
                max_tokens: 500,
                temperature: 1,
                top_p: 0.9,
                frequency_penalty: 0.5,
                presence_penalty: 0,
                best_of: 1,
            };
            const response = await this.requestFromOpenAI(request, 'Testing this');
            this.document.summary = response;
            // this.updateDocument(document.index)
            await this.saveDoc(this.document);
        },
        setTag([index, tag, kind]) {
            console.error(index);
            console.error(tag);
            let validTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'blockquote', 'pre', 'ul', 'ol', 'li', 'div'];
            if (kind) {
                this.document.sections[index].kind = kind;
            }
            if (tag && tag.type) {
                this.document.sections[index].tag = tag.type;
            } else if (tag && validTags.includes(tag)) {
                this.document.sections[index].tag = tag;
            }
        },
        createDocument(document) {
            this.documents.push(document);
            this.document = document;
            // this.sections = document.sections;
        },
        selectItem(item) {
            this.document = item;
        },
        nextSection() {
            // this.$store.commit('setCurrentSection', this.$store.state.currentSection+1);
            this.setSectionIndex(this.sectionIndex + 1);
        },
        previousSection() {
            this.setSectionIndex(this.sectionIndex - 1);
            // this.$store.commit('setCurrentSection', this.$store.state.currentSection-1);
        },
        focusSection([index, ev, cursorPosition]) {
            console.error('cursor position: ' + cursorPosition);
            const neeIndex = index + 1;
            this.currentIndex = neeIndex;
            let newIndex = index + 1;
            if (ev.key === 'ArrowUp') {
                newIndex -= 1;
                this.navigatePrev();
            }
            if (ev.key === 'ArrowDown') {
                newIndex += 1;
                this.navigateNext();
            }
            let cursor = {
                startContainer: cursorPosition,
                startOffset: cursorPosition,
            };
            this.$nextTick(() => {
                if (newIndex < 1) {
                    newIndex = 1;
                } else if (newIndex > this.sections.length) {
                    newIndex = this.sections.length;
                }

                const ref = this.$refs[`${this.sectionId}`];
                let sectionsTotal = ref.$refs.contenteditable.$refs.element;

                if (sectionsTotal) {
                    // sectionTotal.focus();
                    // section.setCursorPosition(cursorPosition);
                    console.error(cursor);
                    // sectionTotal.setCursorPosition(cursorPosition);
                }
            });
        },
        setCaretPosition(elemId, caretPos) {
            if (window.caret) {
                this.$nextTick(() => {
                    const ref = this.$refs[`${this.sectionId}`];
                    let element = ref.$refs.contenteditable.$refs['element'];
                    console.error(element);
                    const startIndex = window.caret;
                    const textNode = element.length;

                    if (startIndex > textNode) {
                        console.error(
                            `Error: offset ${startIndex} is greater than number of child nodes ${childNodeCount}`,
                        );
                        return;
                    }
                    let range, selection;
                    setTimeout(() => {
                        if (document.createRange) {
                            range = document.createRange();
                            range.selectNodeContents(element);
                            range.collapse(true);
                            range.setStart(element.firstChild, window.caret);
                            range.setEnd(element.firstChild, window.caret);
                            selection = window.getSelection();
                            selection.removeAllRanges();
                            selection.addRange(range);
                        }
                        window.caret = null;
                    }, 100);
                });
            }
        },

        keydown(ev) {
            // if (ev.key === 's' && ev.metaKey) {
            //     this.saveDoc(this.document);
            // }
            // if (ev.key === 'n' && ev.metaKey) {
            //     this.createDocument();
            // }
        },
    },
};
</script>

<style lang="scss" scoped>
.dotgrid {
    background: #fdfdff;
    background-image: radial-gradient(#ddd 1px, transparent 0);
    background-position: -19px -19px;
    background-size: 20px 20px;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
}
</style>
<!--&lt;!&ndash;                        {{ groupedSections }}&ndash;&gt;-->
<!--<div v-for="s in groupedSections" class="mb-3 max-w-xl mx-auto">-->
<!--<p v-if="s.kind === 'text' || s.kind === 'normal'">{{ s.content }}</p>-->
<!--<ol v-else-if="s.kind === 'numberedList'" class="border-light p-2">-->
<!--    <li class="ml-5">{{ s }}</li>-->
<!--    <li class="ml-5" v-for="item in s.items">{{ item.content }} {{ item.kind }}</li>-->
<!--</ol>-->
<!--<ul v-else-if="s.kind === 'list'" class="border-light p-2">-->
<!--    <li class="ml-5">{{ s.kind }}</li>-->
<!--    <li class="ml-5" v-for="item in s.items">{{ item.content }} {{ item.kind }}</li>-->
<!--</ul>-->
<!--</div>-->
