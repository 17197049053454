<script>
import ListItem from "@/components/music/SongListItem.vue";

export default {
    components: {ListItem},
    prop: ['item', 'list'],
    created() {

    },
    computed: {
        userFeed() {
            if (this.$store.state.user.feed) {
                return this.$store.state.user.feed;
            } else {
                return false;
            }
        }
    },
    data() {
        return {
            debug: false,
            item: null,
        };
    },
    async mounted() {
        // setTimeout(() => {
        // if(this.$router) {
        //     console.error('this.$router.params.id', this.$router.params.id);
        //     const song = await this.getDBItem('feedItem', null, this.$router.params.id);
        //     this.item = song;
        //     console.error(song);
        // } else if (!this.userFeed) {
        //     this.$nextTick(() => {
        //         this.item = this.list[0];
        //     });
        // } else {
        //     setTimeout(() => {
        //         this.item = this.list[0];
        //     }, 200);
        // }
    },
    watch: {},
    methods: {
        selectItem(item){
                this.$router.push({path: `/profile/${item.id}`, params: {id: item.id}});
            // } else {
            //     this.$parent.item = item;
            // }
        },
        currentItem(item) {
            if (this.$parent && this.$parent.item) {
                if (item === this.$parent.item.id) {
                    return true;
                } else {
                    return false;
                }
            }
        },
    }
};
</script>
<template>
    <div class="">
        <div class="p-3">
            <h4 class="text-base-200">Your songs</h4>
        </div>
        <div class="py-2 px-4">
            Create a new song or select one from your list.
        </div>

        <template v-if="userFeed">
            <template v-for="(obj,index) in userFeed">
                <div @click.prevent="selectItem(obj)" class="animated fadeInUpSmooth duration-5 ease-back"
                     :style="`animation-delay:${100+(50*index)}ms;transition-delay:${200+(50*index)}ms;animation-duration:1000ms;transition-duration:1000ms;`" appear >
<!--                    "-->
                    <list-item v-if="obj && obj.promptData && obj.promptData.artists.name"
                               :biline="obj.promptData.artists.name"
                               :body="obj.promptData.genre.title"
                               :selected="currentItem(obj.id)"
                               :headline="obj.promptData.topic"
                               :list-item="obj"></list-item>
                </div>
            </template>

        </template>
    </div>
</template>
<style lang="scss">
</style>
