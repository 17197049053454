<template>
    <div class="pt-8">
        <!--        <div class="drum-machine">-->
        <!--            <button v-for="pad in pads" @click="playSound(pad.id)">{{ pad.label }}</button>-->
        <!--        </div>-->

        <div class="row">
            <div class="col-8">
                <div v-for="sequence in sequences" :key="sequence.id" class="sequencer x">
                    <div class="d-flex x">
                        <div class="mw-150 d-block x">{{ sequence.label }}</div>
                        <div v-for="step in sequence.sequence" :key="step.id"
                             class="sequencer-step x mw-50 p-2"

                             :class="{'bg-bubble':step.isPlaying,'bg-bubble-300':(step.active && !step.isPlaying),'bg-base-300':(!step.active && !step.isPlaying)}"
                             @click.prevent="step.active = !step.active,updateSequence()">
                            <!--                             @mousedown="step.active = !step.active,updateSequence()"-->
                            <!--                            <input type="checkbox" class="p-2" v-model="step.active" @change="updateSequence"/>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="f aic jcs mt-3">
            <div class="f">
                <button-icon-white icon="play fas" @click="playSequence"></button-icon-white>
                <button-icon-white icon="stop fas" @click="stopSequence"></button-icon-white>
                <button-icon-white icon="drum fas" @click="loadBeat"></button-icon-white>
                <button-icon-white icon="copy fas" @click="captureSequencerState()"></button-icon-white>
                <button-icon-white icon="sync fas" @click="loop = !loop" :class="{'bg-bubble':loop,'bg-snow':!loop}"></button-icon-white>
                <button-icon-white :icon="{'play fas':paused, 'pause fas':!paused }" @click="pauseSequence"></button-icon-white>
            </div>
            <div>
                <label for="beat-select">Select a beat:</label>
                <select id="beat-select" v-model="selectedBeat">
                    <option v-for="beat in beatList" :key="beat.id" :value="beat">{{ beat.name }}</option>
                </select>
                <button @click="loadBeat(selectedBeat)">Load beat</button>
            </div>
            <div>
                <div class="tempo-slider">
                    <label for="tempo">Tempo:</label>
                    <input type="range" min="60" max="240" v-model="tempo" id="tempo" class="form-control-range">
                    <span>{{ tempo }} BPM</span>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import ButtonIcon from "@/components/buttons/ButtonIcon.vue";
import ButtonIconWhite from "@/components/buttons/buttonIconWhite.vue";

export default {
    components: {ButtonIconWhite, ButtonIcon},
    data: () => ({
        selectedKit: 'default',
        loop: false,
        paused: false,
        kits: [
            {id: 'default', name: 'Default'},
            {id: 'electronic', name: 'Electronic'},
            {id: 'acoustic', name: 'Acoustic'}
        ],
        selectedBeat: null,
        // beats: [Standard_4_4_Beat, Rock_Beat, Reggae_Beat, Country_Beat, Hip_Hop_Beat, Jazz_Beat, EDM_Beat, Funk_Beat, Blues_Beat],
        beatList: [
            {
                name: 'Standard 4/4 Beat',
                data: {
                    bpm: 90,
                    drums: [
                        {id: 'kick', sequence: [1, 0, 0, 0, 1, 0, 0, 0]},
                        {id: 'hhopen', sequence: [1, 1, 1, 1, 1, 1, 1, 1]},
                        {id: 'snare', sequence: [0, 0, 1, 0, 0, 0, 1, 0]},
                        {id: 'cymbal', sequence: [1, 0, 0, 0, 0, 0, 0, 0]}
                    ]
                }
            },
            {
                name: 'Rock Beat',
                data: {
                    bpm: 100,
                    drums: [
                        {id: 'kick', sequence: [1, 1, 0, 0, 1, 1, 0, 0]},
                        {id: 'hhopen', sequence: [1, 1, 1, 1, 1, 1, 1, 1]},
                        {id: 'snare', sequence: [0, 0, 1, 0, 0, 0, 1, 0]},
                        {id: 'cymbal', sequence: [1, 0, 0, 0, 0, 0, 0, 0]}
                    ]
                }
            },
            {
                name: 'Jazz',
                bpm: 90,
                drums: [{id: 'kick', sequence: [1, 0, 0, 0, 1, 0, 0, 0]},
                    {id: 'hhopen', sequence: [1, 0, 1, 0, 1, 0, 1, 0]},
                    {id: 'snare', sequence: [0, 1, 1, 0, 0, 1, 1, 0]},
                    {id: 'cymbal', sequence: [1, 0, 0, 0, 0, 0, 0, 0]}
                ]
            },
            {
                name: 'Country',
                bpm: 120,
                drums: [{id: 'kick', sequence: [1, 0, 1, 0, 1, 0, 1, 0]},
                    {id: 'hhopen', sequence: [1, 1, 1, 1, 1, 1, 1, 1]},
                    {id: 'snare', sequence: [0, 0, 1, 0, 0, 0, 1, 0]},
                    {id: 'cymbal', sequence: [1, 0, 0, 0, 0, 0, 0, 0]}
                ]
            },
            {
                name: 'Reagee',
                bpm: 100,
                drums: [{id: 'kick', sequence: [1, 1, 0, 0, 0, 1, 0, 0]},
                    {id: 'hhopen', sequence: [1, 1, 1, 1, 1, 1, 1, 1]},
                    {id: 'snare', sequence: [0, 0, 1, 0, 0, 0, 1, 0]},
                    {id: 'cymbal', sequence: [1, 0, 1, 0, 0, 0, 1, 0]}
                ]
            },
            {
                "name": "Dub",
                "data": {
                    "bpm": 240,
                    "drums": [{"id": "kick", sequence: [0, 0, 0, 0, 0, 0, 0, 0]}, {
                        "id": "kick2",
                       sequence: [1, 0, 0, 0, 0, 0, 0, 0]
                    }, {"id": "snare",sequence: [0, 0, 0, 0, 0, 0, 0, 0]}, {
                        "id": "hhopen",
                       sequence: [1, 1, 1, 1, 1, 1, 1, 1]
                    }, {"id": "clap",sequence: [0, 0, 0, 0, 0, 0, 0, 0]}, {
                        "id": "clap2",
                       sequence: [0, 0, 0, 0, 1, 0, 0, 0]
                    }, {"id": "ride",sequence: [0, 0, 0, 0, 0, 0, 0, 0]}, {
                        "id": "ride2",
                       sequence: [0, 0, 0, 0, 0, 0, 0, 0]
                    }, {"id": "cymbal",sequence: [0, 0, 0, 0, 0, 0, 0, 0]}]
                }
            }

        ],
        pads: [
            {id: 'kick', label: 'Kick'},
            {id: 'kick2', label: 'Kick 2'},
            {id: 'snare', label: 'Snare'},
            {id: 'hhopen', label: 'Hi-hat'},
            {id: 'clap', label: 'Clap'},
            {id: 'clap2', label: 'Clap 2'},
            {id: 'ride', label: 'Ride'},
            {id: 'ride2', label: 'Ride 2'},
            {id: 'cymbal', label: 'Cymbal'}
        ],
        tempo: 120,
        // Define a variable to store the interval ID
        intervalId: null,
        // Define a variable to store the current step index
        currentStep: 0,
        audioContext: new AudioContext(),
        steps: [
            {id: 1, active: false},
            {id: 2, active: false},
            {id: 3, active: false},
            {id: 4, active: false},
            {id: 5, active: false},
            {id: 6, active: false},
            {id: 7, active: false},
            {id: 8, active: false},
            {id: 9, active: false},
        ],
        sequences: [], // added this to store the sequences for each pad

    }),
    mounted() {
        this.audioContext = new AudioContext();
        this.buildSequencers();
    },
    watch: {
        // Update the interval when the tempo changes
        sequences: {
            handler() {
                // Iterate over the sequences
                for (const sequence of this.sequences) {
                    // Find the pad corresponding to the current sequence
                    const pad = this.pads.find((p) => p.id === sequence.id);

                    // Update the pad's sequence
                    pad.sequence = sequence.sequence;
                }
            },
            deep: true, // Watch for changes to the nested objects in the sequences array
        },
        tempo: {
            immediate: true,
            handler() {
                if (this.intervalId) {
                    this.updateSequence();
                    clearInterval(this.intervalId);
                    this.intervalId = setInterval(() => this.playSequence(), 60 / this.tempo * 1000);
                }
            }
        }
    },
    methods: {
        captureSequencerState() {
            // Get the current BPM
            const bpm = this.tempo;

            // Initialize an empty array for the drum sequences
            const drumSequences = [];

            // Iterate over all potential drums
            for (const drum of this.pads) {
                // Get the current sequence for the drum
                let sequence = drum.sequence.map(beat => beat.active);
                // Convert the sequence to an array of 0s and 1s
                sequence = sequence.map(s => s ? 1 : 0);
                // Add the drum's sequence to the array
                drumSequences.push({id: drum.id, sequence});
            }

            // Format the JSON object
            const beatData = {
                name: 'Custom Beat',
                data: {
                    bpm,
                    drums: drumSequences
                }
            };

            // Stringify the JSON object
            const beatString = JSON.stringify(beatData);

            // Copy the string to the clipboard
            navigator.clipboard.writeText(beatString);
        },
        loadBeat(data) {

            this.loadSequence(data);
        },
        loadSequence(sequenceData) {
            // Update the tempo
            this.tempo = sequenceData.bpm;

            // Iterate over the drum sequences in the JSON object
            for (const drum of sequenceData.drums) {
                // Find the corresponding pad in the pads array
                const pad = this.pads.find((p) => p.id === drum.id);

                // Check if there is already a sequence in the sequences array for the current pad
                const existingSequence = this.sequences.find((s) => s.id === pad.id);

                // If a sequence already exists, update its sequence and label
                if (existingSequence) {
                    existingSequence.sequence = drum.sequence.map((active, index) => ({
                        id: index+1,
                        active,
                    }));
                    existingSequence.label = pad.label;
                } else {
                    // If a sequence does not already exist, create a new one and add it to the sequences array
                    this.sequences.push({
                        id: pad.id,
                        label: pad.label,
                        sequence: drum.sequence.map((active, index) => ({
                            id: index+1,
                            active,
                        })),
                    });
                }
            }
        },
        pauseSequence() {
            if (this.paused) {
                this.sequences.forEach((sequence) => {
                    sequence.intervalId = setInterval(() => {
                        this.playSequence();
                    }, 60 / this.tempo * 1000);
                });
                this.paused = false;
            } else {
                this.sequences.forEach((sequence) => {
                    clearInterval(sequence.intervalId);
                });
                this.paused = true;
            }
            this.sequences.forEach((sequence) => {
                clearInterval(sequence.intervalId);
                sequence.tempCurrentStep = sequence.currentStep;
            });
        },
        buildSequencers() {
            let sequences = [];
            this.pads.forEach((pad) => {
                // Create an object to hold the information for the current pad
                // let inst = {
                //     id: pad.id,
                //     label: pad.label,
                //     sequence: [],
                // };
                let inst = {
                    id: pad.id,
                    label: pad.label,
                    sequence: [],
                    intervalId: null, // add this line
                };
                // Create an array to hold the steps for the current pad's sequence
                let sequence = [];

                // Set the number of steps in the sequence
                let steps = 8;

                // Iterate over the steps and create an object for each step
                for (let i = 1; i <= steps; i++) {
                    let step = {id: `${pad.id}${i}`, active: false, isPlaying: false};
                    sequence.push(step);
                }

                // Set the sequence for the current pad
                inst.sequence = sequence;

                // Add the pad's sequence to the list of sequences
                sequences.push(inst);
            });
            this.sequences = sequences;
        },
        playSequence() {
            this.sequences.forEach((sequence) => {
                if (sequence.intervalId) {
                    clearInterval(sequence.intervalId);
                }
            });
            this.sequences.forEach((sequence) => {
                sequence.currentStep = 0;
            });
            this.sequences.forEach((sequence) => {
                sequence.intervalId = setInterval(() => {
                    const step = sequence.sequence[sequence.currentStep];
                    if (step.active) {
                        this.playPad(sequence.id);
                        step.isPlaying = true;
                        setTimeout(() => {
                            step.isPlaying = false;
                        }, 200);
                    }
                    sequence.currentStep += 1;
                    if (sequence.currentStep >= sequence.sequence.length) {
                        sequence.currentStep = 0;
                    }
                }, 60 / this.tempo * 1000); // tempo is in BPM
            });
        },
        stopSequence() {
            this.sequences.forEach((sequence) => {
                if (sequence.intervalId) {
                    clearInterval(sequence.intervalId);
                    sequence.intervalId = null;
                    sequence.currentStep = 0;
                }
            });
        },
        updateSequence(padId) {
            // Find the sequence for the specified pad
            const sequence = this.sequences.find(s => s.id === padId);

            // Cancel any existing interval for the sequence
            if (sequence.intervalId) {
                clearInterval(sequence.intervalId);
            }

            // Start a new interval to play the sequence at the specified tempo
            sequence.intervalId = setInterval(() => {
                // Get the current time in seconds
                const currentTime = audioContext.currentTime;

                // Iterate over the sequence and schedule the active pads to play
                for (const step of sequence.steps) {
                    if (step.active) {
                        this.schedulePad(step.padId, currentTime+step.id-1);
                    }
                }
            }, 60 / this.tempo * 1000); // tempo is in BPM
        },
        async schedulePad(padId, startTime) {
            // Load the audio file for the specified pad
            const response = await fetch(`/assets/audio/drums/${padId}.mp3`);
            const audioBuffer = await response.arrayBuffer();
            const audioData = await audioContext.decodeAudioData(audioBuffer);

            // Create a new audio buffer source node
            const audioBufferSourceNode = audioContext.createBufferSource();
            audioBufferSourceNode.buffer = audioData;

            // Connect the audio buffer source node to the audio context destination
            audioBufferSourceNode.connect(audioContext.destination);

            // Schedule the start and stop times for the audio
            audioBufferSourceNode.start(startTime);
            audioBufferSourceNode.stop(startTime+audioData.duration);
        },
        async playSound(padId) {
            // Create a new audio context
            const audioContext = new AudioContext();

            // Load the audio file for the specified pad
            const response = await fetch(`/assets/audio/drums/${padId}.mp3`);
            const audioBuffer = await response.arrayBuffer();
            const audioData = await audioContext.decodeAudioData(audioBuffer);

            // Create a new audio buffer source node
            const audioBufferSourceNode = audioContext.createBufferSource();
            audioBufferSourceNode.buffer = audioData;

            // Connect the audio buffer source node to the audio context destination
            audioBufferSourceNode.connect(audioContext.destination);

            // Start playing the audio
            audioBufferSourceNode.start();
        },
        async playPad(padId) {
            // Create a new audio context
            const audioContext = new AudioContext();

            // Load the audio file for the specified pad
            const response = await fetch(`/assets/audio/drums/${padId}.mp3`);
            const audioBuffer = await response.arrayBuffer();
            const audioData = await audioContext.decodeAudioData(audioBuffer);

            // Create a new audio buffer source node
            const audioBufferSourceNode = audioContext.createBufferSource();
            audioBufferSourceNode.buffer = audioData;

            // Connect the audio buffer source node to the audio context destination
            audioBufferSourceNode.connect(audioContext.destination);

            // Start playing the audio
            audioBufferSourceNode.start();
        }
    },
    computed: {}
};
</script>

<style lang="scss" scoped>
.drum-machine{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.drum-machine button{
    font-size: 1.5rem;
    padding: 1rem 2rem;
    margin: 0.5rem;
    border: 2px solid #333;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    transition: background-color 0.15s ease-in-out;
}

.drum-machine button:hover{
    background-color: #eee;
}

.drum-machine button:active{
    background-color: #ddd;
}

.drum-kit-selector{
    display: flex;
    align-items: center;
    margin-top: 1rem;
}

.drum-kit-selector label{
    font-size: 1.5rem;
    margin-right: 0.5rem;
}

.drum-kit-selector select{
    font-size: 1.5rem;
    padding: 0.5rem;
    border: 2px solid #333;
    border-radius: 0.5rem;
}

.sequencer{
    display: flex;
    flex-wrap: wrap;
    margin-top: 0rem;
    background: white;
}

.sequencer-step{
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    border: 1px solid #ccc;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sequencer-step.playing{
    background-color: red;
}
</style>
