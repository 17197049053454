<template>
    <div>
        <div v-if="showMenu"
            class="z-3 popMenu top absolute"
            style="font-size: unset !important; transform: translateX(-110%) translateY(0%)"
            appear>
            <div class="p-relative border-light width-400 d-block animated fadeInUpSmooth rounded bg-snow shadow-2xl"
                style="width: 300px">
                <span class="fwb right d-block z-3 border-bottom px-3 py-2">Commands</span>
                <span v-for="item in actionItems" :key="item.id" :class="buttonClass" @click.prevent="itemClicked(item)"
                    ><i :class="item.iconClass" class="mr-3"></i>{{ item.label }}</span
                >
                <!--                <span :class="buttonClass" @click.prevent="generateImage(index,section)"><i class="fas fa-image mr-3  "></i>Generate Image</span>-->
            </div>
        </div>
        <div v-if="hovered"
            class="p-absolute top animated fadeIn opacity-3 handle -left-5 mb-1 ml-2 block p-2 p-1 hover:bg-base-50 hover:opacity-100"
            @click.prevent="showMenu = true"
            ><span class="hovered:opacity-100 opacity-20"><i class="fas fa-bars f-17"></i></span
        ></div>
    </div>
</template>
<script>
import documentMixins from '@/mixins/documentMixins';
import imageMixin from '@/mixins/imageMixin';

export default {
    name: 'PopupMenu',
    mixins: [documentMixins, imageMixin],
    props: {
        hovered: {},
        section: {},
        showHighlightMenu: {},
        index: {
            type: Number,
            default: 0,
        },
    },
    data: () => ({
        showMenu: false,
    }),
    computed: {
        buttonClass() {
            return 'bg-snow text-gray-900 hover:text-green fwb right d-block z-3 px-3 py-2 border-bottom hover:bg-base-50 cursor-pointer';
        },
    },
    methods: {
        itemClicked(item, index, payload) {
            console.error(item);
            if (item.actionType === 'image') {
                this.generateImage(this.index, this.section);
            } else if (item.actionType === 'style') {
                this.setTag(item);
            } else {
                this.aiHelper(this.section.content, item.type, this.index);
            }
            this.closeModal();
        },
        fetchingSection(event) {
            console.error('helloooooooooo');
            console.error(event);
            this.$emit('fetchingSection', event);
            return event;
        },
    },
};
</script>
<style lang="scss" scoped></style>
