<script>
var controller = new ScrollMagic.Controller();
export default {
    data: () => ({
        zoomScene: null,
        animationStart: {},
        animationEnd: {},
        enterScene: null,
        pinScene: null,
        backgroundScene: null,
        leaveScene: null,
        iterationCount: 0,
        fontAdjust: '0300',
        background: 'rgba(0,0,0,0.001)',
        fadeBackground: false,
    }),
    props: ['title', 'paragraph', 'glow', 'zoom', 'fade', 'up', 'sceneLength', 'bgChange'],
    mounted() {
        this.buildAnimation();
        this.initScroll();
    },
    computed: {
        fontWeight() {
            if (this.fontAdjust) {
                return this.fontAdjust;
            } else {
                return false;
            }
        }
    },
    methods: {
        buildAnimation() {
            let animation = {};
            let animationEnd = {};
            if (this.bgChange) {
                // this.animationStart.backgroundColor = 'rgba(255,255,255,0.001)';
                // this.animationEnd.backgroundColor = this.bgChange;
            }
            if (this.zoom) {
                this.animationStart.scale = 0;
                this.animationEnd.scale = 1;
            }
            if (this.fade) {
                this.animationStart.opacity = 0;
                this.animationEnd.opacity = 1;
            }
            if (this.up) {
                this.animationStart.y = 20;
                this.animationEnd.y = 0;
            }
            if (this.up) {
                this.animationStart.fontVariationSettings = `'wght' 0001`;
                this.animationEnd.fontVariationSettings = `'wght' 0600`;
            }
            this.animationEnd.ease = 'Linear.easeOut';
        },
        initScroll() {
            var self = this;

            const startAni = this.animationStart;
            const endAni = this.animationEnd;
            var zoomEnter = new TimelineMax().add([

                TweenMax.fromTo($(self.$refs.element), 3, startAni, endAni, 1),
                // TweenMax.fromTo($(self.$refs.element), 1,
                //     {
                //         opacity: 0,
                //     }, {
                //         opacity: 1,
                //         ease: Linear.None
                //     }, 1),
                //     TweenMax.fromTo($(self), 1,
                //         {
                //             fontAdjust: '0001',
                //         }, {
                //             fontAdjust: '0700',
                //             ease: Back.None,
                //         },1),
            ]);
            let bgFade = {};
            if (this.fadeBackground) {
                bgFade = { background: 'rgba(255,255,255,0.001)'} ;
            }
            var zoomExit = new TimelineMax().add([
                TweenMax.to($(self.$refs.element), 1, startAni),
                TweenMax.to($(self.$refs.background), 1, bgFade),

            ]);
            var background = new TimelineMax().add([
                TweenMax.to($(self.$refs.background), 3, {
                    background: self.bgChange,
                }, 3),
            ]);

            // const sectionHeight = ($(window).height()/4);
            // const sectionLength = $(window).height();
            let sectionLength = $(window).height();
            if (this.sceneLength) {
                sectionLength = this.sceneLength;
            }
            const sectionHeight = (sectionLength / 4);
            const animationDuration = (sectionLength / 4);
            const trigger = self.$refs.trigger;
            this.enterScene = new ScrollMagic.Scene({
                triggerElement: trigger,
                triggerHook: 'onCenter',
                // offset:-sectionHeight/2,
                duration: animationDuration,
                pushFollowers: true,
            }).setTween(zoomEnter).addTo(controller);
            // .addIndicators({parent:"animation1",name:'fadeIn',indent:"100px"});
            const innerHeight = this.$refs.trigger.clientHeight;


            // var pinScene = new TimelineMax().add([
            //     TweenMax.fromTo(self, 1, {
            //         iterationCount: 0,
            //     }, {
            //         iterationCount: 20,
            //     }),
            // ]);

            this.pinScene = new ScrollMagic.Scene({
                triggerElement: trigger,
                triggerHook: 'onCenter',
                offset: (innerHeight / 2),
                pushFollowers: false,
                duration: sectionHeight * 4,
            }).setPin(trigger).addTo(controller);
            // }).setPin(trigger).setTween(pinScene).addTo(controller);
            // .addIndicators({parent:"animation1",name:'pin',indent:"200px"});

            if (this.bgChange) {
                this.backgroundScene = new ScrollMagic.Scene({
                    triggerElement: trigger,
                    triggerHook: 'onEnter',
                    offset: animationDuration * 4,
                    duration: animationDuration,
                    pushFollowers: false,
                }).setTween(background).addTo(controller);
            }


            this.leaveScene = new ScrollMagic.Scene({
                triggerElement: trigger,
                triggerHook: 'onLeave',
                offset: animationDuration,
                duration: animationDuration,
                pushFollowers: false,
            }).setTween(zoomExit).addTo(controller);
            // .addIndicators({parent:"animation1",name:'fadeOut',indent:"300px"});

        },
        beforeDestroy() {
            this.enterScene.destroy(true);
            this.leaveScene.destroy(true);
            this.pinScene.destroy(true);
        },
    },
};
</script>
<template>
    <div ref="trigger" class="f aic jcc x">
        <div class="p-absolute x vh-100" style="z-index:0;" ref="background"></div>
        <div ref="container">
            <div ref="element" class=" text-center f aic jcc">
                <div>
                    <h1 v-if="title && glow" class="text-neon section-header my-0 mb-3" :style="`font-variation-settings: 'wght' ${fontWeight};`">{{ title }}</h1>
                    <h1 v-else-if="title" class="section-header my-0 mb-3" :style="`font-variation-settings: 'wght' ${fontWeight};`">{{ title }}</h1>
                    <p v-if="paragraph" class="stagger-body text-center mw-750 mx-auto my-0" :style="`font-size:calc(10px + 1vw);font-variation-settings: 'wght' ${fontWeight};`">{{ paragraph }}</p>
                    <!--                    <div class="p-relative text-center d-inline-block mx-auto">-->
                    <!--                        <h1 class="white" style="opacity:0!important;">{{ title }}</h1>-->
                    <!--                        <h1 class="p-absolute whitespace-nowrap" v-for="(item,index) in 10" :style="`position:absolute;top:${index * iterationCount}px;opacity:${2/(index * iterationCount)};color:rgb(${255/iterationCount},${2*iterationCount},${255/(iterationCount/2)})`">{{ title }}</h1>-->
                    <!--                        <h1 class="p-absolute whitespace-nowrap" v-for="(item,index) in 10" :style="`position:absolute;bottom:${index * iterationCount}px;color:rgb(${2*iterationCount},${2*iterationCount},${255/(iterationCount/2)});opacity:${2/(index * iterationCount)}`">{{ title }}</h1>-->
                    <!--                    </div>-->
                    <!--                    <p v-if="paragraph" class="stagger-body text-center">{{ paragraph }}</p>-->
                </div>
            </div>
        </div>
    </div>
</template>

