<template>
    <div class="p-relative vh-100 z-4" :class="{ 'z-5': mobile }">
        <Transition
            enter-active-class="animated slideInLeft ease-in-out"
            leave-active-class="animated slideOutLeft ease-in-out">
            <DocList
                v-if="showDocumentDrawer || persistant"
                class="mw-10 z-5"
                :class="{ 'p-fixed': !persistant || mobile }"
                @delete-doc="deleteDoc"
                @close-drawer="close"
                @mouseleave="closeDrawer()"
                @create-document="createDocument"></DocList>
        </Transition>
<!--        <div-->
<!--            class="p-absolute left z-5 bg-white top-nav mt-1 p-2 hover:pl-3"-->
<!--            v-if="!(showDocumentDrawer && mobile)"-->
<!--            style="border-radius: 0px 10px 10px 0px"-->
<!--            @click.prevent="toggleDrawer"-->
<!--            @click="persistant = !persistant">-->
<!--            <i class="fas fa-bars"></i>-->
<!--        </div>-->
        <div v-if="!mobile"
            class="p-fixed left top bottom d-block z-5"
            style="width: 10px; left: 0px"
            @mouseover.prevent="openDrawer()"></div>
    </div>
</template>
<script setup></script>
<script>
import DocList from "@/components/documents/docList.vue";
import DocumentMixins from "@/mixins/documentMixins";

export default {
    components: { DocList },
    mixins: [DocumentMixins],
    props: {
        // showDocs: {}
        alwaysOpen: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["createDocument", "deleteDoc"],
    methods: {
        openDrawer() {
            this.$store.commit("setShowDocumentDrawer", true);
        },
        toggleDrawer() {
            this.$store.commit("setShowDocumentDrawer", !this.showDocumentDrawer);
        },
        closeDrawer() {
            this.$store.commit("setShowDocumentDrawer", false);
        },
        close() {
            this.showDocs = false;
            this.persistant = false;
            this.$store.commit("setShowDocumentDrawer", false);
        },
    },
    data() {
        return {
            showDocs: false,
            persistant: false,
        };
    },
    computed: {
        showDocumentDrawer() {
            return this.$store.state.showDocumentDrawer;
        },
    },
    mounted() {
        // this.$watch('showDocs', (val) => {
        //     console.log('showDocs', val)
        // })
        if (this.alwaysOpen) {
            this.persistant = true;
        }
    },
};
</script>
