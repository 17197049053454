<script>

export default {
    data: () => ({}),
    props: ['text', 'index'],
    mounted() {},
    methods: {},
    computed: {},
};
</script>
<template>

    <div class="mb-3 py-0 pl-2 ml-3" v-if="text !==''">
        <div class="hover:bg-base-100 hover:text-bubble-900 text-bubble-50 bg-base-500 chatBubble">
            {{ text }}</div>
    </div>


</template>
<style lang="scss">
</style>
