<template>
    <div>
        <div class="flex items-center justify-between" v-if="title">
            <h2 class="text-sm font-medium text-gray-900" v-if="title">{{ title }}</h2>
            <a class="text-sm font-medium text-indigo-600 hover:text-indigo-500" href="#" v-if="subtitle">{{ subtitle }}</a>
        </div>

        <RadioGroup v-model="kind" class="mt-2">
            <RadioGroupLabel class="sr-only">Radio label</RadioGroupLabel>
            <div class="f gap-3">
                <RadioGroupOption v-for="option in kinds" :key="option.value" v-slot="{ active, checked }"  :value="option.value" as="template">
                    <div @click.prevent="changeKind(option.value)" :class="['cursor-pointer focus:outline-none border-light', active ? 'ring-2 ring-offset-2 ring-indigo-500' : '', checked ? 'bg-bubble-600 border-transparent text-white hover:bg-bubble-700' : 'bg-white border-gray-200 text-gray-900 hover:bg-gray-50', 'border py-2 rounded-full flex items-center justify-center text-sm font-medium sm:flex-1']">
                        <RadioGroupLabel as="span">{{ option.name }}</RadioGroupLabel>
                    </div>
                </RadioGroupOption>
            </div>
        </RadioGroup>
    </div>
</template>
<script setup>
import {ref} from 'vue';
import {RadioGroup, RadioGroupLabel, RadioGroupOption} from '@headlessui/vue';
</script>
<script>


export default {
    data() {
        return {
            kind: 'presentation',
        };
    },
    emits: ['change'],
    methods: {
        changeKind(kind) {
            this.kind = kind;
            this.$emit('change', kind);
        }
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        subtitle: {
            type: String,
            default: ''
        },
        kinds: {
            type: Array,
            default: () => [
                {
                    name: 'Presentation',
                    kind: 'presentation'
                },
                {
                    name: 'Story',
                    kind: 'story'
                },
            ]
        },
    },
};
</script>
