export default {
    created: function () {},
    mounted() {},
    data: () => ({}),
    computed: {},
    methods: {
        backspaceMethodPopulated(ev) {
            window.caret = this.cursor;
            const previousCursor = this.document.sections[this.sectionIndex].content.length;
            console.error(previousCursor, this.document.sections[this.sectionIndex].content);
            console.error(this.section.content.length, this.section.content);
            window.caret = previousCursor;
            console.error("backspaceMethodPopulated");
            const text = this.section.content;
            if (text.length < 1 && text.startsWith(" ")) {
                this.callRemoveSection();
                // return
            }
            this.$emit("updatePreviousSection", [this.sectionIndex, text]);
            this.$emit("focusSection", this.sectionIndex);

            this.$nextTick(() => {
                this.callRemoveSection(this.sectionIndex);
            });
            return;

            // let element = this.$refs[this.sectionId].$refs.contenteditable.$refs.element;
            // let element = this.$refs[this.sectionId].$refs.contenteditable.$refs.element;
            // let element;
            // this.$nextTick(() => {
            //     setTimeout(() => {
            //         element = this.$refs['contenteditable'].$refs.element;
            //         this.setCaretPosition(element, previousCursor);
            //     },200);
            // });

            // const prevSectionText = this.$parent.sections[newIndex].content;
            // console.log(prevSectionText);

            // this.$nextTick(() => {
            // this.$emit('removeSection', this.index);
            //     this.callRemoveSection();
            // });
        },
        backspaceMethodEmpty(ev) {
            console.error("backspaceMethodPopulated");
            console.log(ev);
            this.$emit("removeSection", this.index);
            // this.callRemoveSection();
            return;
        },
        escapeKeyMethod(event) {
            this.slashMenu = false;
            this.showMenu = false;
        },
        onSlash(event) {
            console.log(event);
            this.slashMenu = true;
        },
        keyShiftEnter(event) {
            console.error("shift enter");
            let selection = window.getSelection();
            // Get the range object representing the selected text
            let range = selection.getRangeAt(0);
            // Insert a newline character into the contenteditable element
            let newLine = document.createTextNode("\n");
            range.insertNode(newLine);
            // Set the new cursor position after the newline character
            range.setStartAfter(newLine);
            range.setEndAfter(newLine);
            selection.removeAllRanges();
            selection.addRange(range);
            // Get the current text selection
        },
        keyArrowUpDown(ev) {
            if (ev.metaKey && ev.shiftKey && (ev.key === "ArrowUp" || ev.key === "ArrowDown")) {
                // Make sure a valid array is provided
                if (Object.prototype.toString.call(this.document.sections) !== "[object Array]") {
                    throw new Error("Please provide a valid array");
                }

                const index = this.index;
                // Delete the item from it's current position
                var item = this.document.sections.splice(index, 1);

                // Make sure there's an item to move
                if (!item.length) {
                    throw new Error("There is no item in the array at index " + index);
                }

                // Move the item to its new position
                if (ev.key === "ArrowUp") {
                    this.document.sections.splice(index - 1, 0, item[0]);
                    // this.document.sections.splice(index - 1, 0, item[0]);
                    const newIndex = index - 1;
                    console.error(newIndex);
                    this.setSectionIndex(newIndex);
                    return;
                } else if (ev.key === "ArrowDown") {
                    this.document.sections.splice(index + 1, 0, item[0]);
                    const newIndex = index + 1;
                    console.error(newIndex);
                    this.setSectionIndex(newIndex);
                    return;
                }
                // this.document.sections.slice(this.index, 0, this.section);
                // this.setTag([this.index,'h1']);
            } else if (ev.shiftKey && (ev.key === "ArrowUp" || ev.key === "ArrowDown")) {
                let cursorPosition = this.cursorPosition();
                this.$emit("focusPreviousSelection", [this.index, ev, cursorPosition]);
                if (ev.key === "ArrowUp") {
                    this.$emit("navigatePrev");
                } else if (ev.key === "ArrowDown") {
                    this.$emit("navigateNext");
                }
            }
        },
        keyShiftLeftRight(event) {
            this.onMouseup();
        },
        keyEnterPressed(ev) {
            //excape key triggers search to close
            if (ev.shiftKey && ev.key === "Enter") {
                return;
            } else if (ev.code === "Enter" && ev.metaKey) {
                console.error("enter ctrl");
                // this.expandThis(this.section.content);
            } else if (ev.key === "Enter") {
                // this.$emit("createNewSection", this.index);
                // this.addSectionBelow();
                // this.newLine();
                this.newLine(this.index, this.section.content, null, "newline");
            }
        },
        keyPress(ev) {
            this.keyPresses(ev);
        },
        onKeyUp(ev) {
            // this.keyPresses(ev, 'up');
        },
        keyPresses(ev, press) {
            let cursorPosition;
            if (this.$refs.contenteditable.$refs.element && this.$refs.contenteditable.$refs.element.innerHTML) {
                cursorPosition = this.cursorPosition();
            }


            this.keyboardStylePicker(ev);
            if (ev.metaKey && ev.altKey && ev.key === "¡") {
                console.error("command + option + 1");
                console.error(this.$refs);
                // this.setTag([this.index,'h1']);
            }
            if (ev.key === "Escape") {
                this.wantsAction = false;
                this.doesntWantAction();
            }
            //option + shift + n creates a new section
            if (ev.shiftKey && ev.altKey && ev.key === "n") {
                console.error("option + shift + n");
                this.createDocument();
            }
            if (ev.altKey && ev.key === "n") {
                // this.$emit('createNewDocument');
                this.createDocument();
                return;
            }
            if (ev.shiftKey && ev.key === "Enter") {
                this.keyShiftEnter(ev);
                return;
            } else if (ev.key === "Enter" && ev.metaKey) {
                // this.aiHelper(this.section.content,'todo');
                this.triggerWantsAction();
                return;
            } else if (ev.key === "Enter") {
                this.keyEnterPressed(ev);
            }
            if (ev.key === "s" && ev.metaKey) {
                // this.save('documents', this.sections);
            }
            //keydown is up and down
            this.keyArrowUpDown(ev);

            if (ev.key === "ArrowLeft" || ev.key === "ArrowRight") {
                let cursorPosition;
                if (
                    this.$refs.contenteditable &&
                    this.$refs.contenteditable.$refs &&
                    this.$refs.contenteditable.$refs.element &&
                    this.$refs.contenteditable.$refs.element.innerHTML
                ) {
                    let cursorPosition = this.cursorPosition();
                }
                if (
                    this.$refs.contenteditable &&
                    this.$refs.contenteditable.$refs &&
                    this.$refs.contenteditable.$refs.element &&
                    this.$refs.contenteditable.$refs.element.innerHTML
                ) {
                    cursorPosition = this.cursorPosition();
                }
                if (ev.shiftKey) {
                    this.keyShiftLeftRight(ev);
                } else {
                    this.onMouseup();
                }
            } else if (ev.key === "/") {
                this.onSlash(ev);
            } else if (ev.key === "Backspace" && this.section.content.length > 0 && this.cursor === 0) {
                // this.discardSection();
                // this.$emit('removeSection',this.sectionIndex);
                this.backspaceMethodPopulated(ev);
            } else if (ev.key === "Backspace" && this.section.content.length === 0) {
                this.backspaceMethodEmpty(ev);
                //backspace remove
            } else if (ev.key === "Backspace" && this.selectedIndexEmpty) {
                console.error("trying to remove section");
                this.callRemoveSection();
                // this.$emit('removeSection', this.sectionIndex);
            } else if (ev.key === "Escape") {
                this.escapeKeyMethod();
            }

            // console.error(cursorPosition);
            const cursorCoord = this.getCaretCoordinates();
            // console.error(cursorCoord);
        },
        keyboardStylePicker(ev) {
            if (ev.metaKey && ev.altKey && ev.code === "Digit1") {
                this.$emit("setTag", [this.index, "h1"]);
            } else if (ev.metaKey && ev.altKey && ev.code === "Digit2") {
                this.$emit("setTag", [this.index, "h2"]);
            } else if (ev.metaKey && ev.altKey && ev.code === "Digit3") {
                this.$emit("setTag", [this.index, "h3"]);
            } else if (ev.metaKey && ev.altKey && ev.code === "Digit4") {
                this.$emit("setTag", [this.index, "h4"]);
            } else if (ev.metaKey && ev.altKey && ev.code === "Digit5") {
                this.$emit("setTag", [this.index, "p"]);
            } else if (ev.metaKey && ev.altKey && ev.code === "Digit6") {
                this.$emit("setTag", [this.index, "li"]);
            } else if (ev.metaKey && ev.altKey && ev.code === "Digit7") {
                this.$emit("setTag", [this.index, "blockquote"]);
            } else if (ev.metaKey && ev.altKey && ev.code === "Digit8") {
                this.$emit("setTag", [this.index, "div", "image"]);
            }
        },
    },
};
