export default {
    created: function () {},
    mounted() {},
    data: () => ({
        cursorPositions: [],
        currentIndex: 0,
    }),
    computed: {},
    methods: {
        saveCursorPosition(index) {
            const selection = window.getSelection();
            if (selection.rangeCount > 0) {
                const range = selection.getRangeAt(0);
                this.cursorPositions[index] = range.startOffset;
            }
        },
        // Restore the cursor position when navigating back to a contenteditable element
        restoreCursorPosition(index) {
            let section = this.$refs[this.sectionId];
            let sectionTotal = section.$refs.contenteditable.$refs.element;
            const element = sectionTotal;
            // console.error(element);
            const range = document.createRange();
            const sel = window.getSelection();
            range.setStart(element.childNodes[0], element.childNodes[0] + 1);
            console.error(range);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        },
        // Navigate to the next contenteditable element
        navigateNext() {
            console.error("wrong methos triggerd");
            this.saveCursorPosition(this.sectionIndex);
            if (this.sectionIndex === this.cursorPositions.length - 1) {
                this.sectionIndex = 0;
            } else {
                // this.sectionIndex++;
                this.nextSection();
            }
            this.restoreCursorPosition(this.sectionIndex);
        },
        navigatePrev() {
            console.error("wrong methos triggerd");
            this.saveCursorPosition(this.sectionIndex);
            if (this.sectionIndex === 0) {
                this.sectionIndex = this.cursorPositions.length - 1;
            } else {
                this.previousSection();
            }
            this.restoreCursorPosition(this.sectionIndex);
        },
    },
};
