<script>
export default {
    data: () => ({}),
    props: ['text', 'small', 'icon', 'stateBool'],
    mounted() {},
    computed: {},
};
</script>
<template>
    <transition appear enter-active-class="fadeInUpSmooth ease-back duration-1 d-10 animated" leave-active-class="fadeOutDown ">
        <div class="width-50 height-50 bg-snow br-50 f aic jcc z-3 m-1 m-md-3" :class="``" >
            <i v-if="!stateBool" aria-hidden="true" class="fal" :class="`fa-${icon}`"></i>
            <i v-else aria-hidden="true" class="fal fa-times f-24"></i>
        </div>
    </transition>
</template>
<style lang="scss">
</style>
