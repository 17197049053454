<template>
    <div v-if="wantsAction" class="p-fixed p-fill f aic jcc z-5">
        <div class="animated fadeIn p-fixed p-fill bg-base-800/50" @click.prevent="closeModal()"></div>
        <Transition
            enter-active-class="animated swingInUp duration-1"
            leave-active-class="animated fadeOutDownSmooth"
            appear>
            <div class="z-5" appear>
                <div class="z-5 br-10 border-light x max-w-lg overflow-hidden bg-snow shadow-md shadow-gray-500/10">
                    <div class="border-bottom bg-gray-100">
                        <div class="p-2">
                            <div class="mb-2">
                                <span class="fwsb d-block">Modify this passage: </span>
                                {{ section.content.slice(0, 150) }} ...
                            </div>
                            <input
                                ref="searchField"
                                v-model="search"
                                class="chatInput bg-white mb-0"
                                type="text"
                                @keyup.esc="closeModal"
                                @keydown.up="highlightPrev"
                                @keydown.enter="selectHighlighted"
                                @keydown.down="highlightNext" />
                        </div>
                    </div>

                    <div class="list x scrollSection max-h-64 scrollbar-hide">
                        <div
                            v-for="(item, i) in filteredActions"
                            :key="'a' + item.id"
                            class="x border-bottom py-2 px-3 text-gray-500"
                            :class="[buttonClass, { highlighted: i === highlightedIndex }]"
                            @click.prevent="itemClicked(item, section)">
                            <i :class="item.iconClass" class="mr-3 text-gray-500"></i>{{ item.label }}
                        </div>
                        <div
                            v-for="tag in filteredStyles"
                            :key="tag.id"
                            :class="buttonClass"
                            class="x border-bottom py-2 px-3 text-gray-500"
                            @click.prevent="itemClicked(tag)">
                            <i class="fas fa-header mr-3 text-gray-500"></i>Change text to {{ tag.longLabel
                            }}<span class="o-5 ml-1">({{ tag.label }})</span>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>
<script>
import documentMixins from "@/mixins/documentMixins";
import imageMixin from "@/mixins/imageMixin";

export default {
    name: "SearchActions",
    mixins: [documentMixins, imageMixin],
    props: ["close", "index", "array", "section", "wantsAction"],
    data: () => ({
        search: "",
        highlightedIndex: -1,
        loadAction: false,
    }),
    computed: {
        buttonClass() {
            return "text-gray-900 hover:text-green fwb right d-block z-3 px-3 py-2 border-top hover:bg-base-50 cursor-pointer";
        },
        filteredActions() {
            if (this.search && this.search.length > 1) {
                return this.array.filter(action => action.label.toLowerCase().includes(this.search.toLowerCase()));
                let imageAction = {
                    id: 0,
                    label: "Generate Image",
                    type: "image",
                    iconClass: "fas fa-image",
                };
                this.array.shift();
                if (this.array && this.array.length && this.array.length > 0) {
                    return this.array
                        .filter(action => action.label.toLowerCase().includes(this.search.toLowerCase()))
                        .sort((a, b) => {
                            const aIndex = a.label.toLowerCase().indexOf(this.search.toLowerCase());
                            const bIndex = b.label.toLowerCase().indexOf(this.search.toLowerCase());
                            if (aIndex < bIndex) return -1;
                            if (aIndex > bIndex) return 1;
                            return 0;
                        });
                }
            } else {
                return this.array;
            }
        },
        filteredStyles() {
            if (this.search && this.search.length > 1) {
                return this.tags.filter(action => action.label.toLowerCase().includes(this.search.toLowerCase()));
                if (this.tags && this.tags.length && this.tags.length > 0) {
                    return this.array
                        .filter(style => style.longLabel.toLowerCase().includes(this.search.toLowerCase()))
                        .sort((a, b) => {
                            const aIndex = a.longLabel.toLowerCase().indexOf(this.search.toLowerCase());
                            const bIndex = b.longLabel.toLowerCase().indexOf(this.search.toLowerCase());
                            if (aIndex < bIndex) return -1;
                            if (aIndex > bIndex) return 1;
                            return 0;
                        });
                }
            } else {
                return this.tags;
            }
        },
    },
    watch: {
        wantsAction() {
            this.$nextTick(() => {
                this.$refs.searchField.focus();
            });
        },
    },
    mounted() {},
    methods: {
        itemClicked(item, index, payload) {
            console.error(item);
            if (item.actionType === "image") {
                this.generateImage(this.index, this.section);
            } else if (item.actionType === "style") {
                this.setTag(item);
            } else {
                this.setTag("h3");
                this.aiHelper(this.section.content, item.type, this.index);
            }
            this.closeModal();
        },
        setTag(tag) {
            this.$emit("setTag", [this.index, tag]);
            this.closeModal();
        },
        closeModal() {
            this.search = "";
            this.highlightedIndex = -1;
            this.$emit("close");
        },
        selectHighlighted() {
            if (this.highlightedIndex >= 0) {
                // this.search = this.filteredActions[this.highlightedIndex].name;
                // let type = this.filteredActions[this.highlightedIndex].type;
                // this.aiHelper(this.section.content, type, this.index, this.document.tone);
                this.itemClicked(this.filteredActions[this.highlightedIndex]);
                this.search = "";
                this.loadAction = true;
                // this.closeModal();
            }
        },
        fetchingSection(event) {
            console.error("helloooooooooo");
            console.error(event);
            this.$emit("fetchingSection", event);
            return event;
        },
        highlightPrev() {
            if (this.highlightedIndex > 0) {
                this.highlightedIndex--;
            }
        },
        highlightNext() {
            if (this.highlightedIndex < this.filteredActions.length - 1) {
                this.highlightedIndex++;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
ul {
    margin-left: 1.5rem;
    display: block;
    list-style-type: disc !important;

    li {
        margin-left: 1.5rem;
        list-style-type: disc !important;
        display: list-item !important;
        font-size: 1rem;
    }
}

div li {
    margin-left: 1rem;
    font-size: 1rem;
    list-style-type: disc !important;
    display: list-item !important;

    &:first-of-type {
        margin-top: 1rem;
    }
}

ol {
    margin-left: 1.5rem;
    display: block;
}

p {
    ul {
        margin-left: 3rem !important;
        display: block;
    }
}

h1 {
    font-size: 2.5rem;
    //color: #353554 !important; margin-bottom: 2rem;
}

h2 {
    font-size: 2rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
    margin-bottom: 0rem;
    margin-top: 0rem;
}

h1,
h2,
h3 {
    //add pseudo classs :before with a margin of 2em
    //&:before{
    //    content: ' ';
    //    display: block;
    //    margin-top: .3em;
    //    visibility: hidden;
    //}
}

p {
    font-size: 1rem;
}

.highlighted {
    background-color: #eee;
}
</style>
