<template>
    <div class="p-3 br-10 bg-gray-50/50 shadow my-3">
        <div class="p-3 text-gray-300 fwsb border-dashed border-4 br-10 border-gray-200 f-20 f fc aic jcc">
            <div class="fa-1x">
                <i class="fa-duotone fa-loader fa-pulse"></i>
            </div>
            <span class="text-center" :class="{'f-13':loadingText.length > 15}">{{ loadingText }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'section-loader',
    props: {
        sectionFetching: {},
        loadingText: {
            type: String,
            default: 'Loading content'
        }
    }
};
</script>
<style lang="scss" scoped>
ul{
    margin-left: 1.5rem;
    display: block;
    list-style-type: disc !important;

    li{
        margin-left: 1.5rem;
        list-style-type: disc !important;
        display: list-item !important;
        font-size: 1rem;
    }
}

div li{

    margin-left: 1rem;
    font-size: 1rem;
    list-style-type: disc !important;
    display: list-item !important;

    &:first-of-type{
        margin-top: 1rem;
    }
}

ol{
    margin-left: 1.5rem;
    display: block;

}

p{
    ul{
        margin-left: 3rem !important;
        display: block;
    }
}

h1{
    font-size: 2.5rem;
    //color: #353554 !important; margin-bottom: 2rem;
}

h2{
    font-size: 2rem;
}

h1, h2, h3, h4, h5, h6, p, span{
    margin-bottom: 0rem;
    margin-top: 0rem;
}

h1, h2, h3{
    //add pseudo classs :before with a margin of 2em
    //&:before{
    //    content: ' ';
    //    display: block;
    //    margin-top: .3em;
    //    visibility: hidden;
    //}
}

p{
    font-size: 1rem;
}

</style>
