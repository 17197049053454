<script>
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";
import ButtonBubbleArray from "@/components/buttons/ButtonBubbleArray.vue";

export default {
    components: {ButtonBubbleArray, ButtonPrimary, ButtonSecondary},
    data() {
        return {
            debug: false,
            dbGenres: null
        };
    },
    methods: {},
    async mounted() {
    },
    computed: {},
};
</script>
<template>
    <div v-if="step === 0" class="d-block funBackground" style="overflow-x: hidden">
        <div class="x text-center text-center">
            <div class="vh-80 f aic jcc fc">
                <div class="floating-item" >
<!--                    :style="`animation-delay:${50 * index}ms; animation-duration:${1000+(index*50)}ms`"-->
                    <span class="f-40 f-md-50 mb-3 white d-block">Where toys turn into tools to inspire creativity</span>
                </div>
                <button-secondary class="animated fadeInUp d-5 duration-5" @click.prevent="nextStep()" text="Get Started"></button-secondary>
            </div>
        </div>
        <div class="container mb-7">
            <div class="row">
                <div class="col-12 col-md-4">
                    <h4>Get inspired</h4>
                    <p>Clear mind is a tool to inspire creativity with ease.</p>
                </div>
                <div class="col-12 col-md-4">
                    <h4>Keep it simple</h4>
                    <p>With a simple interface, you can create a song in a few minutes.</p>
                </div>
                <div class="col-12 col-md-4">
                    <h4>Build on ideas</h4>
                    <p>We help you iterate on ideas quickly.</p>
                </div>
            </div>
        </div>
    </div>

</template>
<style lang="scss">
</style>
