<template>
    <li class="nav-link px-2" @mouseover="hoverMenu" @mouseleave="leaveMenu">
        <span>Tools</span>
        <ul class="dropdown-content p-fixed bg-snow p-3 shadow br-5 right mr-2 z-5 f fc" v-if="showSubMenu">
            <li v-for="item in subArray" class="nav-link px-2">
                <router-link :to="item.path"> {{ item.title }}</router-link>
            </li>
        </ul>
    </li>
</template>
<script>
export default {
    name: 'nav-item',
    props: {
        subArray: {}
    },
    data: () => ({
        showSubMenu: false,
    }),
    methods:{
        hoverMenu(){
            if(this.subArray && this.subArray.length > 0){
                this.showSubMenu = true;
            }
        },
        leaveMenu(){
            this.showSubMenu = false;
        }
    }
};
</script>
