<script>
import Logo from "@/components/navigaion/logo.vue";
import NavItem from "@/components/navigaion/NavItem.vue";

export default {
    components: { NavItem, Logo },
    data: () => ({
        showMenu: false,
        navItems: [
            { title: "Documents", path: "/documents" },
            { title: "Decks", path: "/presentations" },
            { title: "Canvas", path: "/canvas" },

            // {title: 'Discover', path: '/discover'}
        ],
        toolItems: [
            { title: "My Songs", path: "/profile" },
            { title: "Home", path: "/" },
            { title: "Image Play", path: "/imgplay" },
            { title: "Music", path: "/music" },
            { title: "Finetune Lyrics", path: "/press" },
            { title: "Genres", path: "/genres" },
            { title: "Artists", path: "/artists" },
            { title: "Genius Search", path: "/lyrics" },
        ],
        logoColorStart: "rgba(0, 0, 0, 1)",
        logoColorEnd: "rgba(0, 0, 0, 1)",
        navColorStart: "rgba(57, 69, 105, 1)",
        navColorEnd: "rgba(0, 0, 0, 1)",
        navIconColorStart: "rgba(0, 0, 0, 1)",
        navIconColorEnd: "rgba(0, 0, 0, 1)",
        navShadowStart:
            "0 1px 1px rgba(0,0,0,0.0), 0 2px 2px rgba(0,0,0,0.0), 0 4px 4px rgba(0,0,0,0.0), 0 8px 8px rgba(0,0,0,0.0), 0 16px 16px rgba(0,0,0,0.0)",
        navShadowEnd:
            "0 1px 1px rgba(0,0,0,0.02), 0 2px 2px rgba(0,0,0,0.03), 0 4px 4px rgba(0,0,0,0.02), 0 8px 8px rgba(0,0,0,0.02), 0 16px 16px rgba(0,0,0,0.02)",
        backgroundColorStart: "rgba(255, 255, 255, 0)",
        backgroundColorEnd: "rgba(255, 255, 255, 1)",
        marginTopStart: 0,
        marginTopEnd: 0,
        borderBottomStart: "0px solid rgba(0,0,0,0.0)",
        borderBottomEnd: "1px solid rgba(0,0,0,0.1)",
        paddingLeft: "1em",
        paddingRight: "1em",
        paddingBottom: "1em",
        opacityStart: 0,
        opacityEnd: 0,
        showAlertBar: true,
        showDesktopNav: false,
        aboveNavScene: null,
        mobileNavScene: null,
    }),
    computed: {
        logoColor() {
            if (this.toggleView || this.step === 3) {
                return "rgba(255,255,255,1)";
            }
            if (this.$route.path.includes("/profile")) {
                return "rgba(255,255,255,1)";
            } else {
                return "rgba(0,0,0,1)";
            }
        },
        showSolidHeader() {
            return (
                this.$route.path.includes("/documents") ||
                this.$route.path.includes("/imgplay") ||
                this.$route.path.includes("/free") ||
                this.$route.path.includes("/canvas") ||
                this.$route.path.includes("/presentations")
            );
        },
        bgGradient() {
            let color;
            let gradient = true;
            if (this.step >= 3) {
                color = "rgba(0,0,0,1)";
            } else {
                color = "#f5cfea";
            }

            if (this.$route.path.includes("/profile")) {
                color = "rgba(0,0,0,1)";
            }
            if (this.showSolidHeader) {
                gradient = false;
                color = "rgba(255,255,255,1)";
            }
            if (this.darkMode) {
                color = "rgba(0,0,0,1)";
            }
            let background = `background-image: linear-gradient(0deg, transparent,${color})`;
            if (!gradient) {
                background = `background:${color};overflow-x:hidden;background-image:${color}`;
                // background = `background:${color};position:relative;overflow-x:hidden;background-image:${color}`;
            }

            return background;
        },
    },
    watch: {
        $route: {
            handler(to, from) {
                this.bodyFix();
                // this.loadNavScroll();
                if (from != to) {
                    this.showMenu = false;
                }
            },
            immediate: true,
        },
        // $route(oldVal, newVal) {
        //
        //
        // }
    },
    mounted() {
        this.navBarHeight();
    },
    methods: {
        navBarHeight() {
            this.$nextTick(() => {
                if (!this.$refs.navBar) return;
                const navBarHeight = this.$refs.navBar.clientHeight;
                // add a CSS class to head called navTop
                const head = document.querySelector("head");
                // add properties to a CSS class called navTop
                let navTop = document.createElement("style");
                navTop.type = "text/css";
                navTop.innerHTML = `
                .pt-nav{ padding-top:${navBarHeight}px!important; }
                .top-nav{ top:${navBarHeight}px!important; }
                .h-nav{ height:${navBarHeight}px!important; }
                .navTop{ top:${navBarHeight}px!important; }
                .mt-nav{ margin-top:${navBarHeight}px!important; }`;
                head.appendChild(navTop);
                head.classList.add("navTop");
            });
        },
        bodyFix() {
            let route = this.$route.path === "/";
            if (route) {
                this.lockBody();
                const body = document.querySelector("body");
                body.classList.add("funBackground");
                console.log("lock body");
            } else {
                this.unlockBody();
                const body = document.querySelector("body");
                body.classList.remove("funBackground");

                console.log("unlock body");
            }
        },
        lockBody() {
            const body = document.querySelector("body");
            body.classList.add("bodyHeightFix");
            body.classList.add("p-fixed");
            body.classList.add("p-fill");
        },
        unlockBody() {
            const body = document.querySelector("body");
            // body.classList.remove('bodyHeightFix p-fixed p-fill');
            body.classList.remove("bodyHeightFix");
            body.classList.remove("p-fixed");
            body.classList.remove("p-fill");
        },
        loadNavScrollStyles() {
            var self = this;
            const padding = "1em";
            const paddingEnd = "1em";
            this.borderBottomStart = 0;
            this.borderBottomEnd = 0;
            const border0 = "0px solid rgba(0,0,0,0.0)";
            const border1 = "1px solid rgba(0,0,0,0.1)";
            const black = this.navColors("black");
            const white = this.navColors("white");
            const whiteBG = this.navColors("whiteBG");
            const whiteGradient = this.navColors("whiteGradient");
            const blackGradient = this.navColors("blackGradient");
            const blue = this.navColors("blue");
            const transparent = this.navColors("transparent");
            const sustainability = this.navColors("sustainability");
            const mouthwash = this.navColors("mouthwash");
            let navStart = blue;
            this.backgroundColorStart = transparent;
            this.backgroundColorEnd = white;
            let navEnd = black;
            let navBGStart = transparent;
            let navBGEnd = white;
            let borderStart = border0;
            let borderEnd = border0;
            const handle = this.$route.meta.handle;
            const routeName = this.$route.name;

            navStart = black;
            navEnd = black;
            navBGStart = transparent;
            navBGEnd = whiteBG;

            if (this.$route.name === "index" || this.$route.name === "getStarted") {
                if (this.mobile) {
                    navStart = black;
                } else {
                    navStart = blue;
                }
                navBGStart = transparent;
                navBGEnd = whiteBG;
            } else if (this.$route.meta && this.$route.meta.product) {
                if (handle === "mouthwash") {
                    navStart = mouthwash;
                } else if (routeName === "DeodorantLanding") {
                    navStart = white;
                } else if (handle === "whitening" || routeName === "Deodorant") {
                    navStart = black;
                } else if (this.$root.productColor(handle) && this.$root.productColor(handle).dark) {
                    const dark = this.$root.productColor(handle).dark;
                    const light = this.$root.productColor(handle).light;

                    if (this.mobile) {
                        navStart = white;
                        navEnd = black;
                        navBGStart = transparent;
                        navBGEnd = whiteBG;
                    } else {
                        navStart = dark;
                        navEnd = black;
                        navBGStart = transparent;
                        navBGEnd = whiteBG;
                    }
                } else {
                    navStart = blue;
                    navEnd = black;
                }
            } else if (routeName === "Plastic") {
                navStart = sustainability;
                navEnd = black;
            } else if (routeName === "about") {
                navStart = white;
                navEnd = black;
            }
            if (this.$route.meta.normalNav) {
                navBGStart = whiteBG;
                navBGEnd = whiteBG;
                borderStart = border1;
                borderEnd = border1;
            }

            this.logoColorStart = navStart;
            this.logoColorEnd = navEnd;
            this.navColorStart = navStart;
            this.navColorEnd = navEnd;
            this.navIconColorStart = navStart;
            this.navIconColorEnd = navEnd;
            this.backgroundColorStart = navBGStart;
            this.backgroundColorEnd = navBGEnd;

            this.opacityStart = 1;
            this.opacityEnd = 1;

            this.marginTopStart = padding;
            this.marginTopEnd = padding;

            this.borderBottomStart = borderStart;
            this.borderBottomEnd = borderEnd;

            if (this.$store.state.facebookBrowser) {
                this.marginTopStart = padding;
                this.marginTopEnd = padding;
            }
        },
        loadNavScroll() {
            this.loadNavScrollStyles();

            const sceneNavTimeline = new TimelineMax().add([
                // nav logo color
                TweenMax.fromTo(
                    [".logo"],
                    1,
                    {
                        fill: this.logoColorStart,
                    },
                    {
                        fill: this.logoColorEnd,
                        ease: Linear.easeOut,
                    },
                ),
                // nav cart icon color
                TweenMax.fromTo(
                    [".cartIcon"],
                    1,
                    {
                        fill: this.logoColorStart,
                    },
                    {
                        fill: this.logoColorEnd,
                        ease: Linear.easeOut,
                    },
                ),
                // nav text color
                TweenMax.fromTo(
                    [".navText"],
                    1,
                    {
                        color: this.navColorStart,
                    },
                    {
                        color: this.navColorEnd,
                        ease: Linear.easeOut,
                    },
                ),
                // nav right-side text
                TweenMax.fromTo(
                    [".nav-icon"],
                    1,
                    {
                        color: this.navColorStart,
                    },
                    {
                        color: this.navColorEnd,
                        ease: Linear.easeOut,
                    },
                ),
                // nav background
                TweenMax.fromTo(
                    [".mobilenav"],
                    1,
                    {
                        opacity: 1,
                        background: this.backgroundColorStart,
                        borderBottom: this.borderBottomStart,
                        delay: 0,
                    },
                    {
                        opacity: 1,
                        background: this.backgroundColorEnd,
                        borderBottom: this.borderBottomEnd,
                        delay: 0.1,
                        ease: Linear.easeOut,
                    },
                ),
                // nav box-shadow
                TweenMax.fromTo(
                    [".mobilenav"],
                    1,
                    {
                        boxShadow: this.navShadowStart,
                        delay: 0.3,
                    },
                    {
                        boxShadow: this.navShadowEnd,
                        delay: 0.3,
                        ease: Linear.easeOut,
                    },
                ),
            ]);
            this.mobileNavScene = new ScrollMagic.Scene({
                triggerElement: $("body"),
                triggerHook: 0,
                offset: 10,
                duration: 30,
            })
                .setTween(sceneNavTimeline)
                .addTo(controller);

            this.mobileNavScene.update(true);
            controller.update(true);
        },
    },
};
</script>
<template>
    <header
        class="p-fixed top left right z-3 x f aic jcb border-bottom py-md-2 py-0 px-2"
        ref="navBar"
        :style="bgGradient">
        <RouterLink class="f aic jcs width-100 width-min-150 d-block logo mb-0 cursor-pointer pb-0" to="/">
            <Logo :fill-color="logoColor"></Logo>
        </RouterLink>
        <div class="f aic jcb x p-relative">
            <div class="x f aic jce">
                <template v-if="!mobile">
                    <ul class="f">
                        <template v-if="debug">
                            <li class="nav-link px-2">
                                <a
                                    href="#"
                                    :style="`color:${logoColor}!important;`"
                                    class="f-12 z-3 small p-2 transition-all"
                                    @click.prevent="resetChat()"
                                    >Reset</a
                                >
                            </li>
                        </template>
                        <li v-for="item in navItems" class="nav-link px-2">
                            <RouterLink :to="item.path"> {{ item.title }}</RouterLink>
                        </li>
                        <NavItem :sub-array="toolItems" title="Tools"></NavItem>
                    </ul>
                </template>
                <template v-else>
                    <template v-if="debug">
                        <a
                            href="#"
                            :style="`color:${logoColor}!important;`"
                            class="f-12 z-3 p-2 transition-all"
                            @click.prevent="resetChat()"
                            >Reset</a
                        >
                    </template>
                    <!--                    <RouterLink-->
                    <!--                        v-if="$route.path !== '/profile'"-->
                    <!--                        :style="`color:${logoColor}!important;`"-->
                    <!--                        class="f-12 z-3 p-2 transition-all"-->
                    <!--                        to="/profile"-->
                    <!--                        >My songs</RouterLink-->
                    <!--                    >-->
                    <!--                    <RouterLink-->
                    <!--                        v-else-->
                    <!--                        :style="`color:${logoColor}!important`"-->
                    <!--                        class="f-12 z-3 p-2 transition-all"-->
                    <!--                        to="/"-->
                    <!--                        >Create</RouterLink-->
                    <!--                    >-->
                    <div
                        :style="`color:${logoColor}!important`"
                        class="f-24 z-3 line-height-smaller py-3 px-2 transition-all"
                        @click.prevent="showMenu = !showMenu">
                        <i class="fa-solid fa-bars"></i>
                    </div>
                    <Transition
                        duration="300"
                        enter-active-class="animated slideInRight duration-1 ease-1"
                        leave-active-class="animated slideOutRight"
                        name="drawer">
                        <div
                            v-if="showMenu"
                            appear
                            class="p-fixed top right bottom w-75 vh-100 z-5 bg-gray-800 bg-base-700 p-4 pt-7">
                            <li class="white animated nav-link f-20 p-absolute top left p-3" appear>
                                <a class="white" @click.prevent="showMenu = !showMenu">
                                    <i class="fal o-5 fa-times-circle f-30"></i>
                                </a>
                            </li>
                            <ul class="mobileNav">
                                <TransitionGroup duration="100" leave-active-class="animated fadeOutDown" name="item">
                                    <li
                                        v-for="(item, index) in navItems"
                                        :key="`${item + index}`"
                                        :class="`d-${index + 1}`"
                                        appear
                                        class="animated nav-link duration-1 ease-back swingInUp px-2">
                                        <RouterLink :to="item.path" active-class="text-base-900" class="f-30">
                                            {{ item.title }}</RouterLink
                                        >
                                    </li>
                                </TransitionGroup>
                            </ul>
                        </div>
                    </Transition>
                </template>
            </div>
        </div>
    </header>
</template>
<style lang="scss" scoped>
//@import '../../../public/assets/main';

.dropdown-menu {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}
.logo {
    path {
        //fill: rgba(255, 255, 255);
    }
}

.dark-header {
    //.logo{
    //    path{
    //        fill: black;
    //    }
    //}
}

@media (max-width: 576px) {
    .btn.btn-primary {
        font-size: 0.8rem !important;
        padding: 5px 15px !important;
    }
}

body {
    //overflow:hidden;
    //@extend .funBackground!important;
    //overflow-x: hidden !important;
    //max-width: 100vw !important;
    //padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)!important;
}
//mobile .navlink size
.mobileNav {
    .nav-link a {
        color: white;
        &.router-link-active {
            color: red !important;
        }
        &.router-link-exact-active {
            color: red !important;
        }
    }
}
.nav-link {
    font-size: 1.2rem !important;
}
.nav-link:hover {
    text-decoration: none !important;
}
// desktop .navlink size
@media (min-width: 576px) {
    .nav-link {
        font-size: 0.9rem !important;
    }
}
.nav-link {
    //@extend .f-11;
    &li {
        margin: 0px !important;
        padding: 0px !important;
    }
    line-height: 1;
    a {
        //font-size: 0.8rem;
        font-weight: 600 !important;
        color: black;
        @extend .text-uppercase, .ls-1!optional;
    }
}
</style>
