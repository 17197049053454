<script setup>
import MySongs from "@/components/music/MySongs.vue";

</script>

<script>
import firestore from "@/firebase";

export default {
    data() {
        return {};
    },
    created() {
        this.getDBItem('feedItem');

    },
    async mounted() {

    },
    computed: {
        userFeed() {
            return this.$store.state.user.feed;
        }
    },
    methods: {}
};
</script>
<template>
    <div class="x text-base-600 p-relative overflow-y-scroll x y" style="overflow-x:hidden;">
        <div class=" x">
            <my-songs :list="userFeed"></my-songs>
        </div>
    </div>
</template>
<style lang="scss" scoped>
body{
    background-color: black;
}
</style>
