import { doc, getDoc, collection, Timestamp } from "firebase/firestore";
import { ref as storageRef } from "firebase/storage";
import { isProxy, toRaw } from "vue";
import { useFirebaseStorage, useStorageFile } from "vuefire";
import { useCollection, useDocument } from "vuefire";

import { db } from "@/firebase";

const storeMethods = {
    pushAlert(obj) {
        this.$store.commit("pushAlert", obj);
    },
    setSectionIndex(index) {
        this.$store.commit("setSectionIndex", index);
    },
    // addSectionBelow(index, payload) {
    //     console.error(payload.kind);
    //     let section = payload;
    //     const indexBelow = index + 1;
    //     section.index = indexBelow;
    //     console.error("New section: Below");
    //     // console.error(index, section);
    //     this.$store.commit("addSectionBelow", section);
    //     this.setSectionIndex(indexBelow);
    // },
    // addSectionAbove(index, payload) {
    //     let section = payload;
    //     const indexAbove = index - 1;
    //     console.error("New section: above");
    //     console.error(index, section);
    //     this.$store.commit("addSectionAbove", payload);
    //     this.setSectionIndex(indexAbove);
    // },
    // addSectionAtEnd(index, payload) {
    //     let section = payload;
    //     const indexBelow = this.document.sections.length + 1;
    //     section.index = indexBelow;
    //     console.error("New section: end");
    //     this.$store.commit("addSectionAtEnd", payload);
    // },
    setDocuments(documents) {
        this.$store.commit("setDocuments", documents);
    },
    setDocument(d) {
        window.doc = d;
        this.$store.commit("setDocument", d);
    },
    addDocument(document) {
        this.$store.commit("setAddDocument", document);
    },
    storeDocument(document) {
        this.$store.commit("storeDocument", document);
    },
};
export const docStore = {
    state() {
        return {
            alerts: [],
            checkoutStatus: null,
            documents: [],
            sectionIndex: 1,
            sectionId: "section-1",
            sections: [],
            document: {
                id: null,
                title: null,
                description: null,
                summary: null,
                sections: [],
                created: null,
                updated: null,
                favorite: false,
                kind: "text",
                tag: "div",
            },
        };
    },

    mutations: {
        pushAlert(state, payload) {
            state.alerts.push(payload);
        },
        setSectionIndex(state, index) {
            state.sectionIndex = index;
            state.sectionId = "section-" + (index + 1);
        },
        setDocuments(state, payload) {
            state.documents = payload;
        },
        setDocument(state, payload) {
            window.doc = payload;
            state.document = payload;
        },
        setAddDocument(state, payload) {
            state.documents.push(payload);
        },
        storeDocument(state, payload) {
            state.documents.push(payload);
        },
        setCurrentDocument(state, payload) {
            state.currentDocument = payload;
        },
    },
};
const computedStore = {
    alerts() {
        return this.$store.state.alerts;
    },
    document: {
        get() {
            window.doc = this.$store.state.document;
            return this.$store.state.document;
        },
        set(value) {
            this.$store.commit("setDocument", value);
            window.doc = this.$store.state.document;
        },
    },
    sections() {
        let sections = this.$store.state.document.sections;
        if (isProxy(sections)) {
            return toRaw(sections);
        } else {
            return sections;
        }
    },
    sectionIndex() {
        return this.$store.state.sectionIndex;
    },
    sectionId() {
        return this.$store.state.sectionId;
    },
};
export default {
    data: () => ({
        actionItems: [
            {
                id: 1,
                type: "image",
                iconClass: "fas fa-image",
                label: "Generate Image",
                actionType: "image",
            },
            {
                id: 2,
                type: "about",
                iconClass: "fas fa-worm",
                label: "Learn More",
                actionType: "ai",
            },
            { id: 3, type: "expand", iconClass: "fas fa-star", label: "Expand", actionType: "ai" },
            {
                id: 4,
                type: "simplify",
                iconClass: "fas fa-circle",
                label: "Simplify",
                actionType: "ai",
            },
            { id: 5, type: "legal", iconClass: "fas fa-book", label: "Legal", actionType: "ai" },
            {
                id: 6,
                type: "howto",
                iconClass: "fas fa-lightbulb",
                label: "How to",
                actionType: "ai",
            },
            {
                id: 7,
                type: "todo",
                iconClass: "fas fa-list",
                label: "To do list",
                actionType: "ai",
            },
            {
                id: 8,
                type: "outline",
                iconClass: "fas fa-clipboard-list-check",
                label: "Create outline",
                actionType: "ai",
            },
            {
                id: 9,
                type: "versions",
                iconClass: "fas fa-sync",
                label: "Create versions",
                actionType: "ai",
            },
            // { id: 8, type: 'image', iconClass: 'fas fa-image', label: 'Generate Image' }
        ],
        tags: [
            {
                id: 1,
                iconClass: "fas fa-heading",
                type: "h1",
                label: "H1",
                longLabel: "Heading 1",
                actionType: "style",
            },
            {
                id: 2,
                iconClass: "fas fa-heading",
                type: "h2",
                label: "H2",
                longLabel: "Heading 2",
                actionType: "style",
            },
            {
                id: 3,
                iconClass: "fas fa-heading",
                type: "h3",
                label: "H3",
                longLabel: "Heading 3",
                actionType: "style",
            },
            {
                id: 4,
                iconClass: "fas fa-heading",
                type: "p",
                label: "P",
                longLabel: "Paragraph",
                actionType: "style",
            },
            {
                id: 5,
                iconClass: "fas fa-heading",
                type: "li",
                label: "•",
                longLabel: "List item",
                actionType: "style",
            },
            {
                id: 6,
                iconClass: "fas fa-heading",
                type: "ol",
                label: "1.)",
                longLabel: "Ordered list",
                actionType: "style",
            },
            {
                id: 7,
                iconClass: "fas fa-heading",
                type: "img",
                label: "Img",
                longLabel: "Image",
                actionType: "style",
            },
            {
                id: 7,
                iconClass: "fas fa-heading",
                type: "div",
                label: "Div",
                longLabel: "Div",
                actionType: "style",
            },
            {
                id: 9,
                iconClass: "fas fa-heading",
                type: "span",
                label: "Span",
                longLabel: "Span",
                actionType: "style",
            },
            {
                id: 10,
                iconClass: "fas fa-heading",
                type: "blockquote",
                label: "Blockquote",
                longLabel: "Blockquote",
                actionType: "style",
            },
            {
                id: 11,
                iconClass: "fas fa-heading",
                type: "pre",
                label: "Pre",
                longLabel: "Pre",
                actionType: "style",
            },
            {
                id: 12,
                iconClass: "fas fa-heading",
                type: "a",
                label: "A",
                longLabel: "Link",
                actionType: "style",
            },
        ],
        tones: [
            { id: 1, label: "Expert" },
            { id: 2, label: "Professional" },
            { id: 3, label: "Formal" },
            { id: 4, label: "Friendly" },
            { id: 5, label: "Informal" },
            { id: 6, label: "Casual" },
            { id: 7, label: "Very casual" },
            { id: 8, label: "Legal" },
        ],
        saving: false,
        mousePosition: { x: null, y: null },
        // artist: null,
    }),
    computed: {
        ...computedStore,
        documentState() {
            return this.$store.state.savingDocument;
        },
        selectedIndexEmpty() {
            if (this.sectionIndex === this.index && this.section.content === "") {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        // helps track last modified and which section is loading
        ...storeMethods,
        savingStateHelper(state, document) {
            if (state) {
                let currentSection = this.sectionIndex;
                let currentDocument = this.$route.params.id;
                let lastSaved = document.updated;
                let savingDocument = {
                    saving: true,
                    section: currentSection,
                    lastSaved: lastSaved,
                    loading: true,
                    documentId: currentDocument,
                };
                this.$store.commit("setSavingDocument", savingDocument);
                console.log("Started save", savingDocument);
            } else {
                let currentSection = this.sectionIndex;
                let currentDocument = this.$route.params.id;
                let lastSaved = this.document.updated;
                let savingDocument = {
                    saving: false,
                    loading: false,
                    section: currentSection,
                    lastSaved: lastSaved,
                    documentId: currentDocument,
                };
                this.$store.commit("setSavingDocument", savingDocument);
                console.log("Done saving", savingDocument);
            }
        },
        getRecentDocument() {
            let presentationPath = this.$route.path.includes("presentation");
            let documentPath = this.$route.path.includes("document");
            if (presentationPath) {
                let id = localStorage.getItem("lastPresentation");
                console.error(id);
                this.$router.push({ path: "/presentations/" + id });
                return id;
            } else if (documentPath) {
                let id = localStorage.getItem("lastDocument");
                console.error(id);
                this.$router.push({ path: "/documents/" + id });
                return id;
            }
        },
        storeDocId() {
            let presentationPath = this.$route.path.includes("presentation");
            let documentPath = this.$route.path.includes("document");
            if (presentationPath) {
                this.$store.commit("setLastPresentation", this.$route.params.id);
                localStorage.setItem("lastPresentation", this.$route.params.id);
                let id = localStorage.getItem("lastPresentation");
            } else if (documentPath) {
                this.$store.commit("setLastDocument", this.$route.params.id);
                localStorage.setItem("lastDocument", this.$route.params.id);
                let id = localStorage.getItem("lastDocument");
            }
        },
        docStorageHandler() {
            console.error("setGetLocalStorage");
            if (this.$route && this.$route.params && this.$route.params.id) {
                this.storeDocId();
            } else {
                console.error("no id");
                this.getRecentDocument();
            }
        },
        savingStateInit(document, silent) {
            let currentSection = this.sectionIndex;
            let currentDocument = this.$route.params.id;
            let lastSaved = this.document.updated;
            let savingDocument = {
                saving: false,
                section: currentSection,
                lastSaved: lastSaved,
                loading: false,
                documentId: currentDocument,
            };
            this.$store.commit("setSavingDocument", savingDocument);
            if (!silent) {
                console.log("Init document", savingDocument);
            } else {
                console.log(silent, currentSection);
            }
        },
        updateSection(index) {
            this.setSectionIndex(index);
        },
        async saveDoc(document) {
            console.groupCollapsed(`saving document ${document.id}`);
            this.savingStateHelper(true, document);
            let doc = toRaw(document);
            if (doc.documentType === "textEditor") {
                console.error("setting title", doc.sections[0].content);
                doc.title = doc.sections[0].content;
            }

            console.error(`Starting to save ${doc.id}`, doc);
            this.saving = true;
            let savedDoc;
            // let title = this.documentTitleHelper(document)
            // doc.title = title;
            if (this.$route.params.id) {
                console.error(`updating ${this.$route.params.id}`);
                doc.id = this.$route.params.id;
                savedDoc = await this.updateDoc(doc);
                console.error(`Document saved with id ${savedDoc.id}`, savedDoc);
            } else {
                // doc.title = this.documentTitleHelper(doc)
                doc.created = new Date();
                doc.updated = new Date();
                console.log("creating + saving");
                savedDoc = await this.saveDocument("documents", doc);
            }
            this.savingStateHelper(false, document);
            console.groupEnd(`saving document ${document.id}`);
            this.saving = false;
            return;
        },
        sortedDocuments(array) {
            let Array = this.list.sort((a, b) => {
                return b.updated - a.updated;
            });
            return Array;
        },
        sortByUpdated() {
            let Array = this.list.sort((a, b) => {
                return b.updated - a.updated;
            });
            return Array;
        },
        sortByCreated() {
            let Array = this.list.sort((a, b) => {
                return b.created - a.created;
            });
            return Array;
        },
        extractText(objects) {
            if (objects) {
                let text = "";
                for (let i = 1; i < objects.length; i++) {
                    text += objects[i].content + "\n";
                }
                return text;
            }
        },
        async updateDoc(document) {
            console.error(`Save/update doc with id: ${document.id}`, document);
            let doc = document;
            doc.updated = new Date();
            if (!doc.created) {
                doc.created = new Date();
            }
            if (!doc.title) {
                doc.title = "untitled";
            }
            if (doc.id && doc.sections && doc.sections.length > 0) {
                this.setDocument(doc);
            }
            console.error(doc);
            try {
                const savedDoc = await this.updateAnyObject("documents", doc.id, doc);
                console.error("Finally updated", savedDoc);
                return savedDoc;
            } catch (error) {
                console.error(error);
                this.addAlert({
                    type: "Error",
                    message: `Saving didn't work` + error,
                });
            }
        },
        async deleteDoc(collection, item) {
            await this.removeFromFirebase(collection, item.id, false);
        },

        documentTitleHelper(document) {
            if (document) {
                if (!document.title && document.sections[0] && document.sections[0].content) {
                    return document.sections[0].content;
                } else {
                    return document.title;
                }
            } else {
                console.error("No document provided", document);
            }
        },
        async enhancedImagePrompt(text, index, overview) {
            // let promptMod = this.generatePrompt();
            let promptMod = "";
            if (text.includes("photo")) {
                // promptMod = this.generatePhotoPrompt();
            } else if (text.includes("illustration")) {
                // promptMod = this.generateArtPrompt();
            } else {
                promptMod = "";
            }
            let sectionText = this.document.sections[index].body;
            if (this.document.sections[index].enhancedBody) {
                sectionText = this.document.sections[index].enhancedBody;
            }
            let imagePromptFrame = `\n###\nPRESENTATION CONTEXT: [The image will be supporting this passage in the presentation]\n"${sectionText}"\n\n ###\n\n`;
            // const request = {
            //     model: "text-davinci-003",
            //     prompt: `PROMPT:
            // INSTRUCTIONS: Write a prompt to add succinct but specific details about the image's subject, lighting, composition, environment, focal length or artist's style.
            // Prompt (under 300 letters):\n\n${text}\n### This is an image of: ###\n\n`,
            //     temperature: 0.9,
            //     max_tokens: 70,
            //     top_p: 0.9,
            //     frequency_penalty: 0,
            //     presence_penalty: 0,
            // };
            // NEW VERSION TEST
            const request = {
                model: "text-davinci-003",
                prompt: `${imagePromptFrame}INSTRUCTIONS: Write a keyword-dense prompt to add succinct but specific and detailed keywords about the image's subject, lighting, composition, environment and more (see examples). Keyword dense.
Add weights after positive and negative keyphrases by appending ":1" (positive / things to include) and ":-1" (negative / things to exclude). You can increase or decease the number to add more or less emphasis.
Separate all key-phrases using: " | "
###
Examples:
"photo realistic portrait of young woman, red hair, pale, realistic eyes: 1 | gold necklace with big ruby:1 | centered in frame:1 | facing camera:1 | symmetrical face:1 ideal human | 85mm lens,f8, photography:0.8 | ultra details, natural light, dark background:1 | out of focus trees in background:0.7 | 9:16:-1 | testp:-0.5"
"temple in ruines in a forest with stairs and columns in the background: 1.5 | cinematic and detailed: 1.1 | atmospheric, epic, concept art: 0.6 | Matte painting, background, mist:1 | photo-realistic:1 | concept art, volumetric light:1 | cinematic epic + rule of thirds:1.1 |  octane render, 8k, corona render, movie concept art:0.9 | octane render, cinematic, trending on artstation: 1 |  movie concept art, cinematic composition:1 | ultra-detailed, realistic, hyper-realistic: 1 | volumetric lighting, 8k:1 | test:-0.5 | uplight:-1”
"beautiful open kitchen in the style of elena of avalor overlooking aerial wide angle view of a solarpunk: 1 | vibrant city with greenery: 1 | interior architecture, kitchen, eating space: 1 | rendered in octane: 0.9 | in the style of Luc Schuiten, craig mullins: 1 | solarpunk in deviantart: 0.8 |  photorealistic, highly detailed: 1 | Vincent Callebaut, elena of avalor, highly detailed: 1"
"A portrait of a man standing on top of a boat, wearing blue hoodie, resting on a tough day: 1 | profile picture 1024px: 1 | stormy seas, documentary, fish man, older male:1 | Oscar winning: 6 | colourful 3d crystals and gems, vintage, full color manga cover, kewpie, two girls, anime, fairytale illustration, Chinese ribbon dance, children illustration, illustration, silk shoes, classic children’s illustrations, adorable and whimsical: -3 | weird faces, extra limbs, weird anatomy: -2 | sharp face: 1"
"A realistic photo, award winning photography, of a cat sitting on a ledge, a digital painting, pexel contest winner, furry art, sunbathing, illustration, high detail illustration, portrait of a cartoon animal, realistic gouache painting: 6 | man and woman, modern, neck: -3"
"A close up of a comic book cover, a comic book panel, wearing green and yellow hero suit, soldiers running, spectrum, 1980s: 6 | ornate black tuxedo, Bugatti Veyron, sensual lighting, 4k food photography, pink lipstick, detailed white fur, woman with long, peach embellishment, fashion photo shoot, dark blue tint, wearing professional makeup, arknights, peaceful and serene, fashion photography: -4 | childish drawing, poorly drawn, bad art: -1"
Example format: "${this.document.sections[index].image}: 1.5 | [supporting keyphrases]: 1 | [negative keyphrases]: -1"
Refer to this prompt guide for more guidance: https://stable-diffusion-art.com/prompt-guide/
###
${text}
###
This is an image of:
###`,
                temperature: 1,
                max_tokens: 100,
                top_p: 0.9,
                frequency_penalty: 0,
                presence_penalty: 0,
            };
            console.error(request.prompt);

            try {
                const response = await this.requestFromOpenAI(request, `Image Prompt ${index}`);
                // let fixedResponse = response.replace(/^Body: /, '');
                // this.documents[id].sections[index].enhancedImage = response;

                this.document.sections[index].enhancedImage = response;

                console.error(response);
                await this.saveDoc(this.document);
                this.stabilityPresentationImage(index, response, sectionText);
                // await this.presentationImage(index, response);
            } catch (error) {
                if (error.status === 401) {
                    console.log("Error: Invalid API key", error.message);
                } else if (error.status === 403) {
                    console.log("Error: API key has insufficient permissions", error.message);
                } else if (error.status === 429) {
                    console.log("Error: API rate limit exceeded", error.message);
                } else {
                    console.log("Error: " + error.message);
                }
                await this.saveDoc(this.document);
            }
            return;
        },
        async saveDocument(collection, item, array) {
            this.saving = true;
            let saved = await this.addToFirebase(collection, item);
            this.saving = false;
            this.addAlert({
                type: "success",
                message: "Saving your doc",
            });
            if (array) {
                this.$nextTick(() => {
                    this.$forceUpdate();
                    // array.push(item)
                });
            }
            return saved;
        },
    },
};
