<script setup>
import HeaderTitleSection from '@/components/HeaderTitleSection.vue';
import ParagraphSection from '@/components/marketing/ParagraphSection.vue';
import NewSections from '@/components/marketing/scrollSections/NewSections.vue';
</script>
<script>
var controller = new ScrollMagic.Controller();
export default {
    data: () => ({
        teamscene: null,
    }),
    mounted() {
        this.loadHowToScroll();
    },
    beforeUnmount() {
        //     this.scene.destroy()
    },
    methods: {
        loadHowToScroll() {
            var self = this;
            if (this.teamscene) {
                this.teamscene.destroy(true);
                this.teamscene = null;
            }
            const teamtween = new TimelineMax().add([
                TweenMax.staggerFromTo(
                    '.stagger-item',
                    1,
                    {
                        bottom: '-50',
                        opacity: '0',
                    },
                    {
                        opacity: '1',
                        bottom: '0',
                        ease: Back.easeOut,
                    },
                    0.25,
                ),
            ]);
            // build scene
            this.teamscene = new ScrollMagic.Scene({
                triggerElement: self.$refs.trigger,
                triggerHook: 0.7,
                duration: ($(window).height() / 10) * 25,
            })
                .setTween(teamtween)
                .addTo(controller);
        },
    },
};
</script>
<template>
    <div class="white x mt-7" style="min-height: 100vh">
        <HeaderTitleSection
            title="Become the Story."
            intro="THE CINEMATIC UNIVERSE"
            button-text="Explore the Characters"
            route="/"></HeaderTitleSection>
        <ParagraphSection
            paragraph="Our team is on a mission to blah blah blah. Here is the section where we explain that these are the things we’re thinking about. We have to communicate that none of these things are guaranteed. It’s just the “mind map” kind of things we want to pursue."></ParagraphSection>
        <!--        <div class="vh-30 f aie jcc">-->
        <!--            <div class="container">-->
        <!--                <div class="text-center">-->
        <!--                    <h6 class="mb-0 o-5"></h6>-->
        <!--                    <h1>Become the Story.</h1>-->
        <!--                    <a class="btn btn-outline-secondary" href="#"></a>-->
        <!--                </div>-->
        <!--                <div class="my-5" style="width: 100%;height:0px;border: 1px solid #353945;"></div>-->
        <!--            </div>-->
        <!--        </div>-->
        <NewSections></NewSections>
    </div>
</template>
