<template>
    <div class="border-bottom pb-2 pt-2 text-base-900">
        <div class="f aic jcs py-2">
            <div class="c-27 mr-1 bg-base-900"></div>
            <p class="fwb mb-0 text-base-900">{{ comment.user }}</p>
        </div>
        <div class="px-2">
            <span class="fwsb italic">"{{ comment.comment }}"</span>
            <span class="d-block f-13 border-light br-10 line-height-small my-2 whitespace-pre-line bg-gray-100 p-3 text-gray-800">
                {{ comment.original }}
            </span>
            <span class="d-block f-13 br-10 line-height-small my-2 whitespace-pre-line text-gray-300">
                {{ $filters.timeAgo(dateFix(comment.updated)) }}
            </span>
        </div>
    </div>
</template>
<script>
export default {
    name: "comment-item",
    props: {
        comment: {},
    },
};
</script>
