const computedStore = {};
const storeMethods = {};

export const docStore = {
    state() {
        return {};
    },
    mutations: {},
};
import { getStorage, ref, uploadBytes, uploadString, getDownloadURL } from "firebase/storage";

export default {
    created: function () {},
    mounted() {},
    beforeDestroy() {},
    data: () => ({
        imageUrl: "",
    }),
    computed: {
        ...computedStore,
    },
    methods: {
        ...storeMethods,
        async getSImage(request) {
            // eslint-disable no-console, no-control-regex
            console.error("sending request");
            console.error(request);
            // "id": "stable-diffusion-v1-5",

            // const engineId = "stable-diffusion-512-v2-1";
            const engineId = "stable-diffusion-512-v2-0";
            // const apiHost = process.env.API_HOST ?? 'https://api.stability.ai';
            const apiHost = "https://api.stability.ai";
            const apiKey = "sk-ZQUJs3ZA28iwRUZxZdC6RMds03ppmdDSDlH8rKmjxB5E5hoR";
            // const apiKey = import.meta.env.STABILITY_API_KEY;

            if (!apiKey) throw new Error("Missing Stability API key.");

            const response = await fetch(`${apiHost}/v1beta/generation/${engineId}/text-to-image`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${apiKey}`,
                },
                body: JSON.stringify(request),
            });
            console.error(response.body);
            if (!response.ok) {
                console.error(`Non-200 response: ${await response.text()}`);
            }

            const responseJSON = await response.json();
            console.error(responseJSON);
            let imageURL = await this.uploadImage(responseJSON.artifacts[0].base64);

            return imageURL;
        },
        async getStabilityImage(textPrompt, aspect, supportingText) {
            // eslint-disable no-console, no-control-regex
            console.error(`Stability request: ${textPrompt}`);
            // const engineId = 'stable-diffusion-v1-5';
            // const engineId = "stable-diffusion-768-v2-0";
            const engineId = "stable-diffusion-768-v2-1";
            // const apiHost = process.env.API_HOST ?? 'https://api.stability.ai';
            const apiHost = "https://api.stability.ai";
            const apiKey = "sk-ZQUJs3ZA28iwRUZxZdC6RMds03ppmdDSDlH8rKmjxB5E5hoR";
            // const apiKey = import.meta.env.STABILITY_API_KEY;

            if (!apiKey) throw new Error("Missing Stability API key.");
            // + ' | weird hands: -0.5 | distorted faces: -0.5 | sharp faces:1',
            let ar = {
                height: 768,
                width: 768,
            };
            if (aspect && aspect.constructor === Object) {
                console.error(aspect);
                ar = aspect;
            }
            const response = await fetch(`${apiHost}/v1beta/generation/${engineId}/text-to-image`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${apiKey}`,
                },
                body: JSON.stringify({
                    text_prompts: [
                        {
                            text: textPrompt.slice(0, 400),
                            weight: 1,
                        },
                        {
                            text: "extra limbs, deformed, tiling, blur, distorted face, bad anatomy, bad crop, watermark",
                            weight: -2,
                        }
                    ],
                    cfg_scale: 7,
                    clip_guidance_preset: "FAST_GREEN",
                    height: ar.height,
                    width: ar.width,
                    samples: 1,
                    steps: 50,
                }),
            });
            // clip_guidance_preset: "FAST_BLUE",
            // {
            //     text: "duplicate, blur, distorted face, bad anatomy, tiling, bad crop, extra limbs, deformed, watermark",
            //         weight: -1,
            // },
            if (!response.ok) {
                console.error(`Non-200 response: ${await response.text()}`);
            }

            const responseJSON = await response.json();

            let imageURL = await this.uploadImage(responseJSON.artifacts[0].base64);

            return imageURL;
        },
        async stabilityPresentationImage(index, text, sectionText) {
            this.savingStateHelper(true, this.document);
            let ar;
            try {
                if (this.document.sections[index].style.includes("full")) {
                    ar = {
                        height: 768,
                        width: 1024,
                    };
                }
            } catch (e) {
                ar = null;
            }
            let imageURL = await this.getStabilityImage(text, ar, sectionText);
            setTimeout(() => {
                console.error(imageURL);
                this.$emit("fetchingSection", [index, false, null]);
                console.error(this.document);
                this.document.sections[index].imageURL = imageURL;
                this.document.id = this.$route.params.id;
                this.saveDoc(this.document);
                this.savingStateHelper(false, this.document);
                return;
            }, 50);
        },
        async generateSectionImage(index, section, summary) {
            this.$emit("fetchingSection", [index, true, `We're fetching your image!`]);
            // this.$refs['section-'+(this.index)].$refs.contenteditable.sectionFetching(true);
            // kind
            let text;
            if (summary) {
                text = summary;
            } else if (section.summary) {
                text = section.summary;
            }
            // return;
            // let promptBase = `Get an image based on this passage Not an illustration.  `;
            let newPrompt = `Write a prompt to generate a high-quality image made to accompany this passage: ${text}`;
            let imagePrompt;
            console.log(newPrompt);
            if (!section || !section.imagePrompt) {
                imagePrompt = await this.imagePrompt(newPrompt, "bot");
            } else {
                imagePrompt = section.imagePrompt;
            }
            this.$emit("fetchingSection", [index, true, `${imagePrompt[0]}`]);
            // this.$emit('fetchingSection', [index, true, `${imagePrompt}`]);
            // return
            console.log(imagePrompt);
            const response = await window.openai.createImage({
                prompt: imagePrompt[0],
                n: 1,
                size: "512x512",
            });
            this.$emit("fetchingSection", [index, true, `Done!`]);
            const imageURL = response.data.data[0].url;
            let sectionPayload = {
                prompt: imagePrompt,
                response: { ...response.data, ...response.config.dats },
            };
            setTimeout(() => {
                this.$emit("fetchingSection", [index, false, null]);
                this.newSection(this.index, imageURL, null, "image", sectionPayload);
            }, 500);
            // this.$emit('fetching', [index, true,`We're fetching your image!`]);
            // this.$emit('newSection', [this.index, imageURL, null, 'image']);
            // this.newSection(this.index, imageURL,null,'image');
            this.uploadImage(imageURL);
            console.error("image", imageURL);
            return imageURL;
        },
        async generateLyricsImage(item, lyrics) {
            let promptBase = `a ${item.promptData.genre.name} song called ${item.promptData.name}. Don't use text on the cover. Not an illustration. You can use any images you want. Use the description for inspiration:\n${item.promptData.topic}\n `;
            let newPrompt = `Write a prompt to create an image for a realistic album cover based on ${promptBase}`;
            let imagePrompt;
            console.log(newPrompt);
            if (!item.imagePrompt) {
                imagePrompt = await this.imagePrompt(newPrompt, "bot");
            } else {
                imagePrompt = item.imagePrompt;
            }
            let newImagePrompt =
                "Epic album cover: " +
                imagePrompt[0] +
                " Ultra realistic, 8k, sharp image, not animated, dont use any typography. ";
            console.log(newImagePrompt);
            const response = await window.openai.createImage({
                prompt: newImagePrompt,
                n: 1,
                response_format: "b64_json",
                size: "512x512",
            });
            if (response.data.data[0].url) {
                this.imageUrl = await this.uploadImage(response.data.data[0].url, "url");
            } else {
                this.imageUrl = await this.uploadImage(response.data.data[0].b64_json, "b64_json");
            }
            this.$nextTick(() => {
                this.updateAnyObject("feedItem", item.id, { image: this.imageUrl });
            });
            return this.imageURL;
        },
        async generateImage(index, section, summary) {
            console.error("mage Step 1: Initializing image generation ", index, section, summary);
            this.$emit("fetchingSection", [index, true, `We're fetching your image!`]);
            // this.$refs['section-'+(this.index)].$refs.contenteditable.sectionFetching(true);
            // kind
            let text;
            if (summary) {
                text = summary;
            } else if (section.content) {
                console.error("Image Step 2: Prompt from content ", section.content);
                text = section.content;
            } else if (section.summary) {
                console.error("Image Step 2: Prompt from summary ", section.summary);
                text = section.summary;
            }
            // return;
            // let promptBase = `Get an image based on this passage Not an illustration.  `;
            let newPrompt = `Write a detailed description to generate a high-quality image made to accompany this passage: ${text}`;
            let imagePrompt;
            console.log(newPrompt);
            if (!section || !section.imagePrompt) {
                imagePrompt = await this.imagePrompt(newPrompt, "bot");
            } else {
                imagePrompt = section.imagePrompt;
            }
            this.$emit("fetchingSection", [index, true, `${imagePrompt[0]}`]);
            try {
                // const response = await window.openai.createImage({
                //     prompt: imagePrompt[0],
                //     n: 1,
                //     size: "512x512",
                //     response_format: "b64_json",
                // });

                const imageURL = await this.getStabilityImage(imagePrompt[0]);
                this.$emit("fetchingSection", [index, true, `Done!`]);
                let sectionPayload = {
                    prompt: imagePrompt[0],
                    request: imagePrompt[1],
                };

                setTimeout(() => {
                    console.error("imageURL for section");
                    console.error(imageURL);
                    this.$emit("fetchingSection", [index, false, null]);
                    console.error("Image Step 10: Hide loader ");
                    // this.newSection(this.index, storyImage, null, 'image', sectionPayload);
                    this.newSection(this.index, imageURL, null, "image", sectionPayload);
                    console.error(
                        "Image Step 11: Create new section done",
                        this.index,
                        imageURL,
                        null,
                        "image",
                        sectionPayload,
                    );
                    console.trace();
                    return;
                }, 500);
                // this.section.content = imageURL;
                return;
                // this.$emit('fetching', [index, true,`We're fetching your image!`]);
                // this.$emit('newSection', [this.index, imageURL, null, 'image']);
                console.trace();
                console.error("Image Step 12: still going??");
                // this.uploadToFirebase(imageURL);
                // this.newSection(this.index, imageURL,null,'image');
                return await this.uploadImage(response.data.data[0].b64_json, "b64_json");
                console.error("image", imageURL);
                this.section.content = imageURL;
                return imageURL;
            } catch (error) {
                if (error.status === 401) {
                    console.log("Error: Invalid API key");
                } else if (error.status === 403) {
                    console.log("Error: API key has insufficient permissions");
                } else if (error.status === 429) {
                    console.log("Error: API rate limit exceeded");
                } else {
                    console.log("Error: " + error.message);
                }
            }
        },
        async regenerateImage(index, prompt) {
            this.$emit("fetchingSection", [index, true, `We're fetching your image!`]);
            let text = prompt;
            this.$emit("fetchingSection", [index, true, `${text}`]);
            // let request = {
            //     prompt: text,
            //     n: 1,
            //     size: "512x512",
            //     response_format: "b64_json",
            // };
            // const response = await window.openai.createImage(request);
            this.$emit("fetchingSection", [index, true, `Done!`]);

            // let FBImageURL;

            // FBImageURL = await this.uploadImage(response.data.data[0].b64_json, "b64_json");

            const imageURL = await this.getStabilityImage(prompt);
            // let sectionPayload = {
            //     request: request,
            // };
            setTimeout(() => {
                this.$emit("fetchingSection", [index, false, null]);
                // this.newSection(this.index, storyImage, null, 'image', sectionPayload);
                this.section.content = imageURL;
                this.section.prompt = prompt;
                return;
            }, 300);
            // this.section.content = imageURL;
            return;
            // this.$emit('fetching', [index, true,`We're fetching your image!`]);
            // this.$emit('newSection', [this.index, imageURL, null, 'image']);
            this.section.content = imageURL;
            return imageURL;
        },
        async presentationImage(index, prompt, model) {
            console.error(index);
            console.error(prompt);
            this.$emit("fetchingSection", [index, true, `We're fetching your image!`]);
            let text = prompt;
            this.$emit("fetchingSection", [index, true, `${text}`]);

            let request;
            try {
                let response;
                if (model === "sd") {
                } else {
                    request = {
                        prompt: text,
                        n: 1,
                        size: "1024x1024",
                        response_format: "b64_json",
                    };
                    response = await openai.createImage(request);
                }

                this.$emit("fetchingSection", [index, true, `Done!`]);

                let imageURL;

                if (response.data.data[0].url) {
                    imageURL = await this.uploadImage(response.data.data[0].url, "url");
                } else {
                    console.error("Image Step 7: Uploading Image ", response.data.data[0]);
                    imageURL = await this.uploadImage(response.data.data[0].b64_json, "b64_json");
                    console.error("Image Step 8: Uploaded Image ", imageURL);
                }
                setTimeout(() => {
                    console.error(imageURL);
                    this.$emit("fetchingSection", [index, false, null]);
                    console.error(this.document);
                    this.document.sections[index].imageURL = imageURL;
                    this.document.id = this.$route.params.id;
                    this.saveDoc(this.document);
                    return;
                }, 50);

                return imageURL;
            } catch (error) {
                if (error.status === 400) {
                    console.error(
                        "Bad request: A common reason is the prompt exceed the limit of characters allowed by the API",
                    );
                }
                if (error.status === 401) {
                    console.log("Error: Invalid API key");
                } else if (error.status === 403) {
                    console.log("Error: API key has insufficient permissions");
                } else if (error.status === 429) {
                    console.error("Error: API rate limit exceeded");
                } else {
                    console.error("Error: " + error.message);
                }
            }
            return;
        },
        async generateImage2() {
            const apiKey = import.meta.env.VITE_OPENAI_API;
            const organization = import.meta.VITE_OPENAI_ORG;
            const response = await openai.createImage({
                prompt: "a golden goose",
                n: 1,
                response_format: "b64_json",
                size: "512x512",
            });
            console.error(response);
            this.$nextTick(() => {
                if (response.data.data[0].url) {
                    return this.uploadImage(response.data.data[0].url, "url");
                } else {
                    return this.uploadImage(response.data.data[0].b64_json, "b64_json");
                }
            });
        },
        async getImageURLFromFirebase(ref) {
            return await getDownloadURL(ref)
                .then(url => {
                    this.imageUrl = url;
                    console.error(url);
                    return url;
                })
                .catch(error => {
                    console.error(error);
                    return;
                });
        },
        async uploadImage(file, kind) {
            let filename = `images/image-${this.randomId()}.png`;

            const apiKey = import.meta.env.VITE_OPENAI_API;
            let blob;
            if (kind === "url") {
                const imageUrl = file;
                filename = `images\/image-url-${this.randomId()}.png`;
                blob = fetch(file, {
                    method: "GET",
                    mode: "no-cors",
                    accept: "image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8",
                    headers: {
                        "Accept-Encoding": "gzip, deflate, br",
                        "Accept-Language": "en-US,en;q=0.9",
                        "Cache-Control": "no-cache",
                        Connection: "keep-alive",
                        DNT: 1,
                        Host: "oaidalleapiprodscus.blob.core.windows.net",
                        Pragma: "no-cache",
                        Referer: "http://localhost:3000/",
                        "Sec-Fetch-Dest": "image",
                        "Sec-Fetch-Site": "cross-site",
                        "Content-Type": "image/png",
                        Authorization: `Bearer ${apiKey}`,
                    },
                }).then(r => r.blob());
            } else {
                blob = await fetch(`data:image/png;base64,${file}`).then(r => r.blob());
                filename = `images/image-blob-${this.randomId()}.png`;
            }
            const metadata = {
                contentType: "image/png",
            };

            const storage = getStorage();
            const imagesRef = ref(storage, filename);
            let imageURL = "";
            if (kind === "url") {
                let imageBlob = await uploadBytes(imagesRef, blob, metadata).then(snapshot => {
                    console.log("Uploaded a blob or file!");
                    // console.error(snapshot);
                });
                imageURL = await this.getImageURLFromFirebase(imageBlob);
                // return imageBlob;
                return imageURL;
            } else {
                try {
                    console.log("Is Base54 Image");
                    let base64url = await uploadBytes(imagesRef, blob, metadata).then(snapshot => {});
                    imageURL = await this.getImageURLFromFirebase(imagesRef);
                    // return base64url;
                    return imageURL;
                } catch (error) {
                    if (error.status === 401) {
                        console.log("Error: Invalid API key");
                    } else if (error.status === 403) {
                        console.log("Error: API key has insufficient permissions");
                    } else if (error.status === 429) {
                        console.log("Error: API rate limit exceeded");
                    } else {
                        console.log("Error: " + error.message);
                    }
                }
            }
        },
    },
};
