<template>
    <div class="x py-8 text-center vh-70 f aic jcc p-fixed bg-snow p-fill z-3">
        <div>
            <div class="floating-item mb-5">
                <h2 class="animated swingInUp d-1 text-nhap ease-back">Type in any subject</h2>
            </div>
            <div class="floating-item form-group max-w-md text-center mx-auto animated swingInUp ease-back d-2 shadow bg-snow br-5 f-20">
                <input v-model="presentationPrompt" class="mb-0 pb-0 text-gray-900 rounded  border-light d-block p-2 br-5 x placeholder-gray-400 !f-24" placeholder="Literally, any subject ..." style="color:black;" type="text" @keydown.enter="newDeck()"/>
            </div>
            <!-- toggle selector (ie. styled radio selector) for story or presentation -->
<!--            <slide-count @slide-count="slideCount"/>-->
            <!-- toggle selector (ie. styled radio selector) for story or presentation -->

<!--            <toggle-selector class="mx-auto x text-center mw-600" @change="setKind" :kind="kind" ref="toggleSelector" :kinds="presentationKinds"/>-->
<!--            <toggle-selector class="mx-auto x text-center mw-600" @change="setCount" :kind="kind" ref="toggleSelector" :kinds="deckLength"/>-->
            <div class="f mx-auto aic jcc fc floating-item mt-5 ease-back">
                <a class="x btn btn-primary animated swingInUp ease-back d-3 shadow rounded-full border-light" @click.prevent="newDeck()">Start</a>
                <a class="x btn btn-link animated swingInUp d-4" @click.prevent="close()">cancel</a>
            </div>
        </div>
    </div>
</template>
<script>
import DocumentManagementMixin from "@/mixins/documentManagementMixin";
import ToggleSelector from "@/components/Presentations/ToggleSelector.vue";
import SlideCount from "@/components/Presentations/SlideCount.vue";

export default {
    // eslint-disable-next-line vue/no-unused-components
    components: {SlideCount, ToggleSelector},
    mixins: [DocumentManagementMixin],
    data: () => ({
        presentationPrompt: null,
        slideCount: 5,
        kind: 'presentation',
        presentationKinds: [
            {name:"presentation", value:"presentation"},
            {name:"story", value:"story"},
        ],
        deckLength:[
            {name:"short", value:5},
            {name:"medium", value:10},
            {name:"long", value:15},
        ]
    }),
    methods: {
        setCount(payload){
            console.error('setCount',payload)
            this.slideCount = payload;
        },
        setKind(payload){
            console.error('setKind',payload)
            this.kind = payload;
        },
        async close() {
            await this.removeFromFirebase('documents', this.$route.params.id);
            this.$router.go(-1);
            this.$emit('close');
        },
        newDeck() {
            this.$emit('newDeck', this.presentationPrompt,this.kind,this.slideCount)
        },
        handleChange (value) {
            this.kind = value
        }
    },
    props: {
        newDeckStarted: {
            type: Boolean,
            default: false
        },
    }
};
</script>


