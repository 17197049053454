<template>
    <div :ref="`slide-${id}${index}`" :class="slideContainerClasses" class="x vh-70">
        <div class="col-md-6 x h-auto text-left" v-if="!mobile">
            <div :ref="`text-${id}${index}`" class="f aic jcs y z-5 px-xl-6 py-md-8 bg-darks-1 p-3">
                <PresentationSlideText
                    :key="documentId + index"
                    class="text-lights-3"
                    :document="document"
                    :document-id="document.id"
                    :image-dark="imageDark"
                    :index="index"
                    :section="section"></PresentationSlideText>
            </div>
        </div>
        <div :ref="`image-${id}${index}`" :class="randomImageStyle" class="col-md-6 x">
            <PresentationImageContainer
                :key="'p' + documentId + index"
                :document="document"
                :document-id="documentId"
                :inset="inset"
                :image-dark="imageDark"
                :index="index"
                :section="section"></PresentationImageContainer>
        </div>
        <div class="col-md-6 x h-auto text-left" v-if="mobile">
            <div :ref="`text-${id}${index}`" class="f aic jcs y z-5 px-xl-6 py-md-8 p-3">
                <PresentationSlideText
                    :key="documentId + index"
                    :document="document"
                    :document-id="document.id"
                    :image-dark="imageDark"
                    :index="index"
                    :section="section"></PresentationSlideText>
            </div>
        </div>
    </div>
</template>
<script>
import PresentationImageContainer from "@/components/Presentations/slides/PresentationImageContainer.vue";
import PresentationSlideText from "@/components/Presentations/slides/PresentationSlideText.vue";
import SlideStyleMixin from "@/mixins/slideStyleMixin";
import documentManagementMixin from "@/mixins/documentManagementMixin";

export default {
    components: { PresentationImageContainer, PresentationSlideText },
    mixins: [SlideStyleMixin, documentManagementMixin],
    props: {
        id: {},
        index: {},
        section: {},
        document: {},
        documentId: {},
        slideContainerClasses: {},
    },
    data() {
        return {
            imageDark: false,
            inset:false,
        };
    },
    watch: {
        document: {
            handler: function (val, oldVal) {
                if (val.sections !== oldVal.sections) {
                    let t = val.theme.colors || false;
                    if (t) this.textTheme();
                }
            },
            deep: false,
        },
    },
    computed: {
        randomImageStyle() {
          let inset = Math.random() < 0.5
          if(inset) {
            this.inset = true
          }
            return inset ? this.imageInset : this.imageFullbleed;
        },
        imageInset() {
            return "p-3 p-xl-5";
        },
        imageFullbleed() {
            return "p-0";
        },
    },
    mounted() {
        this.slideColors();
        if (this.document.theme) {
            let t = this.document.theme.colors || false;
            if (t) this.textTheme();
        }
    },
    methods: {},
};
</script>
