<template>
    <div ref="summary" class="x bg-gray-100 py-20">
        <div class="mx-auto max-w-lg">
            <h5 class="mb-0 text-gray-300">Summary</h5>
            <div v-if="document && document.summary"
                class="x mx-auto mt-0 whitespace-pre-line p-2 pt-0 text-gray-500"
                contenteditable>
                {{ document.summary }}
            </div>
            <div v-if="document && document.sections"
                class="whitespace-pre-line p-2 text-gray-500"
                v-html="extractText(document.sections)"></div>
            <!--                        <div>{{document.sections}}</div>-->
        </div>
    </div>
</template>
<script>
import documentManagementMixin from '@/mixins/documentManagementMixin';
import presentationMixin from '@/mixins/presentationMixin';

export default {
    props:['document'],
    name: 'DocumentFooter',
    mixins: [documentManagementMixin],
};
</script>
