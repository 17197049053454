<script>
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import ButtonIcon from "@/components/buttons/ButtonIcon.vue";
import AdminMixins from "@/mixins/AdminMixins";

export default {
    components: {ButtonIcon, ButtonSecondary},
    props: ['item', 'subArray'],
    mixins: [AdminMixins],
    mounted() {},
    emits: ['handleItemRemoved','handleItemSaved'],
    data() {
        return {
            debug: false,
        };
    },
    methods: {

    },
    computed: {}
};
</script>
<template>
    <div class="x">
        <div class="d-block mb-3 line-height-smaller f-20 p-2" v-if="item">
            <div class="f aic jcb text-links-900">
                <div>
                    <span class="fwb f-15">{{ item.name }}<span v-if="item.status">- {{ item.status }}</span></span>
                </div>
                <div>
                    <button-icon class="p-2-5" @click="$emit('handleItemRemoved','genre', item )" icon="trash fal f-13"></button-icon>
                </div>
            </div>
            <span class="f-11 d-block mt-1 mb-2" v-if="item.genre"><span class="fwb">Genre:</span> {{ item.genre.name }}</span>
            <span class="f-11 d-block fwb">Keywords:</span>
            <div class="f flex-wrap">
                <span class="f-11 mt-1 mr-2 " v-if="item.keywords" v-for="keyword in item.keywords">{{ keyword }}</span>
            </div>
            <div class="f flex-wrap">
                <div v-if="item.artists" class="f-11" v-html="$filters.prettyObject(item.artists)"></div>

                <template v-for="i in item">
                    <span class="f-11 mt-1 mr-2 " v-if="item.artists" v-for="obj in i">{{ keyword }}</span>
                </template>
            </div>
            <span class="f-11 fwb text-uppercase d-block my-2">{{ $filters.timeAgo(toDate(item)) }}</span>
            <p class="f-11 mb-0" v-for="i in subArray">{{ i.id }}</p>
            <div class="">
                <button-secondary class="d-inline-block f-11 mb-3 mt-2 p-1 mr-1" text="Save" @click="$emit('handleItemSaved',item,'artists')"></button-secondary>
                <button-secondary class="d-inline-block f-11 mb-3 mt-2 p-1" text="artists" @click="getFilteredArtists(item.name)"></button-secondary>

                <span class="text-danger fwb d-block f-11" v-if="item.similarity > 0.7">Similarity: {{ item.similarity }}</span>
                <!--                            <span class="text-danger fwb d-block f-11" v-if="checkName(item.name)">Lev: {{ checkName(item.name) }}</span>-->
            </div>
        </div>
    </div>
</template>
