import { docStore } from "@/mixins/documentManagementMixin";
import mixins from "@/mixins/mixins";
import { presStore } from "@/mixins/presentationMixin";
import { documentStore } from "@/mixins/documentMixins";

export default {
    state() {
        return {
            ...docStore.state(),
            ...presStore.state(),
            count: 0,
            mobile: null,
            imageStylesStore: [],
            selectedStyle: {
                name: "",
                kind: "",
                keywords: [],
                details: [],
                objects: [],
                id: null,
                image: null,
            },
            chatItems: [],
            showInput: false,
            showButtons: true,
            step: 0,
            textResponse: "",
            genreArtist: null,
            fetching: false,
            currentSection: 0,
            showDocumentDrawer: false,
            selectedStyles: {
                scene: [],
                lighting: [],
                art: [],
            },
            artists: [],
            genres: [],
            songs: [],
            user: {
                feed: [],
            },
            promptData: {
                artists: {
                    name: null,
                    genre: null,
                    keywords: null,
                },
                genre: {},
                name: "",
                topic: null,
                darkMode: false,
                location: "",
                artistDetails: [],
                otherArtists: [],
                html: null,
                lastDocument: '',
                lastPresentation: '',
                chatLoading: false,
                savingDocument: {
                    saving: false,
                    loading: false,
                    section: null,
                    lastSaved: null,
                    documentId: null,
                },
            },
        };
    },
    mutations: {
        ...documentStore.mutations,
        ...docStore.mutations,
        ...presStore.mutations,
        increment(state) {
            state.step++;
        },
        setShowDocumentDrawer(state, payload) {
            state.showDocumentDrawer = payload;
        },
        addToSelection(state, { value, kind }) {
            state.selectedStyles[kind].push(value);
        },
        setLastDocument(state, payload) {
            state.lastDocument = payload;
        },
        setLastPresentation(state, payload) {
            state.lastPresentation = payload;
        },
        setSavingDocument(state, payload) {
            state.savingDocument = payload;
        },
        setImageStyles(state, payload) {
            state.imageStylesStore = payload;
        },
        setCurrentStyle(state, payload) {
            state.selectedStyle = payload;
        },
        resetSelection(state, kind) {
            state.selectedStyles[kind] = [];
            // if (value = 'lighting') {
            //     state.selectedStyles.lightingStyle = [];
            // } else if (value = 'environment') {
            //     state.selectedStyles.environmentStyle = [];
            // } else if (value = 'artStyle') {
            //     state.selectedStyles.artStyle = [];
            // }
            // state.selectedStyles = [];
        },
        removeFromSelection(state, { value, kind }) {
            state.selectedStyles[kind].splice(state.selectedStyles[kind].indexOf(value), 1);
        },
        setCurrentSection(state, index) {
            state.currentSection = index;
        },
        setDarkMode(state, payload) {
            state.darkMode = payload;
        },
        setChatMode(state, payload) {
            state.chatMode = payload;
        },
        setGenreArtist(state, payload) {
            state.genreArtist = payload;
        },
        setShowInput(state, payload) {
            state.showInput = payload;
        },
        setShowButtons(state, payload) {
            state.showButtons = payload;
        },
        setFetching(state, payload) {
            state.fetching = payload;
        },
        setToggleView(state, payload) {
            state.toggleView = payload;
        },
        setChatLoadingState(state, payload) {
            state.chatLoading = payload;
        },
        updateUserFeed(state, payload) {
            state.user.feed.push(payload);
        },
        refeshUserFeed(state, payload) {
            state.user.feed = payload;
        },
        setArtist(state, payload) {
            state.promptData.artists = payload;
        },
        setTextResponse(state, payload) {
            state.textResponse = payload;
        },
        setHtml(state, payload) {
            state.promptData.html = payload;
        },
        setArtistDetails(state, payload) {
            state.promptData.artistDetails = payload;
        },
        setGenre(state, payload) {
            state.promptData.genre = payload;
        },
        setGenreDetails(state, payload) {
            let style = this.styles.find(style => style.style === this.promptData.genre);
            state.promptData.genre = payload;
        },
        setTopic(state, payload) {
            state.promptData.topic = payload;
        },
        setLocation(state, payload) {
            state.promptData.location = payload;
        },
        setStep(state, payload) {
            state.step = payload;
        },
        addToChat(state, payload) {
            state.chatItems.push(payload);
        },
        resetChat(state, payload) {
            state.chatItems = [];
        },
        setArtists(state, payload) {
            state.artists = payload;
        },
        setGenres(state, payload) {
            state.genres = payload;
        },
        setSongs(state, payload) {
            state.songs = payload;
        },
        // addArtistDetails(state, payload) {
        //     state.artists.push(payload);
        // },
        ADD_ITEM(state, item) {
            state.chatItems.push(item);
        },
        setMobile(state, payload) {
            state.mobile = payload;
        },
    },
    actions: {
        ...docStore.actions,
        updateItemProperty({ commit }, { itemId, property, value }) {
            // Retrieve the item from the store
            let item = state.documents[itemId];
            // Update the property of the item
            item[property] = value;
            // Commit the update to the store
            commit("updateItem", item);
            // Update the property in Firebase
            this.updateDoc(this.document);
            firestore
                .database()
                .ref(`documents/${itemId}`)
                .update({ [property]: value });
        },
    },
};
