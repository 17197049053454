<template>
    <div
        class="justify-fixed-center x p-fixed bottom ease-elastic"
        @mouseleave="expanded = false"
        :class="{ 'mw-300': !expanded, 'mw-500': expanded }"
        ref="containter">
        <!--        <div v-if="!mobile" style="width: 275px"></div>-->
        <Transition appear enter-active-class="animated swingInUp stagger-10">
            <div class="f z-0 mb-0 mb-3 overflow-hidden" :class="{ 'p-0': !expanded, 'p-2': expanded }" v-cloak>
                <div @mouseover="expanded = true"
                    class="ease-elastic duration-5 z-5 x br-10 bg-blur-xl x btn-bubble border-light mx-auto mx-auto bg-base-800/80 text-center shadow"
                    :class="{ 'br-50 p-0': !expanded, ' mb-md-3 p-md-3 p-2': expanded }">
                    <template v-if="!startingNew">
                        <template v-if="responseText && !startingNew">{{ changeResposeText }}</template>
                        <template v-if="document && document.sections && document.sections.length > 0">
                            <div class="f aic jcb" :class="{ 'px-3': !expanded, '': expanded }">
                                <p
                                    style="transition: all 1s cubic-bezier(0.06, 1.59, 0.4, 0.84)"
                                    class="fwb my-0 whitespace-nowrap py-0 text-left text-base-50"
                                    :class="{ 'mb-md-2 mb-2 mr-2 p-1 py-0': expanded, 'mb-0 mr-2': !expanded }">
                                    {{ document.sections[sectionIndex].title }}
                                </p>
                                <div
                                    class="f aic jcc justify-self-center py-2 pl-1"
                                    @click.prevent="expanded = !expanded">
                                    <i class="fas fa-ellipsis-h white f-24"></i>
                                </div>
                            </div>
                        </template>
                    </template>
                    <div class="p-relative f aic jcc">
                        <transition
                            enter-active-class="opacity-0 h-0 translate-y-10 scale-0"
                            enter-to-class="opacity-0 duration-1 scale-100 fadeInUpSmooth h-auto translate-y-0 ease-elastic"
                            leave-active-class="w-0 h-0 translate-y-0 scale-100 opacity-0 ease-elastic d-5"
                            leave-to-class="opacity-0 scale-1 translate-y-20 h-0 br-50 scale-0 translate-y-0 overflow-hidden translate-y-10 ease-elastic">
                            <div
                                v-if="expanded"
                                :class="{ 'height-0 w-0': !expanded }"
                                class="x p-relative f aic jcc scale-0 scale-100">
                                <input
                                    v-model="prompt"
                                    ref="inputField"
                                    @focus="resizeHandler"
                                    class="x f-20 border p-2 px-3 text-base-900 placeholder-gray-300 shadow shadow-md"
                                    :placeholder="placeholderText"
                                    @keyup.enter="enterHit(prompt)" />
                                <div class="z-5 p-absolute right text-blue p-2" @click.prevent="enterHit(prompt)">
                                    <i class="fas fa-arrow-circle-up f-24"></i>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>
<script>
import StartDeckButton from "@/components/Presentations/StartDeckButton.vue";
import documentManagementMixin from "@/mixins/documentManagementMixin";

export default {
    name: "FloatingMenu",
    components: { StartDeckButton },
    mixins: [documentManagementMixin],
    props: {
        document: {},
    },
    emits: ["startNewDeck"],
    data() {
        return {
            expanded: false,
            responseText: "",
            prompt: "",
            startingNew: false,
        };
    },
    computed: {
        showCreateModal() {
            if (this.document && this.document.sections) {
                this.startingNew = false;
            } else {
                this.startingNew = true;
            }
        },
        changeResposeText() {
            const sectionIndex = this.sectionIndex;
            this.document.sections[sectionIndex].enhancedBody = this.responseText;
        },
        placeholderText() {
            if (this.startingNew) {
                return "What is your new deck about?";
            } else {
                return "Leave a commment";
            }
        },
    },
    watch: {
        expanded(val) {
            if (val) {
                //   const input = this.$refs.inputField;
                this.$nextTick(() => {});
            }
        },
        responseText: {
            handler: function (val, oldVal) {
                if (val !== null) {
                    const sectionIndex = this.sectionIndex;
                    this.document.sections[sectionIndex].enhancedBody = val;
                    val.replace("[[DONE]]", "").replace("null", "");
                }
            },
            deep: true,
        },
    },
    mounted() {
        const self = this;
        if (!this.mobile) {
            this.expanded = true;
        }
        window.visualViewport.addEventListener("resize", self.resizeHandler());
    },
    methods: {
        resizeHandler() {
            this.$nextTick(() => {
                if (this.expanded) {
                    setTimeout(() => {
                        this.$refs.containter.style.bottom = "0px";
                    }, 500);
                }
                this.$refs.inputField.focus();
            });
        },
        async modifyPrecursor(prompt) {
            if (!this.startingNew) {
                let instructions = `The following is a user's instructions to edit a presentation slide ( choices are: image, bodyText, titleText, createNew)\n\n${prompt}\n\nUser intent is to edit the slide's: `;
                let request = {
                    model: "text-davinci-003",
                    prompt: instructions,
                    temperature: 1,
                    max_tokens: 50,
                    top_p: 1,
                    frequency_penalty: 0,
                    presence_penalty: 0,
                };
                const response = await openai.createCompletion(request);
                let text = response.data.choices[0].text;
                text = text.toLowerCase();
                console.error(text);
                if (text.includes("create") || text.includes("new")) {
                    this.startingNew = true;
                    this.prompt = "";
                } else if (text.includes("body")) {
                    this.modifyPassage(prompt);
                }

                // else else {
                //       return;
                //       await this.modifyImage(prompt);
                //   }
            } else {
                this.startDeck(prompt);
            }
        },
        async cancelNewDeck() {
            await this.removeFromFirebase("documents", this.$route.params.id);
            this.$router.go(-1);
            this.$emit("close");
        },
        startDeck(prompt, kind) {
            this.$emit("newDeckModal", prompt);
            this.prompt = "";
        },
        // async newDeck(prompt) {
        //     if (prompt) {
        //         await this.startDeck();
        //
        //     } else {
        //         this.$emit("newDeck", this.prompt);
        //     }
        // },
        enterHit(prompt) {
            if (prompt.includes("cancel")) {
                this.startingNew = false;
                this.prompt = "";
            }
            this.modifyPrecursor(prompt);
            return;
            if (this.startingNew) {
                this.newDeck(prompt);
            } else {
                this.modifyPassage(this.prompt);
            }
        },
        modifyPassage(prompt) {
            let comment = {
                comment: prompt,
                original: this.document.sections[this.sectionIndex].enhancedBody,
                user: "Asher Hunt",
            };
            // this.addComment(this.$route.params.id, comment);
            // this.comments.push(comment);
            this.prompt = "";
            const sectionIndex = this.sectionIndex;
            // this.showComments = false;
            this.responseText = "";
            let text = this.document.sections[sectionIndex].body;
            if (this.document.sections[sectionIndex].enhancedBody) {
                text = this.document.sections[sectionIndex].enhancedBody;
            }
            let context = `CONTEXT:
                            ${this.document.summary}

                            `;
            let modString = `${context}
ORIGINAL PASSAGE:
${text}

INSTRUCTIONS:
${prompt}

UPDATED PASSAGE:
###

            `;

            return this.streamText(modString, "responseText"); // NOTE: i want this.streamText() to write to stream to this.responseText
        },
        async streamText(prompt, VueProperty) {
            let streamPrompt = `${prompt}
(please type "[[DONE]]" on a new line when update is complete.)
###

`;
            const request = {
                model: "text-davinci-003",
                prompt: streamPrompt,
                temperature: 0.9,
                max_tokens: 500,
                top_p: 0.9,
                frequency_penalty: 0,
                presence_penalty: 0,
                stream: true,
                stop: "[[DONE]]",
            };
            console.error();
            const self = this;
            console.log(request);
            try {
                // const response = await fetch("http://localhost:80/textTest1", {
                const response = await fetch("http://34.170.111.18/textTest1", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(request),
                });

                const reader = response.body.getReader();
                while (true) {
                    console.log(response.body);
                    const { value, done } = await reader.read();
                    self[VueProperty] += new TextDecoder("utf-8").decode(value);
                    console.log(new TextDecoder("utf-8").decode(value));
                    if (self[VueProperty].includes("[[DONE]]" || done)) {
                        self[VueProperty] = self[VueProperty].replace(/\[\[DONE\]\]/g, "");
                        await this.saveSection();
                        // this.addComment(this.$route.params.id, {
                        //     comment: "Ok! I did that",
                        //     original: self[VueProperty],
                        //     user: "Clearmind",
                        // });
                        // this.comments.push({comment: 'Ok! I did that', original: self[VueProperty],user:'Clearmind'});
                        break;
                        return;
                    }
                    if (done) {
                        console.error("FINISHED");
                        break;
                    }
                }
            } catch (e) {
                console.error(e);
            }
        },
    },
};
</script>
<style scoped>
.h-0 {
    height: 0px;
}
.ease-elastic {
    //transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition: all 1s cubic-bezier(0.06, 1.59, 0.4, 0.84);
    //transition: all 1s cubic-bezier(0.06, 1.59, 0.4, 0.84);
}
</style>
