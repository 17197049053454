import moment from 'moment';
// export moment;

export default {
    created: function () {
    },
    mounted() {

    },
    data: () => ({

    }),

    computed: {
        chatMode(){
            return this.$store.state.chatMode;
        },
        chatLoading() {
            return this.$store.state.chatLoading;
        },
        fetching() {
            return this.$store.state.fetching;
        },
        hasChats() {
            return Array.isArray(this.chatItems) && this.chatItems.length > 0;
        },
        step() {
            return this.$store.state.step;
        },
        chatItems() {
            return this.$store.state.chatItems;
        },
    },
    methods: {
        setChatMode(payload) {
          this.$store.commit('setChatMode', payload);
        },
        resetChat(){
            this.setStep(0);
            this.$store.commit('resetChat',true);
            this.promptData.topic = null;
            this.promptData.artist.name = '';
            this.promptData.name = '';
            this.promptData.genre = '';
        }
    }
};
