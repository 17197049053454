<template>
    <div class="d-block">
        <div class="spinner br-10 d-inline-flex mt-0 cursor-pointer gap-1" :class="{ dark: dark }" :style="bgMod">
            <div class="bounce1" :style="colorMod"></div>
            <div class="bounce2" :style="colorMod"></div>
            <div class="bounce3" :style="colorMod"></div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        colorMod() {
            if (this.color) {
                return `background:${this.color};`;
            } else {
                return " ";
            }
        },
        bgMod() {
            if (this.background) {
                return `background:${this.background};border-radius:1.2rem;padding:unset;padding:0.8rem 1rem; margin:.3em;white-space:pre-wrap;width:unset;`;
            } else {
                return "padding:unset;margin:unset;white-space:pre-wrap;width:unset;";
            }
        },
    },
    props: {
        background: {
            type: String,
            default: null,
        },
        color: {
            type: String,
            default: null,
        },
        dark: {
            type: Boolean,
            default: false,
        },
    },
    name: "loading-spinner",
};
</script>
