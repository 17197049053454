<script>
import AdminMixins from "@/mixins/AdminMixins.js";
import training from '../../../public/assets/training.js';
import trainingMixins from "@/mixins/TrainingMixins";
// import ButtonSecondary from "@/components/ButtonSecondary.vue";

export default {
    components: {},
    data: () => ({}),
    props: ['song'],
    mounted() {},
    computed: {},
    mixins: [trainingMixins, AdminMixins]
};
</script>
<template>
    <div class="border border-bubble-900 border-solid">
        <p class="mb-0 fwb d-block fwb">{{ song.artist }}</p>
        <p>{{ song.title }}</p>
        <p v-for=" item in song.topicTraining" v-if="song.topicTraining">{{ item }}</p>
        <p class="f-11 whitespace-pre-line" v-if="song.plainText">{{ song.plainText.slice(0,100) }}</p>
        <div class="f-11 mb-2" v-for="section in song.sections" v-if="false">
            <p class="mb-0 fwb d-block">{{ section.section }}</p>
            <p class="mb-0 whitespace-pre-line">{{ section.lyrics }}</p>
        </div>
<!--        <button-secondary text="Train" color="0,0,0" class="f-13 d-inline" @click.prevent="getSongDescription(song)"></button-secondary>-->
        <!--                                    <div :key="artist.name" v-for="section in artist.songs.section">-->
        <!--&lt;!&ndash;                                    <div :key="artist.name" v-for="section in fixSongs(song)">&ndash;&gt;-->

        <!--                                        <div class="py-2" :key="section.section">-->
        <!--                                            <p class="mb-0 fwb d-block">{{ section.section }}</p>-->
        <!--                                            <p class="mb-0 whitespace-pre-line">{{ section.lyrics }}</p>-->
        <!--                                        </div>-->
        <!--                                    </div>-->
    </div>
</template>
