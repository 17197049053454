<template>
    <div :id="'slide' + index" class="p-relative z-1 x slide" @mouseleave="moveOver = false" :class="`slide${index}`">
        <Transition
            enter-active-class="swingInUp ease-back duration-5 d-1 animated"
            leave-active-class="ease-back duration-1 fadeOutDown animated">
            <div
                class="br-10 p-relative px-lg-5 container m-0 mx-auto mb-3 overflow-hidden p-0 px-1 text-center"
                style="max-width: 100%">
                <div class="f x p-relative">
                    <Transition
                        enter-active-class="swingInUp duration-3 animated"
                        enter-from-class="o-0 left-50"
                        enter-to-class="bg-blur"
                        leave-active-class=" duration-1 fadeOut animated ">
                        <div
                            v-if="moveOver"
                            ref="editMenu"
                            style="z-index: 0"
                            class="x mw-350 mw-md-400 br-10 border-light p-absolute top right z-0 m-3 mt-6 mr-2 mt-3 h-auto bg-base-800/90 p-3 shadow"
                            @mouseleave="moveOver = false">
                            <div class="f fc br-10 gap-2">
                                <div class="form-group">
                                    <span class="d-block h6 white f-11 mb-0 text-left">Slide Style</span>
                                    <select
                                        v-model="slide.style"
                                        class="f-15 mb-0 bg-none"
                                        style="border: 1px solid white; background: none; color: white">
                                        <option value="right">Image Right</option>
                                        <option value="left">Image Left</option>
                                        <option value="fullBleed">Full Bleed</option>
                                        <option value="tablegrid">Info Grid</option>
                                        <option value="matrix">Matrix</option>
                                        <option value="timeline">Timeline</option>
                                        <option value="other">other</option>
                                    </select>
                                </div>
                                <div v-if="section.title" class="form-group">
                                    <span class="d-block h6 white f-11 mb-0 mb-1 text-left">Title</span>
                                    <input
                                        v-model="section.title"
                                        class="f-15 br-5 bg-none"
                                        placeholder="Title"
                                        style="border: 1px solid white; background: none; color: white" />
                                </div>
                                <div class="form-group">
                                    <span class="d-block h6 white f-11 mb-0 mb-1 text-left">Body</span>
                                    <textarea v-model="section.body" class="f-17" placeholder="Body" type="textarea" />
                                </div>
                                <div class="form-group">
                                    <span class="d-block h6 white f-11 mb-0 mb-1 text-left">Image prompt</span>
                                    <textarea
                                        v-if="section.enhancedImage"
                                        v-model="section.enhancedImage"
                                        class="f-17 whitespace-pre-line"
                                        style="height: 300px"
                                        placeholder="Image prompt"
                                        type="textarea" />
                                    <textarea
                                        v-else
                                        v-model="section.image"
                                        class="f-17"
                                        placeholder="Body"
                                        type="textarea" />
                                </div>
                                <div>
                                    <ButtonSecondary
                                        class="f-15 x rounded-full"
                                        text="Update"
                                        @click.prevent="
                                            saveDoc(document),
                                            changeStyle(),
                                                enhanceBody(
                                                    section.body,
                                                    index,
                                                    extractPresentationText,
                                                    section,
                                                    document,
                                                )
                                        "></ButtonSecondary>
                                    <!--                                    <button-secondary class="f-13 rounded-full x" @click.prevent="changeStyle()" text="Save style"></button-secondary>-->
                                </div>
                            </div>
                        </div>
                    </Transition>
<!--                    <div-->
<!--                        class="z-3 p-absolute top right h-auto"-->
<!--                        style="bottom: 200px; width: 100px; height: 300px"></div>-->

                    <div
                        class="x d-block z-1"
                        :class="{ moveover: moveOver }"
                        style="transition: transform 0.3s ease-in-out">
                        <div v-if="slide" ref="slide" class="d-block">
                            <SlideRight
                                v-if="slideStyle === 'right'"
                                :id="document.id"
                                :document="document"
                                :document-id="documentId"
                                :index="index"
                                :section="section"
                                :slide-container-classes="slideContainerClasses"></SlideRight>
                            <SlideLeft
                                v-else-if="slideStyle === 'left'"
                                :id="document.id"
                                :document="document"
                                :document-id="documentId"
                                :index="index"
                                :section="section"
                                :slide-container-classes="slideContainerClasses"></SlideLeft>
                            <SlideFullbleed
                                v-else-if="slideStyle === 'fullBleed'"
                                :id="document.id"
                                :document="document"
                                :document-id="documentId"
                                :index="index"
                                :section="section"
                                :slide-container-classes="slideContainerClasses"></SlideFullbleed>
                            <SlideMatrix
                                v-else-if="slideStyle === 'matrix'"
                                :id="document.id"
                                :document="document"
                                :document-id="documentId"
                                :index="index"
                                :section="section"
                                :slide-container-classes="slideContainerClasses" />
                            <SlideTimeline
                                v-else-if="slideStyle === 'timeline'"
                                :id="document.id"
                                :document="document"
                                :document-id="documentId"
                                :index="index"
                                :section="section"
                                :slide-container-classes="slideContainerClasses"></SlideTimeline>
                            <SlideTable
                                v-else-if="slideStyle === 'tablegrid'"
                                :id="document.id"
                                :document="document"
                                :document-id="documentId"
                                :index="index"
                                :section="section"
                                :slide-container-classes="slideContainerClasses"></SlideTable>
                            <SlideOther
                                v-else
                                :id="document.id"
                                :document="document"
                                :document-id="documentId"
                                :index="index"
                                :section="section"
                                :slide-container-classes="slideContainerClasses"></SlideOther>
                        </div>
                        <div class="f jcb aic x">
                            <div class="f d-block mt-3">
                                <div v-if="document.sections" class="z-0 mx-auto max-w-xs text-base-50">
                                    {{ index + 1 }} of {{ document.sections.length }}
                                </div>
                            </div>
                            <div class="f aie jcc z-5 text-center" v-if="!mobile">
                                <ButtonIconWhite
                                    class="fas f-24 align-self-center cursor-pointer"
                                    icon="plus fad"
                                    @click.prevent="addSlideBelow(index)"></ButtonIconWhite>
                                <ButtonIconWhite
                                    class="fas f-20 align-self-center cursor-pointer"
                                    icon="trash fad"
                                    @click.prevent="removeSlideClicked(index)"></ButtonIconWhite>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="!moveOver"
                        class="p-absolute top right z-4 f-30 text-shadow py-2 pr-4 text-base-300 mix-blend-hard-light"
                        @mouseover.prevent="moveOver = true"
                        @click.prevent="moveOver = true">

                        <i class="fa fa-ellipsis-h" aria-hidden="true"></i>
                    </div>
                    <div
                        v-if="moveOver"
                        class="p-absolute top right z-4 f-30 text-shadow py-2 pr-4 text-base-300 mix-blend-hard-light"
                        @click.prevent="moveOver = false">
                        <i class="fa fa-times" v-if="moveOver" aria-hidden="true"></i>
                    </div>
                </div>
                <!--                            <presentation-color-pickers :set-background="setBackground(obj)" :set-image-bg="setImageBg(obj)" :set-text="setText(obj)" :shades="shades(theme.primary.main)" :shades-bezier-dark-primary="shadesBezierDarkPrimary(theme.primary.main)" :theme="theme"/>-->
            </div>
        </Transition>
    </div>
</template>
<script>
import { useIntersectionObserver } from "@vueuse/core";
import { doc, getDoc, collection, Timestamp } from "firebase/firestore";
import { ref } from "vue";
import { useCollection, useDocument } from "vuefire";

import ButtonIconWhite from "@/components/buttons/buttonIconWhite.vue";
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import buttonSecondary from "@/components/buttons/buttonSecondary.vue";
import SlideFullbleed from "@/components/Presentations/slides/largeSlides/FullbleedSlide.vue";
import SlideLeft from "@/components/Presentations/slides/largeSlides/LeftSlide.vue";
import SlideMatrix from "@/components/Presentations/slides/largeSlides/MatrixSlide.vue";
import SlideOther from "@/components/Presentations/slides/largeSlides/OtherSlide.vue";
import SlideRight from "@/components/Presentations/slides/largeSlides/RightSlide.vue";
import SlideTable from "@/components/Presentations/slides/largeSlides/TableSlide.vue";
import SlideTimeline from "@/components/Presentations/slides/largeSlides/TimelineSlide.vue";
import { db } from "@/firebase";
import documentManagementMixin from "@/mixins/documentManagementMixin";
import documentMixins from "@/mixins/documentMixins";
import presenationMixin from "@/mixins/presentationMixin";
import SlideStyleMixin from "@/mixins/slideStyleMixin";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
const documents = collection(db, "documents");
export default {
    components: {
        SlideRight,
        SlideLeft,
        SlideFullbleed,
        SlideOther,
        SlideMatrix,
        SlideTimeline,
        ButtonSecondary,
        buttonSecondary,
        ButtonIconWhite,
        SlideTable,
    },
    mixins: [SlideStyleMixin, presenationMixin, documentManagementMixin],
    data: () => ({
        // edit: false,
        document: {},
        moveOver: false,
        blockStyle: null,
        documents: [],
        selectedBackground: null,
        selectedText: null,
        theme: null,
        colors: null,
        hue: 0,
        color: "#000000",
        colorSecondary: "#000000",
        saturation: 0,
        lightness: 0,
    }),
    created() {
        this.$firestoreBind("document", doc(documents, this.$route.params.id));
        // this.slide.style = this.slideStyle;
    },
    mounted() {
        this.$nextTick(() => {
            this.updateColors();
            setTimeout(() => {
                // this.initScrollTrigger();
            }, 2000);
        });
        // this.updateColors();
    },
    firestore: {
        documents: collection(db, "documents"),
        // document: useDocument(doc(db, 'settings',route.params.id)),
    },

    props: {
        id: {
            type: String,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        slide: {
            type: Object,
            required: true,
        },
        section: {
            type: Object,
            required: true,
        },
        documentId: {
            type: String,
            required: true,
        },
    },
    methods: {
        offsetHeight() {
            // display offset height for section

            return this.$refs[`slide-${this.index}`].offsetHeight;
        },

        updateColors() {
            this.$nextTick(() => {
                if (this.document.theme) {
                    let style = this.slideStyle;
                    let t = this.document.theme.colors || false;
                    if (t) this.textTheme();
                    this.blockStyle = this.slideStyle;
                }
            });
        },
        removeSlideClicked(index) {
            // write code to remove a slide from this.document.sections's firestore reference
            this.document.sections.splice(index, 1);
            this.saveDoc(this.document);
        },
        async changeStyle() {
            // await this.updateSection(this.idFix, this.blockStyle, this.index, 'style');
            // await this.saveDoc(this.idFix, this.document);
            // await this.updateAnyObject('documents', this.idFix, this.document);
            // this.section.style = this.blockStyle;
            await this.saveDoc(this.document);
            this.$firestoreBind("document", doc(documents, this.$route.params.id));
        },
        async resetDoc() {
            console.error("saving new deck");
            const tempId = this.randomId();
            const timestamp = Date.now();
            let doc = {
                title: "New presentation",
                theme: {
                    colors: null,
                    shades: null,
                    why: null,
                },
                id: null,
                created: timestamp,
                updated: timestamp,
                documentType: "presentation",
                sections: [
                    {
                        title: "New slide",
                        style: "left",
                        imageURL: null,
                        body: "new slide",
                        id: tempId,
                    },
                ],
            };
            // await this.updateSection(this.idFix, doc, this.index, 'doc');
        },
    },
    computed: {
        slideStyle() {
            if (this.slide) {
                let slideStyle = this.slide.style;
                let style;
                if (
                    slideStyle.includes("right") ||
                    slideStyle.includes("imageRight") ||
                    slideStyle.includes("rSlide")
                ) {
                    style = "right";
                } else if (slideStyle.includes("left")) {
                    style = "left";
                } else if (slideStyle.includes("matrix")) {
                    style = "matrix";
                } else if (slideStyle.includes("timeline")) {
                    style = "timeline";
                } else if (slideStyle.includes("tablegrid") || slideStyle.includes("list")) {
                    style = "tablegrid";
                } else if (
                    slideStyle.includes("full") ||
                    slideStyle.includes("fullbleed") ||
                    slideStyle.includes("full bleed") ||
                    slideStyle.includes("fullBleedImage")
                ) {
                    style = "fullBleed";
                }
                // console.error(style);
                return style;
            }
        },
        hsl() {
            return `hsl(${this.hue}, ${this.saturation}%, ${this.lightness}%)`;
        },
        slideContainerClasses() {
            return `row x no-gutters m-0 p-0 shadow-md overflow-hidden br-10 h-100`;
            // return `row no-gutters m-0 p-0 bg-base-100 text-gray-900 shadow-md shadow-aqua-50 overflow-hidden br-10`;
        },
    },
    watch: {
        "slide.style": {
            immediate: false,
            handler(val, oldVal) {
                if (val) {
                    this.document.sections[this.index].style = val;
                    this.saveDoc(this.document);
                    this.$firestoreBind("document", doc(documents, this.$route.params.id));
                    console.error("updated Block Style", oldVal, val);
                }
            },
        },
        "document.sections": {
            immediate: true,
            async handler(val, oldVal) {
                if (this.document.theme) {
                    let t = this.document.theme.colors || false;
                    if (t) this.textTheme();
                    let style = this.slideStyle;
                }
            },
        },
        document: {
            immediate: true,
            async handler(val, oldVal) {
                if (this.document.theme) {
                    let t = this.document.theme.colors || false;
                    if (t) this.textTheme();
                    let style = this.slideStyle;
                }
            },
        },
    },
};
</script>
<style scoped>
input,
textarea {
    border: 1px solid white;
    margin-bottom: 0px;
    background: none;
    color: white;
}
.moveover {
    transition: all 0.3s ease-in-out;
    transform: translateX(-95%);
}

@media (min-width: 576px) {
    .moveover {
        transition: all 0.3s ease-in-out;
        transform: translateX(-450px);
    }
}

@media (min-width: 768px) {
    .moveover {
        transition: all 0.3s ease-in-out;
        transform: translateX(-450px);
    }
}

@media (min-width: 992px) {
    .moveover {
        transition: all 0.3s ease-in-out;
        transform: translateX(-450px);
    }
}

@media (min-width: 1200px) {
    .moveover {
        transition: all 0.3s ease-in-out;
        transform: translateX(-450px);
    }
}
</style>
