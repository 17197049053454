<script>
import PromptPreviewVisual from "@/components/music/PromptPreviewVisual.vue";
import ChatBubble from "@/components/chat/ChatBubble.vue";
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";
import DebugPrompts from "@/components/debugging/debugPrompts.vue";

export default {
    components: {DebugPrompts, ButtonPrimary, ButtonSecondary, ChatBubble, PromptPreviewVisual},
    mounted() {},
    data() {
        return {
            debug: false,
        };
    },
    methods: {

        setSongTitle(suggestion) {
            // this.$emit('setSongTitle', suggestion);
            this.promptData.name = suggestion;
            console.log(suggestion);
        },
        requestTitles() {
            // this.$emit('requestSongTitles');
            this.requestSongTitles();
        },
        setSongAbout(suggestion) {
            this.promptData.topic = suggestion;
            // this.$emit('setSongAbout', suggestion);
        },
        // requestSongConcepts() {
        //     this.$emit('requestSongConcepts');
        // },
        requestLyrics() {
            const promptText = this.promptText.raw;
            this.getLyrics(promptText);
        }
    },
    computed: {},
};
</script>
<template>
    <div v-if="!refreshing" class="z-1 x y f fc p-relative mw-600">
        <div class="f aic jcc y mx-auto x" style="min-height:300px;">
            <div class="x p-relative fc ais jcc ">
                <prompt-preview-visual :prompt-data="promptData" :prompt-text="promptText" class="f-24 text-purple-600 fadeInUpSmooth duration-4 d-1 animated" style="letter-spacing:unset;"></prompt-preview-visual>
                <div class="white py-3 line-height-smaller f-20 f-md-24">
                    <p class="py-2"><span class="fwb">Genre:</span> {{ promptData.genre.name }}</p>
                    <p class="py-2"><span class="fwb">Inspired by:</span> {{ promptData.artists.name }}</p>
                    <p class="py-2"><span class="fwb">About: </span> {{ promptData.topic }}</p>
                    <div class="f ais jcc my-2 fc my-2 py-2"><span class="fwb d-block">Name: </span>
                        <input v-model="promptData.name" class="white x" style="background:none;border:2px solid rgba(255,255,255,0.3);">
                    </div>
                    <div class="f ais jcc fc my-2 py-2"><span class="fwb d-block">About: </span>
                        <input v-model="promptData.topic" class="white x" style="background:none;border:2px solid rgba(255,255,255,0.3);">
                    </div>
                </div>
                <button-secondary class="fadeInUpSmooth duration-4 d-2 animated d-inline-block f-20 mr-1 px-5 mt-3 text-center" text="Get Lyrics" @click.prevent="getLyrics(promptText.raw)"></button-secondary>
                <div class="my-3 border-top py-3">
                    <button-secondary class="fadeInUpSmooth duration-4 d-2 animated d-inline-flex f-13 mr-1" icon="sync" text="Randomize" @click.prevent="pickTopic()"></button-secondary>
                    <button-secondary class="fadeInUpSmooth duration-4 d-3 animated d-inline-flex f-13 mr-1" text="Get topics" @click.prevent="getConcepts()"></button-secondary>
                    <button-secondary class="fadeInUpSmooth duration-4 d-4 animated d-inline-flex f-13 mr-1" text="Get titles" @click.prevent="requestSongTitles()"></button-secondary>
                </div>
                <debug-prompts></debug-prompts>
            </div>

        </div>

        <!--                                    <div class="x p-relative overflow-hidden">-->
        <!--                                        <artist-bubbles :refreshing="refreshing" :selected-style="selectedStyle" class="x"></artist-bubbles>-->
        <!--                                    </div>-->
    </div>
</template>
<style lang="scss">
.previous-prompt{
    .prompt{
        letter-spacing: -0.05em;
        color: #777777;

        &-genre{
            font-weight: bold;
            color: white;
        }

        &-name{
            color: white;

            &-title{
                font-weight: bold;
            }
        }

        &-topic{
            font-weight: 500;
            font-style: italic;
            color: white;
        }

        &-artist{
            font-size: 15px;
            font-style: italic;
            display: block;
            margin-top: 10px;
            letter-spacing: 0rem;
            opacity: 0.8;
            color: white;

            &-name{
                font-weight: bold;
            }
        }

        &-location{
            font-weight: bold;
            color: white;
        }
    }
}
</style>
