<template>
    <div>
        <div class="timeline mt-8">
            <div class="timeline-year" v-for="event in timelineYears" v-bind:key="event.year">
                {{ event.year }}
                <div class="timeline-event d-block" v-for="e in event" v-bind:key="e.description">
                    <div class="timeline-event-date d-block fwb">{{ e.date }}</div>
                    <div class="timeline-event-description f-11">{{ e.description }}</div>
                </div>
            </div>
            <!--        a form to add to the timeline -->

        </div>
        <div class="timeline-form">
            <div class="timeline-form-year">
                <label for="year">Year</label>
                <input type="text" id="year" v-model="year">
            </div>
            <div class="timeline-form-description">
                <label for="description">Description</label>
                <input type="text" id="description" v-model="description">
            </div>
            <div class="timeline-form-submit">
                <button @click="addEvent(year,description)">Add Event</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
      watch: {
        document: {
            handler: function (val, oldVal) {
                if (val.sections !== oldVal.sections) {
                    let t = val.theme.colors || false;
                    if (t) this.textTheme();
                }
            },
            deep: false,
        },
    },
    data: () => ({
        year: '',
        description: '',
        timeline: [
            {
                "year": 1789,
                event: {
                    date: "May 5",
                    description: "The Estates-General is called by King Louis XVI to address France's financial crisis."
                }
            },
            {
                "year": 1789,
                event: {
                    date: "May 17",
                    description: "The Third Estate (commoners) declares itself the National Assembly and takes the Tennis Court Oath to create a new constitution."
                }
            },
            {
                "year": 1789,
                event: {
                    date: "July 14",
                    description: "The Bastille is stormed by a mob, marking the start of the French Revolution."
                }
            },
            {
                "year": 1790,
                event: {
                    description: "The National Assembly adopts the Declaration of the Rights of Man and of the Citizen, which outlines the rights and freedoms of French citizens."
                }
            },
            {
                "year": 1790,
                event: {
                    description: "King Louis XVI attempts to flee Paris but is caught and brought back to the city."
                }
            },
            {
                "year": 1791,
                event: {
                    description: "The National Assembly adopts the new French Constitution, which establishes a constitutional monarchy."
                }
            },
            {
                "year": 1791,
                event: {
                    description: "The Legislative Assembly is established, replacing the National Assembly."
                }
            },
            {
                "year": 1792,
                event: {
                    description: "War breaks out between France and several European countries."
                }
            },
            {
                "year": 1792,
                event: {
                    description: "The Legislative Assembly is replaced by the National Convention, which declares France a republic."
                }
            },
            {
                "year": 1792,
                event: {
                    date: "January 21",
                    description: "King Louis XVI is put on trial and found guilty of high treason. He is executed by guillotine."
                }
            },
            {
                "year": 1793-1794,
                event: {
                    description: "The Reign of Terror begins, in which thousands of people are executed by guillotine as enemies of the revolution."
                }
            },
            {
                "year": 1793-1794,
                event: {
                    description: "Maximilien Robespierre becomes the leader of the Committee of Public Safety, which leads the Reign of Terror."
                }
            },
            {
                "year": 1795,
                event: {
                    description: "The Reign of Terror ends and the Thermidorian Reaction begins, a period of relative political stability."
                }
            },
            {
                "year": 1795,
                event: {
                    description: "The Directory is established as the new government of France, with executive power held by five directors."
                }
            }
        ],

    }),
    computed: {
        timelineYears() {
            return this.timeline.sort((a, b) => a.year - b.year);
        }
    },
    methods: {
        // make a method to add a new event to the timeline
        addToTimeline(year, description) {
            //make a method to add a new event to the timeline
            this.timeline.push({
                year: year,
                event: {
                    description: description
                }
            });

        },
        addEvent(year,description) {
            this.addToTimeline(this.year, this.description);
        }
    }
};
</script>

<style>
.timeline {
    display: flex;
    flex-direction: row;
}

.timeline-year {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.timeline-event {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.timeline-event-date {
    font-style: italic;
    margin-right: 10px;
}

.timeline-event-description {
    flex: 1;
}
</style>
