<script setup>
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import AdminArtistCard from "@/components/music/AdminArtistCard.vue";
import MusicGenerator from "@/components/music/musicGenerator.vue";
import AdminGenreCard from "@/components/music/AdminGenreCard.vue";

</script>

<script>
import AdminMixins from "@/mixins/AdminMixins.js";

export default {
    data() {
        return {
            pageArtists: [],
            dbGenres: [],
            selectedGenre: {},
        };
    },
    async mounted() {
        // this.pageArtists = await this.getFilteredArtists('Hard Rock');
        this.dbGenres = await this.getAnyDBItem('genre', this.dbGenres);
    },
    mixins: [AdminMixins],
    computed: {
        filteredArray() {
            const arr1 = this.genres;
            const arr2 = this.dbGenres;
            return arr1.filter(obj => {
                return !arr2.some(i => i.name === obj.name);
            });
        },
    },
    methods: {
        async handleItemSaved(i, subArrayName) {
            console.error(i);
            console.error(subArrayName);
            const arr1 = this.dbGenres;
            let arr2 = this.genre;
            let newItem = i;
            console.error(i.name);
            const subArray = await this.getFilteredArtists(i.name);
            let newSubArray = [];
            for (const i of subArray) {
                newSubArray.push(i.id);
            }
            newItem[subArrayName] = newSubArray;
            // arr1.push(newItem);
            console.error(subArrayName);
            await this.saveItem('genre', newItem, arr1);
        },
        async handleItemRemoved(collection, item) {
            console.error('its at least emitting');
            await this.removeItem(collection, item, this.dbGenres);
        },
        //add feature to hide and show a specific genre item in the database. Update the item don't create a new one.
        async handleHideShow(collection, item, payload) {
            console.log(collection,item.id, payload);
           await this.updateAnyObject(collection, item.id, {
                hidden: payload
            });
        },

        handleGenreSelected(genre) {
            this.filterArtists(genre);
            console.log(genre);
        },

    }
};
</script>
<template>
    <div class="scrollSection py-8">
        <!--        <music-generator></music-generator>-->
        <div class="container-fluid">
            <!--/*            <JsonViewer class="scrollSection x br-20 z-5 mb-3" style="max-height:300px;" :value="genres" sort theme="dark" @onKeyClick="keyClick"/>*/-->
            <div class="container my-5">
                <h3>Update Genres</h3>
                <span class="d-block">{{ artistNames }}</span>
                <button-secondary text="Update genre" class="f-11" @click.prevent="updateGenre(selectedGenre)"></button-secondary>
            </div>
            <div class="container x mx-auto">
                <div class="row no-gutters">

                    <div class="col-12"><h4>Server</h4></div>
                    <template v-for="i in dbGenres">
                        <div class="col-4">
                            <admin-genre-card :item="i" @handle-item-removed="handleItemRemoved" @handle-item-saved="handleItemSaved" @handle-hide-show="handleHideShow"></admin-genre-card>
                        </div>
                    </template>

                    <div class="col-12"><h4>Local</h4></div>
                    <div class="col-6">
                        <template v-for="i in filteredArray">
                            <admin-artist-card :item="i" @handle-item-removed="handleItemRemoved" @handle-item-saved="handleItemSaved"></admin-artist-card>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
