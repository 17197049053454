export default {
    created: function () {},
    mounted() {},
    data: () => ({
        bgDarks: [
            "bg-darks-1",
            "bg-darks-2",
            "bg-darks-3",
            "bg-darks-4",
            "bg-darks-5",
            "bg-darks-6",
            "bg-darks-7",
            "bg-darks-8",
            "bg-darks-9",
        ],
        bgLights: [
            "bg-light",
            "bg-light-2",
            "bg-light-3",
            "bg-light-4",
            "bg-light-5",
            "bg-light-6",
            "bg-light-7",
            "bg-light-8",
            "bg-light-9",
        ],
        textLight: [
            "text-light",
            "text-light-2",
            "text-light-3",
            "text-light-4",
            "text-light-5",
            "text-light-6",
            "text-light-7",
            "text-light-8",
            "text-light-9",
        ],
        textDark: [
            "text-dark",
            "text-dark-2",
            "text-dark-3",
            "text-dark-4",
            "text-dark-5",
            "text-dark-6",
            "text-dark-7",
            "text-dark-8",
            "text-dark-9",
        ],
    }),

    computed: {
        backgroundColor() {
            if (this.document && this.document.theme && this.document.theme.colors) {
                let bg = this.document.theme && this.document.theme.colors && this.document.theme.colors.secondary;
                if (bg) {
                    let c = this.document.theme.colors.secondary || "hsl(100%,0%,90%)";
                    if (c.startsWith("#")) {
                        c = this.hexToHSL(c);
                    }
                    let hslColor = c;
                    let [hue, saturation, lightness] = hslColor.match(/\d+/g);
                    // a random saturation betwen 10% and 30%
                    lightness = "30";
                    saturation = "10";
                    // saturation = Math.floor(Math.random() * 50) + 10;
                    let newColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
                    // random lightness between 10 and 30
                    this.$forceUpdate();
                    return newColor;
                } else {
                    return "hsl(0%,0%,90%)";
                }
            } else {
                // this.document.theme.colors = null;
                return false;
            }
        },
        colorLightest() {
            if (this.backgroundColor) {
                let hslColor = this.backgroundColor;
                let [hue, saturation, lightness] = hslColor.match(/\d+/g);
                lightness = "80";
                saturation = "15";
                let newColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
                return newColor;
            } else {
                return "hsl(0%,0%,90%)";
            }
        },
        colorLighter() {
            if (this.backgroundColor) {
                let hslColor = this.backgroundColor;
                let [hue, saturation, lightness] = hslColor.match(/\d+/g);
                lightness = "35";
                saturation = "10";
                let newColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
                return newColor;
            } else {
                return "hsl(0%,0%,90%)";
            }
        },
        colorLight() {
            if (this.backgroundColor) {
                let hslColor = this.backgroundColor;
                let [hue, saturation, lightness] = hslColor.match(/\d+/g);
                lightness = "35";
                saturation = "10";
                let newColor = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
                return newColor;
            } else {
                return "hsl(0%,0%,90%)";
            }
        },
        colorDarker() {
            if (this.backgroundColor) {
                let hslColor = this.backgroundColor;
                let [hue, saturation, lightness] = hslColor.match(/\d+/g);
                lightness = "25%";
                saturation = "10";
                let newColor = `hsl(${hue}, ${saturation}%, ${lightness})`;
                return newColor;
            } else {
                return "hsl(0%,0%,90%)";
            }
        },
        backgroundColorDarkest() {
            if (this.backgroundColor) {
                let hslColor = this.backgroundColor;
                let [hue, saturation, lightness] = hslColor.match(/\d+/g);
                lightness = "5%";
                saturation = "10";
                let newColor = `hsl(${hue}, ${saturation}%, ${lightness})`;
                return newColor;
            } else {
                return "hsl(0%,0%,90%)";
            }
        },
    },
    methods: {
        slideColors() {
            //pick random textLight
            let slideColors = ["light", "dark"];
            let slideColor = slideColors[Math.floor(Math.random() * slideColors.length)];
            let textLightIndex = Math.floor(Math.random() * this.textLight.length);
            let textDarkIndex = Math.floor(Math.random() * this.textDark.length);
            let bgDarkIndex = Math.floor(Math.random() * this.bgDarks.length);
            let bgLightIndex = Math.floor(Math.random() * this.bgLights.length);
            let allBg = this.bgDarks.concat(this.bgLights);
            let allText = this.textLight.concat(this.textDark);
            let randomBg = allBg[Math.floor(Math.random() * allBg.length)];
            let randomText = allText[Math.floor(Math.random() * allBg.length)];
            let darkSlide = {
                text: this.textLight[textLightIndex],
                bg: this.bgDarks[bgDarkIndex],
                image: randomBg,
            };
            let lightSlide = {
                text: this.textDark[textDarkIndex],
                bg: this.bgLights[bgLightIndex],
                image: randomBg,
            };
            //apply classes to refs
            if (slideColor === "dark") {
                if (this.$refs[`slide-${this.id}${this.index}`]) {
                    this.$refs[`slide-${this.id}${this.index}`].classList.add(darkSlide.bg);
                }
                if (this.$refs[`image-${this.id}${this.index}`]) {
                    // this.$refs[`image-${this.id}${this.index}`].classList.add(darkSlide.bg);
                }
                if (this.$refs[`text-${this.id}${this.index}`]) {
                    this.$refs[`text-${this.id}${this.index}`].classList.add(darkSlide.text);
                }
            } else if (slideColor === "light") {
                if (this.$refs[`slide-${this.id}${this.index}`]) {
                    this.$refs[`slide-${this.id}${this.index}`].classList.add(lightSlide.bg);
                }
                if (this.$refs[`image-${this.id}${this.index}`]) {
                    // this.$refs[`image-${this.id}${this.index}`].classList.add(lightSlide.bg);
                }
                if (this.$refs[`text-${this.id}${this.index}`]) {
                    this.$refs[`text-${this.id}${this.index}`].classList.add(lightSlide.text);
                }
            }
        },
        animatedList(i) {
            return `animation-delay:${100 + 50 * i}ms;transition-delay:${
                200 + 50 * i
            }ms;animation-duration:1000ms;transition-duration:1000ms;user-select:none!important;`;
        },
        colorValueHelper(color, value) {
            let variations = [];
            if (color.startsWith("#")) {
                color = this.hexToHSL(color);
            }
            let hsl = color.match(/\d+/g);
            let h = hsl[0],
                s = hsl[1];

            function easeInOutQuad(t) {
                return t < 1 ? 3 * t * t : -2 + (8 - 2 * t) * t;
            }

            if (value === "dark") {
                // Dark Colors
                for (let i = 0; i <= 100; i += 3) {
                    let l = easeInOutQuad(i / 100) * 100;
                    variations.push(`hsl(${h}, ${s * 2}%, ${l}%)`);
                }
                return variations.slice(4, 9);
            } else {
                // Light Colors
                for (let i = 0; i <= 100; i += 2) {
                    let l = easeInOutQuad(i / 100) * 80;
                    variations.push(`hsl(${h}, ${s}%, ${l}%)`);
                }
                return variations.slice(27, 32);
            }
        },
        setColors(colors) {
            const styleEl = document.getElementById("color-stylesheet");
            if (!styleEl) {
                let styleEl = document.createElement("style");
                styleEl.innerHTML = this.styleSheeter(colors);
                styleEl.setAttribute("type", "text/css");
                styleEl.setAttribute("id", "color-stylesheet");
                setTimeout(() => {
                    document.head.appendChild(styleEl);
                }, 10);
            }
        },
        hexToHSL(H) {
            // Convert hex to RGB first
            let r = 0,
                g = 0,
                b = 0;
            if (H.length == 4) {
                r = "0x" + H[1] + H[1];
                g = "0x" + H[2] + H[2];
                b = "0x" + H[3] + H[3];
            } else if (H.length == 7) {
                r = "0x" + H[1] + H[2];
                g = "0x" + H[3] + H[4];
                b = "0x" + H[5] + H[6];
            }
            // Then to HSL
            r /= 255;
            g /= 255;
            b /= 255;
            let cmin = Math.min(r, g, b),
                cmax = Math.max(r, g, b),
                delta = cmax - cmin,
                h = 0,
                s = 0,
                l = 0;

            if (delta == 0) h = 0;
            else if (cmax == r) h = ((g - b) / delta) % 6;
            else if (cmax == g) h = (b - r) / delta + 2;
            else h = (r - g) / delta + 4;

            h = Math.round(h * 60);

            if (h < 0) h += 360;

            l = (cmax + cmin) / 2;
            s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
            s = +(s * 100).toFixed(1);
            l = +(l * 100).toFixed(1);

            return "hsl(" + h + "," + s + "%," + l + "%)";
        },
        tetradicColor(color) {
            let colors = [];
            let [h, s, l] = color.match(/\d+/g).map(Number);
            for (let i = 0; i <= 3; i++) {
                h = (h + 90 * i) % 360;
                colors.push(`hsl(${newHue}, ${saturation}%, ${lightness}%)`);
            } // tetradic
        },
        triadicColor(color) {
            let colors = [];
            let [h, s, l] = color.match(/\d+/g).map(Number);
            for (let i = 0; i <= 2; i++) {
                h = (h + 120 * i) % 360;
                colors.push(`hsl(${newHue}, ${saturation}%, ${lightness}%)`);
            }
            return `hsl(${h}, ${s}%, ${l}%)`;
        },
        styleSheeter(colors) {
            let styles = "";
            Object.keys(colors).forEach((colorGroupName, index, key) => {
                if (colorGroupName !== "primary" && colorGroupName !== "secondary") {
                    colors[colorGroupName].forEach((color, index) => {
                        styles += `.bg-${colorGroupName}-${index} { background-color: ${color}; }\n`;
                        styles += `.text-${colorGroupName}-${index} { color: ${color}; }\n`;
                        styles += `.bdr-${colorGroupName}-${index} { border: 1px solid ${color}; }\n`;
                    });
                } else {
                    styles += `.bg--${colorGroupName} { background-color: ${key}; }\n`;
                    styles += `.bdr--${colorGroupName} { border: 1px solid ${key}; }\n`;
                    styles += `.text--${colorGroupName} { color: ${key}; }\n`;
                }
            });
            return styles;
        },
        complimentaryColor(color) {
            let fixedColor = color;
            if (color.startsWith("#")) {
                fixedColor = this.hexToHSL(color);
            }
            let [h, s, l] = fixedColor.match(/\d+/g).map(Number);
            h = (h + 180) % 360; // complimentary

            return `hsl(${h}, ${s}%, ${l}%)`;
        },
        splitComplementaryColors(color, angle = 30) {
            let [h, s, l] = color.match(/\d+/g).map(Number);
            let compHue = (h + 180) % 360;
            let colors = [`hsl(${compHue}, ${s}%, ${l}%)`];
            for (let i = -1; i <= 1; i += 2) {
                let newHue = (compHue + angle * i) % 360;
                colors.push(`hsl(${newHue}, ${s}%, ${l}%)`);
            }
            return colors;
        },
    },
};
