<template>
    <!--    <div class="x">-->
    <!--        <div class="scrollSection flex-grow flex fc align-content-stretch pt-4 z-0 px-3">-->
    <div
        v-if="!newDeckStarted"
        ref="toolbar"
        :key="$route.params.id"
        :style="`top:0px;transition:200ms background ease-in;background:${colorLighter}`"
        class="sticky-top f aic border-top border-bottom x jcb z-1 flex-xl-row navTop mt-0 bg-gray-50">
        <div class="px-3 py-2" @click.prevent="toggleDrawer()">
            <i class="fas fa-bars"></i>
        </div>
        <div class="x py-md-0 f aic jcs py-2">
            <!--                        <PresentationThemeInfo v-if="type === 'presentation'" :document="document"></PresentationThemeInfo>-->

            <div
                v-if="documentState"
                class="f-13 line-height-small px-md-3 x mw-200 flex-grow px-1"
                :class="{ 'text-base-100': document && document.theme && document.theme.colors }">
                <div class="flex-basis flex-1 flex-grow">
                    <template v-if="documentState && documentState.saving">Last saved: Saving ...</template>
                    <template v-else>
                        Saved {{ $filters.timeAgo(dateFix(documentState.lastSaved)) }}
                        <!--                        <span v-if="wordCount"> • Word count: {{ wordCount }}</span>-->
                    </template>
                </div>
                <!--                <div v-if="documentState && documentState.section">-->
                <!--                                        <template v-if="documentState && documentState.section">-->
                <!--                        Current section: {{ documentState.section + 1 }}-->
                <!--                    </template>-->
                <!--                </div>-->
            </div>
            <DocumentTonePicker
                v-if="type === 'textEditor' && !mobile"
                class="x"
                :document="document"></DocumentTonePicker>
        </div>

        <div class="f aic jce order-lg-2 order-1 ml-0">
            <PresentationToolbarActions
                v-if="type === 'presentation'"
                class="py-2"
                :document="document"
                @summarize="extractPresentationText"></PresentationToolbarActions>
            <DocumentToolbarActions v-else-if="type === 'textEditor'" :document="document"></DocumentToolbarActions>
        </div>
    </div>
    <!--    </div>-->
</template>
<script setup>
import { useRoute } from "vue-router";

const router = useRoute();
const id = router.params.id;
</script>
<script>
import { useCollection, useDocument } from "vuefire";
import { doc, getDoc, collection, Timestamp } from "firebase/firestore";
import { defineProps, reactive } from "vue";

import DocumentTonePicker from "@/components/documents/DocumentTonePicker.vue";
import DocumentToolbarActions from "@/components/documents/DocumentToolbarActions.vue";
import PresentationThemeInfo from "@/components/documents/PresentationThemeInfo.vue";
import PresentationToolbarActions from "@/components/documents/PresentationToolbarActions.vue";
import { db } from "@/firebase";
import documentManagementMixin from "@/mixins/documentManagementMixin";
import documentMixins from "@/mixins/documentMixins";
import presenationMixin from "@/mixins/presentationMixin";

const documents = collection(db, "documents");

export default {
    components: {
        DocumentToolbarActions,
        PresentationToolbarActions,
        PresentationThemeInfo,
        DocumentTonePicker,
    },
    mixins: [documentMixins, presenationMixin, documentManagementMixin],
    props: {
        newDeckStarted: {
            type: Boolean,
            default: false,
        },
        serp: {
            type: Object,
            default: () => {},
        },
        documentId: {
            type: String,
            default: "",
        },
        document: {
            type: Object,
            default: () => {},
        },
    },
    emits: ["generateImage", "deleteDoc", "enhancePresentation", "summarize", "enhanceColors"],
    data() {
        return {
            // document: {},
            documents: [],
        };
    },
    watch: {
        documentId: {
            // call it upon creation too
            immediate: true,
            handler(id) {
                if (this.$route.params && this.$route.params.id) {
                    // this.$firestoreBind('document', doc(documents, id))
                }
                // console.error(this.$firestoreBind);
            },
        },
    },
    mounted() {},
    // methods: {documents},
    methods: {
        toggleDrawer() {
            this.$store.commit("setShowDocumentDrawer", !this.showDocumentDrawer);
        },
    },
    firestore: {
        documents: collection(db, "documents"),
        // document:useDocument(doc(db, 'settings', this.documentId)),
    },
    computed: {
        showDocumentDrawer() {
            return this.$store.state.showDocumentDrawer;
        },
        type() {
            let r = this.$route.path;
            let d = this.document;
            if (r.includes("free") || r.includes("documents")) {
                return "textEditor";
            }
            if (r.includes("presentations") || (d && d.documentType === "presentation")) {
                return "presentation";
            }
        },
        wordCount() {
            // const words = this.extractText(this.document.sections);
            // // console.error(words);
            // if (words) {
            //     return words.split(' ').length;
            // } else {
            //
            // }
            if (this.document) {
                let words;
                if (this.document.documentType && this.document.documentType === "presentation") {
                    words = this.extractPresentationText;
                    // words = this.extractPresentationText(this.document.sections)
                } else {
                    words = this.extractText(this.document.sections);
                    // words = this.extractText(this.document.sections);
                }
                // console.error(words);
                if (words) {
                    return words.split(" ").length;
                } else {
                }
            }
        },
    },
};
</script>
