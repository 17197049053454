<template>
    <div
        :class="{ 'vh-50': mobile, 'br-10 shadow': inset }"
        :style="`transition:200ms background ease-in;background:${colorLighter}`"
        class="y x p-relative z-0 overflow-hidden">
        <!--        <div class=" y bg-base-900 x p-relative br-10 overflow-hidden" :style="`mix-blend-mode: ${blendMode};`">-->
        <div
            :style="`transition:200ms background ease-in;background:${colorLighter}`"
            class="y x p-relative f aic jcc overflow-hidden bg-base-100"
            :class="{ 'br-10': inset }">
            <div :class="{ 'br-10': inset }">
                <Transition appear enter-active-class="animated fadeIn ease-in-out duration-5">
                    <template v-if="section.imageURL">
                        <img
                            v-show="!imageLoading"
                            :class="{ 'o-5 pulse animation infinite': imageLoading, 'br-10': inset }"
                            :src="section.imageURL"
                            class="x y p-absolute p-fill o-cover"
                            @load="onImgLoad" />
                    </template>
                    <!--                    <img class="x y p-absolute p-fill o-cover" style="mix-blend-mode:luminosity" v-if="section.imageURL" :src="section.imageURL"/>-->
                    <div v-else-if="section.enhancedImage" class="white f-13 p-4">
                        <LoadingSpinner color="white" background="black" class="z-5 mb-3"></LoadingSpinner>
                        <span class="o-4">{{ section.enhancedImage }}</span>
                    </div>
                    <div v-else-if="section.image" class="f-20 line-height-small mw-400 o-4 p-3">
                        <LoadingSpinner color="white" class="z-5 mb-3"></LoadingSpinner>
                        <span class="o-4">{{ section.image }}</span>
                    </div>
                    <div v-else class="f-20 line-height-small mw-400 o-4 p-3">
                        <LoadingSpinner color="white" class="z-5 mb-3"></LoadingSpinner>
                    </div>
                </Transition>
                <!--                <div v-if="imageLoading" class="z-5 p-absolute p-fill f aic jcc">-->
                <!--                    <LoadingSpinner :color="white" background="black" class="mb-3"></LoadingSpinner>-->
                <!--                </div>-->
                <div
                    v-if="!imageLoading && !mobile"
                    class="p-absolute p-fill x y z-1 display:none hover:display:block translate-y-5 opacity-0 transition-opacity ease-out-expo hover:translate-y-0 hover:opacity-100">
                    <div
                        :style="`transition:200ms background ease-in;background:${colorDarker}`"
                        class="p-absolute p-fill o-9 x y bg-base-100"></div>
                    <div
                        v-if="section.enhancedImage"
                        class="p-absolute p-fill f aic jcc x y f-15 animated fadeInUpSmooth whitespace-pre-line p-5 text-base-50">
                        <!--                        {{ section.enhancedImage }}-->
                        <contenteditable
                            v-if="section.enhancedImage"
                            ref="contenteditable"
                            v-model="section.enhancedImage"
                            :contenteditable="true"
                            :no-html="false"
                            :no-nl="false"
                            class="o-5 stagger-2 x d-block whitespace-pre-line"
                            tag="p"
                            @blur="saveSection()"></contenteditable>
                        <div class="p-absolute bottom left f-11 o-2 fwb p-3" v-if="section.enhancedImage.length">
                            {{ section.enhancedImage.length }} Characters
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="section.imageURL && showRegularImage && largeImage"
            :style="`transition:200ms background ease-in;background:${colorDarker}`"
            class="f aic jcc p-absolute p-fill z-5 p-md-5 bg-blur p-3"
            style="background: rgba(0, 0, 0, 0.5)">
            <img
                v-show="section.imageURL && !imageLoading"
                :class="{ 'o-5 pulse animation infinite': imageLoading,'br-10':inset }"
                :src="section.imageURL"
                class="z-5 y x o-cover shadow"
                style="top: 20px; right: 20px; left: 20px; bottom: 20px"
                @load="onImgLoad" />
        </div>
      <div class="p-absolute p-fill f o-5 o-3 bg-base-900 z-4" v-if="section.style.includes('full')"></div>

      <div class="p-absolute bottom right z-5 cursor-pointer p-3">
            <div
                class="f aic jcc bg-blur border-light br-5 base-900 px-2 py-2 text-base-50 hover:bg-snow hover:text-base-900 hover:text-base-900"
                style="box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5), inset 0px 0px 3px rgba(255, 255, 255, 0.5)"
                @click.prevent="fetchingNewImage(), stabilityPresentationImage(index, section.enhancedImage)">
                <i class="fas fa-image f-20 text-shadow text-base-50 hover:text-base-900"></i>
            </div>
        </div>
        <!--        <div class="p-absolute p-fill f aic jcc z-5" v-if="imageLoading"><loading-spinner class="mb-3"></loading-spinner></div>-->
    </div>
</template>
<script>
import { doc, getDoc, collection, Timestamp } from "firebase/firestore";
import contenteditable from "vue-contenteditable";
import { useCollection, useDocument } from "vuefire";

import LoadingSpinner from "@/components/loaders/LoadingSpinner.vue";
import { db } from "@/firebase";
import documentManagementMixin from "@/mixins/documentManagementMixin";
import imageMixin from "@/mixins/imageMixin";
import presenationMixin from "@/mixins/presentationMixin";
import slideStyleMixin from "@/mixins/slideStyleMixin";

const documents = collection(db, "documents");
export default {
    name: "PresentationImageContainer",
    components: { LoadingSpinner, contenteditable },
    mixins: [imageMixin, slideStyleMixin, presenationMixin, documentManagementMixin],
    props: {
        section: {},
        documentId: {},
        fullBleed: {},
        randomImageStyle: {},
        index: {},
        imageDark: {},
        inset: {},
    },
    data() {
        return {
            largeImage: false,
            document: {},
            documents: [],
            imageLoading: true,
        };
    },
    computed: {
        showRegularImage() {
            return Math.random() < 0.5;
        },
        blendMode() {
            if (this.imageDark) {
                return "screen";
            } else {
                return "multiply";
            }
        },
    },
    watch: {
        documentId: {
            // call it upon creation too
            immediate: true,
            handler(id) {
                if (this.$route.params && this.$route.params.id) {
                    this.$firestoreBind("document", doc(documents, this.documentId));
                }
                // console.error(this.$firestoreBind);
            },
        },
        section: {
            handler: function (val, oldVal) {
                if (val.imageURL) {
                    this.imageLoading = false;
                }
            },
            deep: true,
        },
    },
    created() {
        this.imageLoading = true;
    },
    mounted() {
        this.imageTheme;
    },
    // firestore: {
    //     // documents: collection(db, 'documents'),
    //     // document:useDocument(doc(db, 'settings', this.documentId)),
    // },
    methods: {
        async saveSection() {
            const sectionText = this.section.enhancedImage;
            this.section.enhancedImage = sectionText;
            console.error(this.section.enhancedImage);
            let doc = JSON.parse(JSON.stringify(this.document));
            console.error(doc);
            let fixedOldSections = JSON.parse(JSON.stringify([...this.document.sections]));
            let stringNewText = JSON.stringify(this.section);
            const newSection = JSON.parse(stringNewText);
            fixedOldSections.splice(this.index, 1, newSection);
            console.error(fixedOldSections);
            doc.sections = fixedOldSections;
            await this.saveDoc(doc);
            // console.error("saveSection", doc);
            return;
        },
        async onImgLoad() {
            this.$nextTick(() => {
                this.imageLoading = false;
                // console.error("onImgLoad", this.imageLoading);
            });
        },
        fetchingNewImage() {
            this.section.imageURL = "";
            this.imageLoading = true;
        },
    },
};
</script>
