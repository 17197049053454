<script>

export default {
    created() {

    },
    props: {
        title: {
            // type: String,
            default: null,
        },
        subtitle: {
            type: String,
            default: null,
        },
        array: {
            type: Array,
            default: null,
        },
        body: {
            // type: String,
            default: null,
        },
        doubleRow: {
            type: Boolean,
            default: null,
        },
        table: {
            type: Boolean,
            default: null,
        },
        collapsedContent: {
            // type: Object,
            default: null,
        }
    },
    // props: {
    //     props: ['title', 'subtitle', 'array', 'body', 'doubleRow', 'table'],
    // },
    data() {
        return {
            closed: false,
        };
    },
    mounted() {
    },

    watch: {},
    methods: {},
    computed: {},
};
</script>
<template>
    <div class="p-relative section">
        <div v-if="doubleRow" class="hover:bg-base-700">
            <span v-if="title" class="fwb text-base-200 d-block">{{ title }}</span>
            <span v-if="body" class="d-block mb-3">{{ body }}</span>
            <div class="mb-3 border-right-0 border-left-0 py-3 text-base-200 hover:text-bubble-100" style="border:1px solid rgba(255,255,255,0.2);" @click.prevent="closed = !closed">
                <div class="f aic jcb x ">
                    <span v-if="subtitle" class="fwb  d-block">{{ subtitle }}</span>
                    <i aria-hidden="true" class="fa fa-angle-down"></i>
                </div>
                <div v-if="array && closed" class="py-3 text-base-200">
                    <div v-for="string in array" class="d-block">{{ string }}</div>
                </div>
            </div>
        </div>
        <div v-else-if="table" class="hover:bg-base-700 px-3 py-2 br-10">
            <div class="f aic jcb py-1">
                <span v-if="title" class="fwb text-base-200 d-block">{{ title }}</span>
                <span v-if="body" class="d-block">{{ body }}</span>
                <span v-if="subtitle" class="whitespace-nowrap fwb mr-3 d-block">{{ subtitle }}</span>
            </div>
        </div>
        <div v-else class="hover:bg-base-700 px-3 py-2 br-10">
            <span v-if="title" class="fwb text-base-200 d-block">{{ title }}</span>
            <span v-if="body" class="d-block">{{ body }}</span>

            <div v-if="array || collapsedContent" class="border-right-0 border-left-0 py-1 text-base-200 hover:text-bubble-100 cursor-pointer pr-2" @click.prevent="closed = !closed">
                <div class="f aic jcb x ">
                    <div class="f aic jcs x pr-2">
                        <span v-if="subtitle" class="whitespace-nowrap fwb mr-3 d-block">{{ subtitle }}</span>
                        <div v-if="collapsedContent" class="overflow-hidden">
                            <span class="o-3 d-block whitespace-nowrap">{{ collapsedContent }}</span>
                        </div>
                        <div v-else-if="array" class="overflow-hidden flex-shrink ">
                            <span class="o-3 d-block whitespace-nowrap">{{ stringArray(array) }}</span>
                        </div>
                    </div>
                    <div>
                        <i v-if="closed" aria-hidden="true" class="fa fa-angle-up f-20"></i>
                        <i v-else aria-hidden="true" class="fa fa-angle-down f-20"></i>
                    </div>
                </div>
                <div v-if="collapsedContent && closed" class="py-3 f-13 text-base-200">
                    <div class="d-block">{{ collapsedContent }}</div>
                </div>

                <div v-if="array && closed" class="py-3 text-base-200">
                    <div v-for="string in array" class="d-block">{{ string }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
//.item{
//    -ms-user-select: none; /* IE 10 and IE 11 */
//    -webkit-user-select: none; /* Safari */
//    user-select: none; /* Standard syntax */
//}
</style>
