<template>
    <div>
        <fieldset class="create-fieldset">
            <label>Slide count</label>
            <p>Higher values will make your image closer to your prompt.</p>
            <vue-slider v-model="value" :min="0" :max="30"></vue-slider>
        </fieldset>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component'

export default {
    components: {
        VueSlider
    },
    data() {
        return {
            value: 7
        }
    }
}
</script>

<style>
.vue-slider {
    width: 100%;
}
</style>
