<script>
export default {
    data: () => ({}),
    props: ['icon'],
    mounted() {},
    computed: {},
};
</script>
<template>
    <button class=" p-2 bg-bubble-50 text-base-600 hover:text-bubble hover:bg-white width-30 height-30 f aic jcc br-5 " href="#">
        <i aria-hidden="true" :class="`fa fa-${icon}`"></i></button>
</template>
<style lang="scss">
</style>
