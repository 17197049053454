<script>
var controller = new ScrollMagic.Controller();
export default {
    data: () => ({
        zoomScene: null,
        animationStart: {},
        animationEnd: {},
        enterScene: null,
        pinScene: null,
        leaveScene: null,
        iterationCount:0,
    }),
    props: ['offset','scale','img','z','sceneLength'],
    mounted() {
        this.initScroll();
    },
    computed:{
      imageURL(){
          if(this.img){
              return this.img
          } else {
              return '';
          }
      }
    },
    methods: {
        scaler(magnitude){
            let scale;
            switch (magnitude) {
                case '0':
                    scale = 1
                    break
                case '1':
                    scale = 1
                    break
                case '2':
                    scale = 1.25
                    break
                case '3':
                    scale = 1.5;
                    break
                case '4':
                    scale = 1.75;
                    break
            }
            if(scale) {
                return scale;
            } else {
                return 1
            }
        },
        initScroll1(){
            var self = this;

            const startAni = this.animationStart;
            const endAni = this.animationEnd;
            var zoomEnter = new TimelineMax().add([
                TweenMax.fromTo($(self.$refs.element), 1, startAni, endAni, 1),
                TweenMax.fromTo($(self.$refs.element), 1,
                    {
                        opacity: 0,
                    }, {
                        opacity: 1,
                        ease: Linear.None
                    }, 1),
                // TweenMax.fromTo($(self.$refs.element), .5, {scale: 0.01,}, {scale: 1, ease: Quad.easeInOut}, 1),
                // TweenMax.fromTo($(self.$refs.element), 1, {scale: 1,}, {scale: 0, ease: Linear.None}, "+=0.5")
            ]);

            var zoomExit = new TimelineMax().add([
                TweenMax.to($(self.$refs.element), 1, startAni),
                // TweenMax.to($(self.$refs.element), 1, {
                //     opacity: 0,
                //     ease: Linear.easeOut
                // }),
            ]);

            // const sectionHeight = ($(window).height()/4);
            // const sectionLength = $(window).height();
            let sectionLength = $(window).height();
            if (this.sceneLength) {
                sectionLength = this.sceneLength;
            }
            const sectionHeight = (sectionLength / 4);
            const animationDuration = (sectionLength / 4);
            const trigger = self.$refs.trigger;
            this.enterScene = new ScrollMagic.Scene({
                triggerElement: trigger,
                triggerHook: 'onCenter',
                // offset:-sectionHeight/2,
                duration: animationDuration,
                pushFollowers: true,
            }).setTween(zoomEnter).addTo(controller);
            // .addIndicators({parent:"animation1",name:'fadeIn',indent:"100px"});
            const innerHeight = this.$refs.trigger.clientHeight;


            // var pinScene = new TimelineMax().add([
            //     TweenMax.fromTo(self, 1, {
            //         iterationCount: 0,
            //     }, {
            //         iterationCount: 20,
            //     }),
            // ]);

            this.pinScene = new ScrollMagic.Scene({
                triggerElement: trigger,
                triggerHook: 'onCenter',
                offset: (innerHeight / 2),
                pushFollowers: false,
                duration: sectionHeight * 4,
            }).setPin(trigger).addTo(controller);
            // }).setPin(trigger).setTween(pinScene).addTo(controller);
            // .addIndicators({parent:"animation1",name:'pin',indent:"200px"});


            this.leaveScene = new ScrollMagic.Scene({
                triggerElement: trigger,
                triggerHook: 'onLeave',
                offset: animationDuration,
                duration: animationDuration,
                pushFollowers: false,
            }).setTween(zoomExit).addTo(controller);
            // .addIndicators({parent:"animation1",name:'fadeOut',indent:"300px"});

        },
        initScroll() {
            var self = this;
            var heightOffset = $(window).height()/4;
            var zoomEnter = new TimelineMax().add([
                TweenMax.fromTo($(self.$refs.element), 1, {
                    y: heightOffset*self.offset/2,
                    scale:.98,
                }, {
                    y: -(heightOffset*self.offset)/2,
                    scale:1,
                    ease: Power2.easeOut
                }, 1),
                TweenMax.fromTo($(self.$refs.element), 1,
                    {
                        opacity: 1,
                    }, {
                        opacity: 1,
                        ease: Linear.easeOut
                    }, 1),
            ]);
            // var centerTween = new TimelineMax().add([
            //     TweenMax.to($(self.$refs.image), 1, {
            //         y: (self.offset*100),
            //         ease: Linear.easeOut
            //     }, 1),
            // ]);

            let sectionLength = $(window).height()*2;
            if (this.sceneLength) {
                sectionLength = this.sceneLength;
            }
            const animationDuration = (sectionLength);
            const trigger = self.$refs.trigger;
            let offset = 0;
            if(this.offset){
                offset = this.offset;
            }
            this.enterScene = new ScrollMagic.Scene({
                triggerElement: trigger,
                triggerHook: 'onEnter',
                // offset:-offset * 100,
                offset:0,
                // duration: $(window).height()*(1+(1/(offset/2))),
                duration: $(window).height()*2,
                pushFollowers: true,
            }).setTween(zoomEnter).addTo(controller);

            // this.centerScene = new ScrollMagic.Scene({
            //     triggerElement: trigger,
            //     triggerHook: 'onLeave',
            //     // offset:-offset * 100,
            //     offset:-(200*offset),
            //     // duration: $(window).height()*(1+(1/(offset/2))),
            //     duration: 200,
            //     pushFollowers: true,
            // }).setTween(centerTween).addTo(controller);

        },
        beforeDestroy() {
            this.enterScene.destroy(true);
            this.leaveScene.destroy(true);
            this.pinScene.destroy(true);
        },
    },
};
</script>
<template>
    <div ref="trigger" class="f aic jcc x" :style="`z-index:${z}`">
        <div>
            <div ref="element" class=" text-center">
                <img ref="image" :style="`transform:scale(${scaler(scale)});`"  :src="imageURL">
            </div>
        </div>
    </div>
</template>

