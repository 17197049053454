<template>
    <div class="my-4 bg-snow">
        <template v-if="isImage && !customPrompt">
            <div class="br-10 border-light bg-gray-50 p-2 shadow-md"
                @mouseover="hovered = true"
                @mouseleave="hovered = false">
                <div v-if="isImage" class="p-relative image-">
                    <img :src="section.content" class="x y br-10 border-light d-block o-cover shadow-lg" />
                    <Transition enter-active-class="animated fadeIn " leave-active-class="animated fadeOut">
                        <div v-if="hovered" class="p-absolute p-fill">
                            <div class="p-absolute top right p-3" @click.prevent="removeSection()">
                                <div class="bg-white br-10 border-light p-1 px-2 shadow-md">
                                    <i class="far fa-trash"></i>
                                </div>
                            </div>
                            <div class="p-absolute bottom left p-3">
                                <a
                                    :href="section.content"
                                    class="f-11 br-5 border-light d-inline rounded-full bg-snow p-2 px-3 text-gray-800"
                                    target="_blank">
                                    <i class="fas fa-link"></i> Image url
                                </a>
                            </div>
                            <div class="p-absolute bottom right f p-3">
                                <div
                                    class="bg-white br-10 border-light p-1 px-2 shadow-md"
                                    @click.prevent="regenerateImage(index, section.prompt.prompt)">
                                    <i class="far fa-sync"></i>
                                </div>
                                <div
                                    class="bg-white br-10 border-light ml-3 p-1 px-2 shadow-md"
                                    @click.prevent="(customPrompt = true), (step = 3)">
                                    <i class="far fa-search"></i>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
            <div
                v-if="section.prompt && section.prompt.prompt && section.prompt.prompt[0]"
                class="top left z-5 f-11 x p-3 text-center">
                {{ section.prompt.prompt }}
            </div>
        </template>
        <div v-else class="br-10 my-3 bg-gray-50/50 p-3 shadow">
            <div class="fwsb br-10 f-20 f fc aic jcc border-4 border-dashed border-gray-200 p-3 text-gray-300">
                <!--                <div class="fa-1x">-->
                <!--                    <i class="fa-duotone fa-loader fa-pulse"></i>-->
                <!--                </div>-->
                <span class="f-20 text-center"> Add an image</span>
                <span v-if="step === 1" class="f-15 text-center">
                    <a class="fwsb text-base-400" href="#" @click.prevent="step = 2">Paste a url</a>
                    or
                    <a class="fwsb text-base-400" href="#" @click.prevent="step = 3">Generate image</a>
                </span>
                <div v-if="step === 2" class="text-center">
                    <input
                        v-model="url"
                        class="chatInput border-light mw-400 x p-1 shadow-md"
                        placeholder="Paste image url here"
                        type="text" />
                    <div class="mt-2">
                        <button
                            class="btn btn-outline-primary f-15 mx-auto rounded-full border-gray-500/20 py-1 px-3 text-gray-400 hover:bg-snow hover:text-gray-800 hover:shadow-md"
                            @click.prevent="saveImage()"
                            >Add image</button
                        >
                    </div>
                </div>
                <div v-if="step === 3" class="text-center">
                    <span class="f-15 text-center">Generate an image</span>
                    <input
                        v-model="imgPrompt"
                        class="chatInput border-light mw-400 x p-1 shadow-md"
                        placeholder="What do you want your image to be of?"
                        type="text" />
                    <div class="mt-2">
                        <button
                            class="btn btn-outline-primary f-15 mx-auto rounded-full border-gray-500/20 py-1 px-3 text-gray-400 hover:bg-snow hover:text-gray-800 hover:shadow-md"
                            @click.prevent="regenerateImage(index, imgPrompt)"
                            >Add image</button
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import imageMixin from '@/mixins/imageMixin';

export default {
    name: 'ImageSection',
    mixins: [imageMixin],
    props: {
        index: {},
        section: {},
    },
    data: () => ({
        step: 1,
        url: '',
        hovered: false,
        customPrompt: false,
        imgPrompt: '',
    }),
    computed: {
        isImage() {
            if (this.section.content.includes('.png') || this.section.content.includes('.jpg')) {
                return this.section.content;
            } else {
                return false;
            }
            // retur
        },
    },

    methods: {
        saveImage() {
            this.section.content = this.url;
        },
        removeSection() {
            this.section.content = '';
            this.$emit('removeSection', this.index);
        },
    },
};
</script>
<style lang="scss" scoped></style>
