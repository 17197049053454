<script>
var controller = new ScrollMagic.Controller();
export default {
    data: () => ({
        scene: null,
        pinScene: null,
    }),
    mounted() {
        this.initScroll();
    },
    // beforeDestroy() {
    //     this.scene.destroy()
    // },
    methods: {
        initScroll() {
            var self = this;
            const clientHeight = this.$refs.imageContainer1.clientHeight;
            const clientWidth = this.$refs.imageContainer1.clientWidth;
            const frameCount = 118;
            let imgUrl;

            imgUrl = `https://res.cloudinary.com/bitess/image/upload/q_50,c_crop,e_sharpen:100,g_center,h_683,x_0/v1649133218/samples/ACCESS_PASS_v2.0.4_CLEAN_renderCam_`;
            // imgUrl = `https://res.cloudinary.com/bitess/image/upload/b_rgb:000000,c_crop,e_sharpen:100,g_center,h_683,x_0/v1649133218/samples/ACCESS_PASS_v2.0.4_CLEAN_renderCam_`;

            const currentFrame = index => (
                // `${imgUrl}${index.toString().padStart(5, '0')}.jpg`
                `${imgUrl}${index.toString().padStart(5, '0')}.png`
            );
            const images = [];
            for (let i = 1; i < frameCount; i++) {
                const src = currentFrame(i);
                images.push(src);
            }
            const {canvas2} = this.$refs;
            canvas2.width = clientWidth;
            canvas2.height = clientHeight;

            const ctx = canvas2.getContext('2d');
            const img = new Image();
            img.src = images[0];
            img.onload = function () {
                coverImage(img);
            };
            const win = {
                w: clientWidth,
                h: clientHeight,
            };
            const coverImage = (img) => {
                // window.height()
                const imageWidth = 700;
                // const imageWidth = $(window).width();
                const imageHeight = 700;
                // const imageHeight = $(window).height();
                const imgRatio = imageWidth / imageHeight;

                const winRatio = clientHeight / clientWidth;
                const h = clientWidth * imgRatio;
                const w = clientWidth * winRatio / imgRatio;
                const destinationX = 0;
                const destinationY = 0;
                ctx.clearRect(0, 0, canvas2.width, canvas2.height);
                ctx.drawImage(img, destinationX, destinationY, clientWidth, clientHeight);

            };

            const current = {img: 0};

            // create tween
            const tween = TweenMax.to(current, 1,
                {
                    img: images.length-1,	    // animate propery img to number of images
                    roundProps: 'img',				  // only integers so it can be used as an array index
                    repeat: 2,									// repeat 3 times
                    ease: Linear.easeNone,			// show every image the same ammount of time
                    onUpdate() {
                        img.src = images[current.img];

                        coverImage(img);
                    }
                }
            );

            // build scene
            this.scene = new ScrollMagic.Scene({
                triggerElement: canvas2,
                triggerHook: 0,
                offset: 0,
                duration: $(window).height()
            }).setTween(tween).addTo(controller);

            this.pinScene = new ScrollMagic.Scene(
                {
                    triggerElement: self.$refs.pin2,
                    duration: $(window).height(),
                    triggerHook: 0.5,
                })
                .setPin(canvas2)
                .addTo(controller);


            const resize = () => {
                win.w = clientWidth;
                win.h = clientHeight;
                canvas2.width = win.w;
                canvas2.height = win.h;
                img.src = images[current.img];
                coverImage(img);
            };
            window.addEventListener('resize', resize);
        },
    },
};
</script>
<template>
    <div class="p-relative x z-3" style="height:50vh;">
        <div ref="imageContainer1" class="p-relative" style="padding:100%;height:0px;width:100%;">
            <div class="x p-absolute p-fill o-cover">
                <canvas ref="canvas2" class="p-absolute p-fill" style="object-fit:cover;object-position: top;"></canvas>
            </div>
        </div>
    </div>

</template>
