<script setup>
import Roadmap from '@/components/marketing/Roadmap.vue';
import ImageParallax from '@/components/marketing/scrollSections/ImageParallax.vue';
import Team from '@/components/marketing/Team.vue';
import TextBlock from '@/components/marketing/TextBlock.vue';
import TextBlockZoom from '@/components/marketing/TextBlockZoom.vue';
</script>
<script>
var controller = new ScrollMagic.Controller();
export default {
    data: () => ({
        scene: null,
        pinScene: null,
        currentFrm: 0,
    }),
    mounted() {},
    beforeUnmount() {},
};
</script>
<template>
    <div>
        <div class="vh-100"></div>
        <div class="vh-100 z-3 mw-1800 container">
            <div class="row">
                <div class="col-12 col-md-6">
                    <ImageParallax key="1" :offset="1"></ImageParallax
                ></div>
                <div class="col-12 col-md-6"
                    ><TextBlock
                        title="Meet the future of her"
                        :small-text="true"
                        :animated-text="true"
                        paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."></TextBlock
                ></div>
            </div>
        </div>
        <div class="vh-100 p-relative x z-3">
            <div class="f aic jcc vh-100 z-1">
                <ImageParallax v-if="!mobile" key="1" :offset="3"></ImageParallax>
                <ImageParallax v-if="!mobile" key="4" :offset="2"></ImageParallax>
                <ImageParallax key="4" :offset="1"></ImageParallax>
                <ImageParallax key="4" :offset="0"></ImageParallax>
                <ImageParallax key="4" :offset="1"></ImageParallax>
                <ImageParallax v-if="!mobile" key="4" :offset="2"></ImageParallax>
                <ImageParallax v-if="!mobile" key="5" :offset="3"></ImageParallax>
            </div>
            <div class="p-absolute p-fill f aic jcc z-3">
                <h1>Hello</h1>
            </div>
        </div>
    </div>
</template>
