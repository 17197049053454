import About from './views/About.vue';
import Artists from './views/Artists.vue';
import Home from './views/Home.vue';
import Mission from './views/Mission.vue';
import MySongs from './views/MySongs.vue';
import NotFound from './views/NotFound.vue';

import MusicGeneratorBasic from '@/components/music/musicGeneratorBasic.vue';
import Authentication from '@/views/Authentication.vue';
import FreeForm from '@/views/DocumentHome.vue';
import Documents from '@/views/DocumentHome.vue';
import Feed from '@/views/Feed.vue';
import FreeCanvas from '@/views/FreeCanvas.vue';
import Genres from '@/views/Genres.vue';
import imagePlayground from '@/views/imagePlayground.vue';
import Lyrics from '@/views/Lyrics.vue';
import MusicGenerator from '@/views/MusicGenerator.vue';
import Presentations from '@/views/Presentations.vue';
import PressRelease from '@/views/PressRelease.vue';
import Register from '@/views/Register.vue';
import SignIn from '@/views/SignIn.vue';
import Timeline from '@/views/Timeline.vue';
import Stability from '@/views/Stability.vue';

/** @type {import('vue-router').RouterOptions['routes']} */
export const routes = [
    { path: '/', component: Home, meta: { title: 'Home' } },
    { path: '/profile', meta: { title: 'About' }, component: MySongs },
    { path: '/imagegen', meta: { title: 'Images' }, component: Stability },
    { path: '/profile/:id', meta: { title: 'About' }, component: MySongs },
    { path: '/artists' + '', meta: { title: 'Artists' }, component: Artists },
    { path: '/canvas' + '', meta: { title: 'Canvas' }, component: FreeCanvas },
    { path: '/documents/:id', meta: { title: 'Documents' }, component: Documents },
    { path: '/documents' + '', meta: { title: 'Documents' }, component: Documents },
    { path: '/presentations/:id', meta: { title: 'Documents' }, component: Presentations },
    { path: '/presentations' + '', meta: { title: 'Presentations' }, component: Presentations },
    { path: '/music' + '', meta: { title: 'Music Gen' }, component: MusicGeneratorBasic },
    { path: '/free' + '', meta: { title: 'Writing' }, component: FreeForm },
    { path: '/imgplay' + '', meta: { title: 'Image Playground' }, component: imagePlayground },
    { path: '/free/:id' + '', meta: { title: 'Writing' }, component: FreeForm },
    { path: '/timeline' + '', meta: { title: 'Timeline' }, component: Timeline },
    { path: '/genres' + '', meta: { title: 'Genres' }, component: Genres },
    { path: '/register' + '', meta: { title: 'Register' }, component: Register },
    { path: '/authentication' + '', meta: { title: 'Register' }, component: Authentication },
    { path: '/sign-in' + '', meta: { title: 'Sign in' }, component: SignIn },
    { path: '/feed' + '', meta: { title: 'Feed' }, component: Feed },
    { path: '/lyrics' + '', meta: { title: 'Lyrics' }, component: Lyrics },
    { path: '/press' + '', meta: { title: 'Press Release' }, component: PressRelease },
    { path: '/:path(.*)', component: NotFound },
];
