<script setup>
import { useRoute } from "vue-router";
import { collection, doc } from "firebase/firestore";
import { useCollection, useDocument } from "vuefire";
import { ref, onMounted, reactive } from "vue";
import { db } from "@/firebase";
const router = useRoute();
const id = router.params.id;
const currentDoc = useDocument(doc(collection(db, "documents"), id), data);
console.error(currentDoc);
</script>
<script>
import DocumentsToolbar from "@/components/documents/DocumentsToolbar.vue";
import DocumentSummary from "@/components/Presentations/slides/DocumentSummary.vue";
import SlideTemplate from "@/components/Presentations/slides/SlideContainer.vue";
import documentManagementMixin from "@/mixins/documentManagementMixin";
import presentationMixin from "@/mixins/presentationMixin";
import FloatingMenu from "@/components/Presentations/slides/FloatingMenu.vue";
import { collection, doc } from "firebase/firestore";
import { getDoc, Timestamp } from "firebase/firestore";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { toRaw } from "vue";
import { useCollection, useDocument } from "vuefire";
import { db } from "@/firebase";

const documents = collection(db, "documents");
export default {
    name: "PresentationSlides",
    components: { DocumentSummary, DocumentsToolbar, SlideTemplate, FloatingMenu },
    mixins: [presentationMixin, documentManagementMixin],
    data() {
        return {
            document: {},
            documents: [],
            currentSection: 1,
            newDeck: false,
        };
    },
    computed: {
        showSlide() {
            // return true;
            return (
                this.document && this.document.theme && this.document.theme.colors && this.document.sections[0].title
            );
        },
        showContainer() {
            // return true;
            return this.document && this.document.theme && this.document.theme.colors;
        },
        showSummary() {
            if (this.document && this.document.summary) {
                return true;
                // document && document.summary && document.theme && document.theme.colors
                return this.document && this.document.summary && this.document.theme && this.document.theme.colors;
            }
        },
        showSlides() {
            // return true;
            return (
                this.document &&
                this.document.sections &&
                this.document.theme &&
                this.document.theme.colors &&
                this.document.sections[0].title
            );
        },
    },
    mounted() {
        // window.addEventListener("scroll", this.handleScroll);
        // this.handleScroll();
        setTimeout(() => {
            this.initScrollTrigger();
            // this.initIntersectionObserver();
        }, 2000);
    },
    beforeUnmount() {
        window.removeEventListener("scroll", this.handleScroll());
    },
    created() {},
    methods: {
        newDeckModal(payload) {
            this.newDeck = true;
            this.$emit("newDeckModal", payload);
        },
        applyColors() {
            setTimeout(() => {
                const document = toRaw(this.document);
                console.error(document);
                this.document.sections.forEach((section, index) => {
                    console.error("applyingColors");
                    //get elementbyId and set background-color

                    this.$refs["slide-" + index].style.backgroundColor = "red";
                });
            }, 1000);
        },
        initScrollTrigger() {
            // const sections = this.$refs;
            // const ref = `slide-${this.index}`;
            // console.error(sections);
            // console.error(this.$refs[ref]);
            // console.error(this.$refs[ref].getBoundingClientRect().top);
            gsap.registerPlugin(ScrollTrigger);
            const box = document.querySelectorAll(`.slide`);
            const boxes = gsap.utils.toArray(`.slide`);

            // tweens applied for each box that triggers when they enter the viewport
            boxes.forEach((el, i) => {
                let tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: el,
                        start: "top bottom", // top of container hits bottom of viewport
                        end: "top center", // bottom of container hits center of viewport
                        markers: false,
                        scrub: 0,
                    },
                });
                let st = ScrollTrigger.create({
                    trigger: el,
                    start: "top center", // top of container hits bottom of viewport
                    end: "bottom center", // bottom of container hits center of viewport
                    onEnter: ({ progress, direction, isActive }) => {
                        console.log(progress, direction, isActive, i);
                        this.setSectionIndex(i);
                        this.savingStateInit();
                        // console.error(this.$refs[`slide-${i}`][0].$refs.slide[0][0].$refs);
                    },
                    onEnterBack: ({ progress, direction, isActive }) => {
                        console.log(progress, direction, isActive, i);
                        this.setSectionIndex(i);
                        this.savingStateInit();
                        // console.error(this.$refs[`slide-${i}`][0][`image-${sections[i].id}`]);
                    },
                });
                tl.fromTo(
                    el,
                    {
                        scale: 0.8,
                        opacity: 0,
                    },
                    {
                        opacity: 1,
                        scale: 1,
                        //ease back out
                        ease: "back.out",
                    },
                );
            });
        },
    },
    firestore: {
        documents: collection(db, "documents"),
    },
    watch: {
        $route: {
            immediate: true,
            deep: true,
            async handler(oldValue, newValue) {
                this.newDeck = true;
                if (this.$route && this.$route.params && this.$route.params.id) {
                    let docId = this.$route.params.id;

                    let d = useDocument(doc(collection(db, "documents"), docId));

                    // this.initScrollTrigger();
                    // this.initIntersectionObserver();
                    console.error("loaded gsap");
                    // this.initScrollTrigger();
                    // this.initIntersectionObserver();
                    // console.error("loaded gsap");
                    setTimeout(() => {
                        console.error(d._rawValue);

                        // this.setColors(this.document.theme.colors);
                        // window.addEventListener("scroll", this.handleScroll(d));
                    }, 500);
                }
            },
        },
        document: {
            immediate: true,
            deep: true,
            async handler(newValue, oldValue) {
                // if()
              if (newValue && newValue.theme && newValue.theme.colors) {
                this.setColors(newValue.theme.colors);
              }
              if (newValue && newValue.theme && newValue.theme && (newValue.theme !== oldValue.theme)) {
                    this.setColors(newValue.theme.colors);
                }
                if (newValue && newValue.sections && newValue.sections.length) {
                    // this.currentSection = 1;
                    if (
                        newValue.sections &&
                        newValue.sections.length > 1 &&
                        newValue.sections[0].title &&
                        newValue.theme &&
                        newValue.theme.colors
                    ) {
                        this.newDeck = false;
                    }
                }
            },
        },
        documentId: {
            // call it upon creation too
            immediate: true,
            async handler(id) {
                if (this.$route.params && this.$route.params.id) {
                    if (documents) {
                        await this.$firestoreBind("document", doc(documents, id));
                        this.savingStateInit(this.document);
                    }
                }
                // console.error(this.$firestoreBind);
            },
        },
    },
    props: {
        documentId: {},
    },
};
</script>
<template>
    <div>
        <div
            v-if="showContainer"
            :style="`transition:200ms background ease-in;background:${backgroundColor}`"
            class="pt-nav mainScroll pb-28">
            <DocumentsToolbar
                :document-id="documentId"
                class="x"
                :document="document"
                @summarize="summarize"
                @enhance-presentation="enhancePresentation"
                @enhance-colors="enhanceColors"></DocumentsToolbar>
            <div class="px-2">
                <Transition appear enter-active-class="swingInUp animated stagger-1">
                    <DocumentSummary v-if="showSummary" class="x mw-2100 mx-auto" :document="document" />
                </Transition>
                <div v-if="showSlides" class="mw-2200 mx-auto">
                    <div
                        v-for="(section, index) in document.sections"
                        :id="`section` + index"
                        :key="`slide-${document.id}-${index}`"
                        :ref="`section` + index">
                        <div v-if="showSlides" ref="slidesContainer" class="p-relative d-block z-0">
                            <Transition
                                :enter-active-class="`swingInUp animated stagger-${index + 2}`"
                                appear
                                leave-active-class="fadeOut animated">
                                <SlideTemplate
                                    v-if="showSlide"
                                    :id="document.id + 'slide' + index"
                                    :ref="`slide-${index}`"
                                    :key="document.id + index + 'slide'"
                                    :document="document"
                                    :document-id="documentId"
                                    :index="index"
                                    :section="section"
                                    :slide="section"
                                    @click="updateSection(index)"></SlideTemplate>
                            </Transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <FloatingMenu
                v-if="!newDeck"
                @start-new-deck="$emit('startNewDeck')"
                @new-deck="$emit('newDeck')"
                @new-deck-modal="newDeckModal"
                :document="document"></FloatingMenu>
        <!--        <streaming-text v-else-if="document" class="pt-8"></streaming-text>-->
    </div>
</template>
