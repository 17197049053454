import moment from 'moment';

import { db } from '@/firebase';
// import json from "../../public/assets/topicData.json";

// const {topics} = json;
// const {styles} = json;
// const {artists} = json;
// const {genres} = json;
// const {songTopics} = json;
// export moment;

export default {
    created: function () {},
    mounted() {},
    data: () => ({
        edit: false,
        loading: false,
        image: null,
        debug: false,
    }),

    computed: {
        darkMode() {
            return this.$store.state.darkMode;
        },
        chatLoading() {
            return this.$store.state.chatLoading;
        },
        fetching() {
            return this.$store.state.fetching;
        },
        hasChats() {
            return Array.isArray(this.chatItems) && this.chatItems.length > 0;
        },
        toggleView() {
            return this.$store.state.toggleView;
        },
        viewportSize() {
            return window.innerWidth;
        },
        mobile() {
            return this.$store.state.mobile;
        },
        textResponse() {
            return this.$store.state.textResponse;
        },
        genreArtist() {
            return this.$store.state.genreArtist;
        },
        step() {
            return this.$store.state.step;
        },
        chatItems() {
            return this.$store.state.chatItems;
        },
        imagePromot() {
            return `An image inspired by these lyrics: `;
        },
        promptData() {
            return this.$store.state.promptData;
        },
        user() {
            return this.$store.state.user;
        },
        userFeed() {
            return this.$store.state.user.feed;
        },
    },
    methods: {
        isObject(value) {
            return value.constructor === Object;
        },
        isArray(value) {
            return value.constructor === Array;
        },
        hasChildren(node) {
            if (node.constructor === Object) {
                return Object.keys(node).length > 0;
            } else if (node.constructor === Array) {
                return node.length > 0;
            } else {
                return false;
            }
        },
        kebabString(string) {
            string = string.replace(/[^\w\s]/gi, ''); // remove special characters
            return string
                .replace(/([a-z])([A-Z])/g, '$1-$2')
                .replace(/\s+/g, '-')
                .toLowerCase();
        },
        uriString(string) {
            return encodeURIComponent(string);
        },
        setShowInput(payload) {
            this.$store.commit('setShowInput', payload);
        },
        setShowButtons(payload) {
            this.$store.commit('setShowButtons', payload);
        },
        setLoadChat(payload) {
            this.scrollChatItem();
            this.$store.commit('setChatLoadingState', payload);
        },
        refreshTopic() {
            const array = this.topics;
            const length = this.arrayLength(array);
            const index = this.pickRandom(length);
            const randomItem = array[index];
            console.log(randomItem);
            console.log(randomItem.nameIdeas);
            this.promptData.topic = randomItem.topic;
        },
        timeAgo(date) {
            let relDate = moment(date, 'YYYYMMDD').fromNow();
            return relDate;
        },
        date(date) {
            return date.toDate();
        },
        toDate(i) {
            if (i && i.created) {
                const obj = i.created;
                const seconds = obj['seconds'];
                const nanoseconds = obj['nanoseconds'];
                const dateRaw = new Date(seconds * 1000 + nanoseconds / 1000000);
                return dateRaw;
            }
            return false;
        },
        dateFix(i) {
            if (i) {
                const obj = i;
                const seconds = obj['seconds'];
                const nanoseconds = obj['nanoseconds'];
                const dateRaw = new Date(seconds * 1000 + nanoseconds / 1000000);
                return dateRaw;
            }
            return false;
        },
        timestamp(item) {
            var timestamp = item.metadata.updateTime;
            return timestamp;
        },
        setArtist(payload) {
            this.$store.commit('setArtist', payload);
            // this.getArtistKeywords(payload);
        },
        setArtistCustom(payload) {
            let artist = {
                name: payload,
                keywords: [],
            };
            this.$store.commit('setArtist', payload);
            this.getArtistKeywords(payload);
        },
        setResponse(payload) {
            this.$store.commit('setTextResponse', payload);
        },
        setGenre(payload) {
            this.$store.commit('setGenre', payload);
        },
        setTopic(payload) {
            this.$store.commit('setTopic', payload);
        },
        setName(payload) {
            this.$store.commit('setName', payload);
        },
        setLocation(payload) {
            this.$store.commit('setLocation', payload);
        },
        nextStep() {
            const step = this.step;
            let newStep = step + 1;
            this.setStep(newStep);
        },
        prevStep() {
            const step = this.step;
            let newStep = step - 1;
            this.setStep(newStep);
        },
        setStep(step) {
            this.$store.commit('setStep', step);
        },
        scrollFeed() {
            const feed = this.$refs.feed;
            this.$nextTick(() => {
                feed.scrollTop = feed.offsetHeight;
            });
        },
        stringArray(array) {
            return array.join(', ');
        },
        parseList(string) {
            try {
                // let array = string.split('\n').map(item => item.replace(/^\d+\.\s/, ""));
                let array = string.split('\n').map(item => item.replace(/^\d+\.\s/, ''));
                this.consoleObject('Trying list instead', { string, array });
                return array;
            } catch (e) {
                return false;
            }
        },
        fixArray(arrayString) {
            if (!arrayString.endsWith(']')) {
                if (!arrayString.endsWith('"')) {
                    arrayString += '"';
                }
                arrayString += ']';
            }
            console.log(arrayString);
            let array = JSON.parse(arrayString);
            console.log(array);
            return arrayString;
        },
        fixArray2(arrayString) {
            // Ensure there are closing square brackets on the array
            if (!arrayString.endsWith(']')) {
                arrayString += ']';
            }

            // Ensure there are matching quotes on each item
            arrayString = arrayString.replace(/"([^"]*)"/g, (match, p1) => {
                if (p1.includes("'")) {
                    // If the item contains single quotes, wrap it in backticks
                    return '`' + match.slice(1, -1) + '`';
                } else {
                    // Otherwise, escape any double quotes in the item
                    return match.replace(/"/g, '\\"');
                }
            });

            // Ensure any string has a closing quote
            arrayString = arrayString.replace(/^\[|,\s*([^,\]]+)$/g, (match, p1) => {
                if (p1 && !p1.endsWith('"')) {
                    return match + '"';
                }
                return match;
            });

            // Ensure items are never wrapped in single quotes
            arrayString = arrayString.replace(/'/g, '"');

            // Parse the fixed array string into a valid array
            const array = JSON.parse(arrayString);

            return array;
        },
        jsonString(string, errorString) {
            const json = string;
            let trimmedText = json.replace(/[\n\r]+/g, '').replace(/\s{2,}/g, ' ');
            this.consoleObject('Original String', { json, trimmedText });
            try {
                const parsed = JSON.parse(trimmedText);
                this.consoleObject('JSON WORKED', parsed);
                return parsed;
            } catch (e) {
                if (errorString) {
                    console.error(errorString);
                } else {
                    console.error(trimmedText);
                    console.error('[JSON ERROR]:', trimmedText);
                    console.trace();
                }
                let list = this.parseList(string);
                if (list) {
                    list = list.slice(1, 100);
                    return list;
                } else {
                    return false;
                }
                return trimmedText;
            }
        },
        consoleImportant(string) {
            let background = '#d6d9fd';
            let textColor = '#211f2c';
            return console.log(
                `%c${string}`,
                `background: ${background}; color: ${textColor}; padding:5px 7px;font-weight:bold; font-size:11px;`,
            );
        },
        consoleObject(string, object) {
            let background = '#d6d9fd';
            let textColor = '#211f2c';
            console.groupCollapsed(
                `%c${string}`,
                `background: ${background}; color: ${textColor}; padding:5px 7px;font-weight:bold; font-size:11px;`,
            );
            console.table(object);
            console.groupEnd(string);
            return;
        },
        saveChatItem(response, request, type) {
            let chatItem;
            let html;
            let lyrics;
            const promptData = this.promptData;
            const prompt = this.promptText;
            let fixedResponse;
            try {
                fixedResponse = JSON.parse(response);
            } catch (e) {
                fixedResponse = response;
            }
            chatItem = {
                answer: response,
                prompt: request.prompt,
                promptData: promptData,
                response: response,
                request: request,
            };
            if (type === 'titles') {
                chatItem.type = 'titles';
                this.consoleObject('Titles Raw', response);
                try {
                    html = this.jsonString(response, '[JSON ERROR] Titles not parsed');
                    chatItem.bubbles = html;
                    chatItem.answer = html;
                } catch (e) {
                    chatItem.bubbles = response;
                }
            } else if (type === 'lyrics') {
                chatItem.type = 'lyrics';
                this.consoleObject('Lyrics Raw', response);
                try {
                    lyrics = this.jsonString(response, '[JSON ERROR] Lyrics not parsed');
                    if (lyrics) {
                        chatItem.answer = lyrics;
                        chatItem.html = lyrics;
                    } else {
                        chatItem.answer = lyrics;
                        chatItem.html = false;
                    }
                    chatItem.lyrics = true;
                } catch (error) {
                    console.error('[JSON ERROR] Lyrics not parsed');
                    chatItem.html = 'no html';
                }
            } else if (type === 'concepts') {
                chatItem.type = 'concepts';
                this.consoleObject('Concepts Raw', response);
                try {
                    let concepts = this.jsonString(response, '[JSON ERROR] Concepts not parsed');

                    if (concepts) {
                        chatItem.bubbles = concepts;
                        chatItem.answer = concepts;
                        chatItem.html = concepts;
                    }
                } catch (error) {
                    console.error('[JSON ERROR] Concepts not parsed');
                    chatItem.bubbles = response;
                }
            } else if (type === 'chat') {
                chatItem.type = 'chat';
                chatItem.answer = response.message;
                chatItem.prompt = response;
                chatItem.promptData = response;
                chatItem.sender = response.send;
                chatItem.response = response;
                chatItem.request = response;
                chatItem.bubbles = [{ id: this.randomId(), message: response }];
            }
            const randomId = this.randomId();
            chatItem.id = randomId;
            chatItem.step = this.step;
            this.consoleObject('CHAT ITEM', chatItem);
            this.$store.commit('addToChat', chatItem);
            this.$nextTick(() => {
                if (!this.toggleView) {
                    this.$store.commit('setToggleView', true);
                    setTimeout(() => {
                        this.scrollChatItem();
                    }, 400);
                } else {
                    this.scrollChatItem();
                }
            });
            return chatItem;
        },
        scrollChatItem() {
            const element = document.getElementById('feed');
            if (element) {
                this.$nextTick(() => {
                    // element.scrollTop = element.scrollHeight;
                    console.log('SCROLL!!!');
                    element.scrollTo({
                        top: element.scrollHeight,
                        behavior: 'smooth',
                    });
                });
            }
        },
        randomId() {
            let s4 = () => {
                return Math.floor((1 + Math.random()) * 0x10000)
                    .toString(8)
                    .substring(1);
            };
            return (
                'b' + s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
            );
        },
        pickTopic() {
            // this.refreshing = true;
            // const topicIndex = this.pickRandom(15);
            // const titleIndex = this.pickRandom(5);
            // const styleIndex = this.pickRandom(7);
            // const artistIndex = this.pickRandom(9);
            // // const styles = this.styles;
            // const locationIndex = this.pickRandom(15);
            // // const topic = this.topics[topicIndex];
            // // const locationIdea = this.locationIdeas[locationIndex];
            // // const nameIdea = topic.nameIdeas[titleIndex];
            // // const songAbout = topic.topic;
            // // const songStyle = styles[styleIndex];
            // // this.selectGenre();
            // const genre = songStyle;
            // if (this.selectedStyle) {
            //     this.selectGenre(songStyle);
            //     this.location = locationIdea;
            //     // this.about = songAbout;
            //     // this.name = nameIdea;
            // }
            //
            // this.promptData.artists = [genre.artist];
            // this.promptData.genre = genre.style;
            // // this.promptData.topic = songAbout;
            // // this.promptData.location = locationIdea;
            //
            // setTimeout(() => {
            //     this.refreshing = false;
            // }, 200);
        },
        selectGenre(genre) {
            if (genre.artist) {
                console.log(genre.artist);
                this.selectedStyle = genre;
                if (!this.selectedArtist) {
                    this.selectedArtist = genre.artist;
                }
                this.selectedArtists = genre.otherArtists;
            }
            this.promptData.genre = genre.name;
            this.promptData.otherArtists = genre.otherArtists;
        },
        addAlert(alert) {
            this.pushAlert(alert);
        },
        pushAlert(obj) {
            this.$store.commit('pushAlert', obj);
        },
        removeAlert(alert) {
            this.alerts.splice(this.alerts.indexOf(alert), 1);
        },
        submitQuestion(question) {
            this.openAIRequest(question);
        },
    },
};
