import { isProxy, toRaw } from "vue";
const storeMethods = {
    pushAlert(obj) {
        this.$store.commit("pushAlert", obj);
    },
    setSectionIndex(index) {
        this.$store.commit("setSectionIndex", index);
    },
    addSectionBelow(index, payload) {
        console.error(payload.kind);
        let section = payload;
        const indexBelow = index + 1;
        section.index = indexBelow;
        console.error("New section: Below");
        // console.error(index, section);
        this.$store.commit("addSectionBelow", section);
        this.setSectionIndex(indexBelow);
    },
    addSectionAbove(index, payload) {
        let section = payload;
        const indexAbove = index - 1;
        console.error("New section: above");
        console.error(index, section);
        this.$store.commit("addSectionAbove", payload);
        this.setSectionIndex(indexAbove);
    },
    addSectionAtEnd(index, payload) {
        let section = payload;
        const indexBelow = this.document.sections.length + 1;
        section.index = indexBelow;
        console.error("New section: end");
        this.$store.commit("addSectionAtEnd", payload);
    },
    setDocuments(documents) {
        this.$store.commit("setDocuments", documents);
    },
    setDocument(d) {
        window.doc = d;
        this.$store.commit("setDocument", d);
    },
    addDocument(document) {
        this.$store.commit("setAddDocument", document);
    },
    storeDocument(document) {
        this.$store.commit("storeDocument", document);
    },
};
export const documentStore = {
    mutations: {
        setSections(state, payload) {
            state.sections = payload;
        },
        addSectionAbove(state, payload) {
            state.document.sections.splice(payload.index, 0, payload);
            console.log("add section above");
        },
        addSectionBelow(state, payload) {
            state.document.sections.splice(payload.index, 0, payload);
            console.log("add section below");
        },
        addSectionAtEnd(state, payload) {
            state.document.sections.push(payload);
            console.log("add section at end");
        },
        spliceDocument(state, payload) {
            state.documents.splice(payload, 1);
        },
    },
};

export default {
    created: function () {},
    mounted() {
        // window.addEventListener('mousemove', this.updateMousePosition);
    },
    beforeDestroy() {
        // window.removeEventListener('mousemove', this.updateMousePosition);
    },
    data: () => ({
        actionItems: [
            {
                id: 1,
                type: "image",
                iconClass: "fas fa-image",
                label: "Generate Image",
                actionType: "image",
            },
            {
                id: 2,
                type: "about",
                iconClass: "fas fa-worm",
                label: "Learn More",
                actionType: "ai",
            },
            { id: 3, type: "expand", iconClass: "fas fa-star", label: "Expand", actionType: "ai" },
            {
                id: 4,
                type: "simplify",
                iconClass: "fas fa-circle",
                label: "Simplify",
                actionType: "ai",
            },
            { id: 5, type: "legal", iconClass: "fas fa-book", label: "Legal", actionType: "ai" },
            {
                id: 6,
                type: "howto",
                iconClass: "fas fa-lightbulb",
                label: "How to",
                actionType: "ai",
            },
            {
                id: 7,
                type: "todo",
                iconClass: "fas fa-list",
                label: "To do list",
                actionType: "ai",
            },
            {
                id: 8,
                type: "outline",
                iconClass: "fas fa-clipboard-list-check",
                label: "Create outline",
                actionType: "ai",
            },
            {
                id: 9,
                type: "versions",
                iconClass: "fas fa-sync",
                label: "Create versions",
                actionType: "ai",
            },
            // { id: 8, type: 'image', iconClass: 'fas fa-image', label: 'Generate Image' }
        ],
        tags: [
            {
                id: 1,
                iconClass: "fas fa-heading",
                type: "h1",
                label: "H1",
                longLabel: "Heading 1",
                actionType: "style",
            },
            {
                id: 2,
                iconClass: "fas fa-heading",
                type: "h2",
                label: "H2",
                longLabel: "Heading 2",
                actionType: "style",
            },
            {
                id: 3,
                iconClass: "fas fa-heading",
                type: "h3",
                label: "H3",
                longLabel: "Heading 3",
                actionType: "style",
            },
            {
                id: 4,
                iconClass: "fas fa-heading",
                type: "p",
                label: "P",
                longLabel: "Paragraph",
                actionType: "style",
            },
            {
                id: 5,
                iconClass: "fas fa-heading",
                type: "li",
                label: "•",
                longLabel: "List item",
                actionType: "style",
            },
            {
                id: 6,
                iconClass: "fas fa-heading",
                type: "ol",
                label: "1.)",
                longLabel: "Ordered list",
                actionType: "style",
            },
            {
                id: 7,
                iconClass: "fas fa-heading",
                type: "img",
                label: "Img",
                longLabel: "Image",
                actionType: "style",
            },
            {
                id: 7,
                iconClass: "fas fa-heading",
                type: "div",
                label: "Div",
                longLabel: "Div",
                actionType: "style",
            },
            {
                id: 9,
                iconClass: "fas fa-heading",
                type: "span",
                label: "Span",
                longLabel: "Span",
                actionType: "style",
            },
            {
                id: 10,
                iconClass: "fas fa-heading",
                type: "blockquote",
                label: "Blockquote",
                longLabel: "Blockquote",
                actionType: "style",
            },
            {
                id: 11,
                iconClass: "fas fa-heading",
                type: "pre",
                label: "Pre",
                longLabel: "Pre",
                actionType: "style",
            },
            {
                id: 12,
                iconClass: "fas fa-heading",
                type: "a",
                label: "A",
                longLabel: "Link",
                actionType: "style",
            },
        ],
        tones: [
            { id: 1, label: "Expert" },
            { id: 2, label: "Professional" },
            { id: 3, label: "Formal" },
            { id: 4, label: "Friendly" },
            { id: 5, label: "Informal" },
            { id: 6, label: "Casual" },
            { id: 7, label: "Very casual" },
            { id: 8, label: "Legal" },
        ],
        saving: false,
        mousePosition: { x: null, y: null },
        // artist: null,
    }),
    computed: {
        groupedSections() {
            const grouped = [];
            let currentGroup = null;
            for (const section of this.document.sections) {
                if (section.kind === "text" || section.kind === "normal" || !section.kind) {
                    grouped.push(section);
                    currentGroup = [];
                } else if (section.kind === "list") {
                    if (!currentGroup || currentGroup.kind !== section.kind) {
                        currentGroup = { kind: section.kind, items: [] };
                        grouped.push(currentGroup);
                    }
                    currentGroup.items.push(section);
                } else if (section.kind === "numberedList") {
                    if (!currentGroup || currentGroup.kind !== section.kind) {
                        currentGroup = { kind: section.kind, items: [] };
                        grouped.push(currentGroup);
                    }
                    currentGroup.items.push(section);
                }
            }
            return grouped;
        },
        selectedIndexEmpty() {
            if (this.sectionIndex === this.index && this.section.content === "") {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        ...storeMethods,
        sortByUpdated(array) {
            return array.sort((a, b) => {
                return b.updated - a.updated;
            });
        },
        async aiHelper(text, kind, index, tone) {
            let toneText = "";
            if (tone) {
                toneText = `The tone shold be ${tone}.\n\n`;
            }
            let prompt;
            if (kind === "expand") {
                this.$emit("fetchingSection", [index, true, `We're expanding on this for you`]);
                prompt = `Can you expand on this for me?:\n\n ${text}\n \n Return use \\n for spaces. ###`;
            }
            if (kind === "simplify") {
                this.$emit("fetchingSection", [index, true, `We're working to simplify this for you`]);
                prompt = `Can you make this more simple for me?:\n\n ${text}\n \n Return use \\n for spaces. ###`;
            } else if (kind === "todo") {
                this.$emit("fetchingSection", [index, true, `We're putting together a todo list for you`]);
                prompt = `Return a to-do list :\n\n ${text}\n \n Return use \\n for spaces. ###`;
            } else if (kind === "about") {
                this.$emit("fetchingSection", [index, true, `We're expanding on this for you`]);
                prompt = `write to paragraphs about:\n\n ${text}\n \n Return use \\n for spaces. ###`;
            } else if (kind === "howto") {
                this.$emit("fetchingSection", [index, true, `We're pulling together a quick how-to on this`]);
                prompt = `Return a how-to list about this:\n\n ${text}\n \n Return use \\n for spaces. ###`;
            } else if (kind === "legal") {
                this.$emit("fetchingSection", [index, true, `Helping make translate this to legalese`]);
                prompt = `Turn this into legalese :\n\n ${text}\n \n Return use \\n for spaces. ###`;
            } else if (kind === "html") {
                this.$emit("fetchingSection", [index, true, `We're pulling together some code for you`]);
                prompt = `Can you expand on this for me?:\n\n ${text}\n \n ${toneText} Return html code format with header tags, p tags, bullet points etc. ###`;
            } else if (kind === "versions") {
                this.$emit("fetchingSection", [
                    index,
                    true,
                    `We're pulling together a few versions for you to choose from`,
                ]);
                prompt = `Write 3 different versions of this passage:\n\n ${text}\n \n Return use \\n for spaces. ###`;
            } else if (kind === "outline") {
                this.$emit("fetchingSection", [index, true, `We're pulling together an outline for you`]);
                prompt = `Write structured outline for a larger more in-depth body of content I'll work on later. Based on this passage:\n\n ${text}\n \n Return use \\n for spaces. ###`;
            }

            console.log(text);
            console.log(prompt);
            const request = {
                model: "text-davinci-003",
                prompt: prompt,
                max_tokens: 1000,
                temperature: 1,
                top_p: 0.9,
                frequency_penalty: 0.5,
                presence_penalty: 0,
                best_of: 1,
            };

            const response = await this.requestFromOpenAI(request, "Testing this");

            let newResponses = response;
            let textArray = response.split("\n");
            //remove /n from text array items
            textArray = textArray.map(item => {
                let cleanedItem = item.replace(/\\n/g, " ");
                let cleanerItem = cleanedItem.replace(/\\n/g, " ");
                return cleanerItem;
                // return item.replace(/\\n/g, ' ');
            });
            let i;
            if (this.index) {
                i = this.index;
            } else {
                i = index;
            }

            this.$emit("fetchingSection", [index, true, `Done!`]);

            setTimeout(() => {
                this.$emit("fetchingSection", [index, false, null]);
                textArray.forEach((substring, index) => {
                    setTimeout(() => {
                        let text = substring.replace("\n", " ");
                        text = text.replace("\n", "");
                        let kind = "text";
                        if (text.startsWith("-") || text.startsWith("*")) {
                            text = text.replace("-", "").replace("*", "").trim();
                            kind = "list";
                            // this.$store.commit('addSection', {index: i, text: text, type: 'bullet'});
                        } else if (text.match(/^[0-9]+\./)) {
                            text = text.replace(/[0-9]+\./, "").trim();
                            kind = "numberedList";
                            // this.$store.commit('addSection', {index: i, text: text, type: 'numbered'});
                        }
                        if (text.length > 1 && !text.includes("\n")) {
                            this.newSection(i, text, null, kind);
                        }
                        i += 1;
                    }, 200 * index); // delay by one second per index
                });
            }, 1000);

            // this.$emit('newSection', [this.index, response]);
            // this.newSection([this.index, response, null, 'text']);
        },
        newSection(index, text, cursorPosition, kind, prompt) {
            console.error("making new section", kind);
            let doc = this.sections;
            console.error(doc);
            let length = doc.length;
            let sections = doc;
            let section = "section-" + length;
            let payload = {
                content: text,
                tag: "p",
                favorite: false,
                id: "section-" + this.randomId(),
                index: index,
                kind: kind || "text",
            };
            console.error(kind);
            if (kind === "newlineList") {
                console.error("making new list");
                payload.tag = "li";
                payload.kind = "text";
                payload.content = text;
                this.addSectionBelow(index, payload);
                return;
            } else if (kind === "newline") {
                payload.tag = "p";
                payload.kind = "text";
                payload.content = text;
                this.addSectionBelow(index, payload);
                return;
            } else if (kind === "list") {
                payload.tag = "li";
                payload.kind = "text";
                payload.content = text;
                this.addSectionBelow(index, payload);
                return;
            } else if (kind === "numberedList") {
                payload.tag = "li";
                payload.kind = "numberedList";
                payload.content = text;
                this.addSectionBelow(index, payload);
            } else if (kind) {
                if (kind === "image") {
                    payload.content = text;
                    payload.tag = "img";
                    payload.kind = "image";
                    payload.image = true;
                    payload.prompt = prompt;
                    this.addSectionBelow(index, payload);
                    return;
                } else if (kind === "versions") {
                    payload.content = "";
                    payload.versions = [];
                    payload.tag = "div";
                    console.error("add versions");
                    this.addSectionBelow(index, payload);
                    return;
                } else if (kind === "list") {
                    payload.content = text;
                    payload.tag = "li";
                    payload.kind = "text";
                    console.error("add - list");
                    this.addSectionBelow(index, payload);
                    return;
                } else if (kind === "numberedList") {
                    payload.content = text;
                    payload.tag = "li";
                    console.error("add # list");
                    this.addSectionBelow(index, payload);
                    return;
                } else {
                    payload.content = text;
                    payload.kind = "text";
                    payload.tag = "p";
                    this.addSectionBelow(index, payload);
                    console.error("splice text");
                    return;
                }
            } else {
                payload.content = text || " ";
                payload.kind = "text";
                payload.tag = "p";
                payload.index = index;
                console.error("section at end");
                this.addSectionBelow(index, payload);
                // this.addSectionAtEnd(index, payload);
                return;
            }
        },
        updatePreviousSection([index, text]) {
            const newIndex = index - 1;
            const previousText = this.sections[newIndex].content;
            let appendText = previousText + "" + text;
            this.document.sections[newIndex].content = appendText;
            this.removeSection(index);
        },
        async summarize(text) {
            let prompt;
            prompt = `Summarize this document in under 100 words:\n\n ${text}\n \n Return use \\n for spaces. ###`;
            if (this.document && this.document.documentType && this.document.documentType === "presentation") {
                prompt = `Describe the presentation in under 100 words and give context to what it is:\n\n${this.document.title}:\n${text}\n \n Return use \\n for spaces. ###`;
            }
            console.log(text);
            const request = {
                model: "text-davinci-003",
                prompt: prompt,
                max_tokens: 500,
                temperature: 1,
                top_p: 0.9,
                frequency_penalty: 0.5,
                presence_penalty: 0,
                best_of: 1,
            };
            const response = await this.requestFromOpenAI(request, "Testing this");
            // this.document.summary = response;
            await this.uDoc(this.document.id, { summary: response });
            let updatedDoc = await this.getDocumentById(this.document.id);
            console.error(`summarized doc with id ${updatedDoc.id}`, updatedDoc);
            return;
        },
        callRemoveSection() {
            console.error("removing section");
            const sectionIndex = this.sectionIndex;
            const newSectionIndex = sectionIndex - 1;
            // this.$parent.document.sections.splice(this.sectionIndex, 1);
            // this.removeSection(this.sectionIndex);
            this.$emit("removeSection", this.sectionIndex);
            this.setSectionIndex(newSectionIndex);
        },
        discardSection() {
            this.$emit("removeSection", this.sectionIndex);
        },
        updateMousePosition(ev) {
            this.mousePosition = { x: ev.clientX, y: ev.clientY };
        },
        addAlert(alert) {
            // console.log('addAlert', alert);
            // this.alerts.push(alert);
            this.pushAlert(alert);
        },
        extractText(objects) {
            if (objects) {
                let text = "";
                for (let i = 1; i < objects.length; i++) {
                    text += objects[i].content + "\n";
                }
                return text;
            }
        },
        async updatePartial(document) {
            await this.updateAnyObject("documents", doc.id, doc);
        },
        async updateDoc(document) {
            console.error(document.id);
            let doc = document;
            doc.updated = new Date();
            if (!doc.created) {
                doc.created = new Date();
            }
            if (doc.documentType && doc.documentType === "presentation") {
            } else {
                doc.title = document.sections[0].content;
            }
            console.error("triggered alert");
            try {
                await this.updateAnyObject("documents", doc.id, doc);
                // this.addAlert({
                //     type: 'Success',
                //     message: `Your doc has been saved`,
                // });
            } catch (error) {
                this.addAlert({
                    type: "Error",
                    message: `Saving didn't work` + error,
                });
            }
        },
        createDocument() {
            let doc = {
                title: "New document",
                id: null,
                documentType: "textEditor",
                sections: [
                    {
                        content: "New document",
                        tag: "h1",
                        kind: "text",
                        index: 0,
                        favorite: false,
                        id: this.randomId(),
                    },
                    {
                        content: "What do you want to do?",
                        kind: "text",
                        tag: "p",
                        index: 1,
                        favorite: false,
                        id: this.randomId(),
                    },
                ],
            };
            this.$emit("createDocument", doc);
        },

        async deleteDoc(collection, item) {
            let array = this.documents;
            await this.removeFromFirebase(collection, item.id, false);
        },
        // async saveDocument(collection, item, array) {
        //     this.saving = true;
        //     let saved = await this.addToFirebase(collection, item);
        //     this.saving = false;
        //     this.addAlert({
        //         type: 'success',
        //         message: 'Saving your doc',
        //     });
        //     if (array) {
        //         this.$nextTick(() => {
        //             this.$forceUpdate();
        //             // array.push(item)
        //         });
        //     }
        //     return saved;
        //     // this.updateObject('genre', ['id', this.selectedGenre.id], this.selectedGenre);
        // },
    },
};
