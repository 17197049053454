// import {Cloudinary} from 'cloudinary-core';
import firebase from "@/firebase";
import {getStorage, ref, uploadString} from "firebase/storage";

export default {
    data: () => ({}),

    computed: {},
    methods: {
        async getSongDescription(song) {
            let updatedSong = song;
            let prompt = `${song.plainText}\nConcept Prompt\n\nImagine you are ${song.artist}. Make up 3 summarized versions of the topic you had in your head when you wrote the song above. Be illustrative and use the format below.\nYou must be visual with descriptions.\nYou must avoid being vague.\nYou must pick a metaphor.\nYou must pick who the song is about.\nYou must describe what the song is about.\nYou must use simple words.\nWho is the writer speaking to?\nRefer to the writer as "author"\nDon't use song title in the example. \mFor each song, you must pick a perspective (first person, second person, third person).\nLimit text of each version to 170 characters. \nEach example must be a minimum of two sentences\nInclude the mood/tone of the song.\nYou must tell a story.\nReturn them in JSON formatted exactly like this: [ "version1", "version2","version3"]\n\n`;
            console.error(prompt);
            const request = {
                model: "text-davinci-003",
                prompt: prompt,
                temperature: 0.7,
                stop: [`"]`],
                max_tokens: 500, top_p: 0.9, frequency_penalty: 0.6, presence_penalty: 0.5};
            // prompt: `Written by: ${song.artist}\nTitle: ${song.title}\n\n${song.plainText}\n\nConcept Prompt\n\nImagine you are ${artist.name}. Make up 3 summarized versions of the topic you had in your head when you wrote the song above. Be illustrative and use the format below.\nYou must be visual with descriptions.\nYou must avoid being vague.\nYou must pick a metaphor.\nYou must pick who the song is about.\nYou must describe what the song is about.\nYou must use simple words.\nWho is the writer speaking to?\nFor each song, you must pick a perspective (first person, second person, third person).\nAnything in first person perspective must be written in first person narrative (including \"my\", \"I\").\nEach \"topic\" must be a minimum of two sentences.\nYou must tell a story.\nReturn in JSON Format. Example: [\"Topic1\",\"Topic2\"]\n\n]`,
            // this.promptData.artist = this.artistSearch(song.artist);
            let response = await this.requestFromOpenAI(request, 'Get song topics training');
            let fixedArray = await this.arrayDefinitelyFixed(response);
            updatedSong.topicTraining = fixedArray;
            updatedSong.request = request;
            updatedSong.request = request;
            updatedSong.response = response;
            if (song.id) {
                await this.updateAnyObject('trainingSong', song.id, updatedSong);
            } else {
                await this.saveItem('trainingSong', updatedSong);
            }
            console.error(fixedArray);
            return fixedArray;
        },
        arrayDefinitelyFixed(array) {
            const validArray = Array.isArray(array);
            if (validArray) {
                return array;
            } else {
                try {
                    // let fixKeywords = updatedArtist.keywords+'"]';
                    console.error('FIXING ARRAY');
                    console.error(array);
                    let fixString = this.fixArray(array);
                    console.error(fixString);
                    let fixedArray = JSON.parse(fixString);
                    console.error(fixedArray);
                    if (Array.isArray(fixedArray)) {
                        return fixedArray;
                        console.error("FIXED ARRAY");
                    }
                } catch (e) {
                    let fixString = this.fixArray(array);
                    let fixedArray = this.jsonString(fixString);

                    console.error('could not make it work for'+ fixedArray);
                    console.error(e);
                    return;
                }
            }
        }
    }
};
