<script setup>
import { defineProps, reactive } from "vue";
import { useCollection, useDocument } from "vuefire";
import { collection } from "firebase/firestore";
import { useRoute } from "vue-router";
import { db } from "@/firebase";

const router = useRoute();
const id = router.params.id;

// const document0 = useDocument(doc(collection(db, "documents"), id));
// const documents1 = useCollection(textDocs);
// console.error(id);
// console.error(documents);
</script>

<script>
import ListItem from "@/components/documents/documentListItem.vue";
import documentMixins from "@/mixins/documentMixins";
import themeMixins from "@/mixins/themeMixins.js";
import { doc, Timestamp, getDoc, query, collection, where, orderBy } from "firebase/firestore";
import { useCollection, useFirestore } from "vuefire";
import { db, textDocs, presentations } from "@/firebase";

export default {
    components: { ListItem },
    mixins: [themeMixins],
    watch: {
        storeDocument: {
            async handler(newVal, oldVal) {
                if (newVal) {
                        await this.initList();
                }
            },
            deep: true,
        },
    },
    emits: ["createDocument", "selectItem", "selectItem"],
    data() {
        return {
            debug: false,
            type: "",
            documents: [],
        };
    },
    async mounted() {
        await this.initList();
        // if (this.type) {
        // this.documents = textDocs1;
        //     .where("documentType", "==", "presentation")
        //     .orderBy("updated", "desc");
        // }
    },
    computed: {
        documentList() {},
        storeDocument() {
            return this.$store.state.document;
        },
    },
    methods: {
        async initList() {
            if (this.$route.path.includes("presentations")) {
                this.documents = await this.getPresentations();
            } else if (this.$route.path.includes("documents")) {
                this.documents = await this.getDocuments();
            }
        },
        closeDrawer() {
            this.$emit("closeDrawer");
            this.$store.commit("setShowDocumentDrawer", false);
        },
        async getPresentations() {
            await db
                .collection("documents")
                .where("documentType", "==", "presentation")
                .orderBy("updated", "desc")
                .get()
                .then(querySnapshot => {
                    let docs = [];
                    querySnapshot.forEach(doc => {
                        let document = doc.data();
                        document.id = doc.id;
                        docs.push(document);
                    });
                    this.documents = docs;
                });
            return this.documents;
        },
        async getDocuments() {
            await db
                .collection("documents")
                .where("documentType", "==", "textEditor")
                .orderBy("updated", "desc")
                .get()
                .then(querySnapshot => {
                    let docs = [];
                    querySnapshot.forEach(doc => {
                        let document = doc.data();
                        document.id = doc.id;
                        docs.push(document);
                    });
                    this.documents = docs;
                });
            return this.documents;
        },
        createDocument() {
            this.$emit("createDocument");
        },
    },
};
</script>
<template>
    <div class="x z-0 max-w-xs flex-grow scrollbar-hide">
        <div class="z-5 f aic jcb x border-bottom p-absolute h-nav bg-base-50">
            <span class="f-13 o-5 fwsb px-3" v-if="storeDocument && storeDocument.updated">Last saved {{ $filters.timeAgo(dateFix(storeDocument.updated)) }}</span>
            <div class="px-3" @click.prevent="closeDrawer()">
                <i class="fas fa-times f-20"></i>
            </div>
        </div>
        <div
            class="listStagger f fc heightFix x mw-650 border-r-1 border border-solid border-gray-200 bg-base-50 dark:bg-base-800 dark:text-base-50">
            <div class="scrollSection d-flex p-relative pt-nav grow scrollbar-hide">
                <div class="dark:text-base-50">
                    <!--                    <template v-if="documents">{{ documents }}</template>-->
                    <div v-if="documents" class="fc d-flex h-auto flex-1">
                        <div v-for="(obj, index) in documents" :key="obj.id">
                            <!-- <div :style="animatedList(index)" class="animated fadeInUpSmooth duration-5 ease-back cursor-pointer">-->

                            <ListItem
                                v-if="obj && obj.id"
                                :biline="obj.title"
                                :body="obj.title"
                                :headline="obj.title"
                                :list-item="obj"
                                style="color: unset"></ListItem>
                            <!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
            <div class="x f fc mx-auto bg-base-50 px-3 pb-3 pt-3 pb-5 text-center dark:bg-base-700">
                <button
                    class="btn btn-link border-light br-10 hover:text-success text-base-400 shadow-sm dark:backdrop-brightness-150"
                    @click.prevent="createDocument()"
                    >Create new document
                </button>
            </div>
        </div>
    </div>
</template>
