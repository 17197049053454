<template>
    <div v-if="showHighlightMenu" @mouseleave="showHighlightMenu = false" class="border-light rounded shadow bg-snow fixed width-400 d-block" style="transform: translateY(-100%);width:300px;" :style="`top:${y}px;left:${x}px;`">
        <div class="f border-top">
            <div v-for="tag in tags" :key="tag.id" :class="iconButton" @click.prevent="setTag(tag)">{{ tag.label }}</div>
        </div>
    </div>
</template>
<script>
import DocumentMixins from "@/mixins/documentMixins";

export default {
    name: 'highlight-menu',
    mixins: [DocumentMixins],
    methods: {
        setTag(tag) {
            // this.section.content = this.section.content.replace('# ', '');
            // this.focusSection();
            this.$emit('setTag', [this.index, tag]);
        },
    },
    props: {
        iconButton: {},
        index: {
            type: Number,
            required: true
        },
        showHighlightMenu: {
            type: Boolean,
            default: false
        },
        x: {
            type: Number,
            default: 0
        },
        y: {
            type: Number,
            default: 0
        }
    }
};
</script>
<style lang="scss" scoped>
ul{
    margin-left: 3rem;
    display: block;
}

p{
    ul{
        margin-left: 3rem !important;
        display: block;
    }
}

h1{
    font-size: 2.5rem;
    //color: #353554 !important; margin-bottom: 2rem;
}

h2{
    font-size: 2rem;
}

h1, h2, h3, h4, h5, h6, p, span{
    margin-bottom: 0rem;
    margin-top: 0rem;
}

p{
    font-size: 1rem;
}

</style>
