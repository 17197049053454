<template>
    <div class="f">
        <!--        {{ document }}-->
        <div data-ccursor :class="buttonStyle" class="" @click.prevent="$emit">
          <i class="far fa-thought-bubble"></i>
          <span class="ml-2" v-if="!mobile">Summarize</span>
        </div>
        <div data-ccursor :class="buttonStyle" @click.prevent="enhancePresentation()">
            <!--            class="f-15 btn btn-outline-black border-1 btn-bubble ml-3 border border-solid border-base-900/20 shadow"-->
            <i aria-hidden="true" class="fa fa-magic"></i>
          <span class="ml-2" v-if="!mobile">Enhance</span>
        </div>
        <div :class="buttonStyle"
            class=""
            @click.prevent="enhanceColors(document.summary + ' \n\n ' + extractPresentationText)">
            <!--             f-15 btn border-1 btn-bubble btn-outline-black ml-3 border border-solid border-base-900/20-->

            <i class="fal fa-palette"></i>
            <!--            <i aria-hidden="true" class="fa fa-sync mr-2"></i>-->
            <span v-if="!mobile" class="ml-2">Get Theme</span>
        </div>
        <div
            :class="buttonStyle"
            class="hover:!bg-red-200 hover:!text-red-600"
            @click="$emit('deleteDoc', 'documents', document, documents)">
            <i aria-hidden="true" class="fa fa-trash"></i>
            <!--                class="f-15 btn btn-outline-black ml-3 border border-solid shadow"-->
            <!--                br-5 hover:text-red d-block ml-3 border border-base-900/20 bg-snow p-1 px-2 shadow hover:bg-base-50-->
        </div>
    </div>
</template>
<script>
import documentManagementMixin from '@/mixins/documentManagementMixin';
import documentMixins from '@/mixins/documentMixins';
import presenationMixin from '@/mixins/presentationMixin';

export default {
    name: 'PresentationToolbarActions',
    mixins: [documentMixins, presenationMixin, documentManagementMixin],
    props: {
        document: {},
    },
    computed: {
        buttonStyle() {
            let base = 'z-2 f-15 br-5 f btn aic fwsb jcc border-solid border-1 border';
            let style;
            if (this.document && this.document.theme && this.document.theme.colors) {
                style = `
                bg-base-50/10 mr-2 text-base-50/70
                hover:text-base-50 hover:border-gray-300/5 hover:shadow-md
                active:bg-base-50 active:text-base-900
                `;
            } else {
                style = `
                bg-base-50/10
                hover:border-gray-300/5
                hover:bg-base-900/5
                hover:text-base-900
                `;
            }
            return base + ' ' + style;

            // f-15 btn btn-outline-black border-1 border border-solid border-base-900/20 shadow
            // return `f-15 btn btn-outline-black border-1 border border-solid border-base-900/20 shadow`;
        },
    },
};
</script>
<style>
input {
}
</style>
