<template>
    <div :ref="`slide-${id}${index}`" class="x br-5 f aic jcc p-relative" style="height: 0px; padding-bottom: 60%">
        <div class="row no-gutters f aic jcc p-absolute p-fill m-0 p-0">
            <div :ref="`image-sm-${id}${index}`" class="col-6 y">
                <img v-if="slide.imageURL" :src="slide.imageURL" class="x y o-cover" />
                <div v-else class="x y animated loading-pulse o-2"></div>
            </div>
            <div :ref="`text-sm-${id}${index}`" class="col-6 p-2 pl-2 text-base-50">
                <span v-if="slide.title" class="d-block f-11 small fwb line-height-smaller mb-0">{{
                    slide.title
                }}</span>
                <span v-if="slide.enhancedBody"
                    class="d-block f-11 o-5 line-height-smaller mb-0"
                    style="font-size: 6px !important"
                    >{{ slide.enhancedBody.slice(0, 150) }}</span
                >
            </div>
        </div>
    </div>
</template>
<script>
import SlideStyleMixin from '@/mixins/slideStyleMixin';

export default {
    name: 'SlideLeftMini',
    mixins: [SlideStyleMixin],
    props: {
        index: {},
        slide: {},
        document: {},
    },
    data: () => ({
        id: '',
    }),
    mounted() {
        if (this.document.theme) {
            let t = this.document.theme.colors || false;
            if (t) this.textTheme();
        }
    },
    created() {
        this.id = this.randomId();
    },
};
</script>
