<script>
export default {
    data() {
        return {
            property1: [],
        };
    },
    props: ['json'],
    mounted() {

    },
    computed: {},

    methods: {
        method1(){

        }
    }

};
</script>
<template>
    <div></div>
</template>
