<template>
    <div class="p-1">
        <div
            :class="{
                'selected bg-base-500 text-base-50 hover:bg-base-900': selected.includes(`${name} ${kind}`),
                'bg-snow hover:bg-base-50': !selected.includes(`${name} ${kind}`),
            }"
            class="br-5 x cursor-pointer overflow-hidden p-0 hover:shadow"
            @click.prevent="addStyle(style)">
            <div v-if="false" class="p-relative">
                <!--                //style.image-->
                <img :src="style.image" class="x" style="" />
                <div class="p-absolute bottom left right top white gradientBottom f aie jcs p-2">
                    <div>
                        <p class="fwb f-15 line-height-small mb-1">{{ name }}</p>
                        <template v-if="style.details">
                            <p
                                v-if="isArray(style.details)"
                                class="f-11 o-5 line-height-small mb-0"
                                style="text-transform: capitalize">
                                {{ style.details[1] }}
                            </p>
                            <p v-else class="f-13 o-5 line-height-small mb-0" style="text-transform: capitalize">
                                {{ style.details.slice(0, 60) }}
                            </p>
                        </template>
                    </div>
                </div>
            </div>
            <div v-else class="p-2" style="max-width:250px;">
                <p class="fwb f-15 line-height-small mb-1">{{ name }}</p>
                <!--        <p class=" f-11 text-capitalize mb-0" v-if="style.keywords">{{ style.keywords.join(', ') }}</p>-->
                <!--        <p class="f-11 mb-0" style="text-transform:capitalize;" v-if="style.objects">{{ style.objects.join(', ') }}</p>-->
                <template v-if="style.details">
                    <p
                        v-if="isArray(style.details)"
                        class="f-11 line-height-smaller mb-0"
                        style="text-transform: capitalize">
                        {{ style.details[1] }}
                    </p>
                    <p v-else class="f-11 line-height-smaller mb-0" style="text-transform: capitalize">
                        {{ style.details.slice(0, 73) }}
                    </p>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ImageGenList',
    props: {
        kind: {
            type: String,
            default: 'style',
        },
        selected: {
            type: Array,
            default: [],
        },
        style: {},
    },
    computed: {
        name() {
            if (this.style.name) {
                return this.style.name;
            } else {
                return this.style;
            }
        },
    },
    methods: {
        addStyle(style) {
            style.kind = this.kind;
            this.$store.commit('setCurrentStyle', style);
            this.$store.commit('resetSelection', this.kind);
            this.$nextTick(() => {
                if (style.name) {
                    style.kind = this.kind;

                    this.$store.commit('addToSelection', { value: `${style.name} ${this.kind}`, kind: this.kind });

                    if (style.details) {
                        if (style.details.constructor === Array) {
                            this.$store.commit('addToSelection', { value: style.details[0], kind: this.kind });
                        } else {
                            this.$store.commit('addToSelection', { value: style.details, kind: this.kind });
                        }
                    }

                    if (style.lighting) {
                        style.lighting &&
                            style.lighting.forEach(item => {
                                this.$store.commit('addToSelection', { value: item, kind: this.kind });
                            });
                    }
                } else {
                    this.$store.commit('addToSelection', { value: style, kind: this.kind });
                }
            });
        },
    },
};
</script>
