<script>
import {mapState} from 'vuex';
import PromptPreviewVisual from "@/components/music/PromptPreviewVisual.vue";
import ChatBubble from "@/components/chat/ChatBubble.vue";
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";
import LyricsContainer from "@/components/music/lyricsContainer.vue";
import LoadingState from "@/components/loaders/LoadingState.vue";
import ChatItem from "@/components/chat/ChatItem.vue";
import ChatLoader from "@/components/chat/ChatLoader.vue";

export default {
    components: {
        ChatLoader,
        ChatItem,
        LoadingState,
        LyricsContainer,
        ButtonPrimary,
        ButtonSecondary,
        ChatBubble,
        PromptPreviewVisual
    },
    created() {
    },
    mounted() {

    },
    data() {
        return {
            debug: false,
            chatFeed: [],
            chatFeedNew: [],
            inputText: '',
        };
    },
    watch: {
        step: {
            handler(newValue) {
                if (newValue === 3) {
                    console.error(newValue);
                    this.setShowButtons(false);
                    this.setShowInput(true);
                }
                if (newValue === 5) {
                    console.error(newValue);
                    this.setShowButtons(true);
                    this.setShowInput(false);
                }
                if (newValue === 4) {
                    console.error(newValue);
                    this.setShowButtons(false);
                    this.setShowInput(true);
                }
                // if (newValue === 3) {
                //     this.setShowButtons(true);
                //     this.setShowInput(true);
                //     console.error(newValue);
                // }
            },
            immediate: true
        },
    },
    computed: {
        showButtons() {
            return this.$store.state.showButtons;
        },
        showInput() {
            return this.$store.state.showInput;
        },
        showFeed() {
            return this.hasChats;
        },
        inputString() {
            let strings = {
                "headline": null,
                "biline": null,
            };
            if (this.step === 3) {
                strings.headline = 'What is this song about?';
                // strings.biline = 'Choose above or type one';
                return strings;
            } else if (this.step === 4) {
                strings.headline = 'Pick a name or type your own';
                strings.biline = this.step;
                return strings;
            } else {
                strings.headline = "Type a message";
                strings.biline = this.step;
                return strings;
            }
        },
    },
    methods: {
        async concepts(string) {
            this.setShowButtons(false);
            this.setShowInput(false);
            await this.getConcepts();
            // this.setStep(4);

            this.setShowButtons(false);
            this.setShowInput(true);
        },
        sendMessage(prompt, local) {
            let newPrompt = {
                prompt: prompt,
                id: this.randomId(),
                send: true
            };
            this.saveChatItem(newPrompt, newPrompt, 'chat');
            this.inputText = null;
            if (!local) {
                this.openText(prompt);
            }

        },
        scrollFeedNew() {
            const feed = this.$refs.feed;
            document.getElementById('feed').scrollTop = document.getElementById('feed').scrollHeight;
            this.$nextTick(() => {
                feed.scrollTop = feed.offsetHeight;
            });
            console.log('SCROLL!!!');
        },
        requestTitles() {
            this.requestSongTitles();
        },
    },
};
</script>
<template name="chatBubble">
    <div>
        <div class="pl-2 pr-2 pl-md-5 py-2">
            <p class="mb-0 text-left fwb white" v-if="inputString">
                <span v-if="inputString.headline" class="mr-1">{{ inputString.headline }}</span>
                <span v-if="inputString.biline" class="f-11 mb-0 text-left text-base-500 font-weight-normal">{{ inputString.biline }}</span></p>
        </div>
        <div class="f x aic pr-2">
            <div class="f aic jcc mr-md-2" style="padding-right:7px;" @click.prevent="setShowButtons(!showButtons)">
                <i class="f-30 line-height-smaller fal fa-plus-circle" aria-hidden="true"></i>
            </div>

            <div class="chatInput">
                <template v-if="chatMode">
                    <input type="textarea" ref="aboutInput" v-model="inputText" placeholder="Send a message">
                    <div class="f aic jcc pr-3 z-3" @click.prevent="sendMessage(inputText)">
                        <i class="fa fa-paper-plane p-0 m-0" aria-hidden="true"></i>
                    </div>
                </template>
                <template v-else-if="step === 3 || step === 4">
                    <input type="text" v-if="step === 3" ref="aboutInput" v-model="promptData.topic"
                           placeholder="Choose above or type here"
                           @keydown.enter="concepts(promptData.topic)"/>
                    <input type="text" v-else-if="step === 4" ref="titleText"
                           v-model="promptData.name" placeholder="Pick a title or type one"
                           @keydown.enter="sendMessage(`Sweet, we call it ${promptData.name}`, true),setStep(5)"/>
                    <div class="enterButton" v-if="step === 3" @click.prevent="concepts(promptData.topic)"><i class="fa fa-paper-plane p-0 m-0" :class="{'text-glow':promptData.topic && promptData.topic.length > 10}" aria-hidden="true"></i></div>
                    <template v-if="step === 4">
                        <div class="enterButton" v-if="promptData.name && promptData.name.length >= 4" @click.prevent="sendMessage(`Sweet, we call it ${promptData.name}`, true),setStep(5)"><i class="fa fa-paper-plane p-0 m-0" :class="{'text-glow':promptData.name && promptData.name.length > 10}" aria-hidden="true"></i></div>
                        <div class="enterButton" v-else><i class="fa fa-paper-plane p-0 m-0"  aria-hidden="true"></i></div>
                    </template>
                </template>

            </div>
        </div>
    </div>
</template>
<style lang="scss">
</style>
<!--                        <template v-if="step === 4 && !promptData.name">-->
<!--                            <input type="textarea" ref="aboutInput" v-if="step === 3" v-model="promptData.topic" class="y border-0 text-base-100 x" placeholder="" style="background: none;color:white;">-->
<!--                            <div class="f aic jcc pr-3 z-5" @click.prevent="concepts(promptData.topic)">-->
<!--                                <i class="fa fa-paper-plane p-0 m-0" aria-hidden="true"></i>-->
<!--                            </div>-->
<!--                        </template>-->
