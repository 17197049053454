<script>
import ClosingSection from "@/components/elements/ClosingSection.vue";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import imageMixin from "@/mixins/imageMixin";

export default {
    components: {ButtonSecondary, ButtonPrimary, ClosingSection},
    created() {

    },
    mixins: [imageMixin],
    props: {
        item: {
            type: Object,
            default: {}
        }
    },
    watch: {

    },
    data() {
        return {
            debug: false,
        };
    },
    async mounted() {
        await this.getImageIfNone();
    },
    methods: {
       async getImageIfNone(){
            if (this.item.image) {
                this.imageUrl = this.item.image;
            } else {
                let newImage = await this.generateLyricsImage(this.item,this.songLyrics);
                if(this.imageUrl){
                    await this.updateAnyObject('feedItem',this.item.id, {image:this.imageUrl})
                }
            }
        }
    },
    computed: {
        songLyrics() {
            let lyrics = '';
            if (this.item) {
                this.item.html.forEach((item, index) => {
                    if (item.section && item.lyrics) {
                        lyrics += item.lyrics+'\n';
                    }
                });
            }
            return lyrics;
        },
        response() {
            let response = item && item.response.request;
            return response;
        },
        requestData() {
            if (this.item && this.item.request) {
                return this.item.request;
            }
        }
    },
};
</script>
<template>
    <div>
        <div v-if="!mobile">
            <div class="py-3">
                <h4 class="text-base-200">Details</h4>
                <!--                <p class=" mb-0">{{ item.promptData.topic }}</p>-->
                <!--                <p class="mb-0">-->
                <!--                    <span class="fwb">Inspired by</span>-->
                <!--                    <span class="fwb">{{ item.promptData.artists.name }}</span>-->
                <!--                </p>-->
                <!--                <p class="my-2 d-block">{{ item.promptData.genre.name }}</p>-->
            </div>
        </div>
        <div>
            <div class="py-3">
                <div v-if="item.album_cover">Album cover</div>
                <div v-else>
                    <p class="fwb text-base-200">Album cover</p>
                    <div class="d-block">
                        <span class="d-block">{{ item.id }}</span>
                    </div>
                    <div class="x my-3" v-if="image">
                        <img v-if="image" :src="image" class="x">
                    </div>
                    <div class="x my-3" v-if="imageUrl">
                        <img v-if="imageUrl" :src="imageUrl" class="x">
                    </div>
                    <div class="d-block">

                        <button-secondary @click.prevent="generateLyricsImage(item,songLyrics)" class="f-13 mt-3" text="Get image"></button-secondary>
                        <button-secondary v-if="imageUrl" @click.prevent="updateAnyObject('feedItem',item.id, {image:imageUrl})" class="f-13 mt-3" text="Save"></button-secondary>
                    </div>
                </div>
            </div>
            <closing-section :collapsed-content="item.answer" class="whitespace-pre-line" subtitle="Response"></closing-section>
            <closing-section :collapsed-content="item.request" class="whitespace-pre-line overflow-hidden" subtitle="Request"></closing-section>
            <closing-section :collapsed-content="item.prompt" class="whitespace-pre-line" subtitle="Prompt"></closing-section>
            <div class="py-2" v-if="requestData" style="border-bottom:1px solid rgba(255,255,255,0.2)">
                <closing-section v-if="requestData.max_tokens" :body="requestData.max_tokens" :table="true" class=" small fwb white f-13 py-0 line-height-smaller text-capitalize" title="max tokens"></closing-section>
                <closing-section v-if=" requestData.temperature" :body="requestData.temperature" :table="true" class=" small fwb white f-13 py-0 line-height-smaller text-capitalize" title="temperature"></closing-section>
                <closing-section v-if=" requestData.top_p" :body="requestData.top_p" :table="true" class=" small fwb white f-13 py-0 line-height-smaller text-capitalize" title="top P"></closing-section>
                <closing-section v-if=" requestData.frequency_penalty" :body="requestData.frequency_penalty" :table="true" class=" small fwb white f-13 py-0 line-height-smaller text-capitalize" title="frequency penalty"></closing-section>
                <closing-section v-if=" requestData.presence_penalty" :body="requestData.presence_penalty" :table="true" class=" small fwb white f-13 py-0 line-height-smaller text-capitalize" title="presence penalty"></closing-section>
                <closing-section v-if=" requestData.best_of" :body="requestData.best_of" :table="true" class=" small fwb white f-13 py-0 line-height-smaller text-capitalize" title="Best of"></closing-section>
                <!--                <closing-section v-if="item && requestData.stop" :body="requestData.stop" :table="true" class="my-1 text-capitalize" title="stop"></closing-section>-->
            </div>
            <div class="py-2" style="border-bottom:1px solid rgba(255,255,255,0.2)">
                <closing-section v-if="item && item.promptData.artists" :body="item.promptData.artists.name" title="Inspired by"></closing-section>
                <closing-section v-if="item && item.promptData.name" :body="item.promptData.name" title="Name"></closing-section>
                <closing-section v-if="item && item.promptData.name" :body="item.promptData.topic" title="Topic"></closing-section>
                <closing-section v-if="item && item.promptData.location" :array="item.promptData.location" subtitle="Metaphors"></closing-section>
                <closing-section v-if="item && item.promptData.genre" :array="item.promptData.genre.keywords" subtitle="Genre"></closing-section>
                <closing-section v-if="item && item.promptData.artists" :array="item.promptData.artists.keywords" subtitle="Artist Characteristics"></closing-section>
                <closing-section v-if="item && item.promptData.artists" :array="item.promptData.otherArtists" subtitle="Similar Artists"></closing-section>
            </div>

            <!--            <div class="p-absolute left right bottom my-5 p-3">-->
            <!--                <button-primary text="Update lyrics" @click.prevent="getLyrics(requestData.prompt)"></button-primary>-->
            <!--            </div>-->
            <!--            <closing-section :body="item.response" title="Response"></closing-section>-->

            <!--            <closing-section v-for="(key,obj) in item.response" :body="key" :table="true" class="my-1 text-capitalize" :title="obj"></closing-section>-->

            <!--            <p v-for="(obj,key) in item.request" class="mb-3 d-block">-->
            <!--                                        <span class="fwb d-block white">{{ key }}</span>-->
            <!--                <span class="whitespace-pre-line">{{ obj }}</span>-->
            <!--            </p>-->
            <!--            <p v-for="(obj,key) in item.answer" class="mb-3 d-block">-->

            <!--                <span class="fwb">{{ obj }}</span>-->
            <!--            </p>-->
            <!--            <p v-for="(obj,key) in item" class="mb-3 d-block">-->
            <!--                <span class="fwb d-block white">{{ key }}</span>-->
            <!--                <span class="fwb">{{ obj }}</span>-->
            <!--            </p>-->
            <!--            {{ item.request }}-->
        </div>
    </div>

</template>
<style lang="scss">
</style>
