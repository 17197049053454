<template>
    <div class="x p-0">
        <Transition appear enter-active-class="swingInUp animated stagger-1">
            <contenteditable
                v-if="section.title"
                ref="contenteditable"
                v-model="section.title"
                :contenteditable="true"
                :no-html="true"
                :no-nl="false"
                class="stagger-1 x d-block whitespace-pre-line"
                tag="h2"
                @blur="saveSection()"></contenteditable>
            <!--            <h2 v-if="section.title">{{ section.title }}</h2>-->
        </Transition>
        <Transition appear enter-active-class="swingInUp animated stagger-2">
            <!--            <p class="f-20 o-5" v-if="section.body && !section.enhancedBody">{{ section.body }}</p>-->
            <div class="p-relative x">
                <!--                <span class="whitespace-pre-line">{{document.sections[index]}}</span>-->
                <contenteditable
                    v-if="section.enhancedBody"
                    ref="contenteditable"
                    v-model="section.enhancedBody"
                    :contenteditable="true"
                    :no-html="true"
                    :no-nl="false"
                    class="o-8 stagger-2 x d-block f-17 whitespace-pre-line"
                    tag="p"
                    @blur="saveSection()"></contenteditable>
                <contenteditable
                    v-else-if="section.body"
                    ref="contenteditable"
                    v-model="section.body"
                    :contenteditable="true"
                    :no-html="true"
                    :no-nl="false"
                    class="o-8 stagger-2 x d-block f-17 whitespace-pre-line"
                    tag="p"
                    @blur="saveSection()"></contenteditable>
                <p v-else-if="!section.style.includes('full')" class="f-20 o-5">Add some text</p>
                <!--{{comments}}-->
                <div class="p-relative d-block x">
                    <div class="border-top x f-20 o-5 mt-2 pt-2">
                        <div
                            class="f aic jcc btn-bubble c-40 rounded-full"
                            style=""
                            @click.prevent="toggleShowComments()">
                            <i class="fas fa-comment-alt" style="font-size: 13px"></i>
                        </div>
                        <template v-if="responseText">{{ changeResposeText }}</template>
                    </div>
                    <Transition
                        appear
                        enter-active-class="swingInUp animated duration-1"
                        leave-active-class="swingInUp animated duration-1 reverse-animation">
                        <div
                            v-if="showComments"
                            class="mw-500 x f fc p-absolute br-10 mw-400 z-5 p-absolute bottom left right overflow-hidden bg-snow shadow"
                            style="max-height: 400px !important">
                            <div class="x scrollSection px-2 pl-2">
                                <template v-if="comments && comments.length && comments.length > 0">
                                    <template v-for="comment in comments">
                                        <CommentItem :comment="comment"></CommentItem>
                                    </template>
                                </template>
                                <template v-else>
                                    <div class="f aic jcc p-5 text-base-200" style="height: 100px"> No comments yet </div>
                                </template>
                            </div>
                            <div
                                class="p-absolute top right c-30 f aic jcc z-5 bg-white m-1 rounded-full shadow text-base-900"
                                @click.prevent="showComments = false">
                                <i class="far fa-times f-20 text-base-900" aria-hidden="true"></i>
                            </div>
                            <div class="border-top f aic jce f x p-2 pt-1">
                                <div class="x f aic jcc p-relative">
                                    <input
                                        ref="comment"
                                        v-model="prompt"
                                        class="x f-17 mt-0 p-0 px-2 py-1 pr-5"
                                        style="
                                            border: 0px solid rgba(0, 0, 0, 0.1);
                                            border: 1px solid rgba(0, 0, 0, 0.1);
                                            background: rgba(0, 0, 0, 0.02);
                                        "
                                        @keydown.enter="modifyPrecursor(prompt)" />
                                    <div
                                        class="c-20 f-20 text-blue d-inline x f aic jcc p-absolute right mr-0 h-auto flex-shrink pr-0 pl-2 pr-4"
                                        style="max-width: 30px"
                                        @click.prevent="modifyPrecursor(prompt)">
                                        <i class="fas fa-arrow-circle-up"></i
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
            <!--            <p class="f-15 o-5" v-else-if="section.enhancedBody">{{ section.enhancedBody }}</p>-->
        </Transition>

        <!--                                    <p class="f-20 o-5" v-if="section.style">{{ section.style }}</p>-->
    </div>
</template>

<script>
import { collection, doc } from "firebase/firestore";
import contenteditable from "vue-contenteditable";

import CommentItem from "@/components/Presentations/slides/CommentItem.vue";
import { db } from "@/firebase";
import documentManagementMixin from "@/mixins/documentManagementMixin";
import imageMixin from "@/mixins/imageMixin";

const documents = collection(db, "documents");
export default {
    components: { CommentItem, contenteditable },
    mixins: [documentManagementMixin, imageMixin],
    data() {
        return {
            responseText: "",
            prompt: "",
            document: {},
            documents: [],
            comments: [],
            showComments: false,
        };
    },
    created() {
        // this.$firestoreBind('document', doc(documents, this.$route.params.id));
        this.enhancedBody = this.section.enhancedBody;
        this.getCommentsForSectionFromFirebase();
    },
    firestore: {
        documents: collection(db, "documents"),
        // document: getDoc(doc(db, 'documents', route.params.id)),
        // document:useDocument(doc(db, 'settings', this.documentId)),
    },
    computed: {
        updatedDocumentSections() {
            const oldSections = [...this.document.sections];
            const newSection = this.section;
            oldSections.splice(this.index, 1, newSection);
            return;
        },
        changeResposeText() {
            this.section.enhancedBody = this.responseText;
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(route) {
                if (this.$route.params.id) {
                    this.$firestoreBind("document", doc(documents, this.$route.params.id));
                }
            },
        },
        responseText: {
            handler: function (val, oldVal) {
                if (val !== null) {
                    this.section.enhancedBody = val;
                    val.replace("[[DONE]]", "").replace("null", "");
                }
            },
            deep: true,
        },
    },
    methods: {
        async getCommentsForSectionFromFirebase() {
            // Get the comments for the document
            try {
                let showcomments = [];
                // await db.collection('comments').where('documentId', '==', this.$route.params.id).where('sectionId', '==', this.section.id).orderBy('updated', 'desc').get().then(querySnapshot => {
                await db
                    .collection("comments")
                    .where("sectionId", "==", this.section.id)
                    .orderBy("updated")
                    .get()
                    .then(querySnapshot => {
                        querySnapshot.forEach(doc => {
                            let comment = { ...doc.data() };
                            comment = { ...comment, ...comment.comment };
                            showcomments.push(comment);
                        });
                        this.comments = showcomments;
                    });

                if (showcomments.length > 0) {
                    let sorted = showcomments.sort((a, b) => {
                        if (a.updated > b.updated) {
                            return -1;
                        }
                        if (a.updated < b.updated) {
                            return 1;
                        }
                        return 0;
                    });
                    this.comments = sorted;
                    return;
                } else {
                    this.comments = [];
                    return;
                }
            } catch (e) {
                this.comments = [];
                console.error(e);
                return false;
            }
        },
        addComment(documentId, comment) {
            // Create a new comment in Firebase
            let sectionId;
            if (!this.section.id) {
                sectionId = this.randomId();
            } else {
                sectionId = this.section.id;
            }
            let created = new Date();
            const commentRef = db.collection("comments").doc();
            commentRef.set({
                ...comment,
                created: created,
                updated: created,
                documentId: this.$route.params.id,
                sectionId: sectionId,
            });
            // Get the ID of the newly created comment
            const commentId = commentRef.id;
            console.error(commentRef);
            this.$nextTick(function () {
                this.getCommentsForSectionFromFirebase();
            });

            // // Associate the ID of the comment with the ID of the document
            // db.collection("documents").doc(documentId).update({
            //     comments: firebase.firestore.FieldValue.arrayUnion(commentId)
            // });
        },
        toggleShowComments() {
            this.getCommentsForSectionFromFirebase();
            this.$nextTick(function () {
                if (this.showComments) {
                    this.showComments = false;
                } else {
                    this.showComments = true;
                    // focus on the $ref for the text area
                    this.$nextTick(function () {
                        this.$refs.comment.focus();
                    });
                }
            });
        },
        async saveSection() {
            const sectionText = this.section.enhancedBody;
            sectionText.replace("[[DONE]]", " ");
            this.section.enhancedBody = sectionText;
            let doc = JSON.parse(JSON.stringify(this.document));
            let fixedOldSections = JSON.parse(JSON.stringify([...this.document.sections]));
            let stringNewText = JSON.stringify(this.section);
            const newSection = JSON.parse(stringNewText);
            fixedOldSections.splice(this.index, 1, newSection);
            doc.sections = fixedOldSections;
            await this.saveDoc(doc);
            // console.error("saveSection", doc);
            return;
        },
        async modifyPrecursor(prompt) {
            let instructions = `The following is a user's instructions to edit a presentation slide ( choices are: image, bodyText, titleText )\n\n${prompt}\n\nUser intent is to edit the slide's: `;
            let request = {
                model: "text-davinci-003",
                prompt: instructions,
                temperature: 1,
                max_tokens: 50,
                top_p: 1,
                frequency_penalty: 0,
                presence_penalty: 0,
            };
            const response = await openai.createCompletion(request);
            let text = response.data.choices[0].text;
            text = text.toLowerCase();
            console.error(text);
            if (text.includes("body")) {
                this.modifyPassage(prompt);
            } else {
                await this.modifyImage(prompt);
            }
        },
        async modifyImage(prompt) {
            let imagePrompt = this.section.image;
            if (this.section.enhancedImage) {
                imagePrompt = this.section.enhancedImage;
            }
            let comment = {
                comment: prompt,
                original: imagePrompt,
                user: "Asher Hunt",
            };
            this.addComment(this.$route.params.id, comment);
            this.$parent.section.imageURL = "";
            this.prompt = "";
            this.showComments = false;
            let fullText = "";
            if (this.section.style.includes("full")) {
                fullText = "maintain negative space in the center of the image. 16:9 composition";
            }
            let modifiedImagePrompt = `
Modify the ORIGINAL PROMPT to include the following user notes, then follow instructions below. The AI does not have context on the existing image so you'll have to describe it all in full. Keep it under 400 characters total.

###

USER FEEDBACK:
${prompt}

ORIGINAL IMAGE PROMPT:
${imagePrompt} ${fullText}

NEW PROMPT (under 300 characters total):

`;
            await this.enhancedImagePrompt(modifiedImagePrompt, this.index);
            this.addComment(this.$route.params.id, {
                comment: "Ok! I did that",
                original: this.section.enhancedBody,
                user: "Clearmind",
            });
        },
        modifyPassage(prompt) {
            let comment = {
                comment: prompt,
                original: this.section.enhancedBody,
                user: "Asher Hunt",
            };
            this.addComment(this.$route.params.id, comment);
            this.comments.push(comment);
            this.prompt = "";
            this.showComments = false;
            this.responseText = "";
            let text = this.section.body;
            if (this.section.enhancedBody) {
                text = this.section.enhancedBody;
            }
            let context = `CONTEXT:
                            ${this.document.summary}

                            `;
            let modString = `${context}
ORIGINAL PASSAGE:
${text}

INSTRUCTIONS:
${prompt}

UPDATED PASSAGE:
###

            `;

            return this.streamText(modString, "responseText"); // NOTE: i want this.streamText() to write to stream to this.responseText
        },
        async streamText(prompt, VueProperty) {
            let streamPrompt = `${prompt}
(please type "[[DONE]]" on a new line when update is complete.)
###

`;
            const request = {
                model: "text-davinci-003",
                prompt: streamPrompt,
                temperature: 0.9,
                max_tokens: 500,
                top_p: 0.9,
                frequency_penalty: 0,
                presence_penalty: 0,
                stream: true,
                stop: "[[DONE]]",
            };
            console.error();
            const self = this;
            console.log(request);
            try {
                // const response = await fetch("http://localhost:80/textTest1", {
                const response = await fetch("http://34.170.111.18/textTest1", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                    },
                    body: JSON.stringify(request),
                });

                const reader = response.body.getReader();
                while (true) {
                    console.log(response.body);
                    const { value, done } = await reader.read();
                    self[VueProperty] += new TextDecoder("utf-8").decode(value);
                    console.log(new TextDecoder("utf-8").decode(value));
                    if (self[VueProperty].includes("[[DONE]]" || done)) {
                        self[VueProperty] = self[VueProperty].replace(/\[\[DONE\]\]/g, "");
                        await this.saveSection();
                        this.addComment(this.$route.params.id, {
                            comment: "Ok! I did that",
                            original: self[VueProperty],
                            user: "Clearmind",
                        });
                        // this.comments.push({comment: 'Ok! I did that', original: self[VueProperty],user:'Clearmind'});
                        break;
                        return;
                    }
                    if (done) {
                        console.error("FINISHED");
                        break;
                    }
                }
            } catch (e) {
                console.error(e);
            }
        },
    },
    props: {
        index: {
            type: Number,
            required: true,
        },
        section: {
            type: Object,
            required: true,
        },
    },
};
</script>
