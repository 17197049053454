<script>
import json from "../../../public/assets/newGenres.json";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";

export default {
    components: {ButtonPrimary},
    data() {
        return {
            pageArtists: [],
            dbGenres: [],
            selectedGenre: {},
            songs: [],
            genRefs: json,
            geniusSong: null,
            apiKey: '4c1202ee47f00a532c6acc0227da3e8d',
            lyrics: null,
            response: null,
            search: {
                artist: '',
                genre: '',
                genius: '',
            }
        };
    },
    props: ['text', 'small', 'icon'],
    methods: {
        async requestArtistInfo(artistName, page) {
            const baseUrl = 'https://api.genius.com';
            const headers = {
                'Authorization': 'Bearer '+this.apiKey
            };
            const searchUrl = baseUrl+'/search?per_page=10&page='+page;
            const data = {
                'q': artistName
            };
            try {
                const response = await fetch(searchUrl, {
                    headers,
                    method: 'GET',
                    mode: 'cors',
                    cache: 'no-cache',
                    credentials: 'same-origin',
                    redirect: 'follow',
                    referrerPolicy: 'no-referrer',
                    body: JSON.stringify(data)
                });
                return await response.json();
            } catch (error) {
                console.error(error);
            }
        },
        async requestSongUrl(artistName, songCap) {
            let page = 1;
            const songs = [];

            while (true) {
                const url = `/search?per_page=10&page=${page}`;
                const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
                try {
                    const response = await fetch(proxyUrl+`http://api.genius.com/${url}`, {
                        headers: {
                            'Authorization': `Bearer utLWjYy9TAyQPvbBPtsRbsB4BxwkF0Yo03fSjrdi0KXIgXNY2WcXDI4v39R8nVSc`,
                            'Content-Type': 'application/json'
                        },
                        method: 'GET',
                        mode: 'cors',
                        cache: 'no-cache',
                        credentials: 'same-origin',
                        redirect: 'follow',
                        referrerPolicy: 'no-referrer',
                        body: JSON.stringify({'q': artistName})
                    });
                    const json = await response.json();
                    // Collect up to song_cap song objects from artist
                    const songInfo = [];
                    for (const hit of json['response']['hits']) {
                        if (artistName.toLowerCase() in hit['result']['primary_artist']['name'].toLowerCase()) {
                            songInfo.push(hit);
                        }
                    }

                    // Collect song URL's from song objects
                    for (const song of songInfo) {
                        if (songs.length < songCap) {
                            const url = song['result']['url'];
                            songs.push(url);
                        }
                    }

                    if (songs.length === songCap) {
                        break;
                    } else {
                        page += 1;
                    }
                } catch (error) {
                    console.error(error);
                }
            }

            console.log(`Found ${songs.length} songs by ${artistName}`);
            return songs;
        },
        async searchGenius(songUrl) {
            const accessToken = 'your_access_token';
            const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
            let url;
            const query = this.search.genius;
            if (!songUrl) {
                url = `search?q=${query}`;
            } else {
                // url = '/songs/'+songURL
            }
            // songs/378195

            try {
                const response = await fetch(proxyUrl+`http://api.genius.com/${url}`, {
                    headers: {Authorization: `Bearer utLWjYy9TAyQPvbBPtsRbsB4BxwkF0Yo03fSjrdi0KXIgXNY2WcXDI4v39R8nVSc`}
                }).then(response => response.json()).then(data => {
                    this.songs = data.response.hits;
                    console.log(data);
                }).catch(error => {
                    // handle error
                    console.error(error);
                });
            } catch (error) {
                console.error(error);
            }
        },
        async getSong(songUrl) {
            const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
            try {
                const response = await fetch(proxyUrl+`http://api.genius.com/`+songUrl+'?text_format=plain', {
                    headers: {Authorization: `Bearer utLWjYy9TAyQPvbBPtsRbsB4BxwkF0Yo03fSjrdi0KXIgXNY2WcXDI4v39R8nVSc`}
                }).then(response => response.json()).then(data => {
                    this.geniusSong = data.response;
                    console.log(data);
                }).catch(error => {
                    // handle error
                    console.error(error);
                });
            } catch (error) {
                console.error(error);
            }
        }
    },

    computed: {},
};
</script>
<template>
    <div>
        <div class="mx-auto mt-5 bg-base-50 p-3 br-10 f aic mw-900">
            <div class="x">
                <p class="mb-0">Search genius</p>
                <input class="px-3 py-2 text-base-900 x mb-3" style="border:1px solid rgba(0,0,0,0.2)" @keydown.enter="searchGenius()" v-model="search.genius">
            </div>
            <div>
                <button-primary @click="searchGenius()" class="bg-base-900 py-0 f-15" text="Search Genius"></button-primary>
            </div>
        </div>
        <div class="p-3 text-center">
            <button-primary @click="requestSongUrl('Lana Del Rey', 2);" class="mw-500 mx-auto" text="Fetch Songs"></button-primary>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-6">
                    <div v-for="song in songs">
                        <span v-if="song && song.title">{{ song.title }}</span>
                        <dov v-else>
                            <div class="d-flex ais py-3">
                                <div class="mr-2">
                                    <img class="d-block c-100" v-if="song.result.primary_artist.image_url" :src="song.result.primary_artist.image_url">
                                </div>
                                <div class="line-height-small x" @click.prevent="getSong(song.result.api_path)">
                                    <p class="f-24 fwb mb-1 text-base-900">{{ song.result.title }}</p>
                                    <p class="d-block mb-2 fwb text-base-900" v-if="song.result.primary_artist.name">{{ song.result.primary_artist.name }}</p>
                                </div>
                                <div>
                                    <img class="d-block x width-max-100" v-if="song.result.song_art_image_url" :src="song.result.song_art_image_url">
                                </div>
                            </div>
                        </dov>
                    </div>
                </div>
                <div class="col-6 mt-5" v-if="geniusSong">
                    {{ geniusSong.song.description.plain }}
                    <template v-for="item in geniusSong.song.custom_performances">
                        <span class="d-block">
                            {{ item.label }} - {{ item.artists[0].name }}
                        </span>
                    </template>
                </div>

            </div>
        </div>
    </div>
</template>
<style lang="scss">
</style>
