<script>
export default {
    data: () => ({}),
    props: ['simple'],
    mounted() {},
    methods: {},
    computed:{

    }
};
</script>
<template>
    <div class="text-left">
        <div v-if="promptData && simple" class="text-left prompt" style="color:white!important;" v-html="visiblePrompt"></div>
        <div v-else-if="promptData" class="text-left prompt" v-html="visiblePrompt"></div>
<!--        <p v-if="promptText" class=" text-left f-11 py-2 white mb-0">{{ promptText.raw }}</p>-->
    </div>
</template>
<style lang="scss" scoped>
//p{
//    font-size: calc(1vw + 10px);
//}
</style>
