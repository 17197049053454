<script>

export default {
    data: () => ({
        loading: true,
    }),
    mounted(){
        this.scrollChatItem();
    },
    props: ['text', 'index'],
};
</script>
<template>
    <transition enter-active-class="swingInUp ease-back duration-5 animated" leave-active-class="ease-back duration-5 fadeOutDown animated">
        <div class="x f aic jcs mb-5 px-4 p-relative">
            <div class="d-block">
                <div class="spinner mr-5 cursor-pointer mt-0 hover:bg-base-100 hover:text-bubble-900 border-light text-bubble-50 bg-base-500 br-10 d-inline-flex mw-450" style="padding:unset;padding:.9rem 0.8rem!important; border-radius:1.2rem; margin:unset;white-space:pre-wrap;width:unset;">
                    <div class="bounce1"></div>
                    <div class="bounce2"></div>
                    <div class="bounce3"></div>
                </div>
            </div>
            <!--        <button href="#" class="white" @click.prevent="loading = !loading">Test</button>-->
        </div>
    </transition>
</template>
<style lang="scss">
</style>
