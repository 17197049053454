<template>
    <div>
        <div v-if="document && document.theme && document.theme.colors"
            class="f-11 line-height-smaller f aic jcs order-lg-1 order-1"
        >
            <div class="f fc gap-1 pr-2">
                <div
                    v-if="document.theme.colors.primary"
                    :style="`background:${document.theme.colors.primary}`"
                    class="c-10"
                ></div>
                <div
                    v-if="document.theme.colors.secondary"
                    :style="`background:${document.theme.colors.secondary};`"
                    class="c-10"
                ></div>
            </div>
            <span
                v-if="document.theme.why"
                :class="{ 'text-base-100': document.theme.colors }"
                class="x f-11 line-height-smaller d-block"
                >{{ document.theme.why }}</span
            >
        </div>
<!--        <template v-else>-->
<!--            <span>We're getting your colors!</span>-->
<!--        </template>-->
    </div>
</template>
<script>
export default {
    name: 'PresentationThemeInfo',
    props: {
        document: {},
    },
}
</script>
