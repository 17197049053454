<template>
    <div
        :ref="`slide-${id}${index}`"
        :key="`slide-${id}${index}`"
        :class="slideContainerClasses"
        class="x vh-70 p-relative">
        <div :ref="`image-${id}${index}`" class="col-md-6 x sticky-top" :class="randomImageStyle">
            <PresentationImageContainer
                :document="document"
                :document-id="documentId"
                :image-dark="imageDark"
                :index="index"
                :inset="inset"
                :section="section"></PresentationImageContainer>
        </div>
        <div class="col-md-6 x text-left">
            <div :ref="`text-${id}${index}`" class="f aic jcs y z-5 px-xl-7 p-lg-5 py-md-8 p-3">
                <PresentationSlideText
                    :index="index"
                    :document="document"
                    :document-id="document.id"
                    :image-dark="imageDark"
                    :section="section"></PresentationSlideText>
            </div>
        </div>
    </div>
</template>
<script>
import PresentationImageContainer from "@/components/Presentations/slides/PresentationImageContainer.vue";
import PresentationSlideText from "@/components/Presentations/slides/PresentationSlideText.vue";
import SlideStyleMixin from "@/mixins/slideStyleMixin";

export default {
    components: { PresentationImageContainer, PresentationSlideText },
    mixins: [SlideStyleMixin],
    props: {
        id: {},
        index: {},
        section: {},
        document: {},
        documentId: {},
        slideContainerClasses: {},
    },
    watch: {
        document: {
            handler: function (val, oldVal) {
                if (val.sections !== oldVal.sections) {
                    let t = val.theme.colors || false;
                    if (t) this.textTheme();
                }
            },
            deep: false,
        },
    },
    data: () => ({
        imageDark: false,
        inset: false,
    }),
    computed: {
        randomImageStyle() {
            let inset = Math.random() < 0.5;
            if (inset) {
                this.inset = true;
            }
            return inset ? this.imageInset : this.imageFullbleed;
        },
        imageInset() {
            return "p-3 py-xl-5 pl-xl-5";
        },

        imageFullbleed() {
            return "p-0";
        },
    },
    mounted() {
        this.slideColors();
        if (this.document.theme) {
            let t = this.document.theme.colors || false;
            if (t) this.textTheme();
        }
    },
};
</script>
