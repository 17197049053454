<script>
export default {
    data: () => ({

    }),
    props: [],
    mounted() {},
    methods: {},
};
</script>
<template>
    <transition duration="2000">
        <div class="f x y aic jcc transition-all animate slow-animation vh-100">
            <div class="animated pulse slow-animation infinite d-block white">Loading ..</div>
        </div>
    </transition>
</template>
<style lang="scss" scoped>
</style>
