<script>
import ButtonIconWhite from "@/components/buttons/buttonIconWhite.vue";

export default {
    components: {ButtonIconWhite},
    data: () => ({
        debug: false,
    }),
    props: ['open'],
    mounted() {},
    methods: {},
};
</script>
<template>
    <div class="p-relative mw-400" v-if="!mobile">
        <div class="p-absolute right top-10 -right-20 p-3">
            <button-icon-white  @click.prevent="debug = !debug" :state-bool="debug" icon="cog"></button-icon-white>
        </div>
        <div v-if="!mobile && debug" class="x  p-0 scrollSection p-3 bg-blur">
            <div class="f-13">
                <div v-if="debug || open" class="">
                    <div class="d-block mt-5 fadeInUpSmooth duration-4 d-5 animated mw-500" style="white-space:pre-line;">
                        <p class="mb-0 fwb">Song Prompt</p>
                        <p v-if="promptText.raw">{{ promptText.raw }}</p>
                    </div>
                    <div class="d-block mt-5 fadeInUpSmooth duration-4 d-5 animated mw-500" style="white-space:pre-line;">
                        <p class="mb-0 fwb">Concept Prompt</p>
                        <p v-if="songConceptPromptFormatted">{{ songConceptPromptFormatted }}</p>
                    </div>
                    <div class="d-block mt-5 fadeInUpSmooth duration-4 d-5 animated mw-500" style="white-space:pre-line;">
                        <p class="mb-0 fwb">Song Title Prompt</p>
                        <p v-if="songConceptPromptFormatted">{{ songTitlePromptFormatted }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
</style>
