<template>
    <div>
        <img v-if="imageUrl" :src="imageUrl" alt="image" class="w-100">
        <button @click="generateImage()">Generate Image</button>
    </div>
</template>

<script>
import axios from 'axios';
import firestore, {db} from '@/firebase';
import firebaseApp from "@/firebase";
import {getStorage, ref, uploadBytes, uploadString, getDownloadURL} from "firebase/storage";

export default {
    name: 'DalleImage',
    data() {
        return {
            imageUrl: ''
        };
    },
    mounted() {

    },
    methods: {

        async generateImage() {
            const apiKey = import.meta.env.VITE_OPENAI_API;
            const organization = import.meta.VITE_OPENAI_ORG;
            const response = await openai.createImage({
                prompt: "a golden goose",
                n: 1,
                response_format: 'b64_json',
                size: "512x512",
            });
            console.error(response);
            this.imageUrl = response.data.data[0].url;
            this.$nextTick(() => {
                if (response.data.data[0].url) {
                    this.uploadImage(response.data.data[0].url, 'url');
                } else {
                    this.uploadImage(response.data.data[0].b64_json, 'b64_json');
                }

            });
        },
        getImageURLFromFirebase(ref) {
            getDownloadURL(ref).then((url) => {
                this.imageUrl = url;
                return;
            }).catch((error) => {
                console.error(error);
                return;
            });
        },
        async uploadImage(file, kind) {
            let filename = `images/image-${this.randomId()}.png`;

            const apiKey = import.meta.env.VITE_OPENAI_API;
            let blob;
            if (kind === 'url') {
                const imageUrl = file;
                filename = `images\/image-url-${this.randomId()}.png`;
                blob = fetch(file, {
                    method: 'GET',
                    mode: 'no-cors',
                    accept: 'image/avif,image/webp,image/apng,image/svg+xml,image/*,*/*;q=0.8',
                    headers: {
                        'Accept-Encoding': 'gzip, deflate, br',
                        'Accept-Language': 'en-US,en;q=0.9',
                        'Cache-Control': "no-cache",
                        'Connection': "keep-alive",
                        'DNT': 1,
                        'Host': "oaidalleapiprodscus.blob.core.windows.net",
                        'Pragma': "no-cache",
                        'Referer': "http://localhost:3000/",
                        'Sec-Fetch-Dest': "image",
                        'Sec-Fetch-Site': 'cross-site',
                        'Content-Type': 'image/png',
                        'Authorization': `Bearer ${apiKey}`
                    }
                }).then(r => r.blob());
            } else {
                blob = await fetch(`data:image/png;base64,${file}`).then(r => r.blob());
                filename = `images/image-blob-${this.randomId()}.png`;
            }
            const metadata = {
                contentType: 'image/png',
            };

            const storage = getStorage();
            const imagesRef = ref(storage, filename);
            if (kind === 'url') {
                console.error('Is Url Image');
                let imageBlob = uploadBytes(imagesRef, blob, metadata).then((snapshot) => {
                    console.log('Uploaded a blob or file!');
                    console.error(snapshot);
                    this.getImageURLFromFirebase(imageBlob);
                    console.error(this.getImageURLFromFirebase(imageBlob));
                    return snapshot;
                });
                console.error(imageBlob);

                let url = await getDownloadURL(imageBlob.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                });
                console.error(url);
                return url;
            } else {
                console.error('Is Base54 Image');
                let base64url = uploadBytes(imagesRef, blob, metadata).then((snapshot) => {
                    console.log('Uploaded a blob or file!');
                    console.error(snapshot);
                    console.error(this.getImageURLFromFirebase(imagesRef));
                    return snapshot;
                });

                console.error(base64url);
                let url = await getDownloadURL(base64url.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                });

                console.error(url);
                return url;
            }


        },

    }
};
</script>
