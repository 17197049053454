<script setup>
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import AdminArtistCard from "@/components/music/AdminArtistCard.vue";
import GenreExploration from "@/components/music/GenreExploration.vue";
import AdminMixins from "@/mixins/AdminMixins";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";
import GenuisSearch from "@/components/music/GenuisSearch.vue";

</script>

<script>
import json from '/public/assets/newGenres.json';

export default {
    data() {
        return {
            pageArtists: [],
            dbGenres: [],
            selectedGenre: {},
            songs: [],
            genRefs: json,
            geniusSong: null,
            apiKey: '4c1202ee47f00a532c6acc0227da3e8d',
            lyrics: null,
            response: null,
            search: {
                artist: null,
                genre: '',
                genius: '',
                title: null,
            }
        };
    },
    async mounted() {
        this.pageArtists = await this.getAnyDBItem('artists');
    },
    mixins: [AdminMixins],
    computed: {
        cleanLyrics() {
            if (this.lyrics) {
                return this.lyrics.replace('******* This Lyrics is NOT for Commercial use *******', '').replace('...', '');
            }
        },
        genreId() {
            let genre = this.genRefs.find(genre => genre.name === this.search.genre);
            if (genre && genre.id) {
                return genre.id;
            }
            return false;
        },
        artistSearch() {
            let artist = this.pageArtists.find(artist => artist.name === this.search.artist);
            if (artist && artist.id) {
                return artist;
            }
            return false;
        },
    },
    methods: {
        async callCharts() {
            let url = `track.search?q_artist=${this.search.artist}&page_size=3&page=1&s_track_rating=desc`;
            this.callMusic(url);
        },

        async searchSong() {
            let url = 'chart.tracks.get?chart_name=top&page=1&page_size=100&country=us&f_has_lyrics=1';
            this.callMusic(url);
        },
        async callMusic(url) {
            const API_KEY = this.apiKey;
            let artistName;
            let songTitle = this.search.title;
            if (this.artistSearch) {
                artistName = '&q_artist='+this.artistSearch.name;
            } else {
                artistName = '&q_artist=Queen';
            }
            if (songTitle) {
                songTitle = '&q_track='+songTitle;
            } else {
                // songTitle = '&q_track=Bohemian Rhapsody';
            }
            const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
            const mainURL = `http://api.musixmatch.com/ws/1.1/`;
            let endpoint = `matcher.lyrics.get?${songTitle}${artistName}`;
            if (url) {
                endpoint = url;
            }
            try {
                const response = await fetch(proxyUrl+mainURL+endpoint+`&apikey=${API_KEY}`); // use the CORS proxy to make the request
                const data = await response.json(); // use await to wait for the JSON data
                this.response = data;
                if (data.message.body.lyrics) {
                    this.lyrics = data.message.body.lyrics.lyrics_body; // store the lyrics in the component's data
                }
            } catch (error) {
                console.error(error);
            }
        },
        async getLyricsGenius() {

        },

    }
};
</script>
<template>
    <div class="pt-5 bg-white scrollSection" style="min-height:100vh;">
                <genuis-search :search="search"></genuis-search>
        <!-- bind the lyrics data property to the template using the `v-html` directive -->
        <div class=" d-block mx-auto p-3">
            <!-- <p> {{ genreId }}</p><p>{{ artistSearch }} </p>-->
            <div class="mx-auto mt-5 bg-base-50 p-3 br-10 f fc aic mw-600">
                <div class="x">
                    <p class="mb-0">Search Lyrics</p>
                    <label>Artist</label>
                    <input class="px-3 py-2 text-base-900 x mb-3" style="border:1px solid rgba(0,0,0,0.2)" @keydown.enter="callMusic()" placeholder="Artist name" v-model="search.artist">
                    <label>Title</label>
                    <input class="px-3 py-2 text-base-900 x mb-3" style="border:1px solid rgba(0,0,0,0.2)" @keydown.enter="callMusic()" placeholder="Song name" v-model="search.title">
                    <label>Genre</label>
                    <div class="text-base-900 x mb-3 p-relative" style="border:1px solid rgba(0,0,0,0.2)">
                        <input @keydown.enter="callMusic()" class="x px-3 py-2 " v-model="search.genre">
                        <span class="p-absolute right top bottom">{{ genreId }}</span>
                    </div>
                    <div>
                        <button-primary @click="callMusic()" class="bg-base-900 py-0 f-15" text="Search Lyrics"></button-primary>
                        <button-secondary @click.prevent="callMusic()" class="bg-base-900 py-0 f-15" text="Get Lyrics"></button-secondary>
                        <button-secondary @click.prevent="callCharts()" class="bg-base-900 py-0 f-15" text="Get Charts"></button-secondary>
                    </div>
                    <div class="mx-auto">
                        <div v-html="cleanLyrics" class="mw-400 mx-auto mt-5 whitespace-pre-line"></div>
                        {{ cleanLyrics }}
                        <div class=" mx-auto mt-5" v-if="geniusSong || response">
                            <template v-if="response && response.message && response.message.body">
                                <div v-for="track in response.message.body.track_list" class="py-2">
                                    <span class="d-block f-17">{{ track.track.album_name }}</span>
                                    <span class="d-block f-11">{{ track.track.artist_name }}</span>
                                    <span class="d-block f-11">{{ track.track.track_name }}</span>
                                    <span class="d-block f-11">{{ track.track.track_rating }}</span>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

            </div>


            <div class="p-3">
                <div class="input-group">
                    <div v-if="artistSearch">
                        <p class="f-24 fwb">{{ artistSearch.name }}</p>
                        <div class="f-11" v-html="$filters.prettyObject(artistSearch)"></div>
                    </div>
                </div>
            </div>

            <!--            <div v-html="response" class="whitespace-pre-line"></div>-->
            <div>


            </div>
            <!--            <genre-exploration></genre-exploration>-->
        </div>
    </div>
</template>
<style lang="scss" scoped>
.scrollSection{
    overflow-y: scroll;
}
</style>
