<template>
    <div class="lighting d-block my-5 mt-5">
        <div class="f x gap-2">
            <div v-for="(style, key, index) in mainArray" class="fwb f-17 my-2">
                <p class="cursor-pointer whitespace-nowrap hover:text-teal-500"
                    :class="{ ' br-10 text-blue bg-snow px-2': selectedIndex === key }"
                    @click.prevent="changeIndex(key)">
                  {{ style.name }}
                </p>
            </div>
        </div>
        <div v-if="mainArray && selectedIndex">
            <div class="f x flex-wrap">
                <ImageGenList
                    v-for="(s, key, index) in stylesToShow"
                    :key="'a' + index"
                    :selected="selected"
                    class="m-0 x mw-200"
                    :kind="kind"
                    :style="s"></ImageGenList>
                <!--            <image-style :style="mainArray[selectedIndex]" kind="lighting" :selected="selected"></image-style>-->
            </div>
        </div>
    </div>
</template>
<script>
import ImageGenList from '@/components/imageGeneration/ImageGenList.vue';
import ImageStyle from '@/components/imageGeneration/ImageStyle.vue';

export default {
    name: 'StyleBookPagenation',
    components: { ImageGenList, ImageStyle },
    props: {
        styles: {},
        kind: {},
        mainArray: {},
        selected: {},
    },
    data: () => ({
        selectedPage: '',
        selectedIndex: null,
    }),
    computed: {
        stylesToShow() {
            // console.error(this.mainArray[this.selectedIndex].styles)
            return this.mainArray[this.selectedIndex].styles;
            // return this.styles[this.selectedIndex].styles
        },
    },
    mounted() {
        // this.selectedIndex = this.mainArray[0].name;
        this.selectedIndex = Object.keys(this.mainArray)[0];
        // console.log(this.mainArray[this.selectedIndex]);
    },
    methods: {
        changeIndex(index) {
            this.selectedIndex = index;
            // console.error(this.selectedIndex);
            // console.error(this.mainArray[this.selectedIndex]);
            // console.error(index);
        },
    },
};
</script>
