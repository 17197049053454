<script setup>
import AdminLyrics from "@/components/music/adminLyrics.vue";
</script>
<script>
import AdminMixins from "@/mixins/AdminMixins.js";
import trainingMixins from "@/mixins/TrainingMixins";
import training from '../../public/assets/training.js';

export default {
    data() {
        return {
            pageArtists: [],
            dbGenres: [],
            songData: training,
            selectedGenre: {},
            fixedJSON: [],
            localArray: [],
            search: '',
            searching: false,
            highlightedIndex: -1,
            searched: null,
        };
    },
    mixins: [trainingMixins, AdminMixins],
    mounted() {
        // this.songData.forEach((songs,index) => {
        //     const songs = this.songData[index].songs;
        //     this.songData[index] = songs.forEach((song,i)=>{
        //         this.songData[index].songs[i].transformedSections = this.transformLyrics(song.lyrics);
        //     })
        // });
        // this.pageArtists = await this.getFilteredArtists('Hard Rock');
        // this.dbGenres = await this.getAnyDBItem('genre', this.dbGenres);
        // this.dbArtists = await this.getAnyDBItem('artist', this.dbArtists);
        // const songDataString = JSON.stringify(this.songData);
        // console.error(songDataString);
        // this.stringFrequencySongChecker();
        // const filtered = this.fixedJSON


        // this.fixedJSON = this.getAnyDBItem('trainingSong', this.fixedJSON);

        // this.fixedJSON = list.filter(artist => artist.name === 'John Mayer');
        this.launchData();
    },
    computed: {
        filteredArtists() {
            if (this.localArray && this.localArray.length && this.localArray.length > 0) {
                return this.localArray.filter(artist => artist.name.toLowerCase().includes(this.search.toLowerCase())).sort((a, b) => {
                    const aIndex = a.name.toLowerCase().indexOf(this.search.toLowerCase());
                    const bIndex = b.name.toLowerCase().indexOf(this.search.toLowerCase());
                    if (aIndex < bIndex) return -1;
                    if (aIndex > bIndex) return 1;
                    return 0;
                });
            }
        },
        filteredCompleteArtists() {
            let search = this.searched;
            if (search && search.length && search.length > 1) {
                return this.fixedJSON.filter(song => song.artist === search);
            } else {
                return this.fixedJSON;
            }
        },
        localArrayComputed() {
            let search = this.searched;
            let results;
            if (search && search.length > 1) {
                let songsArray = [];
                let artistArray = this.localArray.filter(artist => artist.name === search);
                results = artistArray.forEach((artist, index) => {
                    artist.songs.forEach((song, i) => {
                        songsArray.push(song);
                        console.log(song);
                    });

                });
                const artistResults = songsArray;
                const arr1 = artistResults;
                const arr2 = this.filteredCompleteArtists;
                results = arr1.filter(obj => {
                    return !arr2.some(i => i.title === obj.title);
                });
                return results;

            } else {
                results = this.localArray;
            }
            return results;
        }
    },
    methods: {
        async launchData() {
            let list = await this.buildSongDataJson();
            this.localArray = list;
            this.fixedJSON = await this.getAnyDBItem('trainingSong', this.fixedJSON);
        },
        selectHighlighted() {
            if (this.highlightedIndex >= 0) {
                this.search = this.filteredArtists[this.highlightedIndex].name;
                this.searching = false;
            } else {
                this.search = this.search;
            }
            this.searched = this.search;
            this.searching = false;
            this.highlightedIndex = -1;
        },
        highlightPrev() {
            if (this.highlightedIndex > 0) {
                this.highlightedIndex--;
            }
        },
        highlightNext() {
            if (this.highlightedIndex < this.filteredArtists.length-1) {
                this.highlightedIndex++;
            }
        },
        artistSearch(artist) {
            let artistFound = this.pageArtists.find(artist => artist.name === artist);
            if (artistFound && artistFound.id) {
                return artistFound.id;
            }
            return false;
        },
        async buildSongDataJson() {
            const sData = this.songData;
            let arrayFixed = [];
            for (let i = 0; i < sData.length; i++) {
                const artist = sData[i];
                let artistFixed = artist;
                let songs = artist.songs;
                let songsFixedArray = [];
                for (let j = 0; j < songs.length; j++) {
                    const song = songs[j];
                    let sectionsFixed = this.transformLyrics(song.lyrics);
                    let plainText = this.transformplainTextLyrics(song.lyrics);
                    plainText = `Written by: ${song.artist}\nTitle: ${song.title}\n`+plainText;
                    let songFixed = {
                        sections: sectionsFixed,
                        "title": song.title,
                        "artist": song.artist,
                        "plainText": plainText
                    };
                    if (song.id) {
                        songFixed.id = song.id;
                    }
                    songFixed.title = song.title;
                    songsFixedArray.push(songFixed);
                    // console.log(JSON.stringify(songFixed));
                }
                artistFixed.songs = songsFixedArray;
                arrayFixed.push(artistFixed);
            }
            return arrayFixed;
        },
        getStructuredSection(str) {
            str = str.toLowerCase();
            let ending = ': ';
            if (str.includes("pre-chorus")) {
                return "Pre-Chorus"+ending;
            } else if (str.includes("chorus")) {
                return "Chorus"+ending;
            } else if (str.includes("intro")) {
                return "Intro"+ending;
            } else if (str.includes("verse")) {
                return "Verse"+ending;
            } else if (str.includes("bridge")) {
                return "Bridge"+ending;
            } else if (str.includes("interlude")) {
                return "Interlude"+ending;
            } else if (str.includes("outro")) {
                return "Outro"+ending;
            } else if (str.includes("segue")) {
                return "Segue"+ending;
            } else if (str.includes("refrain")) {
                return "Refrain"+ending;
            } else if (str.includes("solo") || str.includes("break")) {
                return "Solo"+ending;
            } else if (str.includes("Back")) {
                return "Backing Vocals"+ending;
            } else if (str.includes("spoken")) {
                return "Spoken"+ending;
            }
            // If none of the keywords are found, return the original string
            return `${str.capitalize}: `;
        },
        fixSongs(song) {
            return this.transformLyrics(song.lyrics);
        },
        stringFrequencySongChecker() {
            let stringArray = [];
            this.songData.forEach((artist) => {
                artist.songs.forEach((song) => {
                    stringArray.push(song.lyrics);
                    // console.error(song.lyrics);
                });
                // let songs = JSON.stringify(artist.songs);
                // this.stringFrequency(songs);
            });

            this.stringFrequency(stringArray);
            // const songString = JSON.stringify(song);
            // this.stringFrequency(songString);
        },
        stringFrequency(stringArray) {
            // Initialize a frequency counter for the strings
            let stringCounts = {};

            // Iterate over the data
            for (const item of stringArray) {
                // Check if the item is a string
                if (typeof item === "string") {
                    // Iterate over each word in the string
                    let words = item.split("\n");

                    for (const word of words) {
                        // Increment the count for the word
                        if (stringCounts[word]) {
                            stringCounts[word]++;
                        } else {
                            stringCounts[word] = 1;
                        }

                        // Iterate over the words in the string to check for clusters
                        let wordCluster = word.split(" ");
                        for (let i = 0; i < wordCluster.length-1; i++) {
                            // Create a new string of the two adjacent words
                            let newString = wordCluster[i]+" "+wordCluster[i+1];

                            // Increment the count for the new string
                            if (stringCounts[newString]) {
                                stringCounts[newString]++;
                            } else {
                                stringCounts[newString] = 1;
                            }
                        }
                    }
                }
            }

            // Set a threshold value for determining abnormally frequent strings
            const threshold = 9;

            // Iterate over the string counts
            for (const [string, count] of Object.entries(stringCounts)) {
                // Check if the count is above the threshold
                if (count > threshold) {
                    if (string.match(/\s.+\s./)) {
                        // if (string.includes(" ") || string.includes("\n") && string.length > 10) {
                        console.error(`${count} - ${string}`);
                    }
                }
            }
        },
        transformLyrics(inputStr) {
            // Find the index of the first [ character
            const index = inputStr.indexOf('[');
            // Extract the part of the string starting from the [ character
            const stripTitle = inputStr.substring(index);
            const stripMightLike = stripTitle.replace("You might also like", '');
            const stripEmbed = stripMightLike.replace(/(.*)Embed/, '');
            const bridgeFix = stripEmbed.replace("[Bridge III]", '[Bridge]');
            const questionFix = bridgeFix.replace("[?]", '[Bridge]');
            const s139 = questionFix.replace("See Weezer LiveGet tickets as low as $139", '');
            const liveTix = s139.replace(/See (.*) LiveGet tickets as low as \$(.*)/, '');
            // const liveTix = s139.replace("See Weezer LiveGet tickets as low as $", '');

            // Split the stripped string into lines
            const lines = liveTix.split('\n');

            const sections = [];

            let currentSection = {lyrics: ''};

            for (const line of lines) {
                // Check if the line is a section title
                const sectionMatch = line.match(/^\[(.+)\]$/);
                if (sectionMatch) {
                    // If the current section has a section title or lyrics, add it to the sections array
                    if (currentSection.section || currentSection.lyrics) {
                        sections.push(currentSection);
                    }
                    // Create a new section with the section title
                    currentSection = {section: this.getStructuredSection(sectionMatch[1]), lyrics: ''};
                } else {
                    // If the line starts with "You might also like", ignore it
                    if (!line.startsWith('You might also like')) {
                        // Otherwise, add the line to the current section's lyrics
                        currentSection.lyrics += `${line}\n`;
                    }
                }
            }

            // Push the last section, if it has a section or lyrics
            if (currentSection.section || currentSection.lyrics) {
                // Remove the "Embed" part from the last line
                currentSection.lyrics = currentSection.lyrics.replace(/Embed$/, '');
                sections.push(currentSection);
            }

            return sections;
        },
        transformplainTextLyrics(inputStr) {
            // Find the index of the first [ character
            const index = inputStr.indexOf('[');
            // Extract the part of the string starting from the [ character
            const stripTitle = inputStr.substring(index);
            const stripMightLike = stripTitle.replace("You might also like", '');
            const bridgeFix = stripMightLike.replace("[Bridge III]", '[Bridge]');
            const questionFix = bridgeFix.replace("[?]", '[Bridge]');
            const liveTix = questionFix.replace(/See (.*) LiveGet tickets as low as \$(.*)/, '');
            const embed = liveTix.replace(/(.*)(.*)Embed/, '');
            const lines = embed.split('\n');
            let currentSection = '';
            let songStructure = '';
            for (const line of lines) {
                let sectionMatch = line.match(/^\[(.+)\]$/);

                // const sectionMatch = this.getStructuredSection(sectionMatch[1]);
                if (sectionMatch) {
                    const bracketString = stripTitle.replace(/\[(.*)\]/, '$1');
                    const properWord = this.getStructuredSection(sectionMatch[1]);
                    // let secondTitle = this.getStructuredSection(sectionMatch[1]);
                    currentSection = `\n`+properWord+`\n`;
                } else {
                    const cleanLine = line.replace(/(.*)\\n/, '$1');
                    let cleanerLine = cleanLine.replace(/(.*)\\n/, '$1');
                    currentSection = cleanerLine+`\n`;
                    // currentSection = line+`\n`;
                }
                songStructure += currentSection;
            }
            return songStructure;
        }
    }
};
</script>
<template>
    <div class=" bg-white">
        <div class="x">
            <div class="x scrollSection py-8 px-0 overflow-x-hidden">
                <div class="container-fluid p-5 x" style="box-sizing: border-box;" v-if="filteredCompleteArtists">
                    <div class="row no-gutters my-3 px-3">
                        <admin-lyrics class="col-12 col-md-6 col-lg-4 col-xl-3 col-xxl-3 p-3" v-for="(song,index) in filteredCompleteArtists" :key="randomId()" :song="song"></admin-lyrics>
                    </div>
                </div>
                <template v-if="searched && search">
                    <div class="container-fluid p-5 x" style="box-sizing: border-box;">
                        <div class="row no-gutters my-3 px-3">
                            <admin-lyrics v-for="artist in localArrayComputed" class="col-12 col-md-6 col-lg-4 col-xl-3 col-xxl-3 p-3" :song="artist"></admin-lyrics>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="container-fluid p-5 x" style="box-sizing: border-box;" v-for="artist in localArrayComputed">
                        <div class="row no-gutters my-3 px-3" v-if="artist && artist.songs">
                            <admin-lyrics class="col-12 col-md-6 col-lg-4 col-xl-3 col-xxl-3 p-3" v-for="song in artist.songs" :song="song"></admin-lyrics>
                        </div>
                    </div>
                </template>
            </div>
        </div>
        <div class="p-fixed top mx-auto z-5 mt-3 justify-fixed-center x mw-450" style="">
            <input @click="searching = true" class="chatInput bg-white mb-0 mw-650 x" v-model="search" type="text" @keydown.up="highlightPrev" @keydown.enter="selectHighlighted" @keydown.down="highlightNext"/>
            <div v-if="searching && search.length > 0">
                <div v-if="(filteredArtists && filteredArtists.length > 0 && search.length > 0) && searching" class="border-light br-10 shadow">
                    <div v-for="(artist,index) in filteredArtists" class="bg-snow p-2 f-17" :class="{ 'highlighted': index === highlightedIndex }" :key="artist" @click.prevent="searched = artist.name,searching=false">{{ artist.name }}</div>
                </div>
            </div>
        </div>

    </div>
</template>
<style>
.highlighted {
    background-color: #eee;
}
</style>
