<script>
export default {
    data: () => ({}),
    props: ['text', 'small', 'icon', 'loadingState', 'color'],
    mounted() {},
    computed: {
        btnColor() {
            let buttonColor = "255,255,255";
            if (this.color) {
                buttonColor = this.color;
            }
            return {
                text: `color:rgba(${buttonColor},1)!important;`,
                outline: `border-color:rgba(${buttonColor},0.3)!important;`
            };
        }
    },
};
</script>
<template>
    <button v-if="small" class="btn-outline form" href="#" style="border:1px solid rgba(255,255,255,0.3)" :class="{'bg-base-500 disabled':loadingState}">{{ text }}</button>
    <button v-else-if="!small" class="btn-outline" href="#" style="border:2px solid rgba(255,255,255,0.3);" :class="[{'bg-base-500 disabled':loadingState},{'pr-3 pl-2':icon,'px-3':!icon}]">
        <i v-if="icon" :class="`fa fa-${icon} mr-2 fal f-20 mr-2 line-height-smaller d-block o-5 text-bubble-800 `" aria-hidden="true"></i>
        {{ text }}
    </button>
</template>
<style lang="scss" scoped>
@import "../../../public/assets/main";

.btn-outline{
    @extend .btn!optional;
    @extend .btn-outline-white!optional;
    @extend .my-1!optional;

    .form{
        @extend .btn !optional;
        @extend .btn-outline-white!optional;
        @extend .btn!optional;
        @extend .f-11!optional;
        @extend .px-3!optional;
        @extend .py-2!optional;
        @extend .my-1!optional;
    }
}
</style>
