<script>
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";
import {collection, addDoc, doc, deleteDoc, getDoc} from "firebase/firestore";
import {db} from "@/firebase";
import ButtonBubbleArray from "@/components/buttons/ButtonBubbleArray.vue";

// Get a reference to the document

export default {
    components: {ButtonBubbleArray, ButtonPrimary, ButtonSecondary},
    async mounted() {
        // this.pageArtists = this.getAnyDBItem('artists', 'pageArtists');
        await this.loadArtists();
    },
    data() {
        return {
            debug: false,
            tempArtist: null,
            pageArtists: null,
        };
    },
    methods: {
        async loadArtists() {
            const genre = this.promptData.genre;
            const artists = await this.getArtistsByGenre(genre.name);
            setTimeout(() => {
                this.pageArtists = [];
                this.pageArtists = artists;
            }, 700);

        },
        // checkName(name) {
        //     return this.pageArtists.find(object => object.name === name);
        // },
        setSongTitle(suggestion) {
            // this.$emit('setSongTitle', suggestion);
            this.promptData.name = suggestion;
            console.log(suggestion);
        },
        requestTitles() {
            // this.$emit('requestSongTitles');
            this.requestSongTitles();
        },
        setSongAbout(suggestion) {
            this.promptData.topic = suggestion;
        },
        requestLyrics() {
            const promptText = this.promptText.raw;
            this.getLyrics(promptText);
        }
    },
    computed: {},
};
</script>
<template>
    <div v-if="step === 2" class="animated fadeInUpSmooth ease-back d-5">
        <h1 class="text-bubble-900 mx-auto text-center animated fadeInUpSmooth d-3 duration-3 mb-5">
            <span class="floating-item d-block">Choose a {{ promptData.genre.name }} artist</span>
        </h1>

        <div v-if="!pageArtists" class="vh-30 x aic jcc f white animated pulse infinite slow-animation">
            <h3 class="floating-item d-block">We're bringing the goods</h3>
        </div>
        <ul class="mw-1400 text-center animated fadeInUpSmooth d-5 duration-3" v-if="pageArtists">
            <template v-for="(artist,index) in pageArtists.reverse()">
                <button-bubble-array @click.prevent="setArtist(artist),nextStep()" :text="artist.name" :index="index+1" appear></button-bubble-array>
            </template>

            <div v-if="pageArtists && pageArtists.length > 0" :style="`animation-delay:${pageArtists.length*20}ms`" class="animated fadeInUpSmooth duration-5 d-inline-block" appear>
                <div class="floating-item mt-3 f  aic jcs pl-2 pr-3 f-15 f-md-24" style="border-radius:50px;border:2px solid rgba(255,255,255,0.3);background:none;color:white;">
                    <p class="mb-0 line-height-smaller fwb f-11">Other</p>
                    <input v-model="tempArtist" placeholder="Someone else" style="::placeholder:white;background:none;border:none;color:white;font-weight:500;">
                    <div @click.prevent="setArtistCustom(tempArtist),nextStep()">
                        <i aria-hidden="true" class="fa fa-arrow-right"></i>
                    </div>
                </div>
            </div>
        </ul>

    </div>
</template>
<style lang="scss">
input::placeholder{
    color: rgba(255, 255, 255, 0.5);
    font-weight: 400;
}
</style>
<!--                            <template v-for="(artist,index) in artists">-->
<!--                                <div :class="`d-${index+1}`" :style="`animation-delay:${index*15}ms`" class="animated fadeInUpSmooth animation-swing d-inline-block">-->
<!--                                    <div :style="`animation-delay:${10 * index}ms; animation-duration:${2000+(index*50)}ms`" class="floating-item">-->
<!--                                        <button-secondary :text="artist.name" class="m-1 f-24" @click.prevent="promptData.artists = artist.name,step += 1"></button-secondary>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </template>-->


<!--                <div  :style="`animation-delay:${index*15}ms`" class="animated fadeInUpSmooth animation d-inline-block">-->
<!--                    <div :style="`animation-delay:${20 * index}ms; animation-duration:${2000+(index*50)}ms`" class="floating-item">-->
<!--                        <button-secondary :text="artist.name" class="m-1 f-17 f-md-24" ></button-secondary>-->
<!--                    </div>-->
<!--                </div>-->
