// import * as dotenv from 'dotenv'
// dotenv.config();
// import express from 'express';

import axios from "axios";
import { ReCaptchaV3Provider } from "firebase/app-check";
import moment from "moment";
import drag from "v-drag";
import { createApp } from "vue";
import contenteditable from "vue-contenteditable";
import { createRouter, createWebHistory } from "vue-router";
import VueScrollmagic from "vue-scrollmagic";
import JsonViewer from "vue3-json-viewer";
import draggable from "vuedraggable";
import {
    VueFire,
    VueFireAuth,
    VueFireAppCheck,
    databasePlugin,
    VueFireFirestoreOptionsAPI,
    VueFireDatabaseOptionsAPI,
    getCurrentUser,
} from "vuefire";
import { createStore } from "vuex";

import App from "./App.vue";
import firebaseApp from "./firebase";
import ChatMixin from "./mixins/chatMixins.js";
import FirebaseMixin from "./mixins/firebase/firebaseMixin2.js";
import DataMixin from "./mixins/firebase/mixinData.js";
import MyMixin from "./mixins/mixins.js";
import AIMixin from "./mixins/openAIMixins.js";
import PromptMixin from "./mixins/promptMixins.js";
import openai from "./openai";
import { routes } from "./routes.js";
import Store from "./store.js";

import themeMixins from "@/mixins/themeMixins";

// const drag = require("v-drag");

const store = createStore(Store);
// app.prototype.$http = axios;

const app = createApp(App);

app.use(VueFire, {
    // imported above but could also just be created here
    firebaseApp,
    modules: [
        // we will see other modules later on
        // VueFireAuth(),
        VueFireFirestoreOptionsAPI({
            reset: true,
            wait: true,
        }),
        // to use the `firebase` option
        VueFireDatabaseOptionsAPI(),
    ],
});
// app.use(openai);
app.use(drag, {
    // global configuration
});
// app.use(BootstrapVue);

// import '../public/assets/main.scss'
app.config.productionTip = false;

// app.use(contenteditable)
app.use(JsonViewer);
app.use(databasePlugin);
// app.use(Markdown);

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// Vue.config.globalProperties.$mobile = false

// app.use(response)
// app.use(database);
// const root = app.getRoot();

app.mixin(MyMixin);
app.mixin(DataMixin);
app.mixin(PromptMixin);
app.mixin(FirebaseMixin);
app.mixin(AIMixin);
app.mixin(ChatMixin);
app.mixin(themeMixins);

app.use(router);
// app.use(firebaseApp);
app.use(store);

// app.config.globalProperties.appName = 'app';

app.config.globalProperties.$filters = {
    prettyObject(object) {
        let template = ``;
        Object.keys(object).forEach((key, value) => {
            let objHtml;
            if (object[key] && typeof object[key] === "object") {
                objHtml = `<div class="d-block" data-toggle="collapse" aria-expanded="true"><span class="fwb mr-2">${key}:</span></div>`;
                if (Object.keys(object[key].length > 0)) {
                    Object.keys(object[key]).forEach((childKey, value) => {
                        var childObjHtml = `<div  class=" d-block ml-2 f-15 border-left pl-3"><span class="fwb mr-2">${childKey}:</span> ${object[key][childKey]}</div>`;
                        objHtml = objHtml + childObjHtml;
                    });
                }
            } else {
                objHtml = `<div class="d-block"><span class="fwb mr-2">${key}:</span> ${object[key]}</div>`;
            }
            template = template + objHtml;
        });
        return template;
    },
    timeAgo(date) {
        moment.locale("en", {
            relativeTime: {
                future: "in %s",
                past: "%s ago",
                s: "seconds",
                ss: "%ss",
                m: "a minute",
                mm: "%dm",
                h: "an hour",
                hh: "%dh",
                d: "a day",
                dd: "%dd",
                M: "a month",
                MM: "%dM",
                y: "a year",
                yy: "%dY",
            },
        });
        let relDate = moment(date, "YYYYMMDD");
        var currentTime = moment();
        var minutes = currentTime.diff(relDate, "minutes");
        let text;
        // If less than 60 minutes have passed, display the timestamp in a relative format
        if (minutes < 120) {
            var timeAgoString = relDate.fromNow();
            text = timeAgoString;
        } else if (minutes < 180) {
            var timeAgoString = relDate.fromNow();
            var dateString = relDate.format("hh:mm a");
            // text = timeAgoString+' '+dateString;
            text = dateString;
        } else if (minutes < 1440) {
            var timeAgoString = relDate.fromNow();
            var dateString = relDate.format("hh:mm a");
            text = "Today at " + dateString;
        } else {
            var dateString = relDate.format("MMM D, YYYY");
            text = dateString;
        }
        return text;
        // return relDate;
    },
};
// app.config.errorHandler = (err, instance, info,error) => {
//     console.error(info);
//     console.error(instance);
//     console.error(err);
//     store.commit('pushAlert', {type: err, message: instance});
//     console.trace()
// }
// app.config.warnHandler = (msg, instance, trace) => {
//     // `trace` is the component hierarchy trace
//     console.log(msg);
//     console.log(instance);
//     // console.log(trace);
//
// }
function debounce(func) {
    var timer;
    return function (event) {
        if (timer) clearTimeout(timer);
        timer = setTimeout(func, 200, event);
    };
}

let windowWidth = window.innerWidth;
if (windowWidth < 600) {
    console.log("make mobile");
    store.commit("setMobile", true);
} else {
    console.log("make desktop");
    store.commit("setMobile", false);
}

window.addEventListener(
    "resize",
    debounce(() => {
        console.log("resized to " + window.innerWidth);
        let windowWidth = window.innerWidth;
        if (windowWidth < 600) {
            console.log("make mobile");
            store.commit("setMobile", true);
        } else {
            console.log("make desktop");
            store.commit("setMobile", false);
        }
    }),
);

app.mount("#app");

window.app = app;

const appHeight = () => {
    const doc = document.body;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
};
window.addEventListener("resize", appHeight);
appHeight();

app.directive("delay", {
    bind(el, binding) {
        const duration = binding.value || 500;
        const elements = el.querySelectorAll(binding.arg);

        elements.forEach((element, index) => {
            element.style.animationDelay = index * duration + "ms";
        });
    },
});
