<script>
import ClosingSection from "@/components/elements/ClosingSection.vue";

export default {
    components: {ClosingSection},
    props:['title','item'],
    created() {

    },
    data() {
        return {
            debug: false,
        };
    },
    mounted() {
    },

    watch: {},
    methods: {},
    computed: {},
};
</script>
<template>
    <div class="pb-4 x mw-600 mx-auto px-2 fadeInUpSmooth ease-back duration-5  stagger-2">
        <h4 class="text-base-200 animated  fadeInUpSmooth ease-back duration-5  stagger-2" v-if="item.promptData.name">"{{ item.promptData.name }}"</h4>
        <h4 class="text-base-200 animated  fadeInUpSmooth ease-back duration-5  stagger-2" v-else-if="title">{{ title }}</h4>
<!--        <p class="f-24 d-block mw-600 animated fadeInUpSmooth duration-1 d-4 mb-0" v-if="item.promptData.name">"{{ item.promptData.name }}"</p>-->
        <p class="d-block mw-600 animated  fadeInUpSmooth ease-back duration-5 stagger-4 d-6 mb-0" v-if="item">{{ item.promptData.topic }}</p>
    </div>

</template>
<style lang="scss">
</style>
