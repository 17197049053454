<template>
    <a class="" href="#" @click.prevent="$emit('startNewDeck')">{{ text }}</a>
</template>
<script>
export default {
    name: "start-deck-button",
    emits: ["start-new-deck"],
    methods: {
        startNewDeck() {
            this.$emit("startNewDeck");
        },
    },
    props: {
        text: {
            type: String,
            required: true,
            default: "New Deck",
        },
        // startNewDeck: {}
    },
};
</script>
