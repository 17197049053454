export default {
    created: function () {},
    data: () => ({
        // artist: null,
    }),
    // async mounted() {
    //     if (this.document) {
    //         const document = this.document;
    //         if (document.theme) {
    //             let t =this.document.theme.colors || false;
    //             if (t) {
    //                 this.textTheme();
    //             }
    //             if (document) {
    //                 if (this.section && this.section.style) {
    //                     this.blockStyle = this.slideStyle(this.section);
    //                 } else if (this.slide && this.slide.style) {
    //                     this.blockStyle = this.slideStyle(this.slide);
    //                 }
    //             }
    //         }
    //     }
    // },
    watch: {},
    computed: {
        shadesBezierDarkPrimary() {
            if (this.document) {
                let t = this.document.theme;
                if (t) {
                    let color = t.colors[0];
                    let variations = [];
                    let hsl = color.match(/\d+/g);
                    let h = hsl[0], s = hsl[1];

                    for (let i = 0; i <= 100; i += 2) {
                        let l = this.easeInOutQuad(i / 100) * 80;
                        variations.push(`hsl(${h}, ${s}%, ${l}%)`);
                    }
                    return variations.slice(12, 17);
                    return variations;
                }
            }
        },
        shadesBezierLightSecondary() {
            if (this.document) {
                let t = this.document.theme;
                if (t && t.secondary) {
                    const color = t.colors[1];
                    let variations = [];
                    let hsl = color.match(/\d+/g);
                    let h = hsl[0], s = hsl[1];

                    for (let i = 0; i <= 100; i += 2) {
                        let l = this.easeInOutQuad(i / 100) * 80;
                        variations.push(`hsl(${h}, ${s}%, ${l}%)`);
                    }
                    return variations.slice(27, 32);
                    // return variations;
                }
            }
        },
        randomLight() {
            let t = this.document && this.document.theme.colors && this.document.theme.colors && this.document.theme.colors.lights;
            if (t) {
                let c = this.document.theme.colors.lights;
                let randomIndex = Math.floor(Math.random() * c.length);
                return c.slice(randomIndex, randomIndex+1);
            }
        },
        randomDark() {
            let t = this.document && this.document.theme.colors && this.document.theme.colors && this.document.theme.colors.darks;

            if (t) {
                let c = this.document.theme.colors.darks;
                let randomIndex = Math.floor(Math.random() * c.length);
                return c.slice(randomIndex, randomIndex+1);
            }
        },
    },
    methods: {

        textTheme() {
            const number = Math.random() < 0.5;
            if (number) {
                this.setText(this.randomDark);
                this.setBackground(this.randomLight);
            } else {
                this.setText(this.randomLight);
                this.setBackground(this.randomDark);
            }
        },
        imageTheme() {
            const number = Math.random() < 0.5;
            if (number) {
                this.imageDark = null;
                this.setImageBg(this.randomLight);
            } else {
                this.imageDark = true;
                this.setImageBg(this.randomDark);
            }
        },
        randomGradient() {
            let secondary = this.shadesBezierLightSecondary;
            let primary = this.shadesBezierDarkPrimary;
            return `linear-gradient(${secondary[3]}, transparent),linear-gradient(to top left, ${this.randomLight}, transparent),linear-gradient(to top right, ${this.randomDark}, transparent);background-blend-mode: screen`;
            // return `linear-gradient(90deg, ${this.randomLight()} 0%, ${this.randomDark()} 100%)`;

        },
        setImageBg(color) {
            let t = this.document.theme && this.document.theme.colors;
            if (t) {
                if (this.$refs[`image-${this.id}${this.index}`]) {
                    this.$refs[`image-${this.id}${this.index}`].style.background = color;
                } else if (this.$refs[`image-sm-${this.id}${this.index}`]) {
                    this.$refs[`image-sm-${this.id}${this.index}`].style.background = color;
                }
            }
        },

        setText(color) {
            let t = this.document.theme && this.document.theme.colors;
            if (t) {
                if (this.$refs[`text-${this.id}${this.index}`]) {
                    this.$refs[`text-${this.id}${this.index}`].style.color = color;
                } else if (this.$refs[`text-sm-${this.id}${this.index}`]) {
                    this.$refs[`text-sm-${this.id}${this.index}`].style.color = color;
                }
            }
        },
        setBackground(color) {
            let t = this.document.theme && this.document.theme.colors;
            if (t) {
                if (this.$refs[`slide-${this.id}${this.index}`]) {
                    this.$refs[`slide-${this.id}${this.index}`].style.background = color;
                } else if (this.$refs[`slide-sm-${this.id}${this.index}`]) {
                    this.$refs[`slide-sm-${this.id}${this.index}`].style.background = color;
                }
            }

        },
        easeInOutQuad(t) {
            return t < 1 ? 3 * t * t : -2+(8-2 * t) * t;
        },


    },
};
