<template>
    <div :ref="`slide-sm-${id}${index}`"
        class="x br-5 f aic jcc br-5 overflow-hidden bg-base-900"
        style="height: 0px; padding-bottom: 60%">
        <img v-if="slide.imageURL"
            :ref="`image-sm-${id}${index}`"
            :src="slide.imageURL"
            class="p-absolute p-fill x y o-cover o-2"
            style="mix-blend-mode: luminosity" />
        <div class="p-absolute p-fill f aic jcc">
            <div :ref="`text-sm-${id}${index}`" class="z-3 p-3 text-center">
                <p v-if="slide.title" class="f-13 fwb line-height-smaller mb-0">{{
                    slide.title
                }}</p>
                <p v-if="slide.body" class="f-11 o-8 mb-0" style="font-size: 8px">{{
                    slide.body
                }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import SlideStyleMixin from '@/mixins/slideStyleMixin';

export default {
    name: 'SlideOtherMini',
    mixins: [SlideStyleMixin],
    props: {
        index: {},
        slide: {},
        document: {},
    },
    mounted() {
        if (this.document.theme) {
            let t = this.document.theme.colors || false;
            if (t) this.textTheme();
        }
    },
    created() {
        this.id = this.randomId();
    },
};
</script>
