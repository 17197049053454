<script>
import "firebase/functions";

import { collection } from "firebase/firestore";
import { useCollection } from "vuefire";

// import firebase from 'firebase/app';
// import 'firebase/firestore'
import imageParams from "../../../public/assets/imageParams.js";

import GeneratedImage from "@/components/imageGeneration/GeneratedImage.vue";
import ImageGenList from "@/components/imageGeneration/ImageGenList.vue";
import StyleBookPagenation from "@/components/imageGeneration/StyleBookPagenation.vue";
import { db } from "@/firebase";
import imageMixin from "@/mixins/imageMixin";

const imageFilters = useCollection(collection(db, "imageStyle"), {
    orderBy: ["created", "asc"],
    fallback: ["created", "desc"],
});
const imageGenerator = useCollection(collection(db, "imageGenerator"), {
    orderBy: ["updated", "desc"],
});

window.imageParams = imageParams;

export default {
    components: { GeneratedImage, StyleBookPagenation, ImageGenList },
    mixins: [imageMixin],
    data: () => ({
        promptMod: "",
        prompp: "",
        imageImg: null,
        ...imageParams,
        imageParams: imageParams,
        promptModifiersPicked: "",
        shotModifiersPicked: "",
        lightModifiersPicked: "",
        styleModifiersPicked: "",
        realismModifiersPicked: "",
        stylizedPicked: "",
        artStylesPicked: "",
        artKeywordListenersPicked: "",
        photographyKeywordListenersPicked: "",
        selectedArray: [],
        firebaseDB: { ...db.imageStyle },
        shotModifier: null,
        styleModifier: null,
        lightModifier: null,
        realismModifier: null,
        inspiration: null,
        committedPrompt: null,
        newArray: null,
        portraitString: null,
        userInput: "",
        shotDetails: {
            medium: null,
            angle: null,
            distance: null,
            focus: null,
            shot: "wide shot",
        },
        responses: [],
        stream: "",
        imgStyles: imageFilters,
        generatedImages: imageGenerator,
    }),
    firestore: {
        imageStyles: collection(db, "imageStyles"),
        // document: getDoc(doc(db, 'documents', route.params.id)),
        // document:useDocument(doc(db, 'settings', this.documentId)),
    },
    computed: {
        promptString() {
            // let detailedString = " Incredibly detailed, lifelike image of ";
            let detailedString = " ";
            // let detailedString2 = "with sharp features. Focus on subject. ";
            let detailedString2 = "with sharp features. Focus on subject. ";
            // let detailWrapper = " 8k/4k resolution, trending on artstation. Perfect composition. ";
            let detailWrapper = " ";
            let selectedLighting = "";
            let selectedEnvironment = "";
            let selectedArt = "";
            if (this.selectedLighting && this.selectedLighting.length > 0) {
                selectedLighting = ` Lighting: ${this.selectedLighting.join(" ")}. `;
            }
            if (this.selectedEnvironment && this.selectedEnvironment.length > 0) {
                selectedEnvironment = ` Scenery: ${this.selectedEnvironment.join(" ")}. `;
            }
            if (this.selectedArtStyle && this.selectedArtStyle.length > 0) {
                selectedArt = `${this.selectedArtStyle.join(" ")}`;
            }
            if (this.selectedArtStyle.length > 0) {
                return (
                    "" +
                    this.shotDetails.angle +
                    " " +
                    this.shotDetails.shot +
                    " " +
                    this.prompp +
                    "." +
                    selectedEnvironment +
                    ". " +
                    selectedLighting +
                    " " +
                    selectedArt
                );
            } else {
                return (
                    "Generate a " +
                    this.shotDetails.angle +
                    " " +
                    this.shotDetails.shot +
                    " " +
                    detailedString +
                    this.prompp +
                    detailedString2 +
                    selectedEnvironment +
                    selectedLighting +
                    detailWrapper
                );
            }

            // return "Create a photograph of "+this.prompp+" using a "+this.shotModifier+" shot with "+this.lightModifier+" and "+this.realismModifier+" and inspiration from "+this.inspiration+". "+this.selectedArray.join(' ');
        },
        negativePrompts() {
            // return " | distorted faces , ugly, disgusting, poorly drawn, childish, mutilated, mangled, old ugly, tiling, poorly drawn hands, poorly drawn face, out of frame, extra limbs, disfigured, deformed, body out of frame, blurry, bad anatomy, blurred, watermark, grainy, signature, cut off, draft: -1";
            return "distorted faces, disgusting, poorly drawn, childish, mutilated, ugly, tiling, weird hands, weird face, bad crop, extra limbs, disfigured, deformed, out of frame, blurry, watermark, signature";
            // oorly drawn feet,subject cut off, weird hands,
        },
        parseTree() {
            let arr = this.imageParams;
            const traverse = (arr, parentId) =>
                arr
                    .filter(node => node.parentId === parentId)
                    .reduce(
                        (result, current) => [
                            ...result,
                            {
                                ...current,
                                children: traverse(arr, current.id),
                            },
                        ],
                        [],
                    );

            const parseTree = arr =>
                arr
                    .sort(({ order }) => order)
                    .filter(({ parentId }) => !parentId)
                    .map(node => ({
                        ...node,
                        children: traverse(arr, node.id),
                    }));
            return parseTree(arr);
        },
        selected() {
            return [].concat(...Object.values(this.$store.state.selectedStyles));
        },
        selectedLighting() {
            return this.$store.state.selectedStyles.lighting;
        },
        selectedEnvironment() {
            return this.$store.state.selectedStyles.scene;
        },
        selectedArtStyle() {
            return this.$store.state.selectedStyles.art;
        },
    },
    async created() {
        await this.imageStyleStore();
    },
    async mounted() {
        // this.imageImg = document.getElementById('imageImg');
        // const imgstyles = await db.collection('imageStyles'))
        // console.error(this.imageStyles);

        // console.error(this.imageStyles)
        let medium = this.getRandomItem(this.artMedium);
        let style = this.getRandomItem(medium);
        this.shotDetails.medium = style;
        this.shotDetails.focus = this.getRandomItem(this.camera.advanced.focus);
        this.shotDetails.angle = this.getRandomItem(this.camera.angles);
        this.shotDetails.shot = "Medium-shot";
        this.newArray = this.imageParams;
    },

    methods: {
        toggleSelection(value, kind) {
            this.$store.commit("removeFromSelection", { value, kind });
        },

        unselect(item) {
            this.toggleSelection(item);
        },
        modifyImage(item) {
            let prompts = [];
            let subjectPrompt;
            if (subject && subject.length > 3) {
                subjectPrompt = {
                    text: subject,
                    weight: 3.0,
                };
                prompts.push(subjectPrompt);
            }
            let environmentPrompt;
            if (selectedEnvironment && selectedEnvironment.length > 3) {
                environmentPrompt = {
                    text: selectedEnvironment,
                    weight: 3.0,
                };
                prompts.push(environmentPrompt);
            }
            let artPrompt;
            if (selectedArt && selectedArt.length > 3) {
                artPrompt = {
                    text: selectedArt,
                    weight: 3.0,
                };
                prompts.push(artPrompt);
            }
            let shotPrompt;
            if (shot && shot.length > 3) {
                shotPrompt = {
                    text: shot,
                    weight: 0.8,
                };
                prompts.push(shotPrompt);
            }
            let lightingPrompt;
            if (selectedLighting && selectedLighting.length > 3) {
                lightingPrompt = {
                    text: selectedLighting,
                    weight: 2.0,
                };
                prompts.push(lightingPrompt);
            }
            let anglePrompt;
            if (shotAngle && shotAngle.length > 3) {
                anglePrompt = {
                    text: shotAngle,
                    weight: 1.0,
                };
                prompts.push(anglePrompt);
            }
            let negativePromptText;
            if (negativePrompts && negativePrompts.length > 3) {
                negativePromptText = {
                    text: negativePrompts,
                    weight: -1,
                };
                prompts.push(negativePromptText);
            }
            let compositionText;
            if (compositionText && compositionText.length > 3) {
                compositionText = {
                    text: "centered subject",
                    weight: 2,
                };
                prompts.push(compositionText);
            }
            let badCrop = {
                text: "bad crop",
                weight: -2,
            };
            prompts.push(badCrop);
            let promptText = "";
            prompts.forEach(prompt => {
                promptText += ` ${prompt.text}:${prompt.weight} | `;
            });
        },
        async genImageSD(prompt) {
            let selectedLighting = " ";
            let selectedEnvironment = " ";
            let selectedArt = " ";
            if (this.selectedLighting && this.selectedLighting.length > 0) {
                selectedLighting = ` Lighting: ${this.selectedLighting.join(" ")}. `;
            }
            if (this.selectedEnvironment && this.selectedEnvironment.length > 0) {
                selectedEnvironment = ` Scenery: ${this.selectedEnvironment.join(" ")}. `;
            }
            if (this.selectedArtStyle && this.selectedArtStyle.length > 0) {
                selectedArt = `${this.selectedArtStyle.join(" ")}`;
            }
            let shotAngle = this.shotDetails.angle;
            let shot = this.shotDetails.shot;
            let created = new Date();
            let text;

            text = this.promptString;
            const subject = this.prompp;

            let negativePrompts = this.negativePrompts;

            // ...prompts,
            let request = {
                text_prompts: [
                    {
                        text: `${text}`,
                        weight: 3.0,
                    },
                    {
                        text: negativePrompts,
                        weight: -1.0,
                    },
                ],
                cfg_scale: 7,
                clip_guidance_preset: "FAST_BLUE",
                height: 768,
                width: 768,
                samples: 1,
                steps: 50,
                seed: 0,
            };
            this.committedPrompt = request.text_prompts[0].text;
            //FAST_BLUE
            console.error(request.text_prompts[0].text);
            try {
                let imageURL = await this.getSImage(request);

                setTimeout(() => {
                    let currentStyle = this.$store.state.selectedStyle;
                    currentStyle.image = imageURL;
                    // this.addToFirebase('imageStyle', currentStyle);
                    this.imageImg = imageURL;
                    const imageRef = db.collection("imageGenerator").doc();
                    imageRef.set({
                        prompt: request.text_prompts[0].text,
                        shot: shot,
                        angle: shotAngle,
                        image: imageURL,
                        subject: subject,
                        lighting: selectedLighting,
                        environment: selectedEnvironment,
                        artStyle: selectedArt,
                        negativePrompts: negativePrompts,
                        created: created,
                        updated: created,
                    });
                    return;
                }, 50);
            } catch (error) {
                if (error.status === 400) {
                    console.error(
                        "Bad request: A common reason is the prompt exceed the limit of characters allowed by the API",
                    );
                }
                if (error.status === 401) {
                    console.log("Error: Invalid API key");
                } else if (error.status === 403) {
                    console.log("Error: API key has insufficient permissions");
                } else if (error.status === 429) {
                    console.error("Error: API rate limit exceeded");
                } else {
                    console.error("Error: " + error.message);
                }
            }
            return;
        },
        getRandomItem(arr) {
            return arr[Math.floor(Math.random() * arr.length)];
        },
    },
};
</script>
<template>
    <div>
        <div class="x f vh-90 fixheight x f bg-base-300">
            <div class="x y f fc mt-6 max-w-xs px-2" style="max-height: 70vh">
                <div class="">
                    <textarea v-model="prompp" class="f-20" style="font-size: 24px !important" type="text" />
                    <a class="btn btn-primary d-block" @click="genImageSD()">Generate Image</a>
                </div>
                <div class="f-17 f fc mx-auto mb-3 h-auto text-left">
                    <div class="d-block p-relative">
                        <div
                            :class="{
                                'text-red-800': promptString.length + negativePrompts.length > 500,
                                'text-base-700': promptString.length + negativePrompts.length <= 499,
                            }"
                            class="d-block text-red-800">
                            <div class="d-block fwb my-2">
                                Prompt:
                                <div
                                    v-if="promptString.length + negativePrompts.length >= 500"
                                    class="d-inline br-5 f-11 fwb bg-red-500 py-1 px-1 text-snow">
                                    Too long
                                </div>
                            </div>
                            <p class="f-13">{{ (promptString + negativePrompts).slice(0, 500) }}</p>
                        </div>
                    </div>

                    <div class="f p-relative no-gutters scrollPanel h-auto flex-wrap pb-8" style="flex: 1">
                        <ImageGenList
                            v-for="s in imgStyles"
                            :key="randomId()"
                            :selected="selected"
                            :style="s"
                            class="col-6 f m-0 h-auto"
                            kind="art"></ImageGenList>
                    </div>
                </div>
            </div>
            <div
                class="scrollPanel flex-basis mt-5 px-3 pt-3"
                style="height: calc(100vh - 60px); box-sizing: border-box">
                <div class="x">
                    <div class="f x p-relative flex-wrap">
                        <GeneratedImage
                            v-for="(image, index) in generatedImages"
                            :key="image.id"
                            class="d-block p-relative w-20 p-1"
                            :image="image"></GeneratedImage>
                    </div>
                    <StyleBookPagenation
                        :main-array="imageStaging"
                        :selected="selected"
                        kind="scene"></StyleBookPagenation>
                    <StyleBookPagenation
                        :main-array="lightingStyles"
                        :selected="selected"
                        kind="lighting"></StyleBookPagenation>
                    <!--                        <div class="f flex-wrap gap-2">-->
                    <!--                            <ImageGenList-->
                    <!--                                v-for="(s, index) in imageParams.artInspirations"-->
                    <!--                                :key="'d' + index"-->
                    <!--                                :class="{ selected: selected.includes(s) }"-->
                    <!--                                :selected="selected"-->
                    <!--                                :style="s"-->
                    <!--                                kind="art"></ImageGenList>-->
                    <!--                        </div>-->
                </div>
                <!-- <tree-component :node="imageParams" :selected="selected" @toggle-selection="toggleSelection" class="z-5"></tree-component>-->
            </div>
            <div class="scrollSection vh-70 x max-w-sm pt-5">
                <div class="x p-relative">
                    <div class="d-block bg-base-900" style="width: 100%; padding-bottom: 100%; height: 0px">
                        <img :src="imageImg" class="x" />
                    </div>
                    <div class="mt-3">
                        {{ committedPrompt }}
                    </div>
                    <div class="mt-3">
                        <template v-if="selectedArtStyle">
                            <span class="d-block fwb mb-3">Selected:</span>
                            <a
                                v-for="item in selectedArtStyle"
                                class="d-inline-block br-5 mr-2 mb-2 bg-snow px-3 py-2"
                                href="#"
                                @click.prevent="toggleSelection(item, 'art')"
                                >{{ item }}<i class="fas fa-times br-100 small hover:bg-blue-500 ml-2"></i>
                            </a>
                        </template>
                        <span class="d-block fwb mb-3">Camera Shot:</span>
                        <div class="button-group">
                            <div
                                v-for="item in camera.shots"
                                :key="item"
                                :class="{ selected: item === shotDetails.shot }"
                                class="d-inline-block br-5 mr-2 mb-2 bg-snow px-3 py-2"
                                @click.prevent="shotDetails.shot = item">
                                {{ item }}
                            </div>
                        </div>
                        <span class="d-block fwb mb-3">Style:</span>
                        <div class="button-group">
                            <div
                                v-for="item in artMedium.digitalMedium"
                                :key="item"
                                :class="{ selected: item === shotDetails.medium }"
                                class="d-inline-block br-5 mr-2 mb-2 bg-snow px-3 py-2"
                                @click.prevent="shotDetails.shot = item">
                                {{ item }}
                            </div>
                        </div>
                        <span class="d-block fwb mb-3">Camera angle:</span>
                        <div class="button-group">
                            <div
                                v-for="item in camera.angles"
                                :key="item"
                                :class="{ selected: item === shotDetails.angle }"
                                class="d-inline-block br-5 mr-2 mb-2 bg-snow px-3 py-2"
                                @click.prevent="shotDetails.angle = item">
                                {{ item }}
                            </div>
                        </div>
                        <span class="d-block fwb mb-3">Lighting:</span>
                        <a
                            v-for="item in selectedLighting"
                            class="d-inline-block br-5 mr-2 mb-2 bg-snow px-3 py-2"
                            href="#"
                            @click.prevent="toggleSelection(item, 'lighting')">
                            {{ item }}
                            <i class="fas fa-times br-100 small hover:bg-blue-500 ml-2"> </i>
                        </a>
                        <span class="d-block fwb mb-3">Scenery:</span>
                        <a
                            v-for="item in selectedEnvironment"
                            class="d-inline-block br-5 mr-2 mb-2 bg-snow px-3 py-2"
                            href="#"
                            @click.prevent="toggleSelection(item, 'scene')">
                            {{ item }}
                            <i class="fas fa-times br-100 small hover:bg-blue-500 ml-2"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.selected {
    background-color: blue !important;
    color: white !important;
}
</style>
