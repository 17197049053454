<script>
import { nextTick } from 'vue'
var controller = new ScrollMagic.Controller();
export default {
    props: ['link', 'steps', 'title', 'type'],
    data: () => ({
        textScene: null,
        words: null,
        uniqueClass: null,
    }),
    mounted() {
        if (this.title) {
            this.getWords();
        }
        this.uniqueClass = `a${this.randomId()}`;
    },
    methods: {
        randomId() {
            let s4 = () => {
                return Math.floor((1+Math.random()) * 0x10000)
                    .toString(16)
                    .substring(1);
            };
            return 'b'+s4()+s4()+'-'+s4()+'-'+s4()+'-'+s4()+'-'+s4()+s4()+s4();
        },
        getWords() {
            if (this.title) {
                this.words = this.title.split(' ');
                console.log(this.title.split(' '));
            }
            nextTick(() => {
                this.initScroll();
            });
        },
        initScroll() {
            if (this.textScene) {
                this.textScene.destroy(true);
                this.textScene = null;
            }
            const uniqueTextItem = `.${this.uniqueClass} .text-item`;
            const fadeUpText = new TimelineMax().add([
                TweenMax.staggerFromTo(uniqueTextItem, 1, {
                    bottom: -50,
                    opacity: 0,
                }, {
                    opacity: 1,
                    bottom: 0,
                    ease: Back.easeOut
                }, 0.3)
            ]);
            // build scene
            var self = this;
            const textElement = self.$refs.textBlock;
            this.textScene = new ScrollMagic.Scene({
                triggerElement: $(textElement),
                triggerHook: 1,
                duration: $(window).height() / 2,
            }).setTween(fadeUpText).addTo(controller);
        },
    },
    beforeDestroy() {
        const self = this;
        if (this.textScene) {
            this.textScene.destroy(true);
            this.textScene = null;
        }
    }
};
</script>
<template>
    <component :is="type">
        <span ref="textBlock" class="p-relative d-block" :class="uniqueClass">
            <span class="text-item p-relative d-inline-block" v-for="word in words">{{ word + '&nbsp;'}} </span><span> </span>
        </span>
    </component>

</template>
<style lang="scss" scoped>
.section-header{
    font-size: 2vw!important;
}

.text-block{

    p{
        font-size: calc(.5rem + 3vw) !important;
        text-align: center;

        &.smallText{
            font-size: calc(.5rem + 1vw) !important;
        }

    }
}
</style>
