<script setup>

import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import AdminGenrePanel from "@/components/music/AdminGenrePanel.vue";
import AdminArtistCard from "@/components/music/AdminArtistCard.vue";
// import {JsonViewer} from "vue3-json-viewer";


</script>

<script>
import AdminMixins from "@/mixins/AdminMixins.js";

export default {
    data() {
        return {
            pageArtists: [],
            dbGenres: [],
            selectedGenre: {}
        };
    },
    async mounted() {
        await this.getArtists();
        await this.getGenres();
        // this.allArtists = await this.getAnyDBItem('artists');
    },
    mixins: [AdminMixins],
    computed: {
        deduplicatedArray() {
            const artists = [...new Set(this.artists)];
            let formattedArtists = 'artists=( ';
            for (const artist of artists) {
                formattedArtists += `"${artist}" `;
            }
            formattedArtists += ')';

            return formattedArtists;
        },
        artistNames() {
            let names = [];
            if (this.artists && this.artists.length > 0) {
                for (const artist of this.artists) {
                    names.push(artist.name);
                }
            }
            return this.stringArray(names);
        },
        filteredArray1() {
            return this.genres.filter(object => {
                return !this.pageArtists.some(artist => artist.name === object.name);
            });
        },
        filteredGenres() {
            return this.genres.filter(object => {
                if (this.genres.length > 0) {
                    return !this.genres.some(artist => artist.genre === object.name);
                }
                return true;
            });
        },
        similarArtists() {
            let artists = this.pageArtists;

            // Convert names to lowercase and calculate similarity
            artists.forEach(artist => {
                artist.similarity = 0;
                artists.forEach(otherArtist => {
                    if (artist !== otherArtist) {
                        let similarity = 0;
                        for (let i = 0; i < artist.name.length; i++) {
                            if (artist.name[i] === otherArtist.name[i]) {
                                similarity++;
                            }
                        }
                        similarity /= artist.name.length;
                        artist.similarity = Math.max(artist.similarity, similarity);
                    }
                });
            });

            return artists;
        }
    },

    methods: {
        handleItemRemoved(item) {
            this.removeItem('artists', item);
        },
        handleGenreSelected(genre) {
            this.filterArtists(genre);
            console.log(genre);
        },
    }
};
</script>
<template>


    <div class="scrollSection py-8">

        <div class="container-fluid">
            <!--            <JsonViewer class="scrollSection x br-20 z-3 mb-3" style="max-height:300px;" :value="pageArtists" sort theme="dark" @onKeyClick="keyClick"/>-->
            <!--            <json-viewer :json="pageArtists"></json-viewer>-->
            <div class="row no-gutters">
                <admin-genre-panel v-if="genres" :list="genres" :selected="selectedGenre" @genre-selected="handleGenreSelected"></admin-genre-panel>
                <div class="col">
                    <div class="container my-5">
                        <h3>Update {{ selectedGenre }} artists ({{ pageArtists.length }})</h3>
                        <span class="d-block">{{ artistNames }}</span>
                        <button-secondary text="Update genre" class="f-11" @click.prevent="updateGenre(selectedGenre)" @item-removed="handleItemRemoved"></button-secondary>
                    </div>
                    <!--                    <tree-view :data="pageArtists"></tree-view>-->

                    <div class="container x mx-auto mw-650">

                        <div class="row no-gutters">
                            <admin-artist-card :item="artist" v-for="artist in similarArtists"></admin-artist-card>
                            <div class="my-2 col-6 x" v-if="false">

                                <button @click="holdOnToYourButts()" class="btn btn-primary mb-3">Hold on to your butts</button>
                                <!--                    <div v-for="genre in genres" class="x border-top py-2 f">-->
                                <template v-if="false">
                                    <div v-for="genre in pageArtists" class="x border-top py-2 f">
                                        <div class="x">
                                            <h5>{{ genre.name }}</h5>
                                            <div class="f flex-wrap">
                                                <span class="mr-2 mb-0" v-for="keyword in genre.keywords">{{ keyword }}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <button-secondary @click.prevent="fetchArtists(genre.name)" class="f-13" text="Get artists"></button-secondary>
                                        </div>
                                    </div>
                                </template>
                                <template v-if="true">
                                    <div v-for="artist in pageArtists" class="py-2 border-top f w-100 mb-2 x justify-content-between align-content-between">
                                        <div class="x mw-500 f fc x" style="width:100%!important;">
                                            <p class="fwb text-links-800 mb-0 x">{{ artist.name }}</p>
                                            <!--                            <p class="fwb text-links-800 mb-0 x">{{ artist }}</p>-->
                                            <p class="d-block mb-0">{{ artist.genre }}</p>
                                            <div class="f flex-wrap">
                                                <span class="d-inline mr-2" v-for="keyword in artist.keywords">{{ keyword }}</span>
                                            </div>
                                        </div>
                                        <div class="ml-auto align-self-end">
                                            <button-secondary class="d-inline-block f-15" text="Update" @click.prevent="pickArtist(artist)"></button-secondary>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="my-2 col-6 x" v-if="false">
                                <div v-for="artist in genres" v-if="genres" class="py-2 border-top f w-100 mb-2 x justify-content-between align-content-between">
                                    <!--                    <div v-for="artist in pageArtists" class="py-2 border-top f w-100 mb-2 x justify-content-between align-content-between">-->
                                    <div class="x mw-500 f fc x" style="width:100%!important;">
                                        <p class="fwb text-links-800 mb-0 x">{{ artist }}</p>
                                        <!--                            <p class="fwb text-links-800 mb-0 x" v-if="artist.name">{{ artist.name }}</p>-->
                                        <p class="d-block mb-0" v-if="artist.genre">{{ artist.genre.name }}</p>
                                        <div class="f flex-wrap" v-if="artist.keywords">
                                            <span class="d-inline mr-2" v-for="keyword in artist.keywords">{{ keyword }}</span>
                                        </div>
                                    </div>
                                    <div class="ml-auto align-self-end">
                                        <button-secondary class="d-inline-block f-15" text="Remove" @click="removeItem('artists',artist.id)"></button-secondary>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--    <div class="p-fixed p-fill z-3 scrollSection bg-white p-3">-->
    <!--            {{deduplicatedArray}}-->

    <!--    </div>-->
</template>
