<script setup>
import WordFadeup from "@/components/WordFadeup.vue";
</script>
<script>
var controller = new ScrollMagic.Controller();
export default {
    data: () => ({
        teamscene: null,
    }),
    mounted() {
        this.loadHowToScroll();
    },
    beforeDestroy() {
        //     this.scene.destroy()
    },
    methods: {
        loadHowToScroll() {
            var self = this;
            if (this.teamscene) {
                this.teamscene.destroy(true);
                this.teamscene = null;
            }
            const teamtween = new TimelineMax().add([
                TweenMax.staggerFromTo(".stagger-item", 1, {
                        bottom: '-50',
                        opacity: '0',
                    }, {
                        opacity: "1",
                        bottom: '0',
                        ease: Back.easeOut
                    },
                    0.25),
            ]);
            // build scene
            this.teamscene = new ScrollMagic.Scene({
                triggerElement: self.$refs.trigger,
                triggerHook: .7,
                duration: $(window).height() / 2
            }).setTween(teamtween).addTo(controller);

        },
    },
};
</script>
<template>
    <div class="container">
        <div ref="trigger" class="row no-gutters">
            <div class="col-12">
                <h1 class="text-center text-uppercase section-header text-neon mb-5">Team</h1>
            </div>
            <div v-for="item in 8" class="col-6 col-md-4 col-lg-3 text-center p-0 pb-5 stagger-item p-md-4 p-2 mb-0">
                <div class="overflow-hidden d-block ratio-100 p-relative" style="border-radius:200px;">
                    <img class="p-absolute p-fill x" src="https://res.cloudinary.com/bitess/image/upload/v1649123206/samples/Screen_Shot_2022-04-04_at_6.38.28_PM.jpg"/>
                </div>
                <div class="x text-center p-2 mx-auto">
                    <h3 class="fadeInOnly">Team Member</h3>
                    <p class="fadeInOnly f-15 text-uppercase opacity-50">Function</p>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
p{
    text-align: center;
}
</style>
