<script>
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";
import LyricsContainer from "@/components/music/lyricsContainer.vue";
import ClosingSection from "@/components/elements/ClosingSection.vue";
import ListItem from "@/components/music/SongListItem.vue";
import SongList from "@/components/music/SongList.vue";
import MySongsDetails from "@/components/music/MySongsDetails.vue";
import SectionHeader from "@/components/elements/SectionHeader.vue";

export default {
    components: {
        SectionHeader,
        MySongsDetails,
        SongList,
        LyricsContainer,
        ButtonSecondary,
        ButtonPrimary,
        ClosingSection,
        ListItem
    },
    props: ['list'],
    async created() {
        if (this.$route.params && this.$route.params.id) {
            console.error('this.$router.params.id', this.$route.params.id);
            const song = await this.getDBItem('feedItem', null, this.$route.params.id);
            this.item = song;
            console.error(song);
        } else if (this.list && this.list.length && this.list.length > 0) {
            this.item = this.userFeed[0];
        }
    },
    data: () => ({
        debug: false,
        item: null,
    }),
    mounted() {
        // // setTimeout(() => {
        //
        // console.log('songs view mounted');
        // setTimeout(() => {
        //     this.$forceUpdate();
        // }, 200);
    },

    watch: {
        async $route(to, from) {
            if (to.params.id) {
              this.item = await this.getDBItem('feedItem', null, to.params.id);
            }
        },
        mobile: {
            handler() {
                if (this.mobile) {

                } else {
                    this.refreshFeed();
                }
            },
            immediate: true,
        },
        list: {
            handler() {
                if (this.list) {
                    if (!this.mobile) {
                        this.refreshFeed();
                        // this.item = this.list[0];
                    }
                } else {
                    // this.refreshFeed();
                }
            },
        },
    },
    methods: {
        async refreshFeed() {
            // setTimeout(() => {
            if (this.$router.params && this.$router.params.id) {
                const song = await this.getDBItem('feedItem', null, this.$router.params.id);
                this.item = song;
                console.error(song);
            } else if (this.list && this.list.length && this.list.length > 0) {
                this.item = this.userFeed[0];
            }
            // }, 200);

        }
    },
    computed: {
        userFeed() {
            if (this.$store.state.user.feed) {
                return this.$store.state.user.feed;
            } else {
                return false;
            }
        }
    },
};
</script>
<template>
    <div class="bg-base-700 vh-100 z-2 p-relative y" style="max-height:100vh;overflow-y:hidden;">
        <div class="container-fluid m-0 p-0">
            <template v-if="mobile">
                <div v-if="list && !item" key="list" class="x bg-base-800 pt-6 x scrollSection">
                    <song-list :item="item" :list="userFeed"></song-list>
                </div>
                <div v-else-if="item" class="f ais jcc px-3 p-relative" style="box-sizing:border-box;min-height:90vh;max-height:100vh;overflow-y:scroll;">
                    <transition appear enter-active-class="fadeInUpSmooth ease-back duration-2 animated" leave-active-class="ease-back duration-1 fadeOutDown animated">
                        <div class="p-fixed top left right pt-6 bg-base-800 white z-2">
                            <div class="px-3 py-3 f aic jcs line-height-smaller" style="border-top:1px solid rgba(255,255,255,0.3);border-bottom:1px solid rgba(255,255,255,0.3)" @click.prevent="item = null">
                                <i aria-hidden="true" class="fa fa-angle-left mr-2"></i> Songs
                            </div>
                        </div>
                    </transition>

                    <div :key="item.id" class="pb-7 d-block pt-5 x px-0">
                        <lyrics-container v-if="item" :item="item" appear class="pt-8 d-block x mx-auto mw-600 mb-3"></lyrics-container>
                        <my-songs-details :item="item" class="pb-3 d-block"></my-songs-details>
                    </div>
                </div>
                <!--                </transition>-->
            </template>
            <div v-if="!mobile" class="row vh-100 overflow-hidden no-gutters p-relative">
                <template v-if="list">
                    <div v-if="item" class="col-3 pt-6 x vh-100 p-relative align-items-between fc justify-content-between f hidden-md-down bg-base-800 scrollSection">
                        <song-list :item="item" :list="userFeed" class="h-auto flex-grow"></song-list>
                        <div class="p-3 z-1 bg-base-700">Footer</div>
                    </div>
                    <div v-if="item" :key="`a-${item.id}`" class="col f ais jcs px-4 bg-base-900 scrollSection">
                        <div class="py-7 d-block x">
                            <lyrics-container :item="item" class="d-block x mx-auto mw-600"></lyrics-container>
                        </div>
                    </div>
                    <div v-if="item && item.id" :key="item.id" class="col-3 p-3 bg-base-800 d-block h-auto p-relative scrollSection pt-5">
                        <my-songs-details v-if="item" :item="item" class="my-3 d-block"></my-songs-details>
                    </div>
                </template>
            </div>
        </div>
    </div>

</template>
<style lang="scss">
</style>
