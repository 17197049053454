<template>
    <div class="vh-90 f align-content-stretch p-relative x bg-snow dark:bg-base-800">
        <!--        <Notification></Notification>-->
        <DocumentsDrawer :always-open="!mobile" @create-document="createDocument"></DocumentsDrawer>
        <DocumentContainer :key="keyHelper" :document-id="keyHelper"></DocumentContainer>
        <div class="flex-shrink-1 z-0 max-w-xs bg-gray-50"></div>
    </div>
</template>
<script>
import { ref, onMounted } from "vue";

import DocumentContainer from "@/components/documents/DocumentContainer.vue";
import DocumentFooter from "@/components/documents/DocumentFooter.vue";
import DocumentsDrawer from "@/components/documents/DocumentsDrawer.vue";
import Notification from "@/components/Imported/tailwind/Notification.vue";
import { textDocs } from "@/firebase";
import { db, genreRef } from "@/firebase";
import documentManagementMixin from "@/mixins/documentManagementMixin";

import "firebase/storage";
import { doc, Timestamp, getDoc, query, collection, where } from "firebase/firestore";
import { useCollection } from "vuefire";

// let documents = textDocs;
export default {
    components: { DocumentContainer, DocumentsDrawer, DocumentFooter },
    mixins: [documentManagementMixin],
    data() {
        return {
            documents: [],
            document: {},
            localDoc: {},
            localDocuments: {},
        };
    },
    computed: {
        keyHelper() {
            if (this.$route.params.id) {
                return this.$route.params.id;
            } else {
                return "documentHome";
            }
        },
    },
    watch: {
        document: function (val, oldVal) {
            // if (val.sections.length > oldVal.sections.length) {
            //     console.error("new sections!");
            // } else if (val.sections.length < oldVal.sections.length) {
            //     console.error("removed sections!");
            // }
            if (val) {
            }
        },
        $route: {
            // call it upon creation too
            immediate: true,
            async handler(route) {
                if (this.$route.params.id) {
                    this.storeDocId();
                } else {
                    this.docStorageHandler();
                    // let document = await useCollection(documents);
                    console.error(document);
                }
            },
        },
    },
    async mounted() {},
    methods: {
        async createDocument() {
            console.error("saving new deck");
            const timestamp = Date.now();
            let doc = {
                title: "New document",
                created: timestamp,
                updated: timestamp,
                documentType: "textEditor",
                sections: [
                    {
                        content: "New document",
                        tag: "h1",
                        kind: "text",
                        index: 0,
                        favorite: false,
                        id: this.randomId(),
                    },
                    {
                        content: "What do you want to write about?",
                        kind: "text",
                        tag: "p",
                        index: 1,
                        favorite: false,
                        id: this.randomId(),
                    },
                ],
            };
            const networkDoc = await this.addToFirebase("documents", doc);
            console.error(networkDoc.id);
            this.$router.push({ path: "/documents/" + networkDoc.id });
            return;
        },
        selectItem(item) {
            this.document = item;
        },
    },
};
</script>
