export default {
    created: function () {
    },
    data() {
        return {};
    },
    computed: {
        genre() {
            let raw = '';
            let visible = '';
            if (this.promptData.genre) {
                raw = `${this.promptData.genre.name} song`;
                visible = `<em class="prompt-genre">${this.promptData.genre.name}</em> song `;
                return {raw, visible};
            }
            return null;
        },
        promptData() {
            return this.$store.state.promptData;
        },
        sentimentKeywords() {
            let sentimentDetails;
            let keywords;
            if (this.promptData.genre) {
                try {
                    sentimentDetails = this.promptData.genre.keywords;
                    keywords = sentimentDetails;
                    keywords = keywords.join(', ');
                    // return `Song characteristics: ${keywords}`;
                    return `${keywords}`;
                } catch (e) {
                    return '';
                }
            }
            return keywords;
        },
        rhymeSchemes() {
            return [
                ['AABB', 'ABAB', 'AAAX', 'XAXA', 'AAAA'],
                ['AAAA', 'ABAB', 'XAXA', 'AXAA', 'AAXA', 'AAAX', 'XXXX', 'XAXA', 'AXAA', 'AAXA'],
            ];

        },
        artistKeywords() {
            let artistDetails = '';
            let keywords;
            if (this.promptData.artists) {
                // artistDetails = this.artists.find(artist => artist.name === this.promptData.artists);
                artistDetails = this.promptData.artists;
                let sentiment = '';
                if (this.sentimentKeywords && this.sentimentKeywords.length) {
                    sentiment = this.sentimentKeywords;
                }
                if (artistDetails && artistDetails.keywords && artistDetails.keywords.length > 0) {
                    keywords = artistDetails.keywords;
                    keywords = keywords.join(', ');
                    // return `Artist Characteristic: ${keywords}`;
                    return `${keywords}`;
                } else if (this.promptData.artists.keywords) {
                    keywords = this.promptData.artists.keywords;
                    keywords = keywords.join(', ');
                    return `Artist Characteristics: ${keywords}`;
                    // return `${keywords}`;
                }

            }
            return keywords;
        },

        songTitlePrompt() {
            let promptRaw;
            if (this.promptData.topic) {
                let number = 5;
                // promptRaw = 'Written by '+this.promptData.artists.name+', write 10 (1-3 words max) creative '+this.promptData.genre.name+' original song titles. \nAbout: '+this.sentimentKeywords+this.promptData.topic+'\nFormat: ["Song title 1","Song title 2"]';
                promptRaw = `Objective: Generate ${number} original and creative song titles`+'\n'+
                    `Written by: ${this.promptData.artists.name}`+'\n'+
                    `Guide: A mix of 1, 2 and 3 word titles`+`\n`+
                    `Related to: ${this.promptData.topic}`+'\n'+
                    `Genre: ${this.promptData.genre.name}`+`\n`+
                    `${this.sentimentKeywords}\n`+
                    `${this.artistKeywords}`;
            } else {
                // promptRaw = 'Written by '+this.promptData.artists.name+', write 10 (1-3 words max) creative '+this.promptData.genre.name+' original song titles.\nFormat: ["Song title 1","Song title 2"]';
                promptRaw = `Objective: Generate 10 original and creative song titles`+'\n'+
                    `Genre: ${this.promptData.genre.name}\n`+
                    `Guide: A mix of 1,2 and 3 word titles`+`\n`+
                    `Written by: ${this.promptData.artists.name}`+'\n'+
                    `${this.sentimentKeywords}\n`+
                    `${this.artistKeywords}`;
            }
            return promptRaw;
        },
        songTitleFormat() {
            return '\n\nReturn in JSON Format. Example: ["SongTitle1","SongTitle2"]\n';
        },
        songTitlePromptFormatted() {
            let originalPrompt = this.songTitlePrompt;
            return originalPrompt+this.songTitleFormat;
        },
        songConceptPrompt() {
            let number = 3;
            let artist = this.promptData.artists.name;
            let keywords = this.artistKeywords+this.sentimentKeywords;
            let genre = this.promptData.genre.name;
            let topic = '';
            if (this.promptData.topic) {
                topic = `\nRelated to: ${this.promptData.topic}\n`;
            }
            // return 'Inspired by '+this.promptData.artists+', generate 10 original topics for '+this.promptData.genre+' songs.'+this.artistKeywords+'\nFormat: [ "Song topic 1", "Song topic 2" ]';
            let promptRaw = 'Generate a list of 10 original topics for '+artist+' songs.'+keywords+'\nFormat: [ "Song topic 1", "Song topic 2" ]';
            promptRaw = `Objective: Generate ${number} original topics for song lyrics`+'\n'+
                `Genre: ${genre}`+'\n'+
                `Word limit: Keep the concepts short`+`\n`+
                `${this.artistKeywords}\n`+
                `Written by: ${artist}`+'\n'+
                topic+
                `${this.sentimentKeywords}`;

//             promptRaw = `Imagine you are ${artist}. Make up ${number} illustrative topics for ${genre} songs.
// You must be visual with descriptions.
// You must avoid being vague.
// You must pick a metaphor.
// You must pick who the song is about.
// You must describe what the song is about.
// You must use simple words.
// For each song, you must pick a perspective (first person, second person, third person).
// Anything in first person perspective must be written in first person narrative (including "my", "I").
// Each "topic" must be a minimum of two sentences.
// You must tell a story.`+topic;
            promptRaw = `Imagine you are ${artist}. Make up ${number} illustrative topics for ${genre} songs.
You must be visual with descriptions.
You must avoid being vague.
You must pick a metaphor.You must pick who the song is about.
You must describe what the song is about.
You must use simple words. 
Who is the writer speaking to?
Refer to the writer as "author"
Don't use song title in the example.
Limit text of each version to 200 characters.
Include the mood/tone of the song.
Each example must be a minimum of two sentences.
For each song, you must pick a perspective (first person, second person, third person).
You must tell a story.`+topic;

            return promptRaw;
        },
        songConceptPromptFormatted() {
            let originalPrompt = this.songConceptPrompt;
            // Return them in a JSON array using this exact format: [{ "topic": "topic1", "sentiment": 10 }]
            return originalPrompt+`\n\nReturn in JSON Format. Example: ["Concept1","Concept2"]\n`;
        },
        visiblePrompt() {
            let artistVisible = '';
            let aboutVisible = '';
            let locationVisible = '';
            let songNameVisible = '';
            let introVisible = '';
            let genreVisible = '';
            if (this.promptData.artists.name) {
                artistVisible = `<span class="prompt-artist">inspired by artists like <span class="prompt-artist-name">${this.promptData.artists.name}</span></span>`;
            }
            if (this.promptData.name) {
                songNameVisible = ` called <span class="prompt-name">&quot;<span class="prompt-name-title" style="text-transform:capitalize;">${this.promptData.name}</span>&quot;</span>`;
            }
            if (this.promptData.topic) {
                aboutVisible = `about <span class="prompt-topic"> ${this.promptData.topic}.</span> `;
            }
            if (this.promptData.name) {

            }

            let visible = `Write a `+`<em class="prompt-genre">${this.promptData.genre.name}</em> song `+songNameVisible+aboutVisible+locationVisible+artistVisible;
            return visible;
        },
        promptText() {
            let artist = '';
            let about = '';
            let genre = ``;
            let intro = '';
            let title = '';
            let structure = '';
            let format = '';
            let keywords = '';
            let sentiment = '';
            let verse;
            let chorus;
            let chords;
            let chords1;
            let prompt = this.$store.state.promptData;
            let rhymeScheme = '';
            if (prompt.genre.name) {

                intro = `Generate lyrics for a `;
                genre = `${prompt.genre}\n`;
                // console.error(prompt.genre);
                if (prompt.topic) {
                    about = `About: ${prompt.topic}\n`;
                }
                if (prompt.artists) {
                    artist = prompt.artists.name;
                }
                if (this.sentimentKeywords) {
                    sentiment = this.sentimentKeywords;
                }
                if (this.artistKeywords) {
                    keywords = this.artistKeywords;
                }

                if (prompt.name) {
                    title = `\nTitle: ${prompt.name}\n`;
                }

                // const keys = ['C', 'C#', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B'];
                const keys = ['C major', 'G major', 'D major', 'A major', 'E major', 'B major', 'F# major', 'C# major', 'G# major', 'D# major', 'A# major', 'F major', 'C minor', 'G minor', 'D minor', 'A minor', 'E minor', 'B minor', 'F# minor', 'C# minor', 'G# minor', 'D# minor', 'A# minor', 'F minor'];
                const randomKey = keys[Math.floor(Math.random() * keys.length)];

                const structures = [
                    'verse, chorus, verse, bridge, chorus',
                    'verse, pre-chorus, chorus, verse, pre-chorus, chorus, bridge, chorus'
                ];
                structure = `verse, chorus, verse, bridge, chorus`;
                // const randomStructure = structures[Math.floor(Math.random() * structures.length)];
                // console.log(randomKey + randomStructure);
                // structure = randomStructure;
                chords = `"chords":"chord1 chord2"`;
                chords1 = `"chords":"chord3 chord3"`;
                verse = `\{\"section\": \"verse:\",\"lyrics\":\"line1\\nline2\\nline3\\n ...\",`+chords+`\},`;
                chorus = `\{"section\": \"chorus:\",\"lyrics\":\"line1\\nline2\\nline3\\n ...\",`+chords1+`\}`;
                format = `\nReturn in JSON Format. Example: [`+verse+chorus+`]\n`;
                format = 'Return in JSON Format. Example: [\n'+
                    '{\n'+
                    '"section": "verse:",\n'+
                    '"lyrics":"line1\\nline2\\nline3\\n ...",\n'+
                    '"chords":"chord1 chord2"\n'+
                    '},\n'+
                    '{\n'+
                    '"section": "chorus:",\n'+
                    '"lyrics":"line1\\nline2\\nline3\\n ...",\n'+
                    '"chords":"chord3 chord3"\n'+
                    '}\n'+
                    ']';
                let songkey = `\nReturn chords in the key of: ${randomKey}\n`;
                let requirements = songkey;
                // let requirementsV1 = songkey+`Requirements: Very Catchy Chorus lyrics. Easy to remember lyrics. Add variety between verse and chorus. Avoid using the title of the song as the first line of the lyrics. Avoid being too explicit about the subject matter of the song in the first lines of the lyrics. Avoid over-use of iambic pentameter. Explore an irregular rhyme scheme or a non-traditional meter in the lyrics to mix things up. Avoid using the ABAB rhyme pattern in the generated lyrics.\n`;
                // let requirements = `Requirements: Very Catchy Chorus\n`;
                // let rhymeScheme = `Rhyme Schemes: ["AABB","ABAB","ABCB","ABBA","ABAC"]\n`
                // rhymeScheme = `Rhyme Schemes (verse): "AABB" or "ABAB"\nRhyme Schemes (Chorus): Pick from "AABB","ABAB","ABCB","ABBA", or "ABAC"`;
//write a prompt to generate lyrics based on the artist presented and make sure not to include the artist name or song title.
                const newPromptString = ``+
                    `Write a ${artist} song called "${prompt.name}" about ${prompt.topic}.`+`The song should be in the key of ${randomKey}. The song should be represented by the topic, other ${prompt.genre.name} influences, and this collection of keywords: ${keywords} ` +
                    `Sections: ${structure}\n`+
                    format;
                // const newPromptStringV1 = ``+
                //     `Brief: Generate musical lyrics for a catchy ${prompt.genre.name} song\n`+
                //     `Lyrics in the style of: ${artist}\n`+
                //     `${about}`+
                //     `${title}`+
                //     requirements+
                //     rhymeScheme+
                //     metaphors+
                //     keywords+`\n`+
                //     sentiment+` \n`+
                //     `Sections: ${structure}\n`+
                //     format;

                // let raw = intro+artist+genre+songName+about+'.'+location+format;
                let raw = newPromptString;
                let visible = raw;
                return {
                    'visible': visible,
                    'raw': raw,
                };
            }
            return 'nothing yet';
        },
    },
    methods: {}
};
