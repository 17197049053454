<template>
    <div>
        <!--    <div v-for="(style,key) in array" :key="randomId()">-->
        <div class="br-5 border-bottom my-1 px-2 py-2">
            <h5 class="mb-0" @click.prevent="collapse()">{{ style.name }}</h5>
        </div>
        <div v-if="!collapsed">
            <!--            <h5>{{ style.name }}</h5>-->
            <div class="f flex-wrap gap-2">
                <ImageGenList
                    v-for="(s, index) in style.styles"
                    :key="'a' + index"
                    :selected="selected"
                    :kind="kind"
                    :style="s"></ImageGenList>
            </div>
        </div>
    </div>
</template>
<script>
import ImageGenList from '@/components/imageGeneration/ImageGenList.vue';

export default {
    components: { ImageGenList },
    props: {
        array: {},
        style: {},
        kind: {},
        selected: {
            type: Array,
            default: [],
        },
    },
    data: () => ({
        collapsed: false,
        page: 0,
    }),
    methods: {
        collapse() {
            this.collapsed = !this.collapsed;
        },
    },
};
</script>
