<script>

export default {
    components: {},
    data: () => ({}),
    props: ['item','debug'],
    mounted() {},
    methods: {},
    computed: {},
};
</script>
<template>
    <div v-if="debug">
        <p class="white" style="white-space:pre-line;">{{ item.promptText }}</p>
        <p class="white" style="white-space:pre-line;">{{ item.response }}</p>
        <p class="white" style="white-space:pre-line;">{{ item.request.prompt }}</p>
        <span v-if="item.promptData.artists.name" class="d-block">{{ item.promptData.artists.name }}</span>
        <span v-if="item.promptData.artists.keywords " class="d-block">{{ item.promptData.artists.keywords }}</span>
        <span v-if="item.promptData.artists.genre.name" class="d-block">Genre: {{ item.promptData.artists.genre.name }}</span>
    </div>
</template>
<style lang="scss">
</style>
