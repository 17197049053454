<script setup>
import MySongs from "@/components/music/MySongs.vue";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";

</script>

<script>
import firestore, {db} from "@/firebase";
import {collection, addDoc, doc, deleteDoc, getDoc} from "firebase/firestore";

export default {
    data() {
        return {
            pageArtists: [],
            dbGenres: [],
            selectedGenre: {},
        };
    },
    async mounted() {

    },
    computed: {

    },

    methods: {

    }
};
</script>
<template>

</template>
