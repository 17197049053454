// import {Cloudinary} from 'cloudinary-core';
import firebase from "@/firebase";
import {getStorage, ref, uploadString} from "firebase/storage";
import imageMixin from "@/mixins/imageMixin";

export default {
    data: () => ({}),
    mixins: ['imageMixin'],
    computed: {},
    methods: {
        async streamText(prompt, returnString) {
            let streamPrompt = `${prompt}
                            When finished with your response type out "[[DONE]]" on a new line.`;
            const oaiCall = {
                model: "text-davinci-003",
                prompt: streamPrompt,
                temperature: 0.9,
                max_tokens: 500,
                top_p: 0.9,
                frequency_penalty: 0,
                presence_penalty: 0,
                stream: true,
            };
            const self = this;
            console.log(oaiCall);
            try {
                const response = await fetch("http://34.170.111.18/textTest1", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(oaiCall)
                });
                console.log(response);
                const reader = response.body.getReader();
                while (true) {
                    const {value, done} = await reader.read();
                    returnString += new TextDecoder("utf-8").decode(value);
                    if (returnString.includes('[DONE]')) {
                        returnString.replace('[DONE]', '');
                        return returnString
                        return;
                    }
                    if (done) {
                        console.error('FINISHED');
                        break;
                    }
                }
            } catch (e) {
                console.error(e);
            }
        },
        // async streamText(request) {
        //     this.output = '';
        //     let intro = '';
        //     console.error(request);
        //     try {
        //         const response = await fetch("http://34.170.111.18/textTest1", {
        //             // const response = await fetch("http://34.170.111.18/textCompletion1", {
        //             method: 'POST',
        //             headers: {
        //                 'Content-Type': 'application/json'
        //             },
        //             body: JSON.stringify(request)
        //             // body: JSON.stringify({prompt: promptNew})
        //         });
        //         // console.error(response);
        //         const reader = response.body.getReader();
        //         while (true) {
        //             const {value, done} = await reader.read();
        //             this.output += new TextDecoder("utf-8").decode(value);
        //             if (done) {
        //                 console.error('FINISHED');
        //                 break;
        //             }
        //             // console.log('Received', new TextDecoder("utf-8").decode(value));
        //         }
        //     } catch (e) {
        //         console.error(e);
        //     }
        // },
        async getArtistKeywords(artist) {
            let promptV1 = `generate an array of keywords describing ${artist}'s music. In json format.\n Example: ["soulful","funky"]`;
            let prompt = `How would you describe ${artist} lyrics?
             Use 5–10 keywords to: 
             Include their common rhyme scheme and meter.
             Include the perspective to they tend to use (ie. First person, second person, etc).
             Include style.
             Example format: [ "keyword1","keyword2" ] \n`;
            let promptV2 = `How would you describe lyrical characteristics of ${artist}?
Use 5–10 keywords. 
Include the artist's themes
Include the artist's lyrical tone 
Include the artist's lyrical style
Include the artist's Vocabulary
Include the artist's Structure 
Include the artist's Narrative
Include the artist's meter
Include the artist's common rhyme schemes
Include the artist's  common point of view

Example format: [ "keyword1","keyword2" ] 
`;
            // let prompt = this.artistKeywordPromptFormatted;
            var stop = `"]`;
            const request = {
                model: "text-davinci-003",
                prompt: prompt,
                max_tokens: 300,
                temperature: 1,
                top_p: 1,
                frequency_penalty: 0,
                presence_penalty: 0,
                best_of: 1,
                stop: [stop]
            };
            let response = await this.requestFromOpenAI(request, 'Artist Keywords');
            let checkedArray = this.fixArray(response);
            const html = this.jsonString(checkedArray);
            let artistDetails = {name: artist, genre: this.promptData.genre, keywords: html};
            this.$store.commit('setArtist', artistDetails);
            return artistDetails;
        },
        async getArtistsForGenre(genre, exclude, number) {
            let count = 10;
            if (number) {
                count = number;
            }
            let prompt = `List the top ${count} ${genre} music bands/artists. Json format\n Example: ["artist1","artist2"]`;
            if (exclude) {
                prompt = `List the top ${count} ${genre} music bands/artists. \nEXCLUDE:${exclude} \n Json format\n Example: ["artist1","artist2"]`;
            }
            console.error(prompt);
            // const requestV1 = {
            //     model: "text-davinci-003",
            //     prompt: prompt,
            //     max_tokens: 200,
            //     temperature: 0.5,
            //     top_p: 1,
            //     frequency_penalty: 1,
            //     presence_penalty: 0,
            //     best_of: 1,
            //     stop: ["}]"]
            // };
            const request = {
                model: "text-davinci-003",
                prompt: prompt,
                max_tokens: 200,
                temperature: 1,
                top_p: 1,
                frequency_penalty: 1,
                presence_penalty: 0,
                best_of: 1,
                stop: ["}]"]
            };
            let response = await this.requestFromOpenAI(request, 'Get Artists');

            const json = this.jsonString(response);
            this.$store.commit('setGenreArtist', json);
            return json;
        },
        async getLyrics(prompt) {
            console.log(prompt);
            this.setLoadChat(true);
            const request = {
                model: "text-davinci-003",
                prompt: prompt,
                max_tokens: 1000,
                temperature: 0.7,
                top_p: 0.9,
                frequency_penalty: 0.0,
                presence_penalty: 0,
                best_of: 1,
                stop: ["}]"]
            };
            // const requestV3 = {
            //     model: "text-davinci-003",
            //     prompt: prompt,
            //     max_tokens: 1000,
            //     temperature: .3,
            //     top_p: 1,
            //     frequency_penalty: 0.3,
            //     presence_penalty: 0,
            //     best_of: 1,
            //     stop: ["}]"]
            // };
            // const requestV2 = {
            //     model: "text-davinci-003",
            //     prompt: prompt,
            //     max_tokens: 1000,
            //     temperature: 0.5,
            //     top_p: .2,
            //     frequency_penalty: 0.3,
            //     presence_penalty: 0,
            //     best_of: 1,
            //     stop: ["}]"]
            // };
            // const requestv3 = {
            //     model: "text-davinci-003",
            //     prompt: prompt,
            //     max_tokens: 800,
            //     temperature: 0.5,
            //     top_p: .8,
            //     frequency_penalty: 0.3,
            //     presence_penalty: 0,
            //     best_of: 1,
            //     stop: ["}]"]
            // };
            let response = await this.requestFromOpenAI(request, 'Get Lyrics');
            this.saveChatItem(response, request, 'lyrics');
            this.setLoadChat(false);
            return;
            // return console.log(this.textResponse);
        },
        async regenerateLyrics(request) {
            console.log(prompt);
            this.setLoadChat(true);
            let response = await this.requestFromOpenAI(request, 'Get Lyrics');
            this.saveChatItem(response, request, 'lyrics');
            this.setLoadChat(false);
            return;
        },
        async getConcepts() {
            this.setLoadChat(true);
            console.error(this.songConceptPromptFormatted);
            const prompt = this.songConceptPromptFormatted;
            const requestV1 = {
                model: "text-davinci-003",
                prompt: prompt,
                max_tokens: 500,
                temperature: 0.5,
                top_p: 1,
                frequency_penalty: 0.6,
                presence_penalty: 0.5,
                best_of: 1,
            };
            const request = {
                model: "text-davinci-003",
                prompt: prompt,
                max_tokens: 500,
                temperature: 0.7,
                top_p: 0.9,
                frequency_penalty: 0.6,
                presence_penalty: 0.5,
                best_of: 1,
            };
            let response = await this.requestFromOpenAI(request, 'Get Concepts');
            this.saveChatItem(response, request, 'concepts');
            this.setLoadChat(false);
            this.setStep(3);
        },
        async requestSongTitles(text) {
            this.setLoadChat(true);
            const computedPrompt = this.songTitlePromptFormatted;
            let prompt = '';
            if (text) {
                prompt = text;
            } else {
                prompt = computedPrompt;
            }
            const requestV1 = {
                model: "text-davinci-003",
                prompt: prompt,
                max_tokens: 100,
                temperature: 1,
                top_p: 1,
                frequency_penalty: 0.5,
                presence_penalty: -1,
                best_of: 1,
            };
            const request = {
                model: "text-davinci-003",
                prompt: prompt,
                max_tokens: 100,
                temperature: 1,
                top_p: 0.9,
                frequency_penalty: 0.5,
                presence_penalty: 0,
                best_of: 1,
            };
            // this.consoleObject('[SONG TITLE]: Request',request);
            // const response = await window.openai.createCompletion(request);
            const response = await this.requestFromOpenAI(request, 'Song Titles');
            const json = this.jsonString(response, '[GET TITLES]: JSON parse did not work');
            this.saveChatItem(response, request, 'titles',);
            this.$store.commit('setTextResponse', response);

            this.setLoadChat(false);
        },
        async openText(text, sendeer) {
            this.setLoadChat(true);
            console.log(text);
            const request = {
                model: "text-davinci-003",
                prompt: text,
                max_tokens: 100,
                temperature: 1,
                top_p: 0.9,
                frequency_penalty: 0.5,
                presence_penalty: 0,
                best_of: 1,
            };
            const response = await this.requestFromOpenAI(request, 'Testing this');
            const json = this.jsonString(response, '[GET TITLES]: JSON parse did not work');
            this.saveChatItem(response, request, 'chat',);
            this.setLoadChat(false);
        },
        async imagePrompt(text, sendeer) {
            console.log(text);
            const request = {
                model: "text-davinci-003",
                prompt: text,
                max_tokens: 200,
                temperature: 1,
                top_p: 0.9,
                frequency_penalty: 0.5,
                presence_penalty: 0,
                best_of: 1,
            };
            const response = await this.requestFromOpenAI(request, 'Testing this');
            return [response, request];
        },
        async requestFromOpenAI(request, from) {
            this.$store.commit('setFetching', true);
            this.consoleObject(`[REQUEST]: ${from} From OpenAI`, request);
            try {
                const response = await window.openai.createCompletion(request);
                this.$store.commit('setFetching', false);
                this.consoleObject(`[RESPONSE]: ${from} From OpenAI`, response.data.choices[0].text);
                let textResponse = response.data.choices[0].text;
                this.$store.commit('setTextResponse', textResponse);
                return textResponse;
            } catch (error) {
                if (error.status === 401) {
                    console.error("Error: Invalid API key", error.message);
                } else if (error.status === 403) {
                    console.error("Error: API key has insufficient permissions", error.message);
                } else if (error.status === 429) {
                    console.error("Error: API rate limit exceeded", error.message);
                } else {
                    console.log("Error: " + error.message);
                }
            }
        },
        async openAIRequest(payload) {
            const prompt = text;
            console.log(prompt.raw);
            console.log(this.question);
            const response = await window.openai.createCompletion(payload);

            let textResponse = response.data.choices[0].text;
            this.$store.commit('setTextResponse', textResponse);
            const previousQuestion = {
                question: text,
                answer: this.textResponse,
                promptRaw: prompt.raw,
                promptVisual: `${prompt.visible}`,
                promptData: this.promptData,
                promptText: this.promptText,
            };
            this.$store.commit('addToChat', previousQuestion);
            this.question = null;
            return console.log(this.textResponse);
        },
        stability() {


            // Set up image parameters
            const imageParams = new Generation.ImageParameters();
            imageParams.setWidth(512);
            imageParams.setHeight(512);
            imageParams.addSeed(1234);
            imageParams.setSamples(1);
            imageParams.setSteps(50);

            // Use the `k-dpmpp-2` sampler
            const transformType = new Generation.TransformType();
            transformType.setDiffusion(Generation.DiffusionSampler.SAMPLER_K_DPMPP_2M);
            imageParams.setTransform(transformType);

            // Use Stable Diffusion 2.0
            const request = new Generation.Request();
            request.setEngineId("stable-diffusion-v2-0");
            request.setRequestedType(Generation.ArtifactType.ARTIFACT_IMAGE);
            request.setClassifier(new Generation.ClassifierParameters());

            // Use a CFG scale of `13`
            const samplerParams = new Generation.SamplerParameters();
            samplerParams.setCfgScale(13);

            const stepParams = new Generation.StepParameter();
            const scheduleParameters = new Generation.ScheduleParameters();

            // Set the schedule to `0`, this changes when doing an initial image generation
            stepParams.setScaledStep(0);
            stepParams.setSampler(samplerParams);
            stepParams.setSchedule(scheduleParameters);

            imageParams.addParameters(stepParams);
            request.setImage(imageParams);

            // Set our text prompt
            const promptText = new Generation.Prompt();
            promptText.setText(
                "A dream of a distant galaxy, by Caspar David Friedrich, matte painting trending on artstation HQ"
            );

            request.addPrompt(promptText);

            // Authenticate using your API key, don't commit your key to a public repository!
            const metadata = new grpc.Metadata();
            metadata.set("Authorization", "Bearer "+process.env.API_KEY);

            // Create a generation client
            const generationClient = new GenerationService.GenerationServiceClient(
                ApiUrl,
                {}
            );
        }
    }
};
