<script setup>
import { ref } from 'vue';

import ButtonSecondary from '@/components/buttons/buttonSecondary.vue';
import Notification from '@/components/Imported/tailwind/Notification.vue';
import SiteHeader from '@/components/navigaion/SiteHeader.vue';
import contextCursor from '@/mixins/contextCursor';
</script>
<script>
import '@/mixins/contextCursor';

export default {
    data() {
        return {
            configuration: null,
        };
    },
    beforeUnmount() {
        if (typeof window === 'undefined') return;
    },

    mounted() {
        this.setDarkMode();
        // contextCursor();
    },
    methods: {
        setDarkMode() {
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                this.$store.commit('setDarkMode', true);
            } else {
                this.$store.commit('setDarkMode', false);
            }
        },
    },
};
</script>
<template>
    <div class="">
        <SiteHeader class="z-5"></SiteHeader>
        <main>
            <RouterView></RouterView>
        </main>
        <Notification></Notification>
    </div>
</template>

<style lang="scss">
//@import '../public/assets/main.scss';
</style>
