<script>

import ChatBubble from "@/components/chat/ChatBubble.vue";
import DebugChatItem from "@/components/chat/debugChatItem.vue";

export default {
    components: {DebugChatItem, ChatBubble},
    data: () => ({
        debug: false,
        bubbles: [],
        selected: null,
    }),
    props: ['item', 'last', 'index'],
    mounted() {
        console.error('last '+this.last);
        console.error('index '+this.index);
        this.enterMessage(this.item);
    },
    watch: {
        selected(newValue, OldValue) {
            if (newValue) {
                setTimeout(() => {
                    this.scrollChatItem();
                }, 500);
            } else {
                setTimeout(() => {
                    this.scrollChatItem();
                }, 500);
            }

        }
    },
    methods: {
        clickedMessage(string) {
            if (this.item.type === 'titles') {
                this.setSongTitle(string);
            } else if (this.item.type === 'concepts') {
                this.setSongAbout(string);
            }
        },
        setSongTitle(string) {
            this.setSelected(string);
            this.promptData.name = string;
            this.setShowInput(false);
            this.setShowButtons(true);
            this.setStep(5);
            setTimeout(() => {
                this.scrollChatItem();
            }, 500);

            console.log(string);
        },
        setSongAbout(string) {
            this.setSelected(string);
            this.promptData.topic = string;
            this.setShowInput(true);
            this.setStep(4);

            this.setShowButtons(false);
            // this.$nextTick(() => {
            this.requestSongTitles();
            setTimeout(() => {
                this.scrollChatItem();
            },200);
        },
        setSelected(string) {
            // const step = this.item.step;
            this.selected = {message: string};
        },
        unsetSelectedString(type) {
            if (this.item.type == 'titles') {
                this.setStep(4);
            } else if (this.item.type === 'concepts') {
                this.setStep(3);
            }
            const step = this.item.step;
            // this.setStep(step);
            this.selected = null;
        },
        enterMessage(obj) {
            let delay = 300;
            let array;
            const item = this.item;
            if (this.last) {
                if (item.bubbles) {
                    const bubbles = this.item.bubbles;
                    console.error(bubbles);
                    bubbles.forEach((bubble, index) => {
                        setTimeout(() => {
                            const id = this.randomId();
                            let newBubble;
                            if (!bubble.id) {
                                newBubble = {
                                    "message": bubble,
                                    "id": id
                                };
                            } else {
                                newBubble = bubble;
                            }
                            // console.log(newBubble);
                            this.bubbles.push(newBubble);
                            // this.scrollFeedNew()
                            this.scrollChatItem();
                        }, delay);
                        delay += 400;
                    });
                }
            } else {
                const nonAnimatedBubbles = this.item.bubbles;
                nonAnimatedBubbles.forEach((bubble, index) => {
                    const id = this.randomId();
                    let newBubble;
                    if (!bubble.id) {
                        newBubble = {
                            "message": bubble,
                            "id": id
                        };
                    } else {
                        newBubble = bubble;
                    }
                    console.log(newBubble.message);
                    this.bubbles.push(newBubble);
                    // this.scrollFeedNew()
                    this.scrollChatItem();
                });
            }
        },
    },
    computed: {},
};
</script>
<template>
    <div v-if="item" class="f fc jce x">
        <transition enter-active-class="chatBubbleUp ease-back animated" leave-active-class="chatBubbleUp reverse-animation duration-1" mode="out-in">
            <div v-if="!selected">
                <div v-if="step ===3 && last" class="mb-3">
                    <p v-if="promptData.artists.name && !chatMode" class="text-base-500 mx-auto text-left animated d-1 fadeInUpSmooth line-height-small animation f-24">
                        So far, we have a
                        <span class="fwb hover:text-bubble-600 text-base-300" @click.prevent="setStep(1)">{{ promptData.genre.name }}</span> song
                        <span v-if="promptData.name"> called
                            <span class="fwb hover:text-bubble-600 text-base-300" @click.prevent="setStep(2)">"{{ promptData.name }}"</span></span>
                        <span> inspired by
                            <span class="fwb hover:text-bubble-600 text-base-300" @click.prevent="setStep(2)"> {{ promptData.artists.name }}</span></span>
                    </p>
                    <template v-if="item.type ==='titles'">
                        <p>Pick another title below?</p>
                    </template>
                </div>
                <div class="f fc ais jcb mb-4 border-bottom animated swingInUp duration-5 x" appear v-else-if="item.type !=='chat' && !chatMode">
                    <p class="f-24 text-base-400 line-height-small mb-0 text-base-500 pr-3">
                        <span>Here are some suggestions for </span>
                        <span class="fwb white"> {{ item.promptData.genre.name }}</span> song concepts
                    </p>
                    <span class="fwb f-15" @click.prevent="debug = !debug">Debug</span>
                </div>
                <template v-if="item.type === 'chat'">
                    <div class=" py-0 mb-0 my-0 f fc transition-all chat " style="" v-if="item.bubbles" :class="{'aie senderBubble':item.sender,'receivedBubble': !item.sender}">
                        <template v-for="(bubble,index) in bubbles">
                            <!--                <transition duration="100" enter-active-class="swingInUp animated ease-back duration-5" leave-active-class="" name="item">-->
                            <transition enter-active-class="chatBubbleUp animated" leave-active-class="ease-back duration-5 fadeOutDown animated" appear>
                                <chat-bubble v-if="bubble.message && (bubble.message !== '')"
                                             :text="bubble.message.prompt || bubble.message "
                                             class="my-0 chat-bubble chat mw-350"
                                             :key="bubble.id"
                                             @click.prevent="clickedMessage(bubble.message)"></chat-bubble>
                            </transition>

                        </template>
                    </div>
                </template>
                <template v-else>
                    <debug-chat-item :debug="debug" :item="item"></debug-chat-item>
                    <ol class=" pb-3 mb-0 f fc jce transition-all" style="border-bottom:1px solid rgba(255,255,255,0.3);" v-if="item.bubbles">
                        <template v-for="(bubble,index) in bubbles">
                            <!--                <transition duration="100" enter-active-class="swingInUp animated ease-back duration-5" leave-active-class="" name="item">-->
                            <transition enter-active-class="chatBubbleUp animated" leave-active-class="ease-back duration-5 fadeOutDown animated" appear>
                                <chat-bubble v-if="bubble.message && (bubble.message !== '')"
                                             :class="{'[&>div]:bg-bubble d-block':bubble.message === (promptData.name || promptData.topic)}"
                                             :text="bubble.message"
                                             :key="bubble.id"
                                             @click.prevent="clickedMessage(bubble.message)"></chat-bubble>
                            </transition>

                        </template>
                    </ol>
                </template>
            </div>
            <div class="pb-3" v-else style="border-bottom:1px solid rgba(255,255,255,0.2)">
                <p class="f-24 text-base-300">Great! You selected:</p>
                <div class="animated chatBubbleUp ">
                    <div class="animated chatBubble special-bg" v-if="selected.message">
                        <span>{{ selected.message }}</span>
                    </div>
                </div>
                <div class="text-bubble-50 fwb mt-3 d-inline-block" @click.prevent="unsetSelectedString(item.type)">
                    Want to change it?
                </div>
            </div>
        </transition>
    </div>
</template>
<style lang="scss">
.chat{
    &.receivedBubble{
        .chat-bubble{
            .chatBubble{
                background: #00a7dc;
                white-space: normal !important;
            }
        }
    }

    &.senderBubble{
        .chat-bubble{
            .chatBubble{
                background: #ddd !important;
                color: black;
            }
        }
    }
}
</style>
