<template>
    <div
        style="min-width: 275px; max-width: 275px; width: 275px; position: fixed; top: 0px; bottom: 0px"
        class="p-relative x f fc vh-100 sticky-top">
        <div
            :style="`transition:200ms background ease-in;background:${colorDarker};`"
            class="x f fc scrollSection flex-basis h-auto flex-grow gap-3 bg-gray-100 px-3 pt-8 pl-5 scrollbar-hide">
            <h3 class="line-height-smaller text-base-50">Slides</h3>

            <draggable
                tag="transition-group"
                v-model="document.sections"
                enter-active-class="animated swingInUp"
                leave-active-class="animated fadeOutDown"
                group="sections"
                item-key="element.id"
                @end="drag = false"
                @start="drag = true">
                <template #item="{ element, i }">
                    <div :key="`slide+${i}+${document.id}`" :style="animatedList(i)" @click.prevent="clickedSlide(i)">
                        <div
                            :style="`background:${backgroundColor};`"
                            class="br-5 p-relative transition:background br-10 mb-3 scale-75 cursor-pointer shadow hover:scale-75 hover:bg-snow">
                            <PresentationMiniSlide
                                :document="document"
                                :index="i"
                                :slide="element"></PresentationMiniSlide>
                        </div>
                    </div>
                </template>
            </draggable>

            <div class="x mx-auto text-center">
                <ButtonIconWhite
                    class="fas f-20 align-self-center mx-auto cursor-pointer"
                    icon="plus"
                    @click.prevent="addNewSlide(slideCount)"></ButtonIconWhite>
            </div>
        </div>
        <div
            :style="`box-shadow:0px -5px 2px rgba(0,0,0,0.05);transition:200ms background ease-in;background:${colorDarker};`"
            class="x bg-gray-100 p-3 shadow-lg">
            <StartDeckButton class="btn btn-secondary x d-block" @start-new-deck="startNewDeck"></StartDeckButton>
        </div>
    </div>
</template>
<script setup>
import lodash from "lodash";
import draggable from "vuedraggable";
</script>

<script>
import { collection, doc, Timestamp } from "firebase/firestore";
import { useCollection, useDocument } from "vuefire";
import { toRaw } from "vue";
import ButtonIconWhite from "@/components/buttons/buttonIconWhite.vue";
import PresentationMiniSlide from "@/components/Presentations/slides/SlideContainerMini.vue";
import StartDeckButton from "@/components/Presentations/StartDeckButton.vue";
import { db } from "@/firebase";
import documentMixins from "@/mixins/documentMixins";
import presenationMixin from "@/mixins/presentationMixin";
import themeMixins from "@/mixins/themeMixins";
import documentManagementMixin from "@/mixins/documentManagementMixin";

const documents = collection(db, "documents");

export default {
    components: { StartDeckButton, ButtonIconWhite, PresentationMiniSlide, draggable },
    mixins: [documentMixins, presenationMixin, themeMixins, documentManagementMixin],
    data() {
        return {
            showPlaintext: false,
            documents: [],
            drag: false,
            document: {},
        };
    },

    firestore: {
        documents: collection(db, "documents"),
        // document: getDoc(doc(db, 'documents', $route.params.id)),
        // document:useDocument(doc(db, 'settings', this.documentId)),
    },
    computed: {
        slideCount() {
            if (this.document && this.document.sections && this.document.sections.length)
                return this.document.sections.length;
            else {
                return 1;
            }
        },
        storeDoc() {
            return this.$store.state.document;
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler(route) {
                if (this.$route.params.id) {
                    this.$nextTick(() => {
                        this.document = this.$store.state.document;
                    });

                    console.error(this.document);
                    this.$firestoreBind("document", doc(documents, this.$route.params.id));
                }
            },
        },
        // document: {
        //     immediate: false,
        // handler() {
        //     this.$nextTick(() => {
        //         this.document = this.$store.state.document;
        //     });
        // },
    },
    // },
    methods: {
        clickedSlide(index) {
            this.setSectionIndex(index, scroll);
            // this.$emit("scrollToSlide", index);
        },
        startNewDeck() {
            this.$emit("startNewDeck");
        },
        addNewSlide(index) {
            this.document.sections.push({
                title: "New Slide",
                body: "Fill in this slide",
                style: "left",
                createdAt: Timestamp.now(),
                updatedAt: Timestamp.now(),
            });
            this.saveDoc(this.document);
        },
    },
};
</script>
