<script>
import DrumMachine from "@/components/music/drumMachine.vue";

export default {
    components: {DrumMachine},
    data: () => ({
        collapseAlternatives: true,
        mode: {
            name: 'major',
            innerCircleOffset: 3,
            opposingModeName: 'minor',
            stepsToNext: [2, 2, 1, 2, 2, 2, 1],
            chordTypes: [
                {name: 'major', symbol: ''},
                {name: 'minor', symbol: 'm'},
                {name: 'minor', symbol: 'm'},
                {name: 'major', symbol: ''},
                {name: 'major', symbol: ''},
                {name: 'minor', symbol: 'm'},
                {name: 'diminished', symbol: 'dim'}
            ]
        },
        timing: {name: 'Whole Note', multiplyer: 1.0},
        timingOptions: [
            {name: 'Whole Note', multiplyer: 1.0},
            {name: '1/2th Note', multiplyer: 0.5},
            {name: '1/4th Note', multiplyer: 0.25},
            {name: '1/8th Note', multiplyer: 0.125}
        ],
        progression: {name: 'Cliche', steps: [0, 4, 5, 3]},
        scale: [],
        baseNote: null,
        noteModifier: {name: "natural", offset: 0, sign: ".", frontEndSign: ""},
        progressions: [
            {name: 'Alternative', steps: [5, 3, 0, 4]},
            {name: 'Canon', steps: [0, 4, 5, 2, 3, 0, 3, 4]},
            {name: 'Cliche', steps: [0, 5, 2, 6]},
            {name: 'Somber', steps: [0, 5, 3, 4]},
            {name: 'Somber 3', steps: [0, 5, 1, 4]},
            {name: 'Long', steps: [0, 5, 2, 3, 4]},
            {name: 'Endless', steps: [0, 5, 1, 3]},
            {name: 'Energetic', steps: [0, 2, 3, 5]},
            {name: 'Grungy', steps: [0, 3, 2, 5]},
            {name: 'ClicheLong', steps: [0, 0, 0, 0, 4, 4, 4, 4, 5, 5, 5, 5, 3, 3, 3, 3]},
            {name: 'Memories', steps: [0, 3, 0, 4]},
            {name: 'Rebellious', steps: [3, 0, 3, 4]},
            {name: 'Sad', steps: [0, 3, 4, 4]},
            {name: 'Simple', steps: [0, 3]},
            {name: 'Simple 2', steps: [0, 4]},
            {name: 'Twelve Bar Blues', steps: [0, 0, 0, 0, 3, 3, 0, 0, 4, 3, 0, 4]},
            {name: 'Wistful', steps: [0, 0, 3, 5]}
        ],
        allChordsInKey: [],
        mainProgression: [],
        alternatives: [],
        modes: [{
            name: 'major',
            innerCircleOffset: 3,
            opposingModeName: 'minor',
            stepsToNext: [2, 2, 1, 2, 2, 2, 1],
            chordTypes: [
                {name: "major", symbol: ""},
                {name: "minor", symbol: "m"},
                {name: "minor", symbol: "m"},
                {name: "major", symbol: ""},
                {name: "major", symbol: ""},
                {name: "minor", symbol: "m"},
                {name: 'diminished', symbol: 'dim'}]
        },
            {
                name: 'minor',
                innerCircleOffset: 9,
                opposingModeName: 'major',
                stepsToNext: [2, 1, 2, 2, 1, 2, 2],
                chordTypes: [{name: 'minor', symbol: 'm'}, {name: 'diminished', symbol: 'dim'}, {
                    name: 'major',
                    symbol: ''
                }, {name: 'minor', symbol: 'm'}, {name: 'minor', symbol: 'm'}, {
                    name: 'major',
                    symbol: ''
                }, {name: 'major', symbol: ''}]
            }],
        baseNotes: ['C', 'D', 'E', 'F', 'G', 'A', 'B'],
        noteModifiers: [
            {name: 'flat', offset: -1, sign: '-', frontEndSign: '♭'}, {
                name: 'natural',
                offset: 0,
                sign: '.',
                frontEndSign: ''
            },
            {name: 'Sharp', offset: 1, sign: '+', frontEndSign: '♯'}],
        instruments: ['Guitar', 'Piano'],
        instrument: 'Guitar',
        AllNotes: [{freq: 1050, vals: ['C.', 'B+', 'D--']}, {freq: 996, vals: ['C+', 'D-']}, {
            freq: 940,
            vals: ['D.', 'C++', 'E--']
        }, {freq: 887, vals: ['D+', 'E-', 'F--']}, {freq: 837, vals: ['E.', 'F-', 'D++']}, {
            freq: 790,
            vals: ['F.', 'E+', 'G--']
        }, {freq: 746, vals: ['F+', 'G-']}, {freq: 704, vals: ['G.', 'F++', 'A--']}, {
            freq: 665,
            vals: ['G+', 'A-']
        }, {freq: 627, vals: ['A.', 'G++', 'B--']}, {freq: 592, vals: ['A+', 'B-', 'C--']}, {
            freq: 559,
            vals: ['B.', 'C-', 'A++']
        }]
    }),
    mounted() {
        this.mode = this.modes[0];
        this.timing = this.timingOptions[0];
        this.progression = this.progressions[2];
        this.baseNote = this.baseNotes[0];
        this.noteModifier = this.noteModifiers[1];
    },
    watch: {
        baseNote(baseNote) {
            this.recalculateScale();
        },
        noteModifier(noteModifier) {
            this.recalculateScale();
        },
        mode(mode) {
            this.recalculateScale();
        },
        scale(scale) {
            this.recalculateAllChordsInKey();
        },
        allChordsInKey(allChordsInKey) {
            this.recalculateMainProgression();
        },
        progression(progression) {
            this.recalculateMainProgression();
        },
        timing(timing) {
            this.recalculateMainProgression();
        },
        mainProgression(mainProgression) {
            this.recalculateAlternatives();
        },
    },
    methods: {
        randomItem(array) {
            return array[Math.floor(Math.random() * array.length)];
        },
        escapeRegExp(str) {
            return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
        },
        getProgressionFromBaseAndMode(baseNote, mode) {
            var absSteps = this.getAbsoluteSteps(mode);
            var scale = this.getScale(baseNote, absSteps);

            var chordsInKey = [];
            for (let i = 0; i < scale.length; i++) {
                const chord = {
                    name: scale[i].name+mode.chordTypes[i].symbol,
                    mode: mode.chordTypes[i],
                    baseNote: scale[i].details
                };
                chordsInKey.push(chord);
            }
            return this.getProgression(this.progression, chordsInKey);
        },
        getAbsoluteSteps(mode) {
            var absoluteSteps = [];
            var rollingTotal = 0;
            for (let i = 0; i < mode.stepsToNext.length; i++) {
                absoluteSteps.push(rollingTotal);
                rollingTotal += mode.stepsToNext[i];
            }
            return absoluteSteps;
        },
        getNoteDetails(noteStr) {
            let noteDetails;
            for (let i = 0; i < this.AllNotes.length; i++) {
                let currentNote = this.AllNotes[i];
                for (let j = 0; j < currentNote.vals.length; j++) {
                    let currentNoteVal = currentNote.vals[j];
                    // console.error(currentNoteVal);
                    if (currentNoteVal === noteStr) {
                        noteDetails = currentNote;
                        break;
                    }
                }
                if (typeof (noteDetails) !== 'undefined') {
                    break;
                }
            }
            return noteDetails;
        },
        getProgression(progressionDetails, allChordsInKey) {
            var progression = [];
            for (var i = 0; i < progressionDetails.steps.length; i++) {
                var step = progressionDetails.steps[i];
                const multiple = 2 / this.timing.multiplyer;
                for (let j = 0; j < multiple; j++) {
                    progression.push(allChordsInKey[step]);
                }
            }
            return progression;
        },
        prettifyNoteStr(noteStr) {
            var prettyNote = noteStr;

            for (let i = 0; i < this.noteModifiers.length; i++) {
                const noteModifier = this.noteModifiers[i];
                if (prettyNote.indexOf(noteModifier.sign) !== -1) {
                    if (noteModifier.sign == "") {
                        prettyNote = prettyNote.replace(new RegExp(this.escapeRegExp(noteModifier.sign), 'g'), noteModifier.frontEndSign);
                    } else {
                        prettyNote = prettyNote.replace(new RegExp(this.escapeRegExp(noteModifier.sign), 'g'), noteModifier.frontEndSign);
                    }
                }
            }
            return prettyNote;
        },

        getScale(rootNoteStr, steps) {
            // This will return an array of notes representing a scale.
            // baseNote and returned notes should be something like 'C+', 'C-' or 'C.'
            // absoluteSteps is a 0-based array of notes.
            const rootNoteDetails = this.getNoteDetails(rootNoteStr);
            const notesInOrder = this.getAllNotesInOrder(rootNoteDetails);

            // full note details for the scale.
            const detailScale = [];
            for (let i = 0; i < steps.length; i++) {
                detailScale.push(notesInOrder[steps[i]]);
            }

            // just the string values of the notes in the scale.
            const strScale = [];

            let currentLetter = rootNoteStr.charAt(0);

            for (let i = 0; i < detailScale.length; i++) {
                const noteDetails = detailScale[i];
                if (noteDetails) {
                    for (let j = 0; j < noteDetails.vals.length; j++) {
                        const noteStr = noteDetails.vals[j];
                        if (noteStr.charAt(0) === currentLetter) {
                            const prettyNoteStr = this.prettifyNoteStr(noteStr);
                            const note = {name: prettyNoteStr, details: noteDetails};
                            strScale.push(note);
                        }
                    }
                }
                // increment the letter.
                if (currentLetter === 'G') {
                    // lazy way to loop around.
                    currentLetter = 'A';
                } else {
                    currentLetter = String.fromCharCode(currentLetter.charCodeAt(0)+1);
                }
            }
            return strScale;
        },

        getAllNotesInOrder(rootNoteDetails) {
            var orderedNotes = [];
            // loop through AllNotes until we find the startFreq.
            for (let i = 0; i < this.AllNotes.length; i++) {
                var note = this.AllNotes[i];
                if (note.freq === rootNoteDetails.freq) {
                    var startIndex = i;
                    var j = 0;
                    while (j < this.AllNotes.length) { // loop around to the start of the array if necessary.
                        var trueIndex = startIndex+j;
                        if (trueIndex >= this.AllNotes.length) {
                            trueIndex -= this.AllNotes.length;
                        }
                        orderedNotes.push(this.AllNotes[trueIndex]);
                        j++;
                    }
                    break; // found the startFreq, break the 'for'.
                }
            }
            return orderedNotes;
        },

        getAllNotesInOrderOld(rootNoteDetails) {
            let orderedNotes = [];
            // loop through AllNotes until we find the startFreq.
            for (let i = 0; i < this.AllNotes.length; i++) {
                let note = this.AllNotes[i];
                if (note.freq === rootNoteDetails.freq) {
                    let startIndex = i;
                    let j = 0;
                    while (j < this.AllNotes.length) { // loop around to the start of the array if necessary.
                        let trueIndex = startIndex+j;
                        if (trueIndex >= this.AllNotes.length) {
                            trueIndex -= this.AllNotes.length;
                        }
                        orderedNotes.push(this.AllNotes[trueIndex]);
                        j++;
                    }
                    break; // found the startFreq, break the 'for'.
                }
            }
            return orderedNotes;
        },
        playChordAfterDelay(chord, delay) {
            setTimeout(() => {
                this.playChord(chord);
            }, delay);
        },
        rotateCircleOfFifths(circleOfFifths, offset) {
            var index = offset;
            var newCircle = [];
            while (newCircle.length !== circleOfFifths.length) {
                if (index === circleOfFifths.length) {
                    index = 0;
                }
                newCircle.push(circleOfFifths[index]);
                index++;
            }
            return newCircle;
        },
        getCircleOfFifths(startNote, notes) {
            // start circle with the root note.
            var circle = [startNote];
            while (circle.length !== 12) {
                // get the note 7 steps after the latest one in the circle.
                var notes = this.getAllNotesInOrder(circle[circle.length-1]);
                circle.push(notes[7]);
            }
            return circle;
        },
        notesInChord(chord) {
            let baseNoteIndex = -1;
            let scale = this.scale;
            // console.table(scale);
            for (let i = 0; i < scale.length; i++) {
                let note = scale[i];
                // console.error(chord)
                if (chord) {
                    if (note.details.freq === chord.baseNote.freq) {
                        baseNoteIndex = i;
                        break;
                    }
                }
            }
            if (baseNoteIndex >= 0) {
                // get [0, 2, 4] of  this.scale, starting at base note
                const root = scale[baseNoteIndex];
                let notesInChord = [root];
                let second = baseNoteIndex+2;
                if (second >= scale.length) {
                    second = second-scale.length;
                }
                notesInChord.push(scale[second]);
                let third = baseNoteIndex+4;
                if (third >= scale.length) {
                    third = third-scale.length;
                }
                notesInChord.push(scale[third]);
                return notesInChord;
            } else {
                return []; // this is weird.
                // If the base note _isn't_ found, we've got an off-key chord.
                // get [0, 4, 7] of allNotes

                let allNotes = this.getAllNotesInOrder(chord.baseNote);

                let notesInChord = [];
                notesInChord.push(allNotes[0]);
                notesInChord.push(allNotes[4]);
                notesInChord.push(allNotes[7]);

                let formattedNotes = [];

                for (let i = 0; i < notesInChord.length; i++) {
                    let note = notesInChord[i];
                    for (let j = 0; j < note.vals.length; j++) {
                        let noteVal = note.vals[j];
                        if (chord.name.charAt(0) === noteVal.charAt(0)) {
                            let name = this.prettifyNoteStr(noteVal);
                            formattedNotes.push({name: name, details: note});
                            break;
                        }
                    }
                    if (formattedNotes.length <= i) {
                        let noteVal = note.vals[0];
                        let name = this.prettifyNoteStr(noteVal);
                        formattedNotes.push({name: name, details: note});
                    }
                }
                return formattedNotes;
            }
        },
        recalculateAlternativesOld() {
            const alternatives = [];
            const rootNoteStr = `${this.baseNote}${this.noteModifier.sign}`;
            const rootNoteDetails = this.getNoteDetails(rootNoteStr);
            const orderedNotes = this.getAllNotesInOrder(rootNoteDetails);
            const alt1Base = innerCircle[0].vals[0];
            for (let i = 0; i < [alt2Base, alt3Base].length; i++) {
                const baseNote = [alt2Base, alt3Base][i];
                const altProg = this.getProgressionFromBaseAndMode(baseNote, this.mode);
                alternatives.push(altProg);
            }
            this.alternatives = alternatives;
        },
        randomizeProgression() {
            this.noteModifier = this.noteModifiers[1];
            this.progression = this.randomItem(this.progressions);
            this.baseNote = this.randomItem(this.baseNotes);
            this.timing = this.randomItem(this.timingOptions);
            this.mode = this.randomItem(this.modes);
            this.instrument = this.randomItem(this.instruments);
        },
        recalculateScale() {
            var rootNoteStr = `${this.baseNote}${this.noteModifier.sign}`;
            var absSteps = this.getAbsoluteSteps(this.mode);
            this.scale = this.getScale(rootNoteStr, absSteps);
        },
        recalculateAlternatives() {
            var alternatives = [];
            var rootNoteStr = `${this.baseNote}${this.noteModifier.sign}`;
            var rootNoteDetails = this.getNoteDetails(rootNoteStr);
            var orderedNotes = this.getAllNotesInOrder(rootNoteDetails);
            var circleOfFifths = this.getCircleOfFifths(rootNoteDetails, orderedNotes);
            var innerCircle = this.rotateCircleOfFifths(circleOfFifths, this.mode.innerCircleOffset);
            var alt1Base = innerCircle[0].vals[0]; // first note option in the first note.
            let oppMode;
            for (var i = 0; i < this.modes.length; i++) {
                var mode = this.modes[i];
                if (mode.name === this.mode.opposingModeName) {
                    oppMode = mode;
                }
            }
            var altOpposite = this.getProgressionFromBaseAndMode(alt1Base, oppMode);
            alternatives.push(altOpposite);
            var alt2Base = circleOfFifths[1].vals[0]; // +1
            var alt3Base = circleOfFifths[circleOfFifths.length-1].vals[0]; // -1
            for (var i = 0; i < [alt2Base, alt3Base].length; i++) {
                var baseNote = [alt2Base, alt3Base][i];
                var altProg = this.getProgressionFromBaseAndMode(baseNote, this.mode);
                alternatives.push(altProg);
            }
            this.alternatives = alternatives;
        },
        recalculateMainProgression() {
            const rootNoteStr = `${this.baseNote}${this.noteModifier.sign}`;
            const progression = this.getProgressionFromBaseAndMode(rootNoteStr, this.mode);
            this.mainProgression = progression;
        },
        recalculateAllChordsInKey() {
            var allChordsInKey = [];
            for (let i = 0; i < this.scale.length; i++) {
                let chordName = this.scale[i].name+this.mode.chordTypes[i].symbol;
                let chord = {name: chordName, mode: this.mode.chordTypes[i], baseNote: this.scale[i].details};
                allChordsInKey.push(chord);
            }

            this.allChordsInKey = allChordsInKey;
        },
        chordAudioPaths(chord) {
            var paths = [];
            paths.push('/public/assets/audio/piano/'+chord.baseNote.freq+'.'+chord.mode.name+'.mp3');
            return paths;
        },
        playChord(chord) {
            const chordClass = `${chord.baseNote.freq}${chord.mode.name}`;
            const audioElem = document.getElementsByClassName(chordClass)[0];
            audioElem.load();
            audioElem.currentTime = 0;
            let speedMultiple = 1 * (1 / this.timing.multiplyer);
            console.error(speedMultiple);
            audioElem.playbackRate = speedMultiple;
            audioElem.play();
            chord.isPlaying = true;

            const endedHandler = (e) => {
                chord.isPlaying = false;
            };
            audioElem.addEventListener('ended', endedHandler);
        },
        playProgression(progression) {
            const secondsDelay = 1;
            const timing = this.timing.multiplyer;
            console.error(this.timing.multiplyer);
            console.error(this.timing);
            for (let i = 0; i < progression.length; i++) {
                let delay = (i * (secondsDelay * 1000));
                const chord = progression[i];
                this.playChordAfterDelay(chord, delay * timing);
            }
        }
    },
    computed: {
        buttonClass() {
            return 'py-1 px-3 m-0 border-solid border-bubble border-1 border-l-0 border-r-2 last:border-r-1 first:border-l-2 br-0 last:rounded-r-lg first:rounded-l-lg';
        }
    }
};
</script>
<template>
    <div class="container">
        <div class="">
            <tempate >
                <div class="">
                    <div class="f fc gap-3 ">
                        <div class="x form-group ">
                            <label>Name</label>
                            <select class=" form-select" v-model="progression">
                                <option v-for="p in progressions" :key="p.name" :value="p">{{ p.name }}</option>
                            </select>
                        </div>
                        <div class="x form-group ">
                            <label>Key</label>
                            <select v-model="baseNote">
                                <option v-for="bn in baseNotes" :key="bn" :value="bn">{{ bn }}</option>
                            </select>
                        </div>
                        <div class="x form-group ">
                            <label>Timing</label>
                            <select v-model="timing">
                                <option v-for="t in timingOptions" :key="t" :value="t">{{ t.name }}</option>
                            </select>
                        </div>
                        <div class="x form-group ">
                            <label>Sharp or flat?</label>
                            <select v-model="noteModifier">
                                <option v-for="m in noteModifiers" :key="m.frontEndSign" :value="m">{{ m.name }}</option>
                            </select>
                        </div>
                        <div class="x form-group ">
                            <label>Major or Minor</label>
                            <select v-model="mode">
                                <option v-for="m in modes" :key="m.name" :value="m">{{ m.name }}</option>
                            </select>
                        </div>
                    </div>
                    <button class="btn f-13 py-0 pl-1" @click.prevent="randomizeProgression()">
                        <i class="fa fa-sync p-2"></i> Randomize!
                    </button>
                </div>
                <div class="f gap-3 py-3">
                    <div class="x">
                        <h4>Main Progression</h4>

                        <div class="f aic jcs">
                            <button class="btn width-50 height-50 f aic jcc mr-2" @click="playProgression(mainProgression)">
                                <i class="fa fa-play"></i></button>
                            <div class="mw-400 x f">
                                <template v-for="(chord, index) in mainProgression">
                                    <button @click.prevent="playChord(chord)" class="x" :class="[{'bg-bubble scale-110 white d-block':chord.isPlaying },buttonClass]" v-if="chord !== mainProgression[index-1]">
                                        {{ chord.name }}
                                        <audio :class="chord.baseNote.freq + chord.mode.name" preload>
                                            <source v-for="audioPath in chordAudioPaths(chord)" :src="audioPath" :key="audioPath"/>
                                        </audio>
                                    </button>
                                </template>
                            </div>
                        </div>


                        <div v-for="(chord, index) in mainProgression" :key="index" v-if="false">
                            <div v-if="chord !== mainProgression[index-1]" class="f">
                                <p class="mr-1">Notes:</p>
                                <div class="d-inline border mr-1" :class="{'bg-bubble scale-110':note.isPlaying }" v-for="(note, index) in notesInChord(chord)" :key="index"> {{ note.name }}</div>
                            </div>
                        </div>

                        <div class="py-3" @click.prevent="collapseAlternatives = !collapseAlternatives">
                            <span v-if="collapseAlternatives">This</span>
                            <span v-if="!collapseAlternatives">This</span>
                        </div>
                        <div class="" v-show="!collapseAlternatives">
                            <div v-for="(alt,index) in alternatives" class="py-2">
                                <div class="x f">
                                    <button class="btn f aic jcc br-50 width-50 height-50 mr-2" @click.prevent="playProgression(alt)">
                                        <i class="fa fa-play p-2"></i></button>
                                    <div class="f aic jcs x mw-400">
                                        <template v-for="(c,i) in alt">
                                            <button class="x last:rounded-r-lg" v-if="c !== alt[i-1]" :class="[{'bg-bubble scale-110':c.isPlaying },buttonClass]" @click.prevent="playChord(c)">{{ c.name }}</button>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h4>All Chords</h4>
                        <div class="f mw-450 flex-wrap overflow-hidden border border-2 border-solid border-bubble border-t-0 border-r-0">
                            <button :class="[{'bg-bubble scale-110':c.isPlaying },buttonClass]" class="w-25 br-0" style="border-radius:0px;border-left-width:0px!important;border-bottom-width:0px!important;" v-for="c in allChordsInKey" @click.prevent="playChord(c)">{{ c.name }}</button>
                            <button :class="[{'bg-bubble scale-110':c.isPlaying },buttonClass]" class="w-25 br-0" style="border-radius:0px;border-left-width:0px!important;border-bottom-width:0px!important;" v-for="c in allChordsInKey.slice(0,1)" @click.prevent="playChord(c)">{{ c.name }}</button>
                            <div class="f">
                                <span v-for="n in notesInChord(c)">{{ n.details.vals }}</span>
                            </div>
                        </div>
                    </div>
                    <!--                        {{notesInChord(c)}}-->
                </div>
            </tempate>
<!--                        <drum-machine></drum-machine>-->
        </div>
    </div>
</template>
