<script>
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";

export default {
    components: {ButtonPrimary, ButtonSecondary},
    mounted() {
        if (this.$refs.aboutInput) {
            this.$refs.aboutInput.focus();
        }
    },
    data() {
        return {
            debug: false,
        };
    },
    methods: {

        setSongTitle(suggestion) {
            // this.$emit('setSongTitle', suggestion);
            this.promptData.name = suggestion;
            console.log(suggestion);
        },
        requestTitles() {
            // this.$emit('requestSongTitles');
            this.requestSongTitles();
        },
        setSongAbout(suggestion) {
            this.promptData.topic = suggestion;
            // this.$emit('setSongAbout', suggestion);
        },
        // requestSongConcepts() {
        //     this.$emit('requestSongConcepts');
        // },
        requestLyrics() {
            const promptText = this.promptText.raw;
            this.getLyrics(promptText);
        }
    },
    computed: {},
};
</script>
<template>
    <div class=" bg-base-900 f aic jcc x" style="height:100vh;">
        <div class="x f fc flex-wrap aic jcc p-3 x">
            <h1 class="text-base-300 mx-auto text-center animated fadeInUpSmooth d-3 duration-3 mb-5">
                <span class="floating-item d-block">Let the <span class="special-text">magic</span> begin</span>
            </h1>
            <div class="height-100 mw-750 mb-5">
                <p v-if="promptData.artists.name" class="text-bubble-200 mx-auto text-center animated d-1 fadeInUpSmooth line-height-small animation mb-5 f-24 px-3">
                    So far, we have a
                    <span class="fwb hover:text-bubble-600 text-bubble-400" @click.prevent="setStep(1)">{{ promptData.genre.name }}</span> song inspired by
                    <span class="fwb hover:text-bubble-600 text-bubble-400" @click.prevent="setStep(2)">{{ promptData.artists.name }}.</span> Now let's work on your concept.
                </p>
            </div>
            <!--        <p v-if="artistKeywords">{{artistKeywords}}</p>-->
            <div class="d-block mw-800 x animated d-3 fadeInUpSmooth animation duration-3 p-relative" style="mix-blend-mode: overlay" v-if="step > 6">
                <input v-model="promptData.name" v-if="promptData.name" class="animated fadeInUpSmooth ease-back px-0 pr-5 x py-3 br-10 p-relative f-24 f-md-30 z-4" style="background:rgba(0,0,0,0.0);border:2px solid rgba(255,255,255,0.0);color:white;">
                <textarea v-model="promptData.topic" class="px-4 py-3 br-10 p-relative f-24 f-md-30 z-4 height-200" style="background:rgba(0,0,0,0.1);border:2px solid rgba(255,255,255,0.3);color:white;"></textarea>
                <!--            <div class="p-absolute bottom right pb-4 pb-md-5 pr-2 z-5">-->
                <!--                <div class=" width-30 height-30 rounded-full f aic jcc bg-white shadow-xs shadow-purple-600 hover:bg-purple-100 hover:text-bubble-900 hover:backdrop-opacity-100 transition-all border-light border-y-purple-700 border-5 border-x-base-400 z-5" @click.prevent="refreshTopic()">-->
                <!--                    <i aria-hidden="true" class="fal fa-sync f-15 o-5"></i>-->
                <!--                </div>-->
                <!--            </div>-->
            </div>
            <div class="mw-800 f x fc mx-auto text-center jcc animated d-5 fadeInUpSmooth animation duration-3">
                <button-secondary class="animated d-3 fadeInUpSmooth animation duration-2 mw-400 mx-auto px-3"
                                text="Let's go" @click.prevent="getConcepts()"></button-secondary>
                <!--            <button-secondary class="animated d-3 fadeInUpSmooth animation duration-2"-->
                <!--                              text="Get titles" @click.prevent="requestSongTitles()"></button-secondary>-->
                <!--                                    <button-secondary class="animated d-5 fadeInUpSmooth animation duration-2" text="Get concepts" @click.prevent="getConcepts()"></button-secondary>-->
            </div>
        </div>
    </div>
</template>
<style lang="scss">
.previous-prompt{
    .prompt{
        letter-spacing: -0.05em;
        color: #777777;

        &-genre{
            font-weight: bold;
            color: white;
        }

        &-name{
            color: white;

            &-title{
                font-weight: bold;
            }
        }

        &-topic{
            font-weight: 500;
            font-style: italic;
            color: white;
        }

        &-artist{
            font-size: 15px;
            font-style: italic;
            display: block;
            margin-top: 10px;
            letter-spacing: 0rem;
            opacity: 0.8;
            color: white;

            &-name{
                font-weight: bold;
            }
        }

        &-location{
            font-weight: bold;
            color: white;
        }
    }
}
</style>
