import { globalFirestoreOptions } from 'vuefire';
import { getFunctions } from 'firebase/functions';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/firestore';
// import {getFunctions, httpsCallable} from "firebase/functions";
// import { firestore } from 'firebase/app';

const defaultConverter = globalFirestoreOptions.converter;
globalFirestoreOptions.converter = {
    toFirestore: defaultConverter.toFirestore,
    fromFirestore: (snapshot, options) => {
        const data = defaultConverter.fromFirestore(snapshot, options);
        // add anything custom to the returned object
        data.metadata = snapshot.metadata;
        return data;
    },
};

const apiKey = import.meta.env.VITE_FIREBASE_API_KEY;

const firebaseConfig = {
    apiKey: apiKey,
    authDomain: 'ashermuse.firebaseapp.com',
    databaseURL: 'https://ashermuse-default-rtdb.firebaseio.com',
    projectId: 'ashermuse',
    storageBucket: 'ashermuse.appspot.com',
    messagingSenderId: '478548386549',
    appId: '1:478548386549:web:bbd47b8cd46b9a9d511bbc',
    measurementId: 'G-1685KM949D',
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
// const messaging = firebase.messaging()

import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { getFirestore, collection, onSnapshot, query, where, orderBy } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { getStorage, ref, uploadBytes, uploadString, getDownloadURL } from 'firebase/storage';

const firestore = getFirestore();
const db = firebaseApp.firestore();
export { db };
export const genreRef = collection(db, 'genre');
export const feed = collection(db, 'feedItem');
export const documents = collection(db, 'documents');
export const presentations = query(documents, where('documentType', '==', 'presentation'));
export const textDocs = query(documents, where('documentType', '!=', 'presentation'));
// export const textDocs1 = query(documents, where('documentType', '!=', 'presentation')).orderBy('updated', 'asc');

// export default db;

// const auth = firebase.auth();
const storage = getStorage(firebaseApp);
export { storage };
export default firebaseApp.firestore();
const functions = getFunctions(firebaseApp);
export { functions };
// const getFunctions = {getFunctions};
export { getFunctions };
// export httpCallable from {httpsCallable} ;
export { httpsCallable };
export const googleAuthProvider = new GoogleAuthProvider();
