<script>
export default {
    data: () => ({}),
    props: ['text', 'form','loadingState','btnClass','color'],
    mounted() {},
    computed: {
        btnColor(){
            let buttonColor = "255,255,255";
            if(color){
                buttonColor = color;
            }
            return {
                text:`rgba(${buttonColor},1)`,
                outline:`rgba(${buttonColor},0.3)`
            }
        }
    },
};
</script>
<template>
    <button v-if="form" class="px-4 py-1 my-1 white br-5 form-button small f-13" href="#" style="" :class="{'bg-base-500 disabled':loadingState}">{{ text }}</button>
    <a v-else class="btn px-3 py-2 x my-1 white" href="#" style="border:2px solid rgba(255,255,255,0.3)" :class="[{'bg-base-500 disabled':loadingState},{btnClass:btnClass,'bg-bubble':!btnClass}]">{{ text }}</a>
</template>
<style lang="scss" scoped>
    .form-button{
        border-radius: 5px !important;
        padding:5px;
        display:inline-block!important;
    }
</style>
