<script>
import {mapState} from 'vuex';
import PromptPreviewVisual from "@/components/music/PromptPreviewVisual.vue";
import ChatBubble from "@/components/chat/ChatBubble.vue";
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";
import LyricsContainer from "@/components/music/lyricsContainer.vue";
import LoadingState from "@/components/loaders/LoadingState.vue";
import ChatItem from "@/components/chat/ChatItem.vue";
import ChatLoader from "@/components/chat/ChatLoader.vue";
import ChatInput from "@/components/chat/ChatInput.vue";

export default {
    components: {
        ChatInput,
        ChatLoader,
        ChatItem,
        LoadingState,
        LyricsContainer,
        ButtonPrimary,
        ButtonSecondary,
        ChatBubble,
        PromptPreviewVisual
    },
    created() {
        console.error('feed created');
    },
    mounted() {

    },
    data() {
        return {
            debug: false,
            chatFeed: [],
            chatFeedNew: [],
            inputText: '',
        };
    },
    watch: {
        step: {
            handler(newValue) {
                if (newValue === 3) {
                    console.error(newValue);
                    this.setShowButtons(false);
                    this.setShowInput(true);
                }
                if (newValue === 4) {
                    console.error(newValue);
                    this.setShowButtons(false);
                    this.setShowInput(true);
                }
                if (newValue === 5) {
                    console.error(newValue);
                    this.setShowButtons(true);
                    this.setShowInput(false);
                }
                // if (newValue === 3) {
                //     this.setShowButtons(true);
                //     this.setShowInput(true);
                //     console.error(newValue);
                // }
            },
            immediate: true
        },
    },
    computed: {
        showButtons() {
            return this.$store.state.showButtons;
        },
        showInput() {
            return this.$store.state.showInput;
        },
        showFeed() {
            return this.hasChats;
        },
        lastSong() {
            const itemsArray = this.chatItems;
            let lyricsArray = itemsArray.filter(item => item.lyrics === true);
            let index = lyricsArray.length-1;
            console.error(lyricsArray[index]);
            return lyricsArray[index];
        },
        lastIndex() {
            let lastIndex;
            if (this.chatItems.length > 0) {
                lastIndex = this.chatItems.length-1;
            } else {
                lastIndex = 0;
            }
            return lastIndex;
        }
    },
    methods: {
        async saveSong() {
            console.log(this.lastSong);
            const item = this.lastSong;
            if (item) {
                await this.addToFirebase('feedItem', item);
                this.$router.push({path: '/profile'});
                this.resetChat();
                this.setToggleView(false);
            }

        },
        async lyrics() {
            const promptText = this.promptText.raw;
            this.setLoadChat(true);
            this.setStep(6);
            this.setShowButtons(false);
            this.setShowInput(false);
            const result = await this.getLyrics(promptText);
            this.setShowButtons(true);

        },
        async concepts(string) {
            this.sendMessage(string, true);
            this.setStep(3);
            this.setShowButtons(true);
            this.setShowInput(false);
            await this.getConcepts();
            this.setShowButtons(false);
            this.setShowInput(true);

        },
        sendMessage(prompt, local) {
            let newPrompt = {
                prompt: prompt,
                id: this.randomId(),
                send: true
            };
            this.saveChatItem(newPrompt, newPrompt, 'chat');
            this.inputText = null;
            if (!local) {
                this.openText(prompt);
            }

        },
        scrollFeedNew() {
            const feed = this.$refs.feed;
            document.getElementById('feed').scrollTop = document.getElementById('feed').scrollHeight;
            this.$nextTick(() => {
                feed.scrollTop = feed.offsetHeight;
            });
            console.log('SCROLL!!!');
        },
        requestTitles() {
            this.requestSongTitles();
        },
    },
};
</script>
<template name="chatBubble">
    <transition enter-active-class="fadeInRight ease-back duration-1 animated" leave-active-class="ease-back duration-1 fadeOutRight animated" name="chatBubble">
        <div class="f fc bg-base-700 heightFix x mw-650" v-if="fetching || chatLoading || hasChats">
            <div id="feed" ref="feed" class="scrollSection pt-7 d-flex p-relative" style="flex:1;">
                <div class="h-auto mt-auto d-flex fc flex-1 align-items-start" style="flex:1;align-items:flex-end;">
                    <template v-if="chatItems">
                        <div v-for="(chatItem,index) in chatItems" :key="chatItem.id" class="align-self-end px-3  previous-prompt x mx-auto text-left p-relative" :class="{' pt-3  my-3 border-top':!chatMode}">
                            <chat-item v-if="chatItem.type ==='concepts'" :last="lastIndex === index" :index="index" :key="chatItem.id+index" :item="chatItem"></chat-item>
                            <chat-item v-else-if="chatItem.type ==='titles'" :last="lastIndex === index" :index="index" :key="chatItem.id+index" :item="chatItem"></chat-item>
                            <chat-item v-else-if="chatItem.type ==='chat'" :sent="chatItem.sender" :last="lastIndex === index" :index="index" :key="chatItem.id+index" :item="chatItem"></chat-item>

                            <lyrics-container v-else-if="chatItem.lyrics" :key="chatItem.id+index" :item="chatItem"></lyrics-container>
                        </div>
                        <div class="d-block text-base-500 p-3 mr-3" v-if="step === 5">
                            <h5>Ready for lyrics?</h5>
                            <p class="f-17">You have a song called
                                <span class="text-base-200 fwb">"{{ promptData.name }}"</span>
                                <span class="d-block">inspired by <span class="text-base-200 fwb"> {{ promptData.artists.name }}</span>.</span>
                                <span class="d-block">About:</span>
                                <span class="text-base-200">{{ promptData.topic }}</span></p>
                        </div>
                    </template>
                    <chat-loader v-if="chatLoading"></chat-loader>
                </div>
            </div>
            <div class="px-2 pb-3 x mx-auto text-center f fc mb-3" style="border-top: 1px solid rgba(255,255,255,0.2)">
                <chat-input v-if="showInput"></chat-input>
                <div class="px-2" v-if="showButtons || step === 5">
                    <template v-if="step <= 5">
                        <button-primary btn-class="bg-links-600" :loading-state="fetching" class="mx-auto x animated swingInUp d-1 mt-3 ease-back bg-links-700" text="Generate Lyrics" @click.prevent="lyrics()"></button-primary>
                        <div class="f aic jcb my-1 small mx-auto x">
                            <div class="p-2 animated swingInUp d-7 ease-back" @click.prevent="setChatMode(!chatMode)" style="border:2px solid rgba(255,255,255,0.2);border-radius:100px;">
                                <i class="fa fa-comments f-17" aria-hidden="true"></i>
                            </div>
                            <button-secondary :loading-state="fetching" class="x m-1 small f-17 animated swingInUp d-3 ease-back" text="Edit topic" @click.prevent="setStep(3),setShowButtons(false)"></button-secondary>
                            <button-secondary :loading-state="fetching" class="x m-1 small f-17 animated swingInUp d-5 ease-back" text="Edit name" @click.prevent="setStep(4)"></button-secondary>
                        </div>
                    </template>
                    <template v-else-if="step === 6">
                        <button-primary btn-class="" :loading-state="fetching" class="mx-auto x animated swingInUp d-1 mt-3 ease-back bg-links-500" text="Save and edit" @click.prevent="saveSong()"></button-primary>
                        <div class="f fc aic jcb my-1 small mx-auto x">
                            <button-secondary :loading-state="fetching" class="x m-1 animated swingInUp d-3 ease-back" text="Start over" @click.prevent="resetChat()"></button-secondary>
                            <!--                            <button-secondary :loading="fetching" class="x m-1 animated swingInUp d-5 ease-back" text="Get titles" @click.prevent="requestTitles()"></button-secondary>-->
                        </div>
                    </template>
                </div>
            </div>
        </div>

    </transition>

</template>
