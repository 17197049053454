<script>
import PromptPreviewVisual from "@/components/music/PromptPreviewVisual.vue";
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";
import SectionHeader from "@/components/elements/SectionHeader.vue";
import LyricSection from "@/components/music/lyricSection.vue";

export default {
    components: {LyricSection, SectionHeader, ButtonPrimary, ButtonSecondary, PromptPreviewVisual},
    props: ['item'],
    mounted() {},
    data() {
        return {
            debug: false,
        };
    },
    methods: {},
    computed: {
        lyricsObject(){
            const lyricsObject = this.item;
            return lyricsObject;
        }
    },
};
</script>
<template>
    <div class="">
        <transition appear enter-active-class="swingInUp ease-back duration-5 d-1 animated" leave-active-class="ease-back duration-1 fadeOutDown animated">
            <div v-if="item" class="">
                <section-header :key="item.id" :item="item" class="animated swingInUp ease-back duration-2" title="Lyrics"></section-header>

                <!--                <div v-for="(i,key) in item" class="mb-3">-->
                <!--                    <span class="d-block fwb white">{{ key }}:</span>-->
                <!--                    {{ i }}-->
                <!--                </div>-->
                <!--        <prompt-preview-visual :prompt-data="item.promptData" :prompt-text="item.promptText" :simple="true" class="mb-3 text-bubble-900"></prompt-preview-visual>-->
                <!--                        <div class="f-13 p-3 pt-0 mt-0  border-light white bg-base-500 br-10" style="font-family:monospace;padding:unset;margin:unset;white-space:pre-wrap;">{{ item.answer }}</div>-->
                <div class="pt-0 pt-md-2 f-13 mt-0 white funBackground br-10 swingInUp ease-back duration-2 transition-all d-8 animated" style="padding:unset;margin:unset;white-space:pre-wrap;">
                    <div v-if="item.lyrics" class="p-3 text-base-900 border-light">
                        <template v-if="item.html && item.html.length && (item.html.length >= 3) && (item.html.length < 7)">

                            <lyric-section v-for="i in item.html" :key="item.id" :parent-object="lyricsObject" :item="i"></lyric-section>
                        </template>
                        <template v-else>
                            <div class="text-monospace blend-burn text-base-700">
                                <p>{{ item.answer }}</p>
                            </div>
                        </template>
                    </div>
                    <div class="border-top p-3 f aic jcb">
                        <div>Options</div>
                        <div class="f aic jcc gap-6 f-20 " style="mix-blend-mode:multiply;">
                            <button class=" p-2 bg-bubble-50 text-base-600 hover:text-bubble hover:bg-white width-30 height-30 f aic jcc br-5 " href="#" @click="debug = !debug">
                                <i aria-hidden="true" class="fa fa-cog "></i></button>
                            <button class=" p-2 bg-bubble-50 text-base-600 hover:text-bubble hover:bg-white width-30 height-30 f aic jcc br-5 " href="#" @click="addToFirebase('feedItem',item)">
                                <i aria-hidden="true" class="fa fa-download "></i></button>
                            <button class=" p-2 bg-bubble-50 text-base-600 hover:text-bubble hover:bg-white width-30 height-30 f aic jcc br-5 " href="#" @click="removeFromFirebase('feedItem',item.id)">
                                <i aria-hidden="true" class="fa fa-trash "></i></button>

                        </div>
                    </div>
                </div>

                <button-secondary class="mt-3 f-13 text-links-400" @click.prevent="regenerateLyrics(item.request)" text="Regenerate song"></button-secondary>
                <div class="py-1" @click.prevent="debug = !debug">Debug</div>
                <p v-if="debug" class="white f-11 o-5 p-2" style="font-weight:400;">{{ item.request }}</p>
                <p v-if="debug" class="white f-11 o-5 p-2" style="font-weight:400;">{{ item.response }}</p>
            </div>
        </transition>
    </div>
</template>
<style lang="scss">
.previous-prompt{
    .prompt{
        letter-spacing: -0.05em;
        color: #777777;

        &-genre{
            font-weight: bold;
            color: white;
        }

        &-name{
            color: white;

            &-title{
                font-weight: bold;
            }
        }

        &-topic{
            font-weight: 500;
            font-style: italic;
            color: white;
        }

        &-artist{
            font-size: 15px;
            font-style: italic;
            display: block;
            margin-top: 10px;
            letter-spacing: 0rem;
            opacity: 0.8;
            color: white;

            &-name{
                font-weight: bold;
            }
        }

        &-location{
            font-weight: bold;
            color: white;
        }
    }
}
</style>
