<template>
    <div class="f dark:bg-base-800">
        <DocumentsDrawer :always-open="false" @create-document="createDocument"></DocumentsDrawer>
        <PresentationSlideDrawer
            v-if="!mobile"
            :key="`slides-${$route.params.id}`"
            @scroll-to-slide="scrollToSlide"
            @start-new-deck="startNewDeck"></PresentationSlideDrawer>
        <div style="margin-left: 275px" v-if="!newDeckStarted && !mobile"></div>
        <!--        v-if="document && document.theme"-->
        <div :style="`background:${backgroundColor}`" class="fc align-content-stretch z-0 flex flex-grow">
            <!--                        <streaming-text class="mt-5 "></streaming-text>-->
            <MainPresentationView
                ref="mainPresentationView"
                @start-new-deck="startNewDeck"
                @new-deck="newDeck"
                @new-deck-modal="newDeckModal"
                v-if="document"
                :document-id="documentId"></MainPresentationView>
            <!--            :key="'presentation' - $route.params.id"-->
        </div>
        <Transition enter-active-class="fadeIn animated" leave-active-class="fadeOut animated">
            <CreatePresentationFlow
                v-if="newDeckStarted"
                :presentation-prompt="presentationPrompt"
                @close="newDeckStarted = false"
                @new-deck="newDeck" />
        </Transition>
        <!--      <FloatingMenu-->
        <!--          @start-new-deck="$emit('startNewDeck')"-->
        <!--          @new-deck="$emit('newDeck')"-->
        <!--          :document="document"></FloatingMenu>-->
    </div>
</template>

<script>
import "firebase/storage";

import { collection, doc, Timestamp } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useCollection, useDocument } from "vuefire";

import cursorMixin from "../mixins/cursorMixin.js";
import documentMixin from "../mixins/documentMixins.js";

import DocumentsDrawer from "@/components/documents/DocumentsDrawer.vue";
import Notification from "@/components/Imported/tailwind/Notification.vue";
import CreatePresentationFlow from "@/components/Presentations/CreatePresentationFlow.vue";
import PresentationSlideDrawer from "@/components/Presentations/PresentationSlideDrawer.vue";
import MainPresentationView from "@/components/Presentations/slides/MainPresentationView.vue";
import { db, presentations, textDocs } from "@/firebase";
import documentManagementMixin from "@/mixins/documentManagementMixin";
import imageMixin from "@/mixins/imageMixin";
import presentationMixin from "@/mixins/presentationMixin";
import slideStyleMixin from "@/mixins/slideStyleMixin";
import FloatingMenu from "@/components/Presentations/slides/FloatingMenu.vue";

// const documents = collection(db, 'documents');
let documents = textDocs;
export default {
    components: {
        FloatingMenu,
        PresentationSlideDrawer,
        CreatePresentationFlow,
        DocumentsDrawer,
        MainPresentationView,
    },
    mixins: [cursorMixin, imageMixin, presentationMixin, slideStyleMixin, documentManagementMixin],
    data() {
        return {
            documents: [],
            sectionCount: 0,
            newDeckStarted: false,
            presentationPrompt: null,
            slideCount: null,
            serpResults: [],
            serpQuery: "",
            showDocs: false,
            sections: [],
            document: {},
        };
    },
    firestore: {
        // documents: db.collection('documents').where('documentType', '==', 'presentation').orderBy('updated', 'desc')
    },
    computed: {
        documentId() {
            if (this.$route.params.id) {
                return this.$route.params.id;
            } else {
                this.setFirstDocument();
            }
        },
    },
    watch: {
        $route: {
            async handler(to, from) {
                if (this.$route.params && this.$route.params.id) {
                    this.storeDocId();
                } else {
                    this.getRecentDocument();
                }
                this.$nextTick(async () => {
                    this.$forceUpdate();
                });
            },
            immediate: true,
        },
        document: {
            async handler(val, oldVal) {
        
            },
            deep: true,
            immediate: true,
        },
    },
    created: function () {
        this.bindDocument();
        window.addEventListener("keydown", event => {
            if (event.key === "s" && event.metaKey) {
                event.preventDefault();
                // Trigger the save function for your application
            }
            if (event.key === "n" && event.metaKey) {
                event.preventDefault();
                // Trigger the save function for your application
            }
        });
    },
    mounted() {
        if (!this.$route.params.id) {
            this.setFirstDocument();
        }
    },
    methods: {

        // scrollToSlide(index) {
        //   this.$refs.mainPresentationView.scrollToSlide(index)
        // },
        async setFirstDocument() {
            let newSort = await this.documents.sort((a, b) => {
                return b.updated - a.updated;
            });
            let filteredDocs = newSort.filter(documents => documents.documentType === "presentation");
            const firstDoc = filteredDocs[0];
            this.$router.push({ path: "/presentations/" + firstDoc.id });
        },
        async updateDeckOnURL() {
            if (this.$route.params && this.$route.params.id) {
                // this.$firestoreBind('document', doc(documents, this.$route.params.id));
            } else {
                this.$nextTick(() => {
                    this.setFirstDocument();
                });
            }
            return false;
        },
        bindDocument() {
            if (this.documentId) {
                // this.$firestoreBind('document', doc(documents, this.documentId));
            }
        },
        //         async streamText(prompt) {
        //             this.output = '';
        //             let intro = '';
        //
        //             let slideCount = 4;
        //             let subject = `Write a ${slideCount} slide presentation about ${prompt} in this format: Titles should be 2-5 words, the body (optional) should be informative but under 50 words. The image should be descriptive of the containing image. Style is about the type of slide ( imageLeft, imageRight, fullBleedImage, timeline, matrix, etc). Include a cover, an intro, info slides, and a conclusion. In the style of Steve Jobs
        //
        // Slide 1
        // Title:
        // Body:
        // Image: This is an image of ...
        // Style:
        //
        // ###\n\n
        // `;
        //
        //             const oaiCall = {
        //                 model: "text-davinci-003",
        //                 prompt: subject,
        //                 temperature: 0.7,
        //                 max_tokens: 3000,
        //                 top_p: 1,
        //                 frequency_penalty: 0,
        //                 presence_penalty: 0,
        //                 stream: true,
        //             };
        //
        //             console.error(oaiCall);
        //             try {
        //                 const response = await fetch("http://34.170.111.18/textTest1", {
        //                     method: 'POST',
        //                     headers: {
        //                         'Content-Type': 'application/json'
        //                     },
        //                     body: JSON.stringify(oaiCall)
        //                 });
        //                 // console.error(response);
        //                 const reader = response.body.getReader();
        //                 while (true) {
        //                     const {value, done} = await reader.read();
        //                     this.output += new TextDecoder("utf-8").decode(value);
        //                     if (done) {
        //                         console.error('FINISHED');
        //                         break;
        //                     }
        //                     // console.log('Received', new TextDecoder("utf-8").decode(value));
        //                 }
        //             } catch (e) {
        //                 console.error(e);
        //             }
        //         },
        async newDeck(prompt, kind, slideCount) {
            this.newDeckStarted = false;
            console.error(this.document.id);
            if (kind === "story") {
                console.error(prompt);
                await this.requestShortStoryFromOpenAI(prompt, slideCount);
            } else {
                await this.requestPresentationFromOpenAI(prompt, slideCount);
            }
            // await this.requestPresentationFromOpenAI(prompt, this.document.id);
            console.error(prompt);
            this.$forceUpdate();
        },
        async newDeckModal(prompt, kind, slideCount) {
            console.error("saving new deck");
            const tempId = this.randomId();
            const timestamp = Date.now();
            let doc = {
                title: "New presentation",
                theme: {
                    colors: null,
                    shades: null,
                    why: null,
                },
                id: null,
                created: timestamp,
                updated: timestamp,
                documentType: "presentation",
                sections: [
                    {
                        title: "New slide",
                        imageURL: null,
                        body: "new slide",
                        id: tempId,
                        created: timestamp,
                        updated: timestamp,
                    },
                ],
            };
            const networkDoc = await this.addToFirebase("documents", doc);
            this.$router.push({ path: `/presentations/${networkDoc.id}` });
            console.error(this.document.id);
            if (kind === "story") {
                console.error(prompt);
                if (prompt === "undefined") {
                    return;
                }
                await this.requestShortStoryFromOpenAI(prompt, slideCount);
            } else {
                await this.requestPresentationFromOpenAI(prompt, slideCount);
            }
            // await this.requestPresentationFromOpenAI(prompt, this.document.id);
            console.error(prompt);
            this.$forceUpdate();
        },

        async createDocument(document) {
            const documents = await this.getAnyDBItem("documents", null, "updated");
            this.setDocuments(documents);
        },
    },
};
</script>
