<script>
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";
import ButtonBubbleArray from "@/components/buttons/ButtonBubbleArray.vue";

export default {
    components: {ButtonBubbleArray, ButtonPrimary, ButtonSecondary},
    data() {
        return {
            debug: false,
            dbGenres:null
        };
    },
    methods: {
        selected(genre) {
            this.setGenre(genre);
            this.nextStep();
            // this.getArtists(genre.name);
        }
    },
   async mounted() {
        const genres  = await this.getAnyDBItem('genre');
       setTimeout(() => {
           this.dbGenres = [];
           this.dbGenres = genres;
       }, 500);
    },
    computed: {},
};
</script>
<template>
    <div v-if="dbGenres">
        <div v-if="step === 1" class="animated fadeInUpSmooth ease-back d-5 ">
            <h1 class="text-bubble-900 mx-auto text-center animated fadeInUpSmooth d-3 duration-3 mb-5">
                <span class="floating-item d-block">Choose a genre</span>
            </h1>
            <ul class="mw-1400 text-center animated fadeInUpSmooth d-5 duration-5" v-if="dbGenres">
                <template v-for="(genre,index) in dbGenres">
                    <button-bubble-array v-if="genre && !genre.hidden" @click.prevent="selected(genre)" :text="genre.name" :index="index"></button-bubble-array>
                </template>
            </ul>
        </div>
    </div>
</template>
<style lang="scss">
</style>
