<script>
import ButtonPrimary from "@/components/buttons/buttonPrimary.vue";
import ButtonSecondary from "@/components/buttons/buttonSecondary.vue";
import SongFlow1 from "@/components/music/songFlow1.vue";
import SongFlow2 from "@/components/music/songFlow2.vue";
import SongFlow3 from "@/components/music/songFlow3.vue";
import SongFlowMain from "@/components/music/songFlowMain.vue";
import ButtonIconWhite from "@/components/buttons/buttonIconWhite.vue";
import SongFlow0 from "@/components/music/songFlow0.vue";

export default {
    components: {ButtonIconWhite, ButtonPrimary, ButtonSecondary, SongFlow0, SongFlow1, SongFlow2, SongFlow3, SongFlowMain},
    mounted() {},
    data() {
        return {
            debug: false,
        };
    },
    methods: {

        setSongTitle(suggestion) {
            // this.$emit('setSongTitle', suggestion);
            this.promptData.name = suggestion;
            console.log(suggestion);
        },
        requestTitles() {
            // this.$emit('requestSongTitles');
            this.requestSongTitles();
        },
        setSongAbout(suggestion) {
            this.promptData.topic = suggestion;
            // this.$emit('setSongAbout', suggestion);
        },
        // requestSongConcepts() {
        //     this.$emit('requestSongConcepts');
        // },
        requestLyrics() {
            const promptText = this.promptText.raw;
            this.getLyrics(promptText);
        }
    },
    computed: {
        shrinkContainer() {
            if (this.chatItems && this.chatItems.length && this.chatItems.length > 0) {
                return true;
            } else {
                return false;
            }
        },
        buttonPosition() {
            if (this.mobile) {
                return 'bottom:30px;';
            } else {
                return 'top:50%;';
            }
        }
    },
};
</script>
<template>
    <div :class="{'w-50':shrinkContainer,'x':!shrinkContainer}" class="x">
        <div v-if="step > 1 " :class="{'bottom mb-3 ml-3 p-fixed': mobile}" :style="buttonPosition" class="p-absolute left z-4">
            <div class=" f aic jcc pb-3">
                <button-icon-white icon="angle-left fas " @click.prevent="prevStep()"></button-icon-white>
            </div>
        </div>
        <div v-if="!mobile" class="">

            <song-flow0 class="x p-3 py-7 vh-100 f aic jcc" v-if="step === 0"></song-flow0>
            <song-flow1 class="x p-3 py-7 vh-100 f aic jcc" v-else-if="step === 1"></song-flow1>
            <song-flow2 class="x p-3 py-7 vh-100 f aic jcc fc" v-else-if="step === 2" style="overflow-y:scroll;"></song-flow2>
            <song-flow3 v-else-if="step >= 3"></song-flow3>
<!--            <song-flow-main v-else></song-flow-main>-->
        </div>
        <div v-else-if="!toggleView" class="x">
            <song-flow0 class="x py-7 vh-100" style="min-height:100vh;" v-if="step === 0"></song-flow0>
            <song-flow1 class="x p-3 py-7 aic jcc" v-else-if="step === 1"></song-flow1>
            <song-flow2 class="x p-3 py-7 aic jcc"  v-else-if="step === 2" style="overflow-y:scroll;"></song-flow2>
            <song-flow3 v-else-if="step >= 3"></song-flow3>
<!--            <song-flow-main v-else class="x p-3 d-block x overflow-hidden"></song-flow-main>-->
        </div>
        <div v-if="step <=3 " :class="{'mb-3 mr-3 p-fixed': mobile}" :style="buttonPosition" class="p-absolute right z-4">
            <div class=" f aic jcc pb-3">
                <button-icon-white icon="angle-right fas " @click.prevent="nextStep()"></button-icon-white>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
</style>
