<script setup>
import HeaderTitleSection from '@/components/HeaderTitleSection.vue';
import ContentSection from '@/components/marketing/ContentSection.vue';
import ParagraphSection from '@/components/marketing/ParagraphSection.vue';
import NewSections from '@/components/marketing/scrollSections/NewSections.vue';
import Staking from '@/components/marketing/Staking.vue';
import TextBlockZoom from '@/components/marketing/TextBlockZoom.vue';
</script>
<script>
var controller = new ScrollMagic.Controller();
export default {
    data: () => ({
        teamscene: null,
        navItems: [
            { title: 'Home', path: '/' },
            { title: 'Story', path: '/about' },
            { title: 'Mission', path: '/mission' },
            { title: 'Team', path: '/team' },
            { title: 'Gallery', path: '/gallery' },
            { title: 'FAQ', path: '/faq' },
            { title: 'Careers', path: '/careers' },
            { title: 'Shop', path: '/shop' },
        ],
    }),
    mounted() {
        this.loadHowToScroll();
    },
    beforeUnmount() {
        //     this.scene.destroy()
    },
    methods: {
        loadHowToScroll() {
            var self = this;
            if (this.teamscene) {
                this.teamscene.destroy(true);
                this.teamscene = null;
            }
            const teamtween = new TimelineMax().add([
                TweenMax.staggerFromTo(
                    '.stagger-item',
                    1,
                    {
                        bottom: '-50',
                        opacity: '0',
                    },
                    {
                        opacity: '1',
                        bottom: '0',
                        ease: Back.easeOut,
                    },
                    0.25,
                ),
            ]);
            // build scene
            this.teamscene = new ScrollMagic.Scene({
                triggerElement: self.$refs.trigger,
                triggerHook: 0.7,
                duration: ($(window).height() / 10) * 25,
            })
                .setTween(teamtween)
                .addTo(controller);
        },
    },
};
</script>
<template>
    <div class="white x mt-7" style="min-height: 100vh">
        <!--        <div class="vh-100 x"></div>-->
        <!--        <text-block-zoom title="blah"></text-block-zoom>-->
        <div v-for="section in navItems" class="container">
            <ContentSection
                class="x"
                paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                :title="section.title"></ContentSection>
        </div>
        <HeaderTitleSection
            title="Become the Story."
            :underline="true"
            intro="THE  CINEMATIC UNIVERSE"
            button-text="Explore the Characters"
            route="/"></HeaderTitleSection>
        <ParagraphSection
            paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."></ParagraphSection>
        <ParagraphSection
            paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."></ParagraphSection>
        <HeaderTitleSection
            title="Become the Story."
            :underline="true"
            intro="THE  CINEMATIC UNIVERSE"
            button-text="Explore the Characters"
            route="/"></HeaderTitleSection>
        <ParagraphSection
            paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."></ParagraphSection>
        <HeaderTitleSection
            title="Become the Story."
            :underline="true"
            intro="THE  CINEMATIC UNIVERSE"
            button-text="Explore the Characters"
            route="/"></HeaderTitleSection>
        <Staking></Staking>
        <ParagraphSection
            paragraph="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."></ParagraphSection>
        <div class="container mt-3">
            <div class="row">
                <div v-for="(item, index) in 25" class="col-21 col-md-4 col-lg-4 col-xl-3 p-md-3">
                    <div class="card-container p-relative f fc br-10 stagger-item p-3"
                        style="background: rgba(255, 255, 255, 0.1)">
                        <div class="p-relative ratio-100 mb-2">
                            <div class="card-image">
                                <img :src="`https://res.cloudinary.com//image/upload/w_1000/v1650839881/Characters/avatar-${index}.png`"
                                    class="p-absolute p-fill" />
                            </div>
                        </div>
                        <div class="card-details line-height-smaller px-1 pb-2">
                            <p class="f-24 fwb mb-1">Title</p>
                            <p class="f-15 text-uppercase o-5 mb-0">{{ index }}348</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Staking></Staking>
    </div>
</template>
