<script setup>
import ContentFeed from "@/components/chat/ContentFeed.vue";

import SideMenu from "@/components/music/SideMenu.vue";
// import SongFlow3 from "@/components/songFlow3.vue";
// import SongFlow1 from "@/components/songFlow1.vue";
// import SongFlow2 from "@/components/songFlow2.vue";
// import SongFlowMain from "@/components/songFlowMain.vue";
// import LyricsContainer from "@/components/lyricsContainer.vue";
// import MySongs from "@/components/MySongs.vue";
import SongFlowContainer from "@/components/music/songFlowContainer.vue";
import DebugPrompts from "@/components/debugging/debugPrompts.vue";
import SentimentFilter from "@/components/elements/SentimentFilter.vue";
import ButtonIconWhite from "@/components/buttons/buttonIconWhite.vue";

</script>

<script>


// const response = await openai.listEngines();
// import json from "../../public/assets/topicData.json";
export default {
    data() {
        return {
            // topics,
            question: null,
            songSuggestions: null,
            metaphors: [],
            prompt: "",
            about: '',
            concepts: null,
            location: '',
            // locationIdeas: json.locationIdeas,
            selectedArtist: null,
            selectedStyle: {
                artist: 'John Mayer',
                style: 'Singer-songwriter style'
            },
            // styles,
            refreshing: false,
            showMenu: false,
        };
    },
    async mounted() {
        // const response = await openai.listEngines();
        // this.initOpenAi();
        this.pickTopic();
        this.setStep(1);
        this.$store.commit('setToggleView', false);
    },
    computed: {
        showToggleButton() {
            let toggleView = this.toggleView;
            const chats = this.hasChats;
            const mobile = this.mobile;
            // if(this.debug){
            // return true;
            // }

            if (mobile && chats) {
                return true;
            }
            return false;
        },
        showChat() {
            let toggleView = this.toggleView;
            const chats = this.hasChats;
            const mobile = this.mobile;
            let state;
            // debug
            if (chats) {
                state = true;
            } else {
                state = false;
            }
            if (mobile && chats) {
                if (toggleView) {
                    state = true;
                } else if (!toggleView) {
                    state = false;
                }
            } else if (chats) {
                state = true;
                console.error('showChats');
            } else {
                state = false;
            }
            if (this.chatLoading) {
                state = true;
            }
            return state;
        },
        showFlow() {
            const toggleView = this.toggleView;
            const mobile = this.mobile;
            const chatLoading = this.chatLoading;
            if ((chatLoading||toggleView) && mobile) {
                return false;
            }
            return true;
        },
        shrinkContainer() {
            if (this.chatItems && this.chatItems.length && this.chatItems.length > 0) {
                return true;
            } else {
                return false;
            }
        },
        showSideBar() {
            if (!this.showMenu && !this.toggleView && this.chatItems && this.chatItems.length && this.chatItems.length > 0) {
                return true;
            }
            return false;
        },

    },
    methods: {
        pickRandom(max) {
            var min = Math.ceil(0);
            var max = Math.floor(max) || 10;
            return Math.floor(Math.random() * (max-min+1))+min;
        },
        refreshLocation() {
            let length = 10;
            const array = this.locationIdeas;
            if (array && array.length && array.length > 0) {
                length = (array.length-1);
            }
            const locationIndex = this.pickRandom(length);
            const randomItem = array[locationIndex];
            this.promptData.location = randomItem;
        },
        refreshGenre() {
            const styles = this.styles;
            const songStyle = styles[styleIndex];
            this.promptData.genre = songStyle;
            this.selectGenre(songStyle);
        },
        arrayLength(array) {
            let length;
            if (array && array.length && array.length > 0) {
                length = (array.length-1);
            }
            return length;
        },

        refreshName() {
            const array = this.topics;
            const length = this.arrayLength(array);
            const index = this.pickRandom(length);
            const randomItem = array[index];
            // const topic = this.topics[topicIndex];
            const subArray = randomItem.nameIdeas;
            console.log(subArray);
            const sublength = this.arrayLength(subArray);
            const nameIndex = this.pickRandom(sublength);
            console.log(subArray[nameIndex]);
            this.promptData.name = subArray[nameIndex];
        },
        selectArtist(artist) {
            console.log(artist);
            this.$store.commit('setArtist', artist);
        },
        randomOfClass(className) {
            this.promptData[className] = 'random';
        },
    }
};
</script>
<template>
    <div class="x text-base-600 p-relative funBackground" style="overflow:hidden;">
        <div class="m-0 p-0 f">
             <debug-prompts :open="true" class="z-3 text-base-900"></debug-prompts>
            <!-- <sentiment-filter class="p-fixed p-fill funBackground"></sentiment-filter>-->

            <template v-if="mobile">
                <song-flow-container v-if="showFlow" class="scrollSection fadeInUpSooth animated ease-back" appear></song-flow-container>
                <content-feed appear class=" fadeInUpSooth animated ease-back" v-if="showChat"></content-feed>
            </template>
            <template v-else>
                <div class="scrollSection x" v-if="showFlow">
                    <song-flow-container></song-flow-container>
                </div>
                <content-feed v-if="showChat" class="mw-600"></content-feed>
            </template>
        </div>

        <div v-if="showToggleButton" class="p-fixed right z-3" style="right:15px;top:60px;" @click.prevent="$store.commit('setToggleView',!toggleView)">
            <button-icon-white :state-bool="!toggleView" icon="bars fas " @click.prevent="toggle"></button-icon-white>
        </div>
    </div>

</template>
<style lang="scss">

</style>
