<template>
    <div
        :ref="styledRef"
        class="x br-5 f aic jcc br-5 overflow-hidden bg-base-900"
        style="height: 0px; padding-bottom: 66%">
        <img
            v-if="slide.imageURL"
            :ref="`image-sm-${id}${index}`"
            :src="slide.imageURL"
            class="p-absolute p-fill x y o-cover"
            :class="{ 'o-2': imageStyle }"
            :style="imageStyleText" />
        <div class="p-absolute p-fill f aic jcc">
            <div class="z-3 white p-3 text-center">
                <p v-if="slide.title" :ref="`text-sm-${id}${index}`" class="f-13 fwb line-height-smaller mb-0">
                    {{ slide.title }}
                </p>
                <p v-if="slide.body" class="f-11 o-8 mb-0" style="font-size: 8px">
                    {{ slide.body }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import SlideStyleMixin from '@/mixins/slideStyleMixin';

export default {
    name: 'SlideFullbleedMini',
    mixins: [SlideStyleMixin],
    props: {
        index: {},
        slide: {},
        document: {},
    },
    data: () => ({
        id: null,
        imageStyle: false,
    }),
    computed: {
        styledRef() {
            if (this.imageStyle) {
                return `slide-sm-${this.id}${this.index}`;
            }
        },
        imageStyleText() {
            if (this.imageStyle) {
                return 'mix-blend-mode: luminosity';
            }
        },
    },
    created() {
        this.id = this.randomId();
    },
    mounted() {
        if (this.document.theme) {
            let t = this.document.theme.colors || false;
            if (t) this.textTheme();
        }
    },
};
</script>
