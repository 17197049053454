<template>
    <div v-if="imageTheme" :ref="`slide-${id}${index}`" :class="slideContainerClasses">
        <div class="col-12 vh-0 vh-70 text-left">
            <div v-if="section.imageURL" class="p-absolute p-fill bg-base-700" :style="`mix-blend-mode: ${blendMode}`">
                <img
                    v-if="section.imageURL"
                    class="x y p-absolute p-fill o-cover o-8"
                    style="mix-blend-mode: luminosity"
                    :src="section.imageURL" />
            </div>
            <div :ref="`image-${id}${index}`" class="f aic jcs y p-5">
                <div :ref="`text-${id}${index}`" class="z-5 mx-auto max-w-md text-center">
                    <PresentationSlideText :section="section" :index="index"></PresentationSlideText>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { doc, getDoc, collection, Timestamp } from 'firebase/firestore';
import { useCollection, useDocument } from 'vuefire';

import PresentationSlideText from '@/components/Presentations/slides/PresentationSlideText.vue';
import { db } from '@/firebase';
import SlideStyleMixin from '@/mixins/slideStyleMixin';

export default {
    components: { PresentationSlideText },
    mixins: [SlideStyleMixin],
    props: {
        id: {},
        index: {},
        section: {},
        document: {},
        documentId: {},
        slideContainerClasses: {},
    },
    data: () => ({
        imageDark: false,
    }),
    computed: {
        blendMode() {
            if (this.imageDark) {
                return 'screen';
            } else {
                return 'multiply';
            }
        },
    },
    mounted() {
        if (this.document.theme) {
            let t = this.document.theme.colors || false;
            if (t) this.textTheme();
        }
    },
    methods: {},
};
</script>
