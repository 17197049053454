<template>
    <div :class="slideContainerClasses">
        <div v-if="imageTheme" :ref="`slide-${id}${index}`" :class="slideContainerClasses">
            <div :ref="`image-${id}${index}`" class="col-12 vh-0 vh-90 text-left">
                <div
                    v-if="section.imageURL"
                    class="p-absolute p-fill bg-base-700"
                    :style="`mix-blend-mode: ${blendMode}`">
                    <PresentationImageContainer
                        :full-bleed="true"
                        :document="document"
                        :document-id="documentId"
                        :image-dark="imageDark"
                        :index="index"
                        :section="section"></PresentationImageContainer>
                </div>
                <div :ref="`text-${id}${index}`" class="f aic jcs y p-5">
                    <div class="z-5 white mx-auto max-w-md text-center">
                        <PresentationSlideText :section="section" :index="index"></PresentationSlideText>
                    </div>
                </div>
            </div>
        </div>
        <!--        <div :ref="`slide-${id}${index}`" class="f aic jcs y p-relative x vh-80 p-5">-->
        <!--            <div :ref="`text-${id}${index}`" class="x p-relative mx-auto text-center">-->
        <!--                <div v-if="section.imageURL" class="p-absolute p-fill" :style="`mix-blend-mode: ${blendMode};`">-->
        <!--                    <div class="p-absolute x y o-fit" style="mix-blend-mode: luminosity !important">-->

        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="p-absolute p-fill f aic jcc inset-28">-->
        <!--                    <div class="mx-auto max-w-md">-->
        <!--                        <PresentationSlideText-->
        <!--                            :index="index"-->
        <!--                            class="x y p-absolute p-fill"-->
        <!--                            :document="document"-->
        <!--                            :document-id="document.id"-->
        <!--                            :image-dark="imageDark"-->
        <!--                            :section="section"></PresentationSlideText>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>
<script>
import PresentationImageContainer from "@/components/Presentations/slides/PresentationImageContainer.vue";
import PresentationSlideText from "@/components/Presentations/slides/PresentationSlideText.vue";
import presenationMixin from "@/mixins/presentationMixin";
import SlideStyleMixin from "@/mixins/slideStyleMixin";

export default {
    components: { PresentationImageContainer, PresentationSlideText },
    mixins: [SlideStyleMixin, presenationMixin],
    props: {
        id: {},
        index: {},
        section: {},
        document: {},
        documentId: {},
        slideContainerClasses: {},
    },
    watch: {
        document: {
            handler: function (val, oldVal) {
                if (val.sections !== oldVal.sections) {
                    let t = val.theme.colors || false;
                    // if (t) this.textTheme();
                }
            },
            deep: false,
        },
    },
    data: () => ({
        imageDark: false,
    }),
    computed: {
        blendMode() {
            if (this.imageDark) {
                return "screen";
            } else {
                return "multiply";
            }
        },
        blendModeOpposite() {
            if (!this.imageDark) {
                return "screen";
            } else {
                return "multiply";
            }
        },
    },
    mounted: function () {
        // this.slideColors();
        // this.imageTheme;
        // this.randomImageStyle();
    },
    methods: {
        randomImageStyle() {
            this.imageDark = Math.random() < 0.5 ? true : false;
        },
    },
};
</script>
